import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useScrollToTop } from "../../../hooks";

export default function NavigationContainer({ children }) {
  const { pathname } = useLocation();
  const { scrollToTop } = useScrollToTop();
  const include = [
    "/my-profile",
    "/my-activity",
    "/dashboard",
    "/blogs/",
    "/all",
    "/login",
    "/signup",
    "/learn/thesis",
    "/products",
    "/jobs"
  ];

  useEffect(() => {
    if (include.some((path) => pathname.startsWith(path))) {
      scrollToTop();
    }
  }, [pathname]);

  return children;
}

import React from "react";
import { useNavigationInfo } from "../../../hooks";
import { Button } from "react-bootstrap";

export default function HandleMultipleDeletion({
  isAllSelected,
  handleSelectAll,
  isSelectAllDisabled,
  setShowDeleteModal,
  isDeleteButtonDisabled,
  selected
}) {
  const { isFavourites } = useNavigationInfo();
  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleSelectAll}
        disabled={isSelectAllDisabled}
        style={{ textWrap: "nowrap", fontSize: "90%" }}
      >
        {isAllSelected ? "Deselect All" : "Select All"}
      </Button>
      <Button
        variant="outline-primary"
        onClick={() => setShowDeleteModal(true)}
        disabled={isDeleteButtonDisabled}
        style={{ textWrap: "nowrap", fontSize: "90%" }}
      >
        {isFavourites ? "Remove" : "Delete"} Selected
        <span className="rounded-circle px-2 py-1 fw-bold">{selected.length}</span>
      </Button>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { has, isEmpty, isString, isUndefined } from "lodash";
import { connect } from "react-redux";
import { useScrollToTop } from "hooks";
import { useAuth } from "hooks";
import UserProfileAPIClient from "apiClients/Profile/User";
import DateParser from "date";
import StudentPersonalDetailsAPI from "apiClients/Profile/Student/PersonalDetails";
import { getUserProfilePayload, showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ProfileContact from "forms/ProfileContact";
import DateOfBirth from "components/Inputs/DateOfBirth";
import { EDUCATION_LEVEL } from "constants/Profile/Student";
import { FIELD_OF_STUDIES } from "constants/Profile/Student";
import MINIMUM_PROFILE_COMPLETION from "constants/profile-completion";
import CheckBox from "components/Inputs/CheckBox";
import { ACTIVELY_LOOKING } from "constants/Profile/Student";
import Select from "components/Inputs/Select";
import AddressForm from "forms/Address";
import SocialMediaLinks from "forms/SocialMediaLinks";
import ACTION_TYPES from "constants/action-types";
import { isMobile } from "react-device-detect";
import { scroller } from "react-scroll";

function PersonalDetailsForm({ handleSetValues, user, updateUser }) {
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const { scrollToTop } = useScrollToTop();
  const { validateContactInfo } = useAuth();

  const [showMobileVerifyOTP, setShowMobileVerifyOTP] = useState(undefined);
  const [showSecondaryMobileVerifyOTP, setShowSecondaryMobileVerifyOTP] = useState(undefined);
  const [showEmailVerifyOTP, setShowEmailVerifyOTP] = useState(undefined);
  const [showSecondaryEmailVerifyOTP, setShowSecondaryEmailVerifyOTP] = useState(undefined);

  const isStudent = watch("is_student");
  const isWorkingProfessional = watch("is_working_professional");

  useEffect(() => {
    if (isStudent === true) {
      if (isWorkingProfessional === true) {
        setValue("is_working_professional", false);
      }
      clearErrors(["is_student", "is_working_professional"]);
    }
  }, [isStudent]);

  useEffect(() => {
    if (isWorkingProfessional === true) {
      if (isStudent === true) {
        setValue("is_student", false);
      }
      clearErrors(["is_student", "is_working_professional"]);
    }
  }, [isWorkingProfessional]);

  const userClient = new UserProfileAPIClient();

  const update = (data) => {
    if (isUndefined(isStudent) && isUndefined(isWorkingProfessional)) {
      setError("is_student", {
        type: "manual",
        message: "Are you a student?"
      });
      setError("is_working_professional", {
        type: "manual",
        message: "Are you a working professional?"
      });
      return;
    }

    var payload = {
      ...data,
      date_of_birth: DateParser.formatDateToYYYYMMDD(data.date_of_birth)
    };
    ["education_level", "city", "actively_looking_for", "field_of_studies", "state"].forEach(
      (i) => (payload[i] = payload[i].value)
    );

    Object.keys(payload).forEach((el) => {
      if (isString(payload[el]) && isEmpty(payload[el])) {
        payload[el] = null;
      }
    });
    var keysToDelete = ["email", "phone"];
    if (has(payload, "secondary_email")) {
      keysToDelete.push("secondary_email");
    }
    if (has(payload, "secondary_mobile")) {
      keysToDelete.push("secondary_mobile");
    }
    keysToDelete.forEach((el) => delete payload[el]);

    const userProfilePayload = getUserProfilePayload(
      user,
      data,
      `${data.first_name} ${data.last_name}`
    );
    var promises = [
      new StudentPersonalDetailsAPI().update(payload),
      userClient.updateProfile(userProfilePayload)
    ];

    const promise = Promise.all(promises).then((result) => {
      handleSetValues(result[0]);
      if (result.length == 2) {
        updateUser(result[1]);
      }
      scrollToTop();
    });
    showPromiseToast(promise, {
      pending: "Saving personal details",
      success: "Personal details have been saved successfully",
      error: renderErrorToast()
    });
  };

  const onSubmit = (data) => {
    const isEmailVerificationOpen = showEmailVerifyOTP !== undefined;
    const isSecondaryEmailVerificationOpen = showSecondaryEmailVerifyOTP !== undefined;
    const isMobileVerificationOpen = showMobileVerifyOTP !== undefined;
    const isSecondaryMobileVerificationOpen = showSecondaryMobileVerifyOTP !== undefined;

    if (isEmailVerificationOpen) {
      validateContactInfo({ email: showEmailVerifyOTP, otp: data.otp }, () =>
        setShowEmailVerifyOTP(undefined)
      );
    } else if (isSecondaryEmailVerificationOpen) {
      validateContactInfo({ secondary_email: showSecondaryEmailVerifyOTP, otp: data.otp }, () =>
        setShowSecondaryEmailVerifyOTP(undefined)
      );
    } else if (isMobileVerificationOpen) {
      validateContactInfo({ mobile: showMobileVerifyOTP, otp: data.otp }, () =>
        setShowMobileVerifyOTP(undefined)
      );
    } else if (isSecondaryMobileVerificationOpen) {
      validateContactInfo({ secondary_mobile: showSecondaryMobileVerifyOTP, otp: data.otp }, () =>
        setShowSecondaryMobileVerifyOTP(undefined)
      );
    } else {
      if (user.is_email_verified !== true) {
        setError("email", { message: "Please verify your primary email to contiue" });
        scroller.scrollTo("verification");
      } else {
        update(data);
      }
    }
  };

  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Contact Name*</Form.Label>
        <Row xs={1} sm={2}>
          {[
            { name: "first_name", placeholder: "First Name" },
            { name: "last_name", placeholder: "Last Name" }
          ].map((el, index) => (
            <Col key={el.name} className={isMobile && index === 0 ? "mb-3" : ""}>
              <ReactHookInput
                name={el.name}
                type="text"
                placeholder={el.placeholder}
                required={user.is_email_verified}
              />
            </Col>
          ))}
        </Row>
      </Form.Group>
      {user && (
        <ProfileContact
          setShowEmailVerifyOTP={setShowEmailVerifyOTP}
          setShowSecondaryEmailVerifyOTP={setShowSecondaryEmailVerifyOTP}
          setShowMobileVerifyOTP={setShowMobileVerifyOTP}
          setShowSecondaryMobileVerifyOTP={setShowSecondaryMobileVerifyOTP}
          showEmailVerifyOTP={showEmailVerifyOTP}
          showSecondaryEmailVerifyOTP={showSecondaryEmailVerifyOTP}
          showMobileVerifyOTP={showMobileVerifyOTP}
          showSecondaryMobileVerifyOTP={showSecondaryMobileVerifyOTP}
        />
      )}
      <Form.Group>
        <Form.Label>About*</Form.Label>
        <ReactHookInput
          name="about"
          as="textarea"
          placeholder="Tell us about yourself"
          required={user.is_email_verified}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Date of Birth (DOB)*</Form.Label>
        <DateOfBirth required={user.is_email_verified} />
      </Form.Group>
      {[
        {
          label: "Education Level*",
          options: EDUCATION_LEVEL,
          name: "education_level"
        },
        {
          label: "Field of Study*",
          options: FIELD_OF_STUDIES,
          name: "field_of_studies",
          disabled: user.profile_completion_percentage >= MINIMUM_PROFILE_COMPLETION.STUDENT,
          subComponent: (
            <Form.Group>
              <div className="d-flex align-items-center mt-2">
                {[
                  {
                    name: "is_student",
                    label: "Student",
                    disabled:
                      user.profile_completion_percentage >= MINIMUM_PROFILE_COMPLETION.STUDENT
                  },
                  {
                    name: "is_working_professional",
                    label: "Working Professional",
                    disabled:
                      user.profile_completion_percentage >= MINIMUM_PROFILE_COMPLETION.STUDENT
                  }
                ].map((checkbox, index) => (
                  <div className={index !== 0 ? "ms-4" : ""} key={index}>
                    <CheckBox
                      name={checkbox.name}
                      label={checkbox.label}
                      disabled={checkbox.disabled}
                    />
                  </div>
                ))}
              </div>
            </Form.Group>
          )
        },
        {
          label: "Actively Looking For*",
          options: ACTIVELY_LOOKING,
          name: "actively_looking_for"
        }
      ].map((el) => (
        <React.Fragment key={el.name}>
          <Form.Group>
            <Form.Label>{el.label}</Form.Label>
            <Select options={el.options} name={el.name} isDisabled={el.disabled} />
            {el.subComponent && el.subComponent}
          </Form.Group>
        </React.Fragment>
      ))}
      <AddressForm />
      <Form.Group>
        <Form.Label>
          Social Media Links* <span>(at least one required)</span>
        </Form.Label>
        <SocialMediaLinks />
      </Form.Group>
    </DetailsFormContainer>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch({ type: ACTION_TYPES.AUTH.UPDATE_USER, user })
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsForm);

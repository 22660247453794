import ContentFactory from "apiClients/ContentFactory";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import Select from "components/Inputs/Select";
import React, { useEffect } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { renderErrorToast } from "utils";
import { showPromiseToast } from "utils";
import { convertDataUrlToBase64 } from "utils";

export default function AddARVRForm({ editObj, onSuccess }) {
  const { reset, handleSubmit, setValue } = useFormContext();
  const client = ContentFactory.createRequest("arvr");

  useEffect(() => {
    if (editObj) {
      convertDataUrlToBase64(editObj.thumbnail)
        .then((file) => setValue("thumbnail", [file]))
        .then(() => {
          ["title", "link"].map((el) => setValue(el, editObj[el]));
        })
        .then(() => {
          setValue("type", { value: editObj.type, label: editObj.type });
        });
    }
  }, [editObj]);

  const onSubmit = (data) => {
    const payload = { ...data, type: data.type.value };
    const promise = (
      editObj ? client.update({ ...payload, id: editObj.id }) : client.create(payload)
    )
      .then(onSuccess)
      .then(reset);

    showPromiseToast(promise, {
      pending: "Saving ARVR",
      success: "ARVR saved successfully",
      error: renderErrorToast()
    });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Title*</Form.Label>
          <ReactHookInput name="title" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>AR/VR Link*</Form.Label>
          <ReactHookInput name="link" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>AR/VR*</Form.Label>
          <Select
            options={[
              { value: "AR", label: "AR" },
              { value: "VR", label: "VR" }
            ]}
            name="type"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Thumbnail*</Form.Label>
          <ReactHookUpload
            name="thumbnail"
            required
            type="image"
            preferredText="Preferred Resolution: 400 X 330"
            maxSizeMB={1}
          />
        </Form.Group>
        <div className="pb-2">
          <Button className="px-5 ms-auto d-block" type="submit">
            Post
          </Button>
        </div>
      </Stack>
    </Form>
  );
}

import React, { useEffect, useState } from "react";
import BaseContainer from "../../components/Containers/BaseContainer";
import { useNavigate, useParams } from "react-router-dom";
import ContentFactory from "../../apiClients/ContentFactory";
import LoadingContainer from "../../components/Containers/Loading";
import UnderlineHeading from "../../components/Headings";
import SearchList from "../../components/Base/SearchList";
import { useSearchParams } from "../../hooks/searchParams";
import MoodBoardContentFilterPills from "../../components/Filters/MoodBoardContentFilterPills";
import { ReactSVG } from "react-svg";
import { BACK_ICON, DELETE_ICON, EDIT_ICON } from "../../constants/icons";
import { Button, CloseButton, Modal, Stack } from "react-bootstrap";
import AddMoodBoardModal from "../../components/Modals/AddMoodBoard";
import ConfirmDeletionModal from "../../components/Modals/ConfirmDeletion";
import BaseModal from "../../components/Modals/Base";

function SelectContentSourceModal({ show, onHide, type }) {
  const navigate = useNavigate();
  return (
    <BaseModal show={show} onHide={onHide} size="md">
      <Modal.Body className="p-3">
        <div className="d-flex justify-content-end">
          <CloseButton onClick={onHide} />
        </div>
        <Stack gap={4}>
          <h6 className="text-center">
            Add <span className="text-capitalize">{type}s to Mood Board from</span>
          </h6>
          <Stack direction="horizontal" className="justify-content-evenly" gap={4}>
            <Button
              variant="outline-primary"
              className="w-50 text-uppercase"
              onClick={() => navigate(`/my-activity/favourites/${type}s`)}
            >
              SAVED {type}s
            </Button>
            <Button className="w-50 text-uppercase" onClick={() => navigate(`/all/${type}s`)}>
              ALL {type}s
            </Button>
          </Stack>
        </Stack>
      </Modal.Body>
    </BaseModal>
  );
}

export default function MoodBoard() {
  const { id, moodBoardId } = useParams();
  const [moodBoard, setMoodBoard] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showUpdateMoodBoardName, setShowUpdateMoodBoardName] = useState(false);
  const [showDeleteMoodBoardName, setShowDeleteMoodBoardName] = useState(false);
  const [showAddType, setShowAddType] = useState(undefined);

  const { getKey, addKey } = useSearchParams();

  const type = getKey("type");
  const filterTypes = ["image", "video", "project", "product"];

  const isValidType = filterTypes.includes(type);
  const client = ContentFactory.createRequest("ideation-board");

  useEffect(() => {
    if (!type || !isValidType) {
      addKey("type", "image");
    }
  }, [type]);

  const handleGetMoodBoard = async (setLoader = true) => {
    if (setLoader) {
      setLoading(true);
    }
    await client
      .getMoodBoard(id, moodBoardId)
      .then(setMoodBoard)
      .then(() => {
        if (setLoader) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    handleGetMoodBoard();
  }, []);

  const deleteMoodBoard = () =>
    client
      .deleteMoodBoard(moodBoard.ideation_board, moodBoard.id)
      .then(() => navigate(`/my-activity/ideation-board/${moodBoard.ideation_board}`));

  return (
    <LoadingContainer loading={loading || !type || !isValidType}>
      <BaseContainer className="py-4">
        <UnderlineHeading>Project Name</UnderlineHeading>
        <p>
          Create your mood boards here relating to your projects. Use our site content as a helping
          hand.
        </p>
        {moodBoard && (
          <Stack direction="horizontal" className="pb-4 pt-2 align-items-center">
            <ReactSVG
              src={BACK_ICON}
              className="cursor-pointer"
              onClick={() => navigate(`/my-activity/ideation-board/${moodBoard.ideation_board}`)}
            />
            <h5 className="fw-bold mb-0 ms-5">{moodBoard.name}</h5>
            <Stack direction="horizontal" style={{ marginLeft: "10%" }} gap={4}>
              <ReactSVG
                src={EDIT_ICON}
                className="cursor-pointer"
                onClick={() => setShowUpdateMoodBoardName(true)}
              />
              <ReactSVG
                src={DELETE_ICON}
                style={{ width: "2.5rem" }}
                className="cursor-pointer"
                onClick={() => setShowDeleteMoodBoardName(true)}
              />
            </Stack>
          </Stack>
        )}

        {moodBoard && (
          <AddMoodBoardModal
            show={showUpdateMoodBoardName}
            onHide={() => setShowUpdateMoodBoardName(false)}
            onSuccess={handleGetMoodBoard}
            moodboard={moodBoard}
            ideationBoardId={moodBoard.ideation_board}
          />
        )}
        {moodBoard && (
          <ConfirmDeletionModal
            size="md"
            show={showDeleteMoodBoardName}
            onHide={() => setShowDeleteMoodBoardName(false)}
            onCancel={() => setShowDeleteMoodBoardName(false)}
            onConfirm={deleteMoodBoard}
            isPlural={false}
            type="mood-board"
          />
        )}

        <SelectContentSourceModal
          show={filterTypes.includes(showAddType)}
          onHide={() => setShowAddType(undefined)}
          type={showAddType}
        />
        <div className="mb-3">
          <MoodBoardContentFilterPills isValidType={isValidType} />
        </div>
        <SearchList type={type} handleAdd={() => setShowAddType(type)} />
      </BaseContainer>
    </LoadingContainer>
  );
}

import React from "react";
import { Form } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import VALIDATORS from "../../../validators";
import FormError from "../../FormError";
import DEFAULT_STYLE from "../../../styles";

export default function CheckBox({
  name,
  label,
  required,
  customLabel,
  isChecked,
  setChecked,
  containerStyle = {},
  useReactHook = true,
  ...props
}) {
  var field;
  var checked;
  var errors;
  if (useReactHook === true) {
    const {
      control,
      formState: { errors: formErrors },
      watch
    } = useFormContext();
    const { field: hookField } = useController({
      name,
      control,
      rules: required ? VALIDATORS.required : {}
    });
    errors = formErrors;
    field = hookField;
    checked = watch(name);
  } else {
    checked = isChecked;
  }
  const isErrored = errors && errors[name];

  return (
    <Form.Group style={containerStyle}>
      <Form.Group className="d-flex">
        <Form.Check
          type="checkbox"
          label={label}
          checked={checked || false}
          onChange={(e) => {
            e.stopPropagation();
            useReactHook === true ? field.onChange(!checked) : setChecked(!checked);
          }}
          style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
          {...props}
        />
        {customLabel && customLabel}
      </Form.Group>

      {isErrored && <FormError>{errors[name].message}</FormError>}
    </Form.Group>
  );
}

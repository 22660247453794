import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { isArray } from "lodash";
import JobPreferencesAPI from "apiClients/Profile/Student/JobPreferences";
import LockedContent from "components/Containers/LockedContent";
import JobPreferencesForm from "forms/Student/JobPreferences";
import ProfileDetailsContainer from "components/Containers/ProfileDetails";
import ContentHeader from "components/Base/ContentHeader";

export default function JobPreferences() {
  const [loading, setLoading] = useState(true);
  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue } = methods;

  useEffect(() => {
    new JobPreferencesAPI()
      .read()
      .then((result) =>
        ["areas_of_interests", "preferred_locations", "currently_looking_for", "work_mode"].map(
          (el) =>
            result[el] &&
            isArray(result[el]) &&
            setValue(
              el,
              result[el].map((el) => ({ label: el, value: el }))
            )
        )
      )
      .then(() => setLoading(false));
  }, [setLoading, setValue]);

  return (
    <LockedContent>
      <ProfileDetailsContainer loading={loading} methods={methods}>
        <ContentHeader title="Your Preferences" doNotShowSubtitle />
        <JobPreferencesForm setLoading={setLoading} />
      </ProfileDetailsContainer>
    </LockedContent>
  );
}

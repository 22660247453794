import React, { useState } from "react";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import COLORS from "../../../constants/colors";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { isArray, isEmpty } from "lodash";
import IdeationBoard from "../../../screens/UserContent/IdeationBoard";
import { PLUS_ICON, SIDEBAR_ICONS } from "../../../constants/icons";
import AddIdeationBoardModal from "../../Modals/AddIdeationBoard";
import { useSidebar } from "hooks/sidebar";
import { isMobile } from "react-device-detect";

function SubMenuItem({ item }) {
  const { pathname } = useLocation();
  const isActive = pathname === item.menuPath;
  return (
    <MenuItem
      active={isActive}
      icon={<ReactSVG useRequestCache src={item.icon} {...(item.iconProps || {})} />}
      style={
        pathname.includes(item.menuPath)
          ? {
              backgroundColor: "white",
              color: COLORS.PRIMARY_ORANGE,
              fontWeight: "bold"
            }
          : {}
      }
      component={item.subMenucomponent || <Link to={item.menuPath} />}
    >
      {item.label}
    </MenuItem>
  );
}

function SidebarSubMenu({ isLoadingIdeationBoards, menu }) {
  const { pathname } = useLocation();
  const isOpen = pathname.includes(menu.path);

  return (
    <SubMenu
      label={menu.label}
      defaultOpen={isOpen}
      icon={
        isLoadingIdeationBoards && menu.path === "ideation-board" ? (
          <Spinner />
        ) : (
          menu.icon && <ReactSVG useRequestCache src={menu.icon} />
        )
      }
      style={{
        color: isOpen ? COLORS.PRIMARY_ORANGE : "black",
        fontWeight: isOpen ? "bold" : "normal"
      }}
      active={isOpen}
    >
      {menu.subMenu.map((subMenuEl, index) => (
        <SubMenuItem key={`${subMenuEl.label}-${index}`} item={subMenuEl} />
      ))}
    </SubMenu>
  );
}

function BaseMenu({ options, isLoadingIdeationBoards, ideationBoards }) {
  const { pathname } = useLocation();
  const [showAddIdeationBoardModal, setShowAddIdeationBoardModal] = useState(false);

  const updateIdeationBoardMenu = (el) => {
    if (isArray(ideationBoards) && !isEmpty(ideationBoards)) {
      var menu = [];
      for (var i = 0; i < ideationBoards.length; i++) {
        menu.push({
          label: ideationBoards[i].folder_name,
          path: `/ideation-board/:id`,
          menuPath: `/my-activity/ideation-board/${ideationBoards[i].id}`,
          component: <IdeationBoard />,
          icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
        });
      }
      menu.push({
        label: "Add Folder",
        icon: PLUS_ICON,
        iconProps: { style: { width: "1.25rem", marginTop: "-15%" } },
        subMenucomponent: <div onClick={() => setShowAddIdeationBoardModal(true)}></div>
      });
      el.subMenu = menu;
    } else {
      if (el.subMenu) {
        delete el.subMenu;
      }
    }
  };
  const { toggleSideBar, showSidebar } = useSidebar();

  React.useEffect(() => {
    if (isMobile && showSidebar) {
      toggleSideBar(false);
    }
  }, [pathname]);

  return (
    <Menu className="pb-2">
      {options.map((el, index) => {
        if (el.path.includes("ideation-board")) {
          updateIdeationBoardMenu(el);
        }
        return el.subMenu ? (
          <SidebarSubMenu
            key={`${el.label}-${index}`}
            isLoadingIdeationBoards={isLoadingIdeationBoards}
            menu={el}
          />
        ) : (
          el.label && el.menuPath && (
            <MenuItem
              key={`${el.label}-${index}`}
              active={pathname === el.menuPath}
              icon={<ReactSVG src={el.icon} {...(el.iconProps || {})} />}
              style={
                pathname.includes(el.menuPath)
                  ? {
                      backgroundColor: "white",
                      color: COLORS.PRIMARY_ORANGE,
                      fontWeight: "bold"
                    }
                  : {}
              }
              component={<Link to={el.menuPath} />}
            >
              {el.label}
            </MenuItem>
          )
        );
      })}
      {isArray(ideationBoards) && !isEmpty(ideationBoards) && (
        <AddIdeationBoardModal
          show={showAddIdeationBoardModal}
          onHide={() => setShowAddIdeationBoardModal(false)}
        />
      )}
    </Menu>
  );
}
const mapStateToProps = (state) => ({
  isLoadingIdeationBoards: state.contentReducer.isLoadingIdeationBoards,
  ideationBoards: state.contentReducer.ideationBoards
});
export default connect(mapStateToProps)(BaseMenu);

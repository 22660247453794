import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import JOBS1 from "../../assets/jobs1.png";
import COLORS from "../../constants/colors";
import { Link, useNavigate } from "react-router-dom";
import ContentFactory from "../../apiClients/ContentFactory";
import JobCard from "../../components/Cards/Job";
import LoadingContainer from "../../components/Containers/Loading";
import { isArray, isEmpty } from "lodash";
import ProfileImage from "../../components/ProfileImage";
import Location from "../../components/Location";
import JobFilters from "../../components/Filters/JobFilters";
import ProfileCard from "../../components/Cards/Profile";
import CandidateFilters from "../../components/Filters/CandidateFilters";
import EllipsisText from "components/EllipsisText";
import { useSearchParams } from "hooks/searchParams";
import { isMobile } from "react-device-detect";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";
import { getCardHeight } from "components/Base/Card";

const JOB_SEEKER_STEPS = [
  {
    title: "Search",
    content:
      "Search, apply, track and post a job all here on one platform. You don’t need to worry anymore."
  },
  {
    title: "Apply",
    content:
      "Search, apply, track and post a job all here on one platform. You don’t need to worry anymore."
  },
  {
    title: "Track",
    content:
      "Search, apply, track and post a job all here on one platform. You don’t need to worry anymore."
  }
];

const JOB_RECRUITER_STEPS = [
  {
    title: "Search",
    content:
      "Search, apply, track and post a job all here on one platform. You don’t need to worry anymore."
  },
  {
    title: "Connect",
    content:
      "Search, apply, track and post a job all here on one platform. You don’t need to worry anymore."
  },
  {
    title: "Hire",
    content:
      "Search, apply, track and post a job all here on one platform. You don’t need to worry anymore."
  }
];

const JOB_TYPES = [
  { steps: JOB_SEEKER_STEPS, title: "JOB SEEKER" },
  { steps: JOB_RECRUITER_STEPS, title: "RECRUITER" }
];

function JobsListingContainer({ children }) {
  return (
    <Stack className={`py-3 px-${isMobile ? 2 : 5}`} gap={4}>
      {children}
    </Stack>
  );
}

function JobListings({ title, list, sm = 4 }) {
  const navigate = useNavigate();
  return (
    <JobsListingContainer>
      <h6 className="fw-bold">{title}</h6>
      <Row sm={sm}>
        {list.map((el) => (
          <Col key={el.id} className="mb-3">
            <JobCard obj={el} onClick={() => navigate(`seeker/search/details/${el.slug}`)} />
          </Col>
        ))}
      </Row>
      <Button className="px-5 d-block mx-auto" onClick={() => navigate(`seeker/search`)}>
        VIEW ALL
      </Button>
    </JobsListingContainer>
  );
}

function JobInformationContainer({ children }) {
  return (
    <Stack className={`py-${isMobile ? 3 : 5} my-${isMobile ? 4 : 5}`} gap={4}>
      {children}
    </Stack>
  );
}

function JobInformationStrip({ title, subTitle }) {
  const navigate = useNavigate();
  return (
    <JobInformationContainer>
      <h1 className="text-center">{title}</h1>
      <p className="text-center">{subTitle}</p>
      <div>
        <Button className="d-block mx-auto px-5" onClick={() => navigate("/signup")}>
          REGISTER NOW
        </Button>
      </div>
    </JobInformationContainer>
  );
}

function ActivelyRecruitingCard({ obj }) {
  const navigate = useNavigate();
  return (
    <Row
      className={`shadow rounded py-${isMobile ? 3 : 3} mb-3 mx-1 cursor-pointer`}
      onClick={() => navigate(`/profiles/${obj.slug}`)}
      sm={1}
    >
      <Col className={`py-${isMobile ? 2 : 4}`} xs={3}>
        <div
          className="mx-auto"
          style={{ width: isMobile ? "5rem" : "6.5rem", height: isMobile ? "5rem" : "6.5rem" }}
        >
          <ProfileImage customUser={obj} />
        </div>
      </Col>
      <Col className={`my-auto ${isMobile ? "" : "text-center"}`}>
        <EllipsisText component="h6" numOfLines={1}>
          {obj.name}
        </EllipsisText>
        <div className={`${isMobile ? "w-100" : "d-flex justify-content-center"}`}>
          <Location>{obj.location}</Location>
        </div>
      </Col>
    </Row>
  );
}

function ActivelyRecruiting({ actively_recruiting }) {
  return (
    <JobsListingContainer>
      <h6 className="fw-bold">Actively Recruiting Companies</h6>
      <Row xs={1} sm={6}>
        {actively_recruiting.map((el) => (
          <Col key={el.id}>
            <ActivelyRecruitingCard obj={el} />
          </Col>
        ))}
      </Row>
    </JobsListingContainer>
  );
}

function TopProfiles({ profiles, onBookmarked }) {
  return (
    <JobsListingContainer>
      <h6 className="fw-bold">Top Profiles</h6>
      <Row xs={2} sm={5}>
        {profiles.map((el, index) => (
          <Col
            key={`${el.id}-${index}`}
            className={`mb-4 ${isMobile ? "px-0" : ""}`}
            style={{ height: getCardHeight("profile") }}
          >
            <ProfileCard obj={el} onBookmarked={onBookmarked} />
          </Col>
        ))}
      </Row>
    </JobsListingContainer>
  );
}

function NotLoggedInJobs({ listings, onBookmarked }) {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <div>
      <Row className="mx-0" xs={1} sm={2}>
        <Col className="px-0">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className={`px-${isMobile ? 4 : 5}${isMobile ? " py-3" : ""}`}>
              <h1 style={{ fontSize: "250%" }}>
                <span style={{ color: COLORS.PRIMARY_ORANGE }}>SOLUTION</span> to your{" "}
                <span style={{ color: COLORS.PRIMARY_ORANGE }}>JOB</span> related problems
              </h1>
              <p className="mt-4">
                Search, apply, track and post a job all here on one platform. You dont need to worry
                anymore.
              </p>
              <Stack gap={3} className="mt-4">
                {[
                  { label: "SEARCH FOR A JOB", variant: "primary", route: "/jobs/seeker/search" },
                  {
                    label: "SEARCH FOR A CANDIDATE",
                    variant: "primary",
                    route: "/jobs/recruiter/search"
                  },
                  { label: "POST A JOB", variant: "outline-primary", route: "/login" }
                ].map((el) => (
                  <Link key={`${el.label}-button`} style={{ minWidth: "32.5%" }} to={el.route}>
                    {el.label}
                  </Link>
                ))}
              </Stack>
            </div>
          </div>
        </Col>
        <Col className="px-0">
          <Image src={JOBS1} className="w-100 h-100" />
        </Col>
      </Row>
      <JobInformationStrip
        title="We know your struggle, let us help you!"
        subTitle="Our platform aims to help the construction industries biggest problem, unemployment. Let
          us help you get a job."
      />
      {listings &&
        isArray(listings.actively_recruiting) &&
        !isEmpty(listings.actively_recruiting) && (
          <ActivelyRecruiting actively_recruiting={listings.actively_recruiting} />
        )}
      <JobInformationContainer>
        <h1 className="text-center">Just few simple steps</h1>
        <Stack direction="horizontal" gap={4} className="mx-auto">
          {JOB_TYPES.map((el, index) => (
            <p
              className="fw-bold cursor-pointer"
              key={el.title}
              onClick={() => setActiveStep(index)}
              style={{
                color: activeStep === index ? COLORS.PRIMARY_ORANGE : COLORS.GREY2,
                textDecoration: activeStep === index ? "underline" : "none"
              }}
            >
              {el.title}
            </p>
          ))}
        </Stack>
        <Container className="px-5">
          <Row xs={1} sm={3}>
            {JOB_TYPES[activeStep].steps.map((step, stepIndex) => (
              <Col key={step.title}>
                <p style={{ fontSize: "2rem", color: COLORS.GREY2 }}>{stepIndex + 1}.</p>
                <h4>{step.title}</h4>
                <p className="pe-3">{step.content}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </JobInformationContainer>
      <TopProfiles profiles={listings.top_profiles} onBookmarked={onBookmarked} />
      {listings && isArray(listings.trending) && !isEmpty(listings.trending) && (
        <JobListings title="Trending Job Titles" list={listings.trending} />
      )}
      <JobInformationStrip
        title="Register now for more relevant search"
        subTitle="Our platform aims to help the construction industries biggest problem, unemployment. Let us
        help you get a job."
      />
    </div>
  );
}
function Header({ title, description, linkText, linkTo, filtersComponent }) {
  const [filters, setFilters] = useState({});

  return (
    <Stack gap={4} className="py-5" style={{ backgroundColor: "rgba(217, 217, 217, 0.50)" }}>
      <h1 className="text-center fw-bold">{title}</h1>
      <p className="text-center mx-auto mb-0">{description}</p>
      <Link className="text-center" to={linkTo}>
        {linkText}
      </Link>
      <div className={`w-${isMobile ? 100 : 75} mx-auto mt-3`}>
        {filtersComponent && filtersComponent({ filters, setFilters })}
      </div>
    </Stack>
  );
}

function SeekerJobs({ listings }) {
  return (
    <div className="bg-white">
      <Header
        title="Find the job you have been waiting for"
        description="Search for the job that is right for you at your desired location. Save jobs liked by you and get application status for all your applications."
        linkText="Haven’t created a resume yet? Create now."
        linkTo="/my-profile/resume"
        filtersComponent={({ filters, setFilters }) => (
          <JobFilters filters={filters} setFilters={setFilters} />
        )}
      />
      <Stack className="my-5" gap={4}>
        {listings &&
          isArray(listings.actively_recruiting) &&
          !isEmpty(listings.actively_recruiting) && (
            <ActivelyRecruiting actively_recruiting={listings.actively_recruiting} />
          )}
        {listings && isArray(listings.trending) && !isEmpty(listings.trending) && (
          <JobListings title="Trending Job Titles" list={listings.trending} />
        )}
        {listings && isArray(listings.urgently_hiring) && !isEmpty(listings.urgently_hiring) && (
          <JobListings title="Urgently Hiring" list={listings.urgently_hiring} />
        )}
      </Stack>
    </div>
  );
}

function RecruiterJobs({ listings, onBookmarked }) {
  return (
    <div className="bg-white">
      <Header
        title="Find the right candidate"
        description="Search for the candidate that is right for you at your desired location. Save profiles liked by you and get application details for all your postings."
        linkText="Want to post a job? Post Now"
        linkTo="/my-activity/my-jobs/posted/create-new-job"
        filtersComponent={({ filters, setFilters }) => (
          <CandidateFilters filters={filters} setFilters={setFilters} />
        )}
      />
      <Stack className="my-5" gap={4}>
        {listings && isArray(listings.top_profiles) && !isEmpty(listings.top_profiles) && (
          <TopProfiles profiles={listings.top_profiles} onBookmarked={onBookmarked} />
        )}
        {listings && isArray(listings.trending) && !isEmpty(listings.trending) && (
          <JobListings title="Trending Job Titles" list={listings.trending} />
        )}
        {listings && isArray(listings.urgently_hiring) && !isEmpty(listings.urgently_hiring) && (
          <JobListings title="Urgently Hiring" list={listings.urgently_hiring} />
        )}
      </Stack>
    </div>
  );
}
function LoggedInJob({ listings, onBookmarked }) {
  const user = useSelector((state) => state.authReducer.user);
  const isSeeker = user.is_student;

  return isSeeker ? (
    <SeekerJobs listings={listings} />
  ) : (
    <RecruiterJobs listings={listings} onBookmarked={onBookmarked} />
  );
}

function Jobs() {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const client = ContentFactory.createRequest("job");
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { resetQuery } = useSearchParams();

  const getTopProfiles = () =>
    client
      .homeListings()
      .then((result) => setListings({ ...listings, top_profiles: result.top_profiles }));
  useEffect(() => {
    resetQuery();
  }, []);
  const getListings = () => client.homeListings().then(setListings);
  useEffect(() => {
    getListings().then(() => setLoading(false));
  }, [isLoggedIn]);

  return (
    <LoadingContainer loading={loading}>
      {isLoggedIn ? (
        <LoggedInJob listings={listings} onBookmarked={getTopProfiles} />
      ) : (
        <NotLoggedInJobs listings={listings} onBookmarked={getTopProfiles} />
      )}
    </LoadingContainer>
  );
}

export default withMobileOnlySidebar(Jobs);

import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useScrollToTop } from "hooks";
import { useAuth } from "hooks";
import UserProfileAPIClient from "apiClients/Profile/User";
import { BrandBasicDetailsAPI } from "apiClients/Profile/Brand/ProfileDetails";
import { getUserProfilePayload, showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ProfileContact from "forms/ProfileContact";
import Select from "components/Inputs/Select";
import { YEARS } from "constants/Profile/Studio";
import AddressForm from "forms/Address";
import ACTION_TYPES from "constants/action-types";
import { useFormContext } from "react-hook-form";
import { scroller } from "react-scroll";

function BasicDetailsForm({ user, handleSetValues, updateUser }) {
  const { scrollToTop } = useScrollToTop();
  const { validateContactInfo } = useAuth();
  const { setError } = useFormContext();

  const [showMobileVerifyOTP, setShowMobileVerifyOTP] = useState(undefined);
  const [showSecondaryMobileVerifyOTP, setShowSecondaryMobileVerifyOTP] = useState(undefined);
  const [showEmailVerifyOTP, setShowEmailVerifyOTP] = useState(undefined);
  const [showSecondaryEmailVerifyOTP, setShowSecondaryEmailVerifyOTP] = useState(undefined);

  const userClient = new UserProfileAPIClient();

  const update = (data) => {
    var payload = {
      ...data
    };
    ["establishment_year", "city", "state"].forEach((i) => (payload[i] = payload[i].value));

    const client = new BrandBasicDetailsAPI();
    var promises = [client.update(payload)];
    const userProfilePayload = getUserProfilePayload(user, data, data.name);
    if (Object.keys(userProfilePayload).length > 0) {
      promises.push(userClient.updateProfile(userProfilePayload));
    }
    const promise = Promise.all(promises).then((result) => {
      handleSetValues(result[0]);
      if (result.length == 2) {
        updateUser(result[1]);
      }
      scrollToTop();
    });
    showPromiseToast(promise, {
      pending: "Saving basic details",
      success: "Basic details saved successfully",
      error: renderErrorToast()
    });
  };

  const onSubmit = (data) => {
    const isEmailVerificationOpen = showEmailVerifyOTP !== undefined;
    const isSecondaryEmailVerificationOpen = showSecondaryEmailVerifyOTP !== undefined;
    const isMobileVerificationOpen = showMobileVerifyOTP !== undefined;
    const isSecondaryMobileVerificationOpen = showSecondaryMobileVerifyOTP !== undefined;

    if (isEmailVerificationOpen) {
      validateContactInfo({ email: showEmailVerifyOTP, otp: data.otp }, () =>
        setShowEmailVerifyOTP(undefined)
      );
    } else if (isSecondaryEmailVerificationOpen) {
      validateContactInfo({ secondary_email: showSecondaryEmailVerifyOTP, otp: data.otp }, () =>
        setShowSecondaryEmailVerifyOTP(undefined)
      );
    } else if (isMobileVerificationOpen) {
      validateContactInfo({ mobile: showMobileVerifyOTP, otp: data.otp }, () =>
        setShowMobileVerifyOTP(undefined)
      );
    } else if (isSecondaryMobileVerificationOpen) {
      validateContactInfo({ secondary_mobile: showSecondaryMobileVerifyOTP, otp: data.otp }, () =>
        setShowSecondaryMobileVerifyOTP(undefined)
      );
    } else {
      if (user.is_email_verified !== true) {
        setError("email", { message: "Please verify your primary email to contiue" });
        scroller.scrollTo("verification");
      } else {
        update(data);
      }
    }
  };

  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Company Name*</Form.Label>
        <ReactHookInput
          name="name"
          type="text"
          placeholder="Company Name"
          required={user.is_email_verified}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>About*</Form.Label>
        <ReactHookInput
          name="about"
          as="textarea"
          required={user.is_email_verified}
          placeholder="India’s most leading tile manufacturer"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Person Name*</Form.Label>
        <Row>
          <Col>
            <ReactHookInput
              name="contact_person_first_name"
              required={user.is_email_verified}
              placeholder="First Name"
            />
          </Col>
          <Col>
            <ReactHookInput
              name="contact_person_last_name"
              required={user.is_email_verified}
              placeholder="Last Name"
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Form.Label>Year of Establishment*</Form.Label>
        <Select options={YEARS} name="establishment_year" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Company Website Link</Form.Label>
        <ReactHookInput name="website_link" type="url" placeholder="www.kajaria.com" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Market Capitalization</Form.Label>
        <ReactHookInput name="market_capitalization" placeholder="201.42 billion INR" />
      </Form.Group>
      {user && (
        <ProfileContact
          setShowEmailVerifyOTP={setShowEmailVerifyOTP}
          setShowSecondaryEmailVerifyOTP={setShowSecondaryEmailVerifyOTP}
          setShowMobileVerifyOTP={setShowMobileVerifyOTP}
          setShowSecondaryMobileVerifyOTP={setShowSecondaryMobileVerifyOTP}
          showEmailVerifyOTP={showEmailVerifyOTP}
          showSecondaryEmailVerifyOTP={showSecondaryEmailVerifyOTP}
          showMobileVerifyOTP={showMobileVerifyOTP}
          showSecondaryMobileVerifyOTP={showSecondaryMobileVerifyOTP}
        />
      )}
      <AddressForm />
    </DetailsFormContainer>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch({ type: ACTION_TYPES.AUTH.UPDATE_USER, user })
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailsForm);

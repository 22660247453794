import { useLocation } from "react-router-dom";
import ROUTE_CONSTANTS from "../constants/routes";

export const useNavigationInfo = () => {
  const location = useLocation();
  const { pathname, hash } = location;

  const isActivity = pathname.startsWith("/my-activity");
  const isMyProfile = pathname.includes(ROUTE_CONSTANTS.ROUTE_PREFIXES.MY_PROFILE);
  const isMyProfileOrActivity = isMyProfile || isActivity;
  const isFavourites = pathname.startsWith("/my-activity/favourites");
  const isAll = pathname.startsWith("/all");
  const isProfileOrFavourites = isMyProfile || isFavourites;
  const isLearn = pathname.includes("/learn");
  const isJobs = pathname.includes("jobs");
  const isJob = pathname.includes("job");
  const isUnderReview = pathname.includes("under-review");
  const isPosted = pathname.includes("posted");
  const isClosed = pathname.includes("closed");
  const isDrafted = pathname.includes("draft");
  const isProfiles = pathname.includes("profiles");
  const isPreview = pathname.includes("preview") || hash.includes("preview");
  const isProfile = pathname.includes("profile");
  const isMoodBoard = pathname.includes("mood-board");
  const isSampleProfile = pathname.includes("my-profile/sample");
  const isJobsSearch = pathname.includes("jobs/seeker/search");
  const isCandidateSearch = pathname.includes("jobs/recruiter/search");
  const isJobDetails = pathname.includes("jobs/search/details/");
  const isCatalogues = pathname.includes("content/catalogues");
  const isPostedJobs = pathname.includes("my-activity/my-jobs/posted");
  const isAppliedJobDescription =
    pathname.includes("job-description") && pathname.includes("jobs/applied/");
  const isCategories = pathname.includes("/products/categories");
  const isAllCategories = pathname === "/products/categories";
  const isProducts = pathname === "/products";
  const isProductsBrands = pathname === "/products/brands";
  const isProfileDetail = /^\/profiles\/[^/]+\/[^/]+\/[^/]+$/.test(pathname);
  const isLearnDetail = /^\/learn\/[^/]+\/[^/]+$/.test(pathname);

  return {
    isActivity,
    isMyProfile,
    isMyProfileOrActivity,
    isAll,
    isFavourites,
    isProfileOrFavourites,
    isLearn,
    isJob,
    isUnderReview,
    isPosted,
    isDrafted,
    isProfiles,
    isPreview,
    isProfile,
    isMoodBoard,
    isSampleProfile,
    isJobs,
    isJobsSearch,
    isJobDetails,
    isCatalogues,
    isCandidateSearch,
    isPostedJobs,
    isAppliedJobDescription,
    isClosed,
    isCategories,
    isProducts,
    isAllCategories,
    isProductsBrands,
    isProfileDetail,
    isLearnDetail
  };
};

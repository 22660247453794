import Api, { ApiHeaders } from "../../api";

class JobPreferencesAPI extends ApiHeaders {
  constructor() {
    super();
    this.endpoint = "/student/job-preferences/";
  }
  async read() {
    return await Api.get(this.endpoint, {}, this.authenticationHeaders).then(
      (response) => response.data
    );
  }

  async update(payload) {
    return await Api.put(this.endpoint, payload, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

export default JobPreferencesAPI;

import React, { useEffect, useState } from "react";
import ContentFactory from "../../../apiClients/ContentFactory";
import LoadingContainer from "../../Containers/Loading";
import { SelectedApplication } from "../../../screens/Jobs/Applications";

export default function ReviewApplication({ id }) {
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const client = ContentFactory.createRequest("job-application");

  const getData = () => client.read(id).then(setApplication);

  useEffect(() => {
    getData().then(() => setLoading(false));
  }, [id]);

  return (
    <LoadingContainer loading={loading}>
      {application && <SelectedApplication application={application} noActionButtons />}
    </LoadingContainer>
  );
}

const PROJECT_CONSTANTS = {
  TYPES: [
    { value: "Residential", label: "Residential" },
    { value: "Commercial & Offices", label: "Commercial & Offices" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Retail", label: "Retail" },
    { value: "Institutional", label: "Institutional" },
    { value: "Religious", label: "Religious" },
    {
      value: "Industrial & Infrastructure",
      label: "Industrial & Infrastructure"
    },
    { value: "Landscape & Urbanism", label: "Landscape & Urbanism" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Mixed type", label: "Mixed type" }
  ],
  AREA_UNIT: [
    { value: "sq.yd", label: "sq.yd" },
    { value: "sq.ft", label: "sq.ft" },
    { value: "sq.mt", label: "sq.mt" },
    { value: "acre", label: "acre" }
  ]
};

export default PROJECT_CONSTANTS;

import React from "react";

export default function Loading() {
  return (
    <div
      style={{ minHeight: "50.75vh" }}
      className="d-flex align-items-center justify-content-center w-100"
    >
      Loading
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Button, CloseButton, Form, Stack } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { isDate, isNull, isString } from "lodash";
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import DateParser from "date";
import COLORS from "constants/colors";
import FormError from "components/FormError";
import RESUME_CONSTANTS from "constants/Resume";
import StartEndDateInput from "components/Inputs/StartEndDate";
import CheckBox from "components/Inputs/CheckBox";
import EditDeleteIcons from "components/Icons/EditDeleteIcons";
import DateRangeFormatter from "components/DateRangeFormatter";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";
import { useResumeFields } from "hooks";
import ResumeFormContainer from "components/Containers/ResumeForm";
import { scroller } from "react-scroll";

function AddExperienceForm({ onSave, experience, onClose }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [company, setCompany] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [employmentType, setEmploymentType] = useState(undefined);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (experience) {
      setTitle(experience.title);
      setEndDate(DateParser.parseFromYYYYMMDD(experience.end_date));
      setStartDate(DateParser.parseFromYYYYMMDD(experience.start_date));
      setCompany(experience.company);
      setCurrentlyWorking(experience.currently_working_here);
      setEmploymentType({ value: experience.employment_type, label: experience.employment_type });
    }
  }, []);

  useEffect(() => {
    if (!currentlyWorking && !isNull(endDate)) {
      setEndDate(null);
    }
  }, [currentlyWorking]);

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setCompany("");
    setStartDate(null);
    setEndDate(null);
    setCurrentlyWorking(false);
    setEmploymentType(undefined);
  };
  const validateEducationForm = () => {
    const errors = {};

    if (title.trim() === "") {
      errors.title = "Invalid title";
    }
    if (employmentType === undefined) {
      errors.employmentType = "Invalid employment type";
    }

    if (company.trim() === "") {
      errors.company = "Invalid company";
    }

    if (startDate === null) {
      errors.startDate = "Invalid start date";
    }

    if (endDate === null && !currentlyWorking) {
      errors.endDate = "Invalid end date";
    }

    return errors;
  };

  const onAdd = () => {
    const errors = validateEducationForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      const newExperience = {
        title,
        company,
        start_date: isString(startDate)
          ? startDate
          : isDate(startDate)
          ? DateParser.formatDateToYYYYMMDD(startDate)
          : null,
        end_date: isString(endDate)
          ? endDate
          : isDate(endDate)
          ? DateParser.formatDateToYYYYMMDD(endDate)
          : null,
        description,
        currently_working_here: currentlyWorking,
        employment_type: employmentType.value
      };
      onSave(newExperience);
      clearForm();
      scroller.scrollTo("Experience");
    }
  };
  return (
    <Stack gap={4} className="p-4 mb-3" style={{ border: `1px ${COLORS.GREY2} dashed` }}>
      <div className="d-flex justify-content-between">
        <h6>Add Experience</h6>
        <CloseButton onClick={onClose} />
      </div>
      <Form.Group>
        <Form.Label>Job Title*</Form.Label>
        <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} required />
        {errors && errors.title && <FormError>{errors.title}</FormError>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Employment Type*</Form.Label>
        <ReactSelect
          value={employmentType}
          onChange={(value) => setEmploymentType(value)}
          options={RESUME_CONSTANTS.EMPLOYMENT_TYPES}
        />
        {errors && errors.employmentType && <FormError>{errors.employmentType}</FormError>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Company*</Form.Label>
        <Form.Control value={company} onChange={(e) => setCompany(e.target.value)} required />
        {errors && errors.company && <FormError>{errors.company}</FormError>}
      </Form.Group>
      <div>
        <StartEndDateInput
          showEndDate={!currentlyWorking}
          showStartDate
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          errors={errors}
        />
        <CheckBox
          containerStyle={{ marginTop: "0.5rem" }}
          isChecked={currentlyWorking}
          setChecked={setCurrentlyWorking}
          label="Currently working here"
          useReactHook={false}
        />
      </div>

      <Form.Group>
        <Form.Label>Breif Description</Form.Label>
        <Form.Control
          value={description}
          as="textarea"
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </Form.Group>
      <Button className="w-25" type="button" onClick={onAdd}>
        {experience ? "UPDATE" : "ADD"}
      </Button>
    </Stack>
  );
}

export function SavedExperience({ experience, onDelete, onEdit }) {
  return (
    <Stack className="mb-3">
      <div className="d-flex justify-content-between">
        <h6 className="mb-0">{experience.title}</h6>
        <EditDeleteIcons onDelete={onDelete} onEdit={onEdit} />
      </div>
      <p className="mb-0">{experience.employment_type}</p>
      <DateRangeFormatter
        startDate={experience.start_date}
        endDate={experience.end_date}
        dateFormat="MM/YYYY"
        isPresent={experience.currently_working_here}
      />
      <Form.Text muted>{experience.description}</Form.Text>
    </Stack>
  );
}

function SavedExperienceSection({ experience, onUpdate, index, onDelete }) {
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const update = (updatedExperience) => {
    onUpdate(updatedExperience, index);
    setEdit(false);
  };

  const onClose = () => setEdit(false);

  const handleDelete = () => {
    onDelete(index);
    setDel(false);
  };

  return edit === true ? (
    <AddExperienceForm experience={experience} onSave={update} onClose={onClose} />
  ) : (
    <>
      <SavedExperience
        onDelete={() => setDel(true)}
        onEdit={() => setEdit(true)}
        experience={experience}
      />
      <ConfirmDeletionModal
        show={del}
        onHide={() => setDel(false)}
        type="experience"
        onCancel={() => setDel(false)}
        onConfirm={handleDelete}
        hideSubTitle
      />
    </>
  );
}

export default function ExperienceSection() {
  const { control } = useFormContext();
  const { experience, appendExperience, removeExperience, updateExperience } = useResumeFields({
    control
  });
  const [showForm, setShowForm] = useState(false);

  const onSave = (newExperience) => {
    appendExperience(newExperience);
    setShowForm(false);
  };

  const onClose = () => setShowForm(false);

  const onDelete = (index) => removeExperience(index);

  const onUpdate = (updatedExperience, index) => updateExperience(index, updatedExperience);

  return (
    <ResumeFormContainer>
      {experience.map((el, index) => (
        <SavedExperienceSection
          experience={el}
          key={el.id}
          onUpdate={onUpdate}
          index={index}
          onDelete={onDelete}
        />
      ))}

      {showForm === true ? (
        <AddExperienceForm onClose={onClose} onSave={onSave} />
      ) : (
        <Link
          className="text-decoration-underline ms-2 fw-bold d-flex justify-content-end mt-3"
          onClick={() => setShowForm(true)}
        >
          ADD MORE
        </Link>
      )}
    </ResumeFormContainer>
  );
}

import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import FormError from "../../FormError";
import VALIDATORS from "../../../validators";
import DEFAULT_STYLE from "../../../styles";
import { isNumber } from "lodash";
import { formatPrice, formatToIndianNumberMask } from "../../../utils";

export default function Price({ name, required }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const isErrored = errors && errors[name];

  const { field } = useController({ name, control, rules: required ? VALIDATORS.required : {} });

  const handleInputChange = (e) => field.onChange(formatToIndianNumberMask(e.target.value));

  return (
    <>
      <InputGroup>
        <Form.Control
          {...field}
          value={isNumber(field.value) ? formatPrice(field.value) : ""}
          onChange={handleInputChange}
          type="text"
          placeholder="2000"
          style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
        />
        <InputGroup.Text>Rs.</InputGroup.Text>
      </InputGroup>
      {isErrored && <FormError>{errors[name].message}</FormError>}
    </>
  );
}

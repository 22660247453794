import ACTION_TYPES from "../../constants/action-types";

const initalState = {
  token: undefined,
  refreshToken: undefined,
  session: undefined,
  isLoggedIn: false,
  user: {}
};

export default function authReducer(state = initalState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case ACTION_TYPES.AUTH.LOGIN:
      return {
        ...newState,
        token: action.data.token,
        refreshToken: action.data.refreshToken,
        session: action.data.session,
        isLoggedIn: true,
        user: action.data.user || newState.user
      };
    case ACTION_TYPES.AUTH.UPDATE_USER:
      return { ...newState, user: action.user };
    default:
      return state;
  }
}

import "./styles.scss";
import React from "react";
import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Stack } from "react-bootstrap";
import OTP from "components/Inputs/OTP";

export default function OtpVerificationForm({ handleResend }) {
  return (
    <Form>
      <Stack gap={2}>
        <Form.Group className="mb-3">
          <OTP required />
        </Form.Group>
        <Row>
          <Col>
            <Button
              variant="outline-primary"
              className="w-100 text-uppercase"
              onClick={handleResend}
            >
              Resend OTP
            </Button>
          </Col>
          <Col>
            <Button variant="primary" type="submit" className="w-100 text-uppercase">
              Verify
            </Button>
          </Col>
        </Row>
      </Stack>
    </Form>
  );
}

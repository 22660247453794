import Dropdown from "components/Dropdown";
import JOB_CONSTANTS from "constants/Jobs";
import { useSearchParams } from "hooks/searchParams";
import React from "react";

export default function ExperienceLevelFilter() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "experience_level";

  const value = getKey(KEY);

  return (
    <Dropdown
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
      value={value}
      options={JOB_CONSTANTS.EXPERIENCE_LEVEL}
      label="Experience Level"
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

import React from "react";
import { Dropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

function CustomComponent() {
  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      More Filters
    </Link>
  );
}

// eslint-disable-next-line react/display-name
export const ThreeDotsVerticalButton = React.forwardRef(
  ({ onClick, Component = CustomComponent }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <Component />
    </div>
  )
);

export function MoreFiltersDropdown({ children, ...props }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={ThreeDotsVerticalButton} {...props} />
      <Dropdown.Menu
        style={{ width: "50vw", maxHeight: "60vh", overflow: "auto" }}
        className="py-0 border-0"
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import FormFooter from "../../Footer";
import { isObject } from "lodash";
import { convertDataUrlToBase64 } from "utils";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import ContentFactory from "apiClients/ContentFactory";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import DropdownFactory from "apiClients/DropdownFactory";

const AddImageForm = ({ onSuccess, editObj }) => {
  const [part, setPart] = useState(1);
  const { handleSubmit, setValue, reset } = useFormContext();

  const parts = ["Image", "Details"];

  useEffect(() => {
    if (isObject(editObj)) {
      convertDataUrlToBase64(editObj.image)
        .then((image) => {
          setValue("image", [image]);
        })
        .then(() => ["title", "description"].map((el) => setValue(el, editObj[el])))
        .then(() =>
          ["category"].map((el) => setValue(el, { label: editObj[el], value: editObj[el] }))
        )
        .then(() => {
          const tags = [];
          editObj.tags.forEach((el) => tags.push({ label: el, value: el }));
          setValue("tags", tags);
        });
    }
  }, []);

  const loadCategories = (search) =>
    DropdownFactory.createRequest("image-categories").list({ search });

  const FormPartOne = (
    <Form.Group className="mb-4">
      <Form.Label>Add your Image file*</Form.Label>
      <ReactHookUpload
        name="image"
        type="image"
        required
        maxSizeMB={1}
        preferredText="Preferred Resolution: 1240 X 680"
      />
    </Form.Group>
  );

  const FormPartTwo = (
    <Stack gap={3}>
      <Form.Group>
        <Form.Label>Title*</Form.Label>
        <ReactHookInput name="title" type="text" required />
      </Form.Group>
      <Form.Group>
        <Form.Label>Image Category*</Form.Label>
        <Select loadOptions={loadCategories} name="category" isAsync />
      </Form.Group>
      <Form.Group>
        <Form.Label>Tags*</Form.Label>
        <Select isMulti isCreateable name="tags" />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Brief Description <span className="fst-italic fw-light">Optional</span>
        </Form.Label>
        <ReactHookInput name="description" as="textarea" />
      </Form.Group>
    </Stack>
  );

  const handleFormOneSubmit = () => setPart(part + 1);

  const handleFormTwoSubmit = async (data) => {
    const payload = {
      title: data.title,
      category: data.category.value,
      tags: data.tags.map((el) => el.value),
      image: data.image,
      description: data.description
    };
    const client = ContentFactory.createRequest("image");

    const promise = (
      editObj ? client.update({ ...payload, id: editObj.id }) : client.create(payload)
    )
      .then(onSuccess)
      .then(reset);

    showPromiseToast(promise, {
      pending: `${editObj ? "Updating" : "Creating"} image`,
      success: `Image ${editObj ? "updated" : "created"} successfully`,
      error: renderErrorToast()
    });
  };

  return (
    <Form onSubmit={handleSubmit(part === 1 ? handleFormOneSubmit : handleFormTwoSubmit)}>
      {part === 1 ? FormPartOne : FormPartTwo}
      <FormFooter part={part} setPart={setPart} parts={parts} />
    </Form>
  );
};

export default AddImageForm;

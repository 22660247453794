import React from "react";
import { useSearchParams } from "../../../hooks/searchParams";
import Dropdown from "../../Dropdown";
import STAKEHOLDERS from "../../../constants/stakeholders";

export default function StakeholdersFilters({ label, options, filterKey = "posted_by", ...props }) {
  const { searchParamsAsObject, addKey } = useSearchParams();
  const value = searchParamsAsObject[filterKey];
  return (
    <Dropdown
      value={value}
      label={label || "Stakeholders"}
      options={options || STAKEHOLDERS}
      onClick={(value) => addKey(filterKey, value)}
      {...props}
    />
  );
}

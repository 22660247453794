import { createFormData } from "../../../utils";
import Api, { ApiHeaders } from "../../api";

class ProfileDetailsAPI extends ApiHeaders {
  constructor(endpoint) {
    super();
    this.endpoint = endpoint;
  }

  read = async () => {
    const response = await Api.get(this.endpoint, {}, this.authenticationHeaders);
    return response.data;
  };

  update = async (payload) =>
    await Api.patch(this.endpoint, createFormData(payload), this.authenticationHeaders).then(
      (response) => response.data
    );
}

class BrandBasicDetailsAPI extends ProfileDetailsAPI {
  constructor() {
    super("/brand/basic-detail/");
  }
}

class BrandKYCDetailsAPI extends ProfileDetailsAPI {
  constructor() {
    super("/brand/kyc-detail/");
  }
}

class BrandDistributionNetworksAPI extends ProfileDetailsAPI {
  constructor() {
    super("/brand/distribution-network/");
  }
  read = async (id) =>
    await Api.get(`${this.endpoint}${id}/`, {}, this.authenticationHeaders).then(
      (response) => response.data
    );
  list = async (filters) => {
    var params = {};

    if (filters) {
      params = { ...params, ...filters };
    }

    const response = await Api.get(this.endpoint, params, this.authenticationHeaders);
    return response.data;
  };
  create = (payload) =>
    Api.post(this.endpoint, payload, this.authenticationHeaders).then((response) => response.data);

  delete = (id) =>
    Api.delete(`${this.endpoint}${id}/`, {}, this.authenticationHeaders).then(
      (response) => response.data
    );
  update = async (payload) =>
    await Api.patch(`${this.endpoint}${payload.id}/`, payload, this.authenticationHeaders).then(
      (response) => response.data
    );
}
class BrandBranchDetailAPI extends ProfileDetailsAPI {
  constructor() {
    super("/brand/branch-detail/");
  }
  read = async (id) =>
    await Api.get(`${this.endpoint}${id}/`, {}, this.authenticationHeaders).then(
      (response) => response.data
    );
  list = async (filters) => {
    var params = {};

    if (filters) {
      params = { ...params, ...filters };
    }

    const response = await Api.get(this.endpoint, params, this.authenticationHeaders);
    return response.data;
  };
  create = (payload) =>
    Api.post(this.endpoint, payload, this.authenticationHeaders).then((response) => response.data);

  delete = (id) =>
    Api.delete(`${this.endpoint}${id}/`, {}, this.authenticationHeaders).then(
      (response) => response.data
    );
  update = async (payload) =>
    await Api.patch(`${this.endpoint}${payload.id}/`, payload, this.authenticationHeaders).then(
      (response) => response.data
    );
}

export {
  BrandBasicDetailsAPI,
  BrandKYCDetailsAPI,
  BrandDistributionNetworksAPI,
  BrandBranchDetailAPI
};

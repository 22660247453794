import React from "react";
import BaseModal from "../Base";
import { Button, Modal } from "react-bootstrap";

export default function MaxPinsReachedModal({ show, onHide }) {
  return (
    <BaseModal show={show} onHide={onHide}>
      <Modal.Body>
        <div className="fw-bold text-center pt-4">
          You have already pinned 3 blogs. Please unpin a blog to make space for other one.
        </div>
        <div className="d-flex justify-content-evenly mt-4 pb-3">
          <Button
            variant="primary"
            style={{ width: "45%" }}
            className="text-uppercase"
            onClick={onHide}
          >
            Ok
          </Button>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

import React, { useEffect } from "react";
import ContentList from "../../components/Base/ContentList";
import { useSelector } from "react-redux";
import { useSearchParams } from "../../hooks/searchParams";
import LoadingContainer from "../../components/Containers/Loading";

const Catalogues = () => {
  const user = useSelector((state) => state.authReducer.user);
  const { getKey, addKey } = useSearchParams();
  const key = getKey("type");

  useEffect(() => {
    if (!key) {
      addKey("type", "Price");
    }
  }, [key]);
  return (
    <LoadingContainer loading={!key}>
      <ContentList type="catalogue" initialFilters={{ user: user.id }} />
    </LoadingContainer>
  );
};

export default Catalogues;

/* eslint-disable no-undef */
import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { isArray, isEmpty } from "lodash";
import { useAuth } from "hooks";
import ACCEPTED_FILE_TYPES from "constants/accepted-file-types";
import { getContentAbsoluteUrl } from "utils";
import COLORS from "constants/colors";
import { checkFileRejections } from "components/Uploaders/BaseUpload";
import FormError from "components/FormError";
import { isMobile } from "react-device-detect";

function UploadBannerImageForm({ user, onHide }) {
  const { banner_images } = user;
  const selectedImage = banner_images.filter((el) => el.is_selected === true);
  const [selected, setSelected] = useState(
    selectedImage && isArray(selectedImage) && !isEmpty(selectedImage) && selectedImage[0].id
  );
  const { updateBannerImage } = useAuth();
  const [error, setError] = useState({});

  const onSubmit = () => updateBannerImage(selected, () => onHide());

  const solidBackgrounds = banner_images.filter((el) => el.is_solid === true);
  const imageBackgrounds = banner_images.filter((el) => !el.is_solid);

  const UploadComponent = () => {
    const { uploadBannerImage } = useAuth();
    const handleFileUpload = (obj) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(obj);
      });

    const setCError = (name, value) => setError({ bannerImage: value });

    const onDrop = async (acceptedFiles, fileRejections) => {
      const isRejected = checkFileRejections(setCError, 1, "bannerImage", "image", fileRejections);
      if (isRejected) {
        return;
      }
      await handleFileUpload(acceptedFiles[0])
        .then(uploadBannerImage)
        .then(() => setError({}));
    };

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      maxSize: 1 * 1024 * 1024,
      accept: ACCEPTED_FILE_TYPES.image
    });
    return (
      <div
        className="bg-white d-flex justify-content-center align-items-center w-100"
        onClick={open}
        {...getRootProps()}
        style={{ border: `1px dashed ${COLORS.GREY2}` }}
      >
        <div>
          <BsPlus
            className="d-block mx-auto mb-2 mt-3 rounded-circle"
            size={25}
            style={{ backgroundColor: COLORS.GREY1 }}
          />
          <input {...getInputProps()} />
          <p className="fw-bold px-2 text-center" style={{ fontSize: "72.5%" }}>
            Upload from Device
          </p>
        </div>
      </div>
    );
  };

  const solidBackgroundsRow = (
    <Row xs={3} sm={6} className="pb-5">
      {solidBackgrounds.map((el, index) => (
        <Col
          key={`${el}-${index}`}
          className="p-1 d-flex justify-content-center align-items-center"
        >
          <div
            style={selected === el.id ? { border: `1.5px solid ${COLORS.PRIMARY_ORANGE}` } : {}}
            onClick={() => setSelected(el.id)}
            className="cursor-pointer position-relative p-1  w-100 h-100"
          >
            {el.id === selected && (
              <div
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  right: "0",
                  top: "0"
                }}
                className="bg-orange position-absolute rounded-circle d-flex justify-content-center align-items-center"
              >
                <FaCheck color="white" size={12} />
              </div>
            )}
            <Image
              src={getContentAbsoluteUrl(el.image)}
              className="w-100 h-100 object-fit-contain"
            />
          </div>
        </Col>
      ))}
    </Row>
  );

  const imageBackgroundsRow = (
    <Row xs={6} className="pb-5">
      {!isMobile && (
        <Col className="d-flex justify-content-center align-items-center">
          <UploadComponent />
        </Col>
      )}
      {imageBackgrounds.map((el, index) => (
        <Col
          key={`${el}-${index}`}
          className="p-1 d-flex justify-content-center align-items-center"
        >
          <div
            style={selected === el.id ? { border: `1.5px solid ${COLORS.PRIMARY_ORANGE}` } : {}}
            onClick={() => setSelected(el.id)}
            className="cursor-pointer position-relative p-1  w-100 h-100"
          >
            {el.id === selected && (
              <div
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  right: "0",
                  top: "0"
                }}
                className="bg-orange position-absolute rounded-circle d-flex justify-content-center align-items-center"
              >
                <FaCheck color="white" size={12} />
              </div>
            )}
            <Image
              src={getContentAbsoluteUrl(el.image)}
              className="w-100"
              style={{ height: "5.25rem", objectFit: "contain" }}
            />
          </div>
        </Col>
      ))}
    </Row>
  );

  return (
    <Container className={`px-${isMobile ? 3 : 5} pt-${isMobile ? 3 : 5}`}>
      {isMobile && (
        <div className="mb-4">
          <UploadComponent />
        </div>
      )}
      <h6>Image Backgrounds</h6>
      {imageBackgroundsRow}
      <h6>Solid Backgrounds</h6>
      {solidBackgroundsRow}
      {error && error.bannerImage && (
        <FormError className="d-flex justify-content-center">{error.bannerImage.message}</FormError>
      )}
      <Button className="d-block px-4 ms-auto mb-4" onClick={onSubmit}>
        APPLY
      </Button>
    </Container>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(UploadBannerImageForm);

import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import ACTION_TYPES from "../constants/action-types";
import contentReducer from "./Content/reducer";
import productsReducer from "./Products/reducer";

const appReducer = combineReducers({ authReducer, contentReducer, productsReducer });

const rootReducer = (state, action) => {
  if (action.type === ACTION_TYPES.AUTH.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

import COLORS from "constants/colors";
import { isFunction } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

export default function AppLink({ children, to = "", onClick }) {
  return to ? (
    <Link to={to}>{children}</Link>
  ) : (
    <p
      onClick={isFunction(onClick) && onClick}
      className="text-decoration-underline fw-bold mb-0 cursor-pointer"
      style={{ color: COLORS.PRIMARY_ORANGE }}
    >
      {children}
    </p>
  );
}

import React from "react";
import ProductsContainer from "./Container";
import BrandsWithProducts from "components/BrandsWithProducts";
import { Container } from "react-bootstrap";

export default function AllBrands() {
  return (
    <ProductsContainer>
      <Container className="pb-5 pt-3">
        <h6>Filter By Brands</h6>
        <BrandsWithProducts page_size={16} inifinteScroll />
      </Container>
    </ProductsContainer>
  );
}

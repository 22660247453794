import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isDate, isNil, omitBy } from "lodash";
import ContentFactory from "apiClients/ContentFactory";
import DateParser from "date";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import CheckBox from "components/Inputs/CheckBox";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import JOB_CONSTANTS from "constants/Jobs";
import Price from "components/Inputs/Price";
import { CITIES, STATES } from "constants/states";
import CustomDate from "components/Inputs/CustomDate";
import WYSIWYG from "components/Inputs/WSIWYG";
import SKILLS from "constants/skills";
import Email from "components/Inputs/Email";
import DropdownFactory from "apiClients/DropdownFactory";
import { isMobile } from "react-device-detect";

export default function AddJobForm({ id }) {
  const navigate = useNavigate();
  const [publish, setPublish] = useState(false);

  const { handleSubmit, watch, setValue, getValues } = useFormContext();
  const is_range = watch("is_range");
  const is_fixed_amount = watch("is_fixed_amount");
  const is_not_disclosed = watch("is_not_disclosed");
  const selectedState = watch("location");

  const client = ContentFactory.createRequest("job");

  useEffect(() => {
    if (is_range) {
      if (is_fixed_amount) {
        setValue("is_fixed_amount", false);
        setValue("fixed_amount", undefined);
      }
      if (is_not_disclosed) {
        setValue("is_not_disclosed", false);
      }
    }
  }, [is_range]);

  useEffect(() => {
    if (is_fixed_amount) {
      if (is_range) {
        setValue("is_range", false);
        setValue("salary_min_amount", undefined);
        setValue("salary_max_amount", undefined);
      }
      if (is_not_disclosed) {
        setValue("is_not_disclosed", false);
      }
    }
  }, [is_fixed_amount]);

  useEffect(() => {
    if (is_not_disclosed) {
      if (is_range) {
        setValue("is_range", false);
        setValue("salary_min_amount", undefined);
        setValue("salary_max_amount", undefined);
      }
      if (is_fixed_amount) {
        setValue("is_fixed_amount", false);
        setValue("fixed_amount", undefined);
      }
    }
  }, [is_not_disclosed]);

  useEffect(() => {});
  const onSubmit = (data) => {
    var salary_type;
    if (is_fixed_amount) {
      salary_type = "Fixed";
    }
    if (is_range) {
      salary_type = "Range";
    }
    if (is_not_disclosed) {
      salary_type = "Not Disclosed";
    }

    var payload = {
      ...data,
      valid_till: isDate(data.valid_till)
        ? DateParser.formatDateToYYYYMMDD(data.valid_till)
        : undefined,
      category: data.category && data.category.value ? data.category.value : undefined,
      employment_type:
        data.employment_type && data.employment_type.value ? data.employment_type.value : undefined,
      location: data.location && data.location.value ? data.location.value : undefined,
      workplace_type:
        data.workplace_type && data.workplace_type.value ? data.workplace_type.value : undefined,
      position: data.position && data.position.value ? data.position.value : undefined,
      min_experience_required:
        data.min_experience_required && data.min_experience_required.value
          ? data.min_experience_required.value
          : undefined,
      salary_type,
      city: data.city && data.city.value ? data.city.value : undefined,
      core_competencies: (data.core_competencies || []).map((el) => el.value),
      technical_skills: (data.technical_skills || []).map((el) => el.value),
      management_skills: (data.management_skills || []).map((el) => el.value),
      personal_skills: (data.personal_skills || []).map((el) => el.value),
      questions_to_ask: (data.questions_to_ask || []).map((el) => el.value),
      is_drafted: !publish,
      under_review: publish,
      closed: false,
      is_posted: false
    };

    payload = omitBy(payload, isNil);

    var promise;
    if (id) {
      promise = client
        .update({ ...payload, id }, false)
        .then(() => navigate("/my-activity/my-jobs/posted"));
    } else {
      promise = client.create(payload, false).then(() => navigate("/my-activity/my-jobs/posted"));
    }
    showPromiseToast(promise, {
      pending: "Saving job",
      success: "Job saved successfully",
      error: renderErrorToast()
    });
  };

  useEffect(() => {
    setValue("city", null);
  }, [selectedState]);

  const loadCategories = (search) =>
    DropdownFactory.createRequest("job-categories").list({ search });

  const loadJobCoreCompetence = (search) =>
    DropdownFactory.createRequest("job-core-competence").list({ search });

  const loadPositions = (search) => DropdownFactory.createRequest("job-positions").list({ search });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Form.Group>
          <CheckBox name="urgently_hiring" label="Urgently Hiring" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Job Title*</Form.Label>
          <ReactHookInput name="title" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Job Category*</Form.Label>
          <Select loadOptions={loadCategories} name="category" isAsync required={publish} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Position/Designation*</Form.Label>
          <Select loadOptions={loadPositions} name="category" isAsync required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Employment Type*</Form.Label>
          <Select
            name="employment_type"
            required={publish}
            options={JOB_CONSTANTS.EMPLOYMENT_TYPES}
          />
        </Form.Group>
        <div>
          <Form.Label className="mb-2">Job Location*</Form.Label>
          <Row xs={1} sm={2}>
            <Col>
              <Form.Group>
                <Select
                  name="location"
                  required={publish}
                  options={STATES}
                  placeholder="Select a state"
                />
              </Form.Group>
            </Col>
            <Col className={isMobile ? "mt-4" : ""}>
              <Form.Group>
                <Select
                  name="city"
                  placeholder="Select a city"
                  options={
                    (selectedState &&
                      selectedState.value &&
                      selectedState.value in CITIES &&
                      CITIES[selectedState.value].map((el) => ({
                        value: el,
                        label: el
                      }))) ||
                    []
                  }
                  required={publish}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Form.Group>
          <Form.Label>Workplace Type*</Form.Label>
          <Select
            name="workplace_type"
            required={publish}
            options={JOB_CONSTANTS.WORKPLACE_TYPES}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Salary Range Annually</Form.Label>

          <Stack direction="horizontal" gap={4}>
            {[
              {
                name: "is_range",
                label: "Range"
              },
              {
                name: "is_fixed_amount",
                label: "Fixed Amount"
              },
              {
                name: "is_not_disclosed",
                label: "Not Disclosed"
              }
            ].map((checkbox, index) => (
              <div className={index !== 0 ? "ms-3" : ""} key={index}>
                <CheckBox name={checkbox.name} label={checkbox.label} />
              </div>
            ))}
          </Stack>
          {(is_range || is_fixed_amount) && (
            <Form.Group className="mt-4">
              {is_range ? (
                <>
                  <Form.Label>Please follow format min-max amount.</Form.Label>
                  <Price
                    name="salary_min_amount"
                    required={is_range}
                    placeholder="Minimum"
                    key="salary_min_amount"
                  />
                  <div className="mt-3">
                    <Price
                      name="salary_max_amount"
                      key="salary_max_amount"
                      required={is_range}
                      placeholder="Maximum"
                    />
                  </div>
                </>
              ) : is_fixed_amount ? (
                <>
                  <Form.Label>Please enter the fixed amount.</Form.Label>
                  <Price
                    name="fixed_amount"
                    key="fixed_amount"
                    required={is_fixed_amount}
                    placeholder="Fixed Amount"
                  />
                </>
              ) : null}
            </Form.Group>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Minimum Experience Required*</Form.Label>
          <Select
            name="min_experience_required"
            required={publish}
            options={JOB_CONSTANTS.MINIMUM_EXPERIENCE_REQUIRED}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Number of Vacancies*</Form.Label>
          <ReactHookInput name="vacancies" type="number" required={publish} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Apply Before*</Form.Label>
          <CustomDate name="valid_till" minDate={new Date()} required={publish} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Job Description*</Form.Label>
          <WYSIWYG name="description" required={publish} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Core Competencies</Form.Label>
          <Select
            className="w-100 mt-1"
            name="core_competencies"
            loadOptions={loadJobCoreCompetence}
            isAsync
            isMulti
            isCreateable
          />
        </Form.Group>
        {[
          {
            name: "technical_skills",
            label: "Technial Skills",
            options: SKILLS.TECHNICAL_SKILLS,
            required: false,
            min: publish ? 3 : 0
          },
          {
            name: "management_skills",
            label: "Management Skills",
            options: SKILLS.MANAGEMENT_SKILLS,
            required: false,
            min: publish ? 3 : 0
          },
          {
            name: "personal_skills",
            label: "Personal Skills",
            options: SKILLS.PERSONAL_SKILLS,
            required: false,
            min: publish ? 3 : 0
          },
          {
            name: "questions_to_ask",
            label: "Additional Questions To Ask",
            createable: true,
            options: [],
            inputLabel: "What is the question?",
            placeholder: "What is the question?",
            required: false
          }
        ].map((el) => (
          <Form.Group key={el.label}>
            <Form.Label>
              {el.label}
              {el.maxLength && (
                <span className="text-muted fst-italic ms-2">(Max {el.maxLength})</span>
              )}
            </Form.Label>
            <Select
              className="w-100 mt-1"
              name={el.name}
              isMulti
              minLength={el.min || 0}
              isOptionDisabled={() => {
                const value = getValues(el.name);
                return el.maxLength && value && el.maxLength === value.length;
              }}
              isCreateable={el.createable}
              options={el.options}
              placeholder={el.placeholder}
              required={el.required}
            />
          </Form.Group>
        ))}
        <Form.Group>
          <Form.Label>Email Address to receive responses*</Form.Label>
          <Email name="email_address_to_receive_response" required={publish} />
        </Form.Group>
        <div className={`my-4 ${isMobile ? "d-flex justify-content-between" : ""}`}>
          <Button
            variant="outline-primary"
            style={{ width: isMobile ? "47.5%" : "25%" }}
            type="submit"
            onClick={() => setPublish(false)}
          >
            SAVE
          </Button>
          <Button
            className={`ms-${isMobile ? 0 : 4}`}
            style={{ width: isMobile ? "47.5%" : "25%" }}
            type="submit"
            onClick={() => setPublish(true)}
          >
            POST
          </Button>
        </div>
      </Stack>
    </Form>
  );
}

import FormError from "components/FormError";
import Password from "components/Inputs/Password";
import React, { useState } from "react";
import { Form, Stack } from "react-bootstrap";

export default function ResetPasswordForm() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      // Passwords match, do further processing or submit the form
      setError("");
      // Handle form submission or further processing here
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={4}>
        <Password
          type="password"
          label="Password*"
          placeholder="Enter password"
          value={password}
          onChange={handlePasswordChange}
        />

        <Password
          type="password"
          label="Confirm Password*"
          placeholder="Confirm password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        {error && <FormError error={error} />}
      </Stack>
    </Form>
  );
}

import { SIDEBAR_ICONS } from "../../constants/icons";
import ContentLeads from "../../screens/UserContent/Leads";

export default {
  label: "My Leads",
  path: "my-leads",
  menuPath: "/my-activity/my-leads/",
  component: <ContentLeads />,
  icon: SIDEBAR_ICONS.MY_LEADS_SIDEBAR_ICON
};

import { isArray, isEmpty, isNull, isString, isUndefined } from "lodash";

export const validatePhoneNumber = (value) => {
  const regex = /^[6-9]\d{9}$/;
  return regex.test(value);
};

export const validateEmail = (value) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value);
};

export const validateDOB = (value) => {
  const regex = /^(\d{2})-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d{4})$/;
  return regex.test(value);
};

function isValidGSTIN(inputValue) {
  const gstinFormat = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return gstinFormat.test(inputValue);
}
function isValidPAN(pan) {
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

  if (!pan || typeof pan !== "string" || pan.length !== 10 || !pan.match(panRegex)) {
    return false;
  }

  // Validate the 4th character (5th position) must be a letter
  const fourthCharacter = pan[3];
  if (!/[A-Z]/.test(fourthCharacter)) {
    return false;
  }

  // Validate the last character (10th position) must be a letter
  const lastCharacter = pan[9];
  if (!/[A-Z]/.test(lastCharacter)) {
    return false;
  }

  return true;
}

const VALIDATORS = {
  gst: (required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
        if (isString(value) && !isEmpty(value) && !isValidGSTIN(value)) {
          return "Invalid GST number";
        }
      }
    };
  },
  pan: (required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
        if (!isValidPAN(value)) {
          return "Invalid PAN number";
        }
      }
    };
  },
  required: { required: "This field is required" },
  phone: (required = false) => {
    return {
      validate: (value) => {
        if (
          required === true &&
          (isUndefined(value) || isNull(value) || (isString(value) && isEmpty(value)))
        ) {
          return "This field is required";
        }
        if (isString(value) && !isEmpty(value) && !validatePhoneNumber(value)) {
          return "Invalid phone number";
        }
      }
    };
  },
  email: (required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
        if (required && !validateEmail(value)) {
          return "Invalid email address";
        }
      }
    };
  },
  dob: (required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
      }
    };
  },
  maxLengthArray: (maxLength, required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
        if (isArray(value) && value.length < maxLength) {
          return `Please select atleast ${maxLength}`;
        }
      }
    };
  },
  minLengthArray: (minLength, required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
        if (isArray(value) && value.length < minLength) {
          return `Please select atleast ${minLength}`;
        }
      }
    };
  },
  otp: (len, required = false) => {
    return {
      validate: (value) => {
        if (required === true && (isUndefined(value) || isNull(value))) {
          return "This field is required";
        }
        if (value.length !== len) {
          return `OTP needs to be of length ${len}`;
        }
      }
    };
  },
  behance_url: {
    pattern: {
      value: /^(https?:\/\/)?(www\.)?behance\.net\/[a-z0-9\-_]+(\/.*)?$/i,
      message: "Invalid Behance url"
    }
  },
  pinterest_url: {
    pattern: {
      value: /^(https?:\/\/)?(www\.)?pinterest\.com\/[a-z0-9\-_]+(\/.*)?$/i,
      message: "Invalid Pinterest url"
    }
  },
  instagram_url: {
    patter: {
      value: /^(https?:\/\/)?(www\.)?instagram\.com\/[a-z0-9_]+(\/.*)?$/i,
      message: "Invalid Instagram url"
    }
  }
};

export default VALIDATORS;

import React, { useState } from "react";
import { Accordion, Button, CloseButton, Col, Image, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "../../../hooks/searchParams";
import DropdownCheckbox, { DropDownCheckboxMenuItem } from "../../Inputs/DropdownCheckbox";
import { STATES } from "../../../constants/states";
import { useNavigationInfo } from "../../../hooks";
import JOB_CONSTANTS from "../../../constants/Jobs";
import { AREAS_OF_INTERESTS, FIELD_OF_STUDIES } from "../../../constants/Profile/Student";
import { isMobile } from "react-device-detect";
import FILTER_ICON from "assets/icons/filter.png";
import Sheet from "react-modal-sheet";
import BottomSheet from "components/Modals/BottomSheet";

export default function CandidateFilters({ filters, setFilters }) {
  const [isOpen, setOpen] = useState(false);

  const {
    job_type,
    experience_level,
    education_level,
    field_of_study,
    open_to_work,
    preferred_location,
    areas_of_interests
  } = filters;

  const { isCandidateSearch } = useNavigationInfo();
  const navigate = useNavigate();
  const { setQueryFromObj, resetQuery, toQuery } = useSearchParams();

  const handleSetFilters = (name, value) => {
    if (!value && name in Object.keys(filters)) {
      delete filters[name];
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleSearch = () => {
    setQueryFromObj({ ...filters });
    if (!isCandidateSearch) {
      navigate(`/jobs/recruiter/search/?${toQuery(filters)}`);
    }
  };
  const renderOptions = (title, options, name, value) => (
    <Col className={`px-1 ${isMobile ? "py-2" : "py-0"}`}>
      <DropdownCheckbox
        title={title}
        name={name}
        options={options}
        setFilter={handleSetFilters}
        value={value}
      />
    </Col>
  );

  const clearFilters = (e) => {
    e.preventDefault();
    setFilters({});
    resetQuery();
  };

  const handleItemClick = (name, value, newValue) => {
    handleSetFilters(
      name,
      value.includes(newValue) ? value.filter((el) => el !== newValue) : [...value, newValue]
    );
  };

  const AccordionFilter = ({ item, eventKey }) => {
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <span className="fw-bold">{item.title}</span>
        </Accordion.Header>
        <Accordion.Body className="px-0 pb-0 pt-2">
          {item.options.map((el, itemIndex) => (
            <DropDownCheckboxMenuItem
              key={`${itemIndex + el.label}-dropdown-menu-item`}
              item={el}
              handleItemClick={() => handleItemClick(item.name, item.value || [], el.value)}
              checked={(item.value || []).includes(el.value)}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const FiltersInAccordion = ({ list }) => {
    return (
      <Accordion defaultActiveKey="0">
        {list.map((item, index) => (
          <AccordionFilter
            key={`${index + item.title}-accordion-item`}
            item={item}
            eventKey={index}
          />
        ))}
      </Accordion>
    );
  };

  const filterOptionsArray = [
    {
      title: "Job Type",
      options: JOB_CONSTANTS.EMPLOYMENT_TYPES,
      name: "job_type",
      value: job_type
    },
    {
      title: "Experience Level",
      options: JOB_CONSTANTS.EXPERIENCE_LEVEL,
      name: "experience_level",
      value: experience_level
    },
    {
      title: "Education Level",
      options: [
        { label: "PhD", value: "PhD" },
        { label: "Post Graduation", value: "Post Graduation" },
        { label: "Graduation", value: "Graduation" },
        { label: "Diploma", value: "Diploma" }
      ],
      name: "education_level",
      value: education_level
    },
    {
      title: "Field of Study",
      options: FIELD_OF_STUDIES,
      name: "field_of_study",
      value: field_of_study
    },
    {
      title: "Open to Work",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false }
      ],
      name: "open_to_work",
      value: open_to_work
    }
  ];

  return (
    <Stack className={`rounded shadow p-${isMobile ? 3 : 5} bg-white`} gap={4}>
      <Row xs={1} sm={isCandidateSearch ? 3 : 2}>
        {[
          {
            title: "Areas of Interests",
            options: AREAS_OF_INTERESTS,
            name: "areas_of_interests",
            value: areas_of_interests
          },
          {
            title: "Job Location",
            options: STATES,
            name: "preferred_location",
            value: preferred_location
          }
        ].map((option) => renderOptions(option.title, option.options, option.name, option.value))}
        {isCandidateSearch && (
          <Col xs={3}>
            <Button className="w-100" onClick={handleSearch}>
              SEARCH
            </Button>
          </Col>
        )}
      </Row>

      {(!isMobile || !isCandidateSearch) && (
        <Row>
          <Row className="mb-1 pe-0">
            <Col>
              <h6>Add Filters</h6>
            </Col>
            {isCandidateSearch && (
              <Col className="text-end px-0">
                <Link onClick={clearFilters}>Clear All</Link>
              </Col>
            )}
          </Row>
          {filterOptionsArray.map((option) =>
            renderOptions(option.title, option.options, option.name, option.value)
          )}
        </Row>
      )}
      {isMobile && isCandidateSearch && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div
              onClick={() => setOpen(true)}
              className="d-flex justify-content-between align-items-center border border-dark rounded px-4 py-2"
            >
              <Image src={FILTER_ICON} style={{ width: "1rem", objectFit: "contain" }} />
              <h6 className="ms-2 mb-0">FILTERS</h6>
            </div>
            <Link onClick={clearFilters}>Clear All</Link>
          </div>
          <BottomSheet open={isOpen} close={() => setOpen(false)}>
            <div className="mx-3 mb-3 d-flex justify-content-between cursor-pointer">
              <h5 className="fw-bold">Filters</h5>
              <CloseButton onClick={() => setOpen(false)} />
            </div>
            <Sheet.Scroller>
              <FiltersInAccordion list={[...filterOptionsArray]} />
            </Sheet.Scroller>
          </BottomSheet>
        </>
      )}
      {isMobile ||
        (!isCandidateSearch && (
          <Button
            className="px-5 mx-auto"
            style={{ width: isMobile ? "100%" : "max-content" }}
            onClick={handleSearch}
          >
            SEARCH CANDIDATES
          </Button>
        ))}
    </Stack>
  );
}

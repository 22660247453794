import { BrandBranchDetailAPI } from "apiClients/Profile/Brand/ProfileDetails";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";
import Email from "components/Inputs/Email";
import Phone from "components/Inputs/Phone";
import ReactHookInput from "components/Inputs/ReactHookInput";
import AddressForm from "forms/Address";
import { useScrollToTop } from "hooks";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export default function BranchDetailsForm({ onSuccess, detail }) {
  const { setValue, reset } = useFormContext();
  const client = new BrandBranchDetailAPI();
  const { scrollToTop } = useScrollToTop();

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (detail) {
      [
        "branch_contact_person_name",
        "designation",
        "contact_number",
        "email",
        "country",
        "pincode",
        "address_line1"
      ].forEach((el) => setValue(el, detail[el]));
      ["city", "state"].forEach((el) =>
        setValue(el, {
          value: detail[el],
          label: detail[el]
        })
      );
    }
  }, []);
  const onSubmit = (data) => {
    const payload = { ...data, city: data.city.value, state: data.state.value };
    (detail ? client.update({ ...payload, id: detail.id }) : client.create(payload))
      .then(onSuccess)
      .then(reset)
      .then(() => scrollToTop());
  };

  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Branch Contact Person Name*</Form.Label>
        <ReactHookInput name="branch_contact_person_name" required />
      </Form.Group>
      <Form.Group>
        <Form.Label>Designation*</Form.Label>
        <ReactHookInput name="designation" required />
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Number*</Form.Label>
        <Phone name="contact_number" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Email ID*</Form.Label>
        <Email required />
      </Form.Group>

      <AddressForm />
    </DetailsFormContainer>
  );
}

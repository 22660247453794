import React from "react";
import ContentTable from "../../components/Base/ContentTable";
import SortByFilter from "../../components/Filters/SortBy";
import StatusFilter from "../../components/Filters/Status";
import StakeholdersFilters from "../../components/Filters/Stakeholders";
import MobileRequestCard from "components/Cards/MobileRequestCard";

function ContentRequests() {
  return (
    <ContentTable
      type="request"
      title="My Requests"
      subTitle="All your requests will apear here"
      MobileCard={MobileRequestCard}
      filterPillsOptions={[
        { value: null, label: "All" },
        { value: "Sample", label: "Sample" },
        { value: "Quotation", label: "Quotation" },
        { value: "Appointment", label: "Appointment" },
        { value: "Collaboration", label: "Collaboration" },
        { value: "Project", label: "Project" },
        { value: "Job Offers", label: "Job Offers" },
        { value: "Others", label: "Others" }
      ]}
      FilterComponent={
        <>
          <StakeholdersFilters key="stakeholder-dropdown-filter" filterKey="recipient__group" />
          <StatusFilter key="status-dropdown-filter" options={[{ label: "Status", value: null }]} />
          <SortByFilter key="sort-dropdown-filter" />
        </>
      }
    />
  );
}

export default ContentRequests;

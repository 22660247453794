import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import FilterPills from "../FilterPills";
import { useSearchParams } from "../../../hooks/searchParams";

export default function MoodBoardContentFilterPills({ isValidType }) {
  const { getKey, addKey } = useSearchParams();
  const type = getKey("type");
  const OPTIONS = [
    { value: "image", label: "Images" },
    { value: "video", label: "Videos" },
    { value: "project", label: "Projects" },
    { value: "product", label: "Products" }
  ];

  useEffect(() => {
    if (!type || !isValidType) {
      addKey("type", OPTIONS[0].value);
    }
  }, [type]);

  return (
    <Row className="mx-0 mt-4">
      <Col className="px-0">
        <FilterPills options={OPTIONS} name="type" />
      </Col>
    </Row>
  );
}

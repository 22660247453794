import React from "react";
import Avatar from "react-avatar";
import { isArray, isEmpty, isString } from "lodash";
import { useSelector } from "react-redux";
import { getContentAbsoluteUrl, isBase64 } from "utils";

export const getUserProfileImage = (userObj) => {
  var { profile_images } = userObj;
  const profile_image = (profile_images || []).filter((el) => el.is_selected === true);
  if (isArray(profile_image) && !isEmpty(profile_image)) {
    return getContentAbsoluteUrl(profile_image[0].image);
  }
};

function ProfileImage({ customUser, customImage, style, round = true }) {
  var user;
  if (customUser) {
    user = customUser;
  } else {
    user = useSelector((state) => state.authReducer.user);
  }
  const profile_image = getUserProfileImage(user);
  const { name } = user;

  return isString(customImage) || profile_image ? (
    <Avatar
      style={{ objectFit: "contain", ...style }}
      src={
        customImage
          ? isBase64(customImage)
            ? customImage
            : getContentAbsoluteUrl(customImage)
          : profile_image
      }
      round={round}
    />
  ) : (
    <Avatar name={name || "ML User"} round={round} className="w-100 h-100" style={{ ...style }} />
  );
}

export default ProfileImage;

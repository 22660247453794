import ACTION_TYPES from "constants/action-types";

const initialState = {
  isLoadingFilters: false,
  filters: undefined,
  categories: undefined,
  isLoadingCategories: false
};
export default function productsReducer(state = initialState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_REQUEST:
      return { ...newState, isLoadingFilters: true };
    case ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_SUCCESS:
      return { ...newState, isLoadingFilters: false, filters: action.result };
    case ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_FAILURE:
      return { ...newState, isLoadingFilters: false };
    case ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_REQUEST:
      return { ...newState, isLoadingCategories: true };
    case ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_SUCCESS:
      return { ...newState, isLoadingCategories: false, categories: action.result };
    case ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_FAILURE:
      return { ...newState, isLoadingCategories: false };
    default:
      return newState;
  }
}

const SKILLS = {
  TECHNICAL_SKILLS: [
    "Site Engineering",
    "Quantity surveying",
    "Project Management",
    "Project Billing",
    "Risk Assessment",
    "Design Verification",
    "Autocad",
    "STAAD Pro",
    "Space Planning",
    "Navisworks",
    "Microsoft Excel",
    "Microsoft Word",
    "Microsoft PowerPoint",
    "Solidworks",
    "Primavera",
    "Map Reading",
    "Reviewing Blueprints",
    "Design Techniques",
    "Civil Engineering",
    "Building Construction",
    "Quality Assurance/Quality Control",
    "3ds Max",
    "ArchiCAD",
    "Rhino",
    "Revit",
    "Graphic Designing",
    "Illustrator",
    "Photoshop",
    "Corel Draw",
    "Graphic Tools",
    "Visual Effects",
    "Interior Designing",
    "Live Interior 3d",
    "Infurnia",
    "SketchUp Pro",
    "Knowledge of Design Trends and Styles",
    "Color Basics Knowledge",
    "Understanding of Spatial Balance",
    "Autodesk 3ds Max",
    "Substance 3D by Adobe",
    "Photography",
    "Video Editing",
    "Video making",
    "Premier Pro",
    "Light Room CC",
    "Final Cut Pro",
    "Sketch/Drawing",
    "Model Building",
    "Design",
    "Technical Drawing"
  ]
    .sort()
    .map((skill) => ({ value: skill, label: skill })),
  MANAGEMENT_SKILLS: [
    "Team Management",
    "Leadership",
    "Organisational Skills",
    "Budgeting Skills",
    "Vendor Management"
  ]
    .sort()
    .map((skill) => ({ value: skill, label: skill })),
  PERSONAL_SKILLS: [
    "Communication",
    "Critical thinking",
    "Attention To detail",
    "Problem Solving",
    "Time Management",
    "Negotiation",
    "Creativity",
    "Collaboration",
    "Visualization",
    "Teamwork"
  ]
    .sort()
    .map((skill) => ({ value: skill, label: skill }))
};

export default SKILLS;

import React from "react";
import { Form } from "react-bootstrap";
import { useSearchParams } from "../../../hooks/searchParams";

export default function CheckboxFilter({ attribute, label }) {
  const { addKey, getKey } = useSearchParams();
  const value = getKey(attribute);
  return (
    <Form.Check
      checked={value === "true"}
      onChange={() => addKey(attribute, !value)}
      label={label}
      style={{ fontSize: "90%" }}
    />
  );
}

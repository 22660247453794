import React, { useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useController, useFormContext } from "react-hook-form";
import { isObject, isUndefined } from "lodash";
import FormError from "../../FormError";

function WYSIWYG({ name, required = false }) {
  const editorRef = useRef(null);
  const {
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    if (required === false && errors && errors[name]) {
      clearErrors(name);
    }
  }, [required]);

  const { field } = useController({
    name,
    control,
    rules: { required: required ? "This field is required" : undefined }
  });

  const plugins = "lists code emoticons image wordcount quickbars preview link fullscreen";
  const toolbar =
    "undo redo | styles | bold italic | " +
    "alignleft aligncenter alignright alignjustify | " +
    "outdent indent | numlist bullist | emoticons | image | wordcount | preview | link | fullscreen";

  const content_style = "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }";

  return (
    <>
      <Editor
        apiKey="fgf1jfl7jfmc6oo21djrmwlyrnldhzieagc6f74joarc1kyg"
        onInit={(_, editor) => (editorRef.current = editor)}
        init={{
          selector: "textarea#emoticons",
          height: 500,
          plugins,
          toolbar,
          quickbars_insert_toolbar: true,
          quickbars_image_toolbar: true,
          quickbars_selection_toolbar: true,
          link_default_target: "_blank",
          content_style
        }}
        value={field.value}
        onEditorChange={(e) => field.onChange(e)}
      />
      {!isUndefined(errors) && isObject(errors) && errors[name] && (
        <FormError error={errors[name].message} />
      )}
    </>
  );
}

export default WYSIWYG;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import { FaBehanceSquare, FaLinkedinIn } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { BsPinterest } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { isArray, isEmpty, isNull, isObject, isString, isUndefined } from "lodash";
import ContentFactory from "../apiClients/ContentFactory";
import COLORS from "../constants/colors";
import LoadingContainer from "../components/Containers/Loading";
import RelatedContainer from "../components/Containers/RelatedContainer";
import { SavedEducation } from "../forms/Content/AddResume/Sections/Education";
import { SavedExperience } from "../forms/Content/AddResume/Sections/Experience";
import ImageCard from "../components/Cards/Image";
import Video from "../components/Cards/Video";
import Portfolio from "../components/Cards/Portfolio";
import Project from "../components/Cards/Project";
import Thesis from "../components/Cards/Thesis";
import Blog from "../components/Cards/Blog";
import ProfileImage from "../components/ProfileImage";
import ProfileBannerImage from "../components/ProfileBannerImage";
import AppLink from "../components/AppLink";
import { useNavigationInfo, useScrollToTop } from "../hooks";
import ShareButton from "../components/Buttons/Share";
import FavouriteButton from "../components/Buttons/Favourite";
import ConnectModal from "../components/Modals/Connect";
import ContentDetailsNavigator from "./ContentDetails";
import ProfileCard from "../components/Cards/Profile";
import { SavedCertificationCourses } from "../forms/Content/AddResume/Sections/Courses";
import { SavedAwardsAchievement } from "../forms/Content/AddResume/Sections/AwardsAchievements";
import Location from "../components/Location";
import CatalogueCard from "../components/Cards/Catalogue";
import BaseModal from "../components/Modals/Base";
import Table from "../components/Table";
import TableColumnsFactory from "../factory/TableColumns";
import ARVR from "../components/Cards/ARVR";
import { isMobile } from "react-device-detect";

function SocialMediaIcons({ personal_details }) {
  const { behance, pinterest, linkedin, instagram } = personal_details;
  const { isSampleProfile } = useNavigationInfo();

  return (
    <Stack gap={3} direction="horizontal">
      {[
        { platform: "linkedin", link: linkedin, icon: <FaLinkedinIn size={20} color="black" /> },
        { platform: "behance", link: behance, icon: <FaBehanceSquare size={20} color="black" /> },
        { platform: "pinterest", link: pinterest, icon: <BsPinterest size={20} color="black" /> },
        {
          platform: "instagram",
          link: instagram,
          icon: <AiFillInstagram size={24} color="black" />
        }
      ].map(
        (socialMedia, index) =>
          socialMedia.link && (
            <a
              key={index}
              href={isSampleProfile ? undefined : socialMedia.link}
              target="_blank"
              rel="noreferrer"
            >
              {socialMedia.icon}
            </a>
          )
      )}
    </Stack>
  );
}

function SectionContainer({ title, children }) {
  return (
    <div style={{ backgroundColor: "white" }} className={`px-${isMobile ? 3 : 4} pt-4 pb-2 mb-2`}>
      {title && <h5 className="mb-3 fw-bold">{title}</h5>}
      {children}
    </div>
  );
}

function ContentSectionContainer({ children }) {
  return <SectionContainer>{children}</SectionContainer>;
}

function Skill({ title, list }) {
  if (isObject(list[0])) {
    list = list.map((el) => el.value);
  }
  return (
    <div>
      <h6 className="mb-0 fw-bold">{title}</h6>
      <p className="mt-1 mb-0">{list.join(", ")}</p>
    </div>
  );
}

function ContentSection({ type, list, title, CardComponent, handleGetProfileDetails }) {
  const { isSampleProfile, isPreview } = useNavigationInfo();
  const [viewAll, setViewAll] = useState(false);
  const navigate = useNavigate();
  const lookupField = ["catalogues"].includes(type) ? "id" : "slug";

  const firstId = list[0][lookupField];
  return (
    <ContentSectionContainer>
      <div className="d-flex mb-3 justify-content-between align-items-center">
        <h5 className="mb-0 fw-bold">
          {title} ({list.length})
        </h5>
        {!isSampleProfile && !isPreview && list.length > 3 && (
          <AppLink
            onClick={() =>
              type === "blogs" ? setViewAll(!viewAll) : navigate(`${type}/${firstId}`)
            }
          >
            View {viewAll === true ? "Less" : "All"}
          </AppLink>
        )}
      </div>
      {isMobile ? (
        <div className="d-flex" style={{ overflow: "scroll", flexWrap: "nowrap" }}>
          {list.slice(0, type === "blogs" && viewAll ? list.length : 3).map((el) => (
            <div
              key={`${title}-${el[lookupField]}`}
              className="mb-4"
              style={{ height: "28rem", width: "80vw", marginRight: "1rem" }}
            >
              <CardComponent
                obj={el}
                showSelect={false}
                onBookmarked={handleGetProfileDetails}
                onClick={
                  isSampleProfile || isPreview
                    ? undefined
                    : () => navigate(`${type}/${el[lookupField]}`)
                }
                cardStyle={{ width: "inherit" }}
              />
            </div>
          ))}
        </div>
      ) : (
        <Row xs={1} sm={3} className="mt-4">
          {list.slice(0, type === "blogs" && viewAll ? list.length : 3).map((el) => (
            <Col key={`${title}-${el[lookupField]}`} className="mb-4" style={{ height: "28rem" }}>
              <CardComponent
                obj={el}
                showSelect={false}
                onBookmarked={handleGetProfileDetails}
                onClick={
                  isSampleProfile || isPreview
                    ? undefined
                    : () => navigate(`${type}/${el[lookupField]}`)
                }
              />
            </Col>
          ))}
        </Row>
      )}
    </ContentSectionContainer>
  );
}

function UserDetails({
  user,
  resume_image,
  personal_details,
  handleGetProfileDetails,
  isMyProfile,
  kyc_details
}) {
  const {
    name,
    is_student,
    is_studio,
    latest_education,
    latest_experience,
    is_working_professional,
    is_brand,
    location
  } = user;
  const { isSampleProfile } = useNavigationInfo();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const { id } = useParams();
  if (isArray(resume_image) && !isEmpty(resume_image)) {
    resume_image = resume_image[0];
  }
  var instituition, employment_type;
  if (is_student) {
    if (is_working_professional && latest_experience) {
      instituition = `${latest_experience.employment_type}, ${latest_experience.company}`;
    } else if (latest_education && latest_education.degree && latest_education.college) {
      instituition = `${latest_education.degree}, ${latest_education.college}`;
    } else if (latest_experience) {
      instituition = `${latest_experience.employment_type}, ${latest_experience.company}`;
    }

    if (latest_experience) {
      employment_type = latest_experience.employment_type;
    }
  }

  return (
    <SectionContainer>
      <ProfileBannerImage
        customUser={user}
        style={{ width: "100%", height: isMobile ? "120px" : "17.5rem" }}
      />
      <div className="position-relative">
        <div
          className="position-absolute shadow rounded-circle"
          style={{
            border: "10px solid white",
            width: "8rem",
            height: "8rem",
            top: "-5.5rem"
          }}
        >
          <ProfileImage customUser={user} />
        </div>
        {!isMyProfile && !isSampleProfile && (
          <Stack
            direction="horizontal"
            gap={4}
            className="justify-content-end position-absolute"
            style={{ right: "1rem", top: "1rem" }}
          >
            {[
              { component: <ShareButton url={`${window.location.origin}/profiles/${id}`} /> },
              {
                component: (
                  <FavouriteButton type="profile" obj={user} onSuccess={handleGetProfileDetails} />
                )
              }
            ].map((el, index) => (
              <div style={{ width: "2.5rem", height: "2.5rem" }} key={index}>
                {el.component}
              </div>
            ))}
            {/* <Button variant="outline-primary" onClick={() => {}} className="px-5">
              SUBSCRIBE
            </Button> */}
            {!isMobile && (
              <Button variant="primary" onClick={() => setShowConnectModal(true)} className="px-5">
                CONNECT
              </Button>
            )}
          </Stack>
        )}
      </div>
      {isMobile && !isSampleProfile && (
        <div
          className=" d-flex justify-content-end position-relative"
          style={{ right: "1rem", top: "4.5rem" }}
        >
          <Button variant="primary" onClick={() => setShowConnectModal(true)} className="px-2">
            CONNECT
          </Button>
        </div>
      )}
      <Stack gap={2} className={`mt-${isMobile ? 4 : 5} pb-2`}>
        <p className="fw-bold mb-0" style={{ color: COLORS.PRIMARY_ORANGE }}>
          {is_student
            ? is_working_professional
              ? "Professional"
              : "Student"
            : is_studio
            ? "Studio"
            : kyc_details.nature_of_orgranisation}
        </p>
        <h5 className="fw-bold mb-0">{name}</h5>
        {is_studio && <h5 className="fw-bold mb-0">{personal_details.principal_designer}</h5>}
        {is_student && is_working_professional && employment_type && (
          <p className="mb-0">{employment_type}</p>
        )}
        {is_student && instituition && <p className="mb-0">{instituition}</p>}
        {is_studio && <p className="mb-0">{personal_details.company_type}</p>}
        {is_brand && (
          <>
            <p className="mb-0">{kyc_details.nature_of_orgranisation}</p>
            <p className="mb-0">Since {personal_details.establishment_year}</p>
            <Location style={{ marginTop: "3.5px" }}>{location}</Location>
            {personal_details.website_link &&
              isString(personal_details.website_link) &&
              !isEmpty(personal_details.website_link) && (
                <Stack
                  className="mb-0 pe-3 text-wrap"
                  direction="horizontal"
                  gap={1}
                  style={{ flexWrap: "wrap" }}
                >
                  <div className="text-nowrap">Website Link: </div>
                  <AppLink onClick={() => window.open(personal_details.website_link, "_blank")}>
                    {personal_details.website_link}
                  </AppLink>
                </Stack>
              )}
          </>
        )}
        <SocialMediaIcons personal_details={personal_details} />
      </Stack>
      <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} user={user} />
    </SectionContainer>
  );
}
function ProfileContent({ profile, handleGetProfileDetails }) {
  const { videos, images, portfolios, projects, thesis, blogs, user, catalogues, arvr } = profile;
  const { is_brand } = user;

  return (
    <div style={{ backgroundColor: "white" }}>
      {isArray(catalogues) && !isEmpty(catalogues) && is_brand && (
        <ContentSection
          type="catalogues"
          list={catalogues}
          title="Catalogues"
          CardComponent={CatalogueCard}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(images) && !isEmpty(images) && (
        <ContentSection
          type="images"
          list={images}
          title="Images"
          CardComponent={ImageCard}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(videos) && !isEmpty(videos) && (
        <ContentSection
          type="videos"
          list={videos}
          title="Videos"
          CardComponent={Video}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(portfolios) && !isEmpty(portfolios) && (
        <ContentSection
          type="portfolios"
          list={portfolios}
          title="Portfolios"
          CardComponent={Portfolio}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(projects) && !isEmpty(projects) && (
        <ContentSection
          type="projects"
          list={projects}
          title="Projects"
          CardComponent={Project}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(thesis) && !isEmpty(thesis) && (
        <ContentSection
          type="thesis"
          list={thesis}
          title="Thesis & Dissertations"
          CardComponent={Thesis}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(arvr) && !isEmpty(arvr) && (
        <ContentSection
          type="arvr"
          list={arvr}
          title="AR/VR"
          CardComponent={ARVR}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
      {isArray(blogs) && !isEmpty(blogs) && (
        <ContentSection
          type="blogs"
          list={blogs}
          title="Blogs"
          CardComponent={Blog}
          handleGetProfileDetails={handleGetProfileDetails}
        />
      )}
    </div>
  );
}

function ProfileContainer({ children }) {
  return <Stack gap={3}>{children}</Stack>;
}

function StudentProfile({ profile, handleGetProfileDetails, isMyProfile, setProfile }) {
  const {
    is_favourited,
    resume: {
      education,
      employment,
      image,
      hobbies,
      technical_skills,
      management_skills,
      personal_skills,
      languages,
      courses,
      awards
    },
    user,
    personal_details
  } = profile;
  const { about } = personal_details;
  return (
    <ProfileContainer>
      <UserDetails
        user={{ ...user, is_favourited }}
        personal_details={personal_details}
        resume_image={image}
        handleGetProfileDetails={handleGetProfileDetails}
        isMyProfile={isMyProfile}
      />
      {isString(personal_details) && !isEmpty(about) && (
        <SectionContainer title="About">
          <p>{about}</p>
        </SectionContainer>
      )}
      <ToggleBetweenContentAndContentDetails
        profile={profile}
        handleGetProfileDetails={handleGetProfileDetails}
        setProfile={setProfile}
      >
        {isArray(employment) && !isEmpty(employment) && (
          <SectionContainer title="Experience">
            {(employment || []).map((el) => (
              <SavedExperience experience={el} key={el.id} />
            ))}
          </SectionContainer>
        )}
        {isArray(education) && !isEmpty(education) && (
          <SectionContainer title="Education">
            {(education || []).map((el) => (
              <SavedEducation education={el} key={el.id} />
            ))}
          </SectionContainer>
        )}
        {isArray(courses) && !isEmpty(courses) && (
          <SectionContainer title="Courses & Certification">
            {(courses || []).map((el) => (
              <SavedCertificationCourses certificationCourse={el} key={el.id} />
            ))}
          </SectionContainer>
        )}
        {isArray(awards) && !isEmpty(awards) && (
          <SectionContainer title="Awards & Achievemets">
            {(awards || []).map((el) => (
              <SavedAwardsAchievement awardsAchievement={el} key={el.id} />
            ))}
          </SectionContainer>
        )}
        {((isArray(hobbies) && !isEmpty(hobbies)) ||
          (isArray(personal_skills) && !isEmpty(personal_skills)) ||
          (isArray(technical_skills) && !isEmpty(technical_skills)) ||
          (isArray(management_skills) && !isEmpty(management_skills)) ||
          (isArray(languages) && !isEmpty(languages))) && (
          <div style={{ backgroundColor: "white" }} className="pb-2">
            <SectionContainer title="Skills, Languages & Hobbies">
              <Stack gap={4}>
                {isArray(technical_skills) && !isEmpty(technical_skills) && (
                  <Skill title="Technical Skills" list={technical_skills} />
                )}
                {isArray(management_skills) && !isEmpty(management_skills) && (
                  <Skill title="Management Skills" list={management_skills} />
                )}
                {isArray(personal_skills) && !isEmpty(personal_skills) && (
                  <Skill title="Personal Skills" list={personal_skills} />
                )}
                {isArray(languages) && !isEmpty(languages) && (
                  <Skill title="Languages" list={languages} />
                )}
                {isArray(hobbies) && !isEmpty(hobbies) && <Skill title="Hobbies" list={hobbies} />}
              </Stack>
            </SectionContainer>
          </div>
        )}
      </ToggleBetweenContentAndContentDetails>
    </ProfileContainer>
  );
}

function StudioProfile({ profile, handleGetProfileDetails, isMyProfile, setProfile }) {
  const { personal_details, user, is_favourited } = profile;
  return (
    <ProfileContainer>
      <UserDetails
        user={{ ...user, is_favourited }}
        personal_details={personal_details}
        handleGetProfileDetails={handleGetProfileDetails}
        isMyProfile={isMyProfile}
      />
      {isString(personal_details.company_description) &&
        !isEmpty(personal_details.company_description) && (
          <SectionContainer title="About">
            <p>{personal_details.company_description}</p>
          </SectionContainer>
        )}
      <ToggleBetweenContentAndContentDetails
        profile={profile}
        handleGetProfileDetails={handleGetProfileDetails}
        setProfile={setProfile}
      />
    </ProfileContainer>
  );
}

function BrandProfile({ profile, handleGetProfileDetails, isMyProfile, setProfile }) {
  const [showModal, setShowModal] = useState(null);
  const {
    personal_details,
    user,
    is_favourited,
    kyc_details,
    branch_details,
    distribution_networks,
    categories
  } = profile;
  return (
    <ProfileContainer>
      <UserDetails
        user={{ ...user, is_favourited }}
        personal_details={personal_details}
        handleGetProfileDetails={handleGetProfileDetails}
        isMyProfile={isMyProfile}
        kyc_details={kyc_details}
      />
      {isString(personal_details.about) && !isEmpty(personal_details.about) && (
        <SectionContainer title="About">
          <p>{personal_details.about}</p>
          {isArray(branch_details) && !isEmpty(branch_details) && (
            <div className="d-flex justify-content-between w-100">
              <p className="text-muted" style={{ width: "35%" }}>
                Branches
              </p>
              <p style={{ width: "15%" }}>{branch_details.length}</p>
              <p
                className="fw-bold cursor-pointer"
                style={{ color: COLORS.PRIMARY_ORANGE, width: "50%" }}
                onClick={() => setShowModal("branch_details")}
              >
                View Branch Details
              </p>
            </div>
          )}
          {isArray(categories) && !isEmpty(categories) && (
            <div className="d-flex justify-content-between w-100">
              <p className="text-muted" style={{ width: "35%" }}>
                Categories
              </p>
              <p style={{ width: "15%" }}>{categories.length}</p>
              <p
                className="fw-bold cursor-pointer"
                style={{ color: COLORS.PRIMARY_ORANGE, width: "50%" }}
              >
                {categories.map((el) => el.title).join(", ")}
              </p>
            </div>
          )}
          {isArray(distribution_networks) && !isEmpty(distribution_networks) && (
            <div className="d-flex justify-content-between w-100">
              <p className="text-muted" style={{ width: "35%" }}>
                Distribution Networks
              </p>
              <p style={{ width: "15%" }}>{distribution_networks.length}</p>
              <p
                className="fw-bold cursor-pointer"
                style={{ color: COLORS.PRIMARY_ORANGE, width: "50%" }}
                onClick={() => setShowModal("distribution_networks")}
              >
                View Distribution Networks
              </p>
            </div>
          )}
        </SectionContainer>
      )}
      <ToggleBetweenContentAndContentDetails
        profile={profile}
        handleGetProfileDetails={handleGetProfileDetails}
        setProfile={setProfile}
      />
      <BaseModal show={!isNull(showModal)} onHide={() => setShowModal(null)} size="xl">
        <Modal.Header closeButton>
          {showModal && (
            <Modal.Title>
              {showModal === "branch_details" ? "Branch Details" : "Distribution Networks"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Table
            data={
              showModal
                ? showModal === "branch_details"
                  ? branch_details
                  : distribution_networks
                : []
            }
            columns={
              showModal
                ? showModal === "branch_details"
                  ? new TableColumnsFactory().createColumnForProfileBasicDetails()
                  : new TableColumnsFactory().createColumnForProfileDistributionNetwork()
                : []
            }
          />
        </Modal.Body>
      </BaseModal>
    </ProfileContainer>
  );
}

function ProfileContentDetails({ profile, handleGetProfileDetails, setProfile }) {
  const [startIndex, setStartIndex] = useState(undefined);
  const { contentType, id, contentId } = useParams();
  const navigate = useNavigate();
  var type;
  if (contentType) {
    type = contentType;
    if (!["thesis", "arvr"].includes(type)) {
      type = contentType.slice(0, -1);
    }
  }

  const handleChange = (changedId) => {
    navigate(`/profiles/${id}/${contentType}/${changedId}`, { replace: true });
  };
  var list = profile[contentType];

  var lookupField = ["catalogues"].includes(contentType) ? "id" : "slug";

  useEffect(() => {
    if (contentId) {
      for (var i = 0; i < list.length; i++) {
        if (String(list[i][lookupField]) === contentId) {
          setStartIndex(i);
          break;
        }
      }
    } else {
      setStartIndex(0);
    }
  }, [contentId]);

  return (
    <LoadingContainer loading={isUndefined(startIndex)}>
      <div className="pb-5">
        <ContentDetailsNavigator
          list={list}
          startIndex={startIndex}
          onAction={handleGetProfileDetails}
          type={type}
          onNext={handleChange}
          onPrevious={handleChange}
          onViewed={() => {
            var newProfile = { ...profile };
            var newList = [...list];
            const index = newList.findIndex((el) => el[lookupField] === contentId);
            if (index !== -1) {
              newList[index].views = newList[index].views + 1;
            }
            newProfile[contentType] = newList;
            setProfile(newProfile);
          }}
        />
      </div>
    </LoadingContainer>
  );
}

function ToggleBetweenContentAndContentDetails({
  children,
  profile,
  handleGetProfileDetails,
  setProfile
}) {
  const { contentType, contentId } = useParams();

  return contentType && contentId ? (
    <ProfileContentDetails
      profile={profile}
      handleGetProfileDetails={handleGetProfileDetails}
      setProfile={setProfile}
    />
  ) : (
    <>
      {children}
      <ProfileContent profile={profile} handleGetProfileDetails={handleGetProfileDetails} />
    </>
  );
}

function RelatedProfile({ profiles, handleGetRelatedProfiles }) {
  return (
    <div className="mt-3">
      <h6 className="mb-4">Related Profiles</h6>
      <Row xs={2} sm={1}>
        {profiles.map((el, index) => (
          <Col key={`${el.id}-${index}`} className={`mb-4 ${isMobile ? "px-0" : ""}`}>
            <ProfileCard obj={el} onBookmarked={handleGetRelatedProfiles} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

function Profile({ userId, previewProfile, preview, showBack = true, showSecondCol = true }) {
  const user = useSelector((state) => state.authReducer.user);
  const [loading, setLoading] = useState(true);
  const client = ContentFactory.createRequest("profile");
  const [profile, setProfile] = useState(undefined);
  const [relatedProfiles, setRelatedProfiles] = useState([]);
  const [component, setComponent] = useState(undefined);
  const { id } = useParams();
  const { scrollToTop } = useScrollToTop();
  var { isPreview, isSampleProfile } = useNavigationInfo();
  var isMyProfile;
  if (profile && profile.user && profile.user.slug) {
    isMyProfile = user.slug === profile.user.slug;
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleGetProfileDetails = async () => {
    const readParam = userId ? userId : isPreview || preview ? user.slug : id;
    return await client.read(readParam).then((result) => {
      var newProfile = { ...result.profile };
      if (isPreview || preview) {
        newProfile = { ...newProfile, ...previewProfile };
      }
      setProfile(newProfile);
    });
  };

  const handleGetRelatedProfiles = () => client.relatedProfiles(id).then(setRelatedProfiles);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (showSecondCol) {
      handleGetRelatedProfiles();
    }
    if (!isSampleProfile) {
      handleGetProfileDetails().then(() => setLoading(false));
    } else {
      setProfile(previewProfile);
      setLoading(false);
    }
  }, [id, previewProfile]);

  useEffect(() => {
    if (profile) {
      var componentToRender;
      const profileUser = isPreview || preview ? user : profile.user;
      if (profileUser.is_student) {
        componentToRender = (
          <StudentProfile
            profile={profile}
            handleGetProfileDetails={handleGetProfileDetails}
            isMyProfile={isMyProfile}
            setProfile={setProfile}
          />
        );
      } else if (profileUser.is_studio) {
        componentToRender = (
          <StudioProfile
            profile={profile}
            handleGetProfileDetails={handleGetProfileDetails}
            isMyProfile={isMyProfile}
            setProfile={setProfile}
          />
        );
      } else if (profileUser.is_brand) {
        componentToRender = (
          <BrandProfile
            profile={profile}
            handleGetProfileDetails={handleGetProfileDetails}
            isMyProfile={isMyProfile}
            setProfile={setProfile}
          />
        );
      }
      setComponent(componentToRender);
    }
  }, [profile]);

  return (
    <LoadingContainer loading={loading}>
      <div style={{ backgroundColor: COLORS.GREY1 }} className="w-100">
        {component && (
          <RelatedContainer
            showBack={showBack}
            FirstCol={component}
            SecondCol={
              !isPreview && !isMyProfile && !isSampleProfile && showSecondCol ? (
                <RelatedProfile
                  profiles={relatedProfiles}
                  handleGetRelatedProfiles={handleGetRelatedProfiles}
                />
              ) : showSecondCol ? (
                <></>
              ) : null
            }
          />
        )}
      </div>
    </LoadingContainer>
  );
}

export default Profile;

import React from "react";
import { useSearchParams } from "../../../hooks/searchParams";
import Dropdown from "../../Dropdown";

export default function TimeCreatedFilters({ ...props }) {
  const { addKey, getKey } = useSearchParams();
  const KEY = "time_created";

  const OPTIONS = [
    { value: "this_week", label: "This Week" },
    { value: "this_month", label: "This Month" },
    { value: "this_year", label: "This Year" },
    { value: "last_year", label: "Last Year" },
    { value: "all", label: "All" }
  ];

  const value = getKey(KEY);
  return (
    <Dropdown
      value={value}
      options={OPTIONS}
      label="Time Created"
      onClick={(value) => addKey(KEY, value)}
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
      {...props}
    />
  );
}

import React from "react";
import Form from "react-bootstrap/esm/Form";

export default function FormError({ error, className, children, ...props }) {
  return (
    <Form.Text className={`text-danger${className ? ` ${className}` : ""}`} {...props}>
      {children || error}
    </Form.Text>
  );
}

const ORIGIN = window.location.origin;
const BRAND_PROFILE_SAMPLE = {
  is_favourited: null,
  saves: 0,
  count: {},
  user: {
    id: 54,
    name: "Kajaria",
    username: "kajaria33",
    email: "contact@kajaria.com",
    mobile: "7303548511",
    is_student: false,
    is_brand: true,
    is_studio: false,
    is_email_verified: true,
    is_mobile_verified: true,
    secondary_email: null,
    secondary_mobile: null,
    is_secondary_email_verified: false,
    is_secondary_mobile_verified: false,
    profile_images: [
      {
        id: 1,
        image: `${ORIGIN}/sample-profile/brand/profile.png`,
        image_type: "default",
        is_selected: true,
        is_solid: false
      }
    ],
    banner_images: [
      {
        id: 1,
        image: `${ORIGIN}/sample-profile/brand/cover-image.jpg`,
        image_type: "default",
        is_selected: true,
        is_solid: true
      }
    ],
    profile_completion_percentage: 67.2,
    last_active: "2023-11-06T22:30:14.542411+05:30",
    open_to_work: false,
    ml_verified: false,
    promoted: false,
    allow_thesis: false,
    location: "North West Delhi, Delhi",
    is_working_professional: false,
    rating: 0,
    latest_education: null,
    latest_experience: null
  },
  personal_details: {
    id: 2,
    created: "2023-10-15T16:46:26.786218+05:30",
    address_line1: "Kajaria Ceramics Limited J1/B1 (Extn.), Mohan Co - op Industrial Estate",
    country: "India",
    state: "Delhi",
    city: "New Delhi",
    pincode: 110044,
    about:
      "Kajaria brings to you premium wall tiles that are detailed with perfection. Each tile is made using state of the art technology making them strong, durable & easy to maintain.",
    establishment_year: 1985,
    contact_person_first_name: "Vibhuti",
    contact_person_last_name: "Chand",
    website: "https://www.kajariaceramics.com/",
    market_capitalization: "10 Crore",
    user: 54
  },

  videos: [
    {
      id: 9,
      title: "Bathroom Tile",
      description:
        "A spectacular mirror like effect is what you get in this superior finish. The highly polished look is achieved with a thicker coat of glaze, enhancing image sharpness and giving you a rich and true color experience.",
      category: "Architectural",
      user: "Test Brand",
      slug: "test-3",
      created: "2023-11-06T22:27:25.810967+05:30",
      user_id: 54,
      tags: ["test"],
      ideation_board_id: null,
      mood_board_id: null,
      mood_board_link_id: null,
      is_favourited: null,
      is_liked: null,
      views: 0,
      likes: 0,
      saves: 0,
      video: "https://youtu.be/kWSHdixzMco?feature=shared"
    }
  ],
  portfolios: [
    {
      id: 13,
      title: "Kajaria Work Porfolio",
      thumbnail: `${ORIGIN}/sample-profile/brand/portfolio.jpg`,
      type: "Residential",
      description:
        "Kajaria, the largest manufacturer of ceramic and vitrified tiles in India. It has an annual aggregate capacity of 86.47 mn. sq. meters, distributed across seven plants - Sikandrabad in Uttar Pradesh, Gailpur & Malootana in Rajasthan, Srikalahasti in Andhra Pradesh, Balanagar in Telangana and two plants in Gujarat. ",
      file: `${ORIGIN}/sample-profile/brand/portfolio.pdf`,
      user: "Test Brand",
      created: "2023-11-06T22:25:41.936772+05:30",
      user_id: 54,
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    }
  ],

  blogs: [
    {
      id: 25,
      title: "Tiles Evoloution With Time",
      slug: "test-blog-3",
      content: "<p>Test</p>",
      created: "2023-11-06T22:29:08.935902+05:30",
      author: "Kajaria",
      category: "Construction",
      is_posted: true,
      under_review: false,
      rejected: false,
      review_not_accepted_response: "",
      thumbnail: `${ORIGIN}/sample-profile/brand/blog-thumbnail.jpg`,
      keywords: ["Architecture, Construction, Tiles"],
      is_drafted: false,
      is_pinned: false,
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    }
  ],
  branch_details: [
    {
      id: 12,
      user: "Test Brand",
      created: "2023-11-06T11:50:34.143770+05:30",
      address_line1: "C-45 Rajiv Chowk",
      country: "India",
      state: "Delhi",
      city: "New Delhi",
      pincode: 110009,
      branch_contact_person_name: "Rahul Sharma",
      designation: "Branch Manager",
      contact_number: 7303548585,
      email: "rahulsharma@kajaria.com"
    }
  ],
  distribution_networks: [
    {
      id: 15,
      user: "Test Brand",
      created: "2023-11-06T20:24:24.509223+05:30",
      address_line1: "Huda Market Sector 43",
      country: "India",
      state: "Haryana",
      city: "Gurugram",
      pincode: 122004,
      firm_name: "Sharma Brothers & Tiles",
      network_type: "Distributor",
      contact_number: 7303548585,
      email: "Sharma@kajaria.com"
    }
  ],
  kyc_details: {
    id: 2,
    created: "2023-10-22T18:01:37.651701+05:30",
    nature_of_orgranisation: "Manufacturer",
    registration_certificate:
      "http://localhost:5050/static/brand/registration_certificate/registration_certificate_2zEKQCS.jpeg",
    pan: "AABCK1613R",
    gstin_status: "I have a GSTIN",
    gstin: "36AABCK1613R1ZC",
    registration_under_msme: "No",
    msme_number: null,
    user: 54
  },
  catalogues: [
    {
      id: 1,
      title: "Bathroom Tile",
      thumbnail: `${ORIGIN}/sample-profile/brand/price-catalogue-thumbnail.jpg`,
      file: `${ORIGIN}/sample-profile/brand/price-catalogue.pdf`,
      user_id: 54,
      user: "Test Brand",
      type: "Price",
      created: "2023-10-22T18:01:37.651701+05:30"
    },
    {
      id: 3,
      title: "Interior Tile",
      thumbnail: `${ORIGIN}/sample-profile/brand/product-catalogue-thumbnail.jpg`,
      file: `${ORIGIN}/sample-profile/brand/product-catalogue.pdf`,
      user_id: 54,
      user: "Test Brand",
      type: "Product",
      created: "2023-10-22T18:01:37.651701+05:30"
    }
  ],
  "AR/VR": [
    {
      title: "Tile",
      thumbnail: `${ORIGIN}/sample-profile/brand/bathroom-tiles.jpg`,
      type: "AR",
      description:
        "These premium floor tiles come in a variety of unique designs and sizes which makes them a showstopper, always.",
      link: "https://youtu.be/TMsCgYLd52s?feature=shared",
      user: "Test Brand",
      user_id: 54,
      created: "2023-10-27T19:01:30.225930+05:30",
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    }
  ]
};

export default BRAND_PROFILE_SAMPLE;

import UserProfileAPIClient from "../apiClients/Profile/User";
import { useDispatch } from "react-redux";
import { renderErrorToast, showPromiseToast } from "../utils";
import ACTION_TYPES from "../constants/action-types";
import { isFunction } from "lodash";

export const useAuth = () => {
  const dispatch = useDispatch();
  const login = (data) => dispatch({ type: ACTION_TYPES.AUTH.LOGIN, data });
  const updateUser = (user) => dispatch({ type: ACTION_TYPES.AUTH.UPDATE_USER, user });
  const userAPIClient = new UserProfileAPIClient();

  const promiseLoginToast = (promise) => {
    showPromiseToast(promise, {
      pending: "Logging you in",
      success: "Logged in successfully",
      error: renderErrorToast()
    });
  };

  const promiseOTPToast = (promise) => {
    showPromiseToast(promise, {
      pending: "Sending OTP",
      success: "OTP sent successfully!",
      error: renderErrorToast()
    });
  };
  const loginWithEmail = ({ email, password }) => {
    const promise = userAPIClient.loginWithEmail(email, password).then(login);
    promiseLoginToast(promise);
  };

  const loginWithPhone = ({ otp, phone }) => {
    const promise = userAPIClient.confirmLoginWithOTP(phone, otp).then(login);
    promiseLoginToast(promise);
  };

  const sendOTP = (destination, setShowVerifyOTP, checkUser = true) => {
    const promise = userAPIClient
      .sendOTP(destination, checkUser)
      .then(() => isFunction(setShowVerifyOTP) && setShowVerifyOTP(destination));
    promiseOTPToast(promise);
  };

  const signupUser = ({ email, role, mobile, password }, setShowVerifyOTP) => {
    const promise = userAPIClient
      .signup({ email, role, mobile, password })
      .then(() => sendOTP(mobile, setShowVerifyOTP));
    showPromiseToast(promise, {
      pending: "Signing you up",
      success: "Your user has been created successfully, please verify your phone number",
      error: renderErrorToast()
    });
  };

  const verifyMobileAndLogin = ({ otp, mobile, email, password }) => {
    const promise = userAPIClient
      .validateContactInfo({ mobile, otp }, true)
      .then(() => loginWithEmail({ email, password }));
    showPromiseToast(promise, {
      pending: "Confirming your contact number",
      success: "Contact number confirmed successfully.",
      error: renderErrorToast()
    });
  };

  const validateContactInfo = (payload, onSuccess) => {
    const promise = userAPIClient
      .validateContactInfo(payload, true)
      .then(getUserInfo)
      .then(() => isFunction(onSuccess) && onSuccess());
    showPromiseToast(promise, {
      pending: "Confirming your contact information",
      success: "Contact information confirmed successfully.",
      error: renderErrorToast()
    });
  };

  const updateProfileImage = (id, onSuccess) => {
    const promise = userAPIClient
      .updateProfileImage(id)
      .then(getUserInfo)
      .then(() => isFunction(onSuccess) && onSuccess());
    showPromiseToast(promise, {
      pending: "Updating your profile image",
      success: "Profile image updated successfully.",
      error: renderErrorToast()
    });
  };

  const uploadProfileImage = (image, onSuccess) => {
    const promise = userAPIClient
      .uploadProfileImage(image)
      .then(getUserInfo)
      .then(() => isFunction(onSuccess) && onSuccess());
    showPromiseToast(promise, {
      pending: "Uploading your profile image",
      success: "Profile image uploaded successfully.",
      error: renderErrorToast()
    });
  };

  const updateBannerImage = (id, onSuccess) => {
    const promise = userAPIClient
      .updateBannerImage(id)
      .then(getUserInfo)
      .then(() => isFunction(onSuccess) && onSuccess());
    showPromiseToast(promise, {
      pending: "Updating your banner image",
      success: "Banner image updated successfully.",
      error: renderErrorToast()
    });
  };

  const uploadBannerImage = (image, onSuccess) => {
    const promise = userAPIClient
      .uploadBannerImage(image)
      .then(getUserInfo)
      .then(() => isFunction(onSuccess) && onSuccess());
    showPromiseToast(promise, {
      pending: "Uploading your banner image",
      success: "Banner image uploaded successfully.",
      error: renderErrorToast()
    });
  };

  const getUserInfo = () =>
    userAPIClient
      .getUserInfo()
      .then((user) => updateUser(user))
      .catch((error) => console.log("Error while fetching user: ", error));
  return {
    loginWithEmail,
    loginWithPhone,
    sendOTP,
    signupUser,
    verifyMobileAndLogin,
    validateContactInfo,
    getUserInfo,
    updateProfileImage,
    uploadProfileImage,
    updateBannerImage,
    uploadBannerImage
  };
};

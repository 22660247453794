import React, { memo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaLinkedin, FaInstagram, FaPinterest, FaBehance } from "react-icons/fa";
import { useFormContext } from "react-hook-form";
import VALIDATORS from "../../validators";
import FormError from "components/FormError";
import ReactHookInput from "components/Inputs/ReactHookInput";
import { useSelector } from "react-redux";

const socialMediaPlatforms = [
  {
    name: "LinkedIn",
    icon: FaLinkedin,
    fieldName: "linkedin",
    placeholder: "LinkedIn URL",
    validationRules: { required: false, ...VALIDATORS.linkedin_url }
  },
  {
    name: "Instagram",
    icon: FaInstagram,
    fieldName: "instagram",
    placeholder: "Instagram URL",
    validationRules: { required: false, ...VALIDATORS.instagram_url }
  },
  {
    name: "Pinterest",
    icon: FaPinterest,
    fieldName: "pinterest",
    placeholder: "Pinterest URL",
    validationRules: { required: false, ...VALIDATORS.pinterest_url }
  },
  {
    name: "Behance",
    icon: FaBehance,
    fieldName: "behance",
    placeholder: "Behance URL",
    validationRules: { required: false, ...VALIDATORS.behance_url }
  }
];

const SocialMediaLinks = () => {
  const {
    formState: { errors, submitCount },
    watch,
    setError,
    clearErrors
  } = useFormContext();
  const user = useSelector((state) => state.authReducer.user);

  const watchedValues = watch(socialMediaPlatforms.map((platform) => platform.fieldName));

  const isAtLeastOneFieldFilled = watchedValues.some((value) => !!value);

  useEffect(() => {
    if (!isAtLeastOneFieldFilled && user.is_email_verified) {
      setError("social_media_links", {
        type: "manual",
        message: "At least one of the social media URL is required"
      });
    } else if (isAtLeastOneFieldFilled && errors.social_media_links) {
      clearErrors("social_media_links");
    }
  }, [isAtLeastOneFieldFilled, setError, submitCount]);

  return (
    <div>
      {errors.social_media_links && (
        <div className="mb-2">
          <FormError className="fw-bold">{errors.social_media_links.message}</FormError>
        </div>
      )}

      {socialMediaPlatforms.map((platform) => (
        <Form.Group className="mb-3" key={platform.fieldName}>
          <ReactHookInput
            type="url"
            placeholder={platform.placeholder}
            name={platform.fieldName}
            validation={platform.validationRules}
            Icon={platform.icon}
          />
        </Form.Group>
      ))}
    </div>
  );
};

export default memo(SocialMediaLinks);

import React from "react";
import { Button, Form } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import VALIDATORS, { validateEmail } from "../../../validators";
import FormError from "../../FormError";
import { isEmpty, isFunction, isString } from "lodash";
import { FaPlus } from "react-icons/fa";
import DEFAULT_STYLE from "../../../styles";

const Email = ({
  handleVerify,
  showSecondaryEmail,
  setShowSecondaryEmail,
  required,
  name = "email",
  ...props
}) => {
  const {
    formState: { errors },
    control,
    watch
  } = useFormContext();
  const value = watch(name);

  const { field } = useController({ name, control, rules: VALIDATORS.email(required) });
  const isErrored = errors && errors[name];

  return (
    <>
      <Form.Group className="d-flex w-100">
        <>
          <Form.Control
            type="email"
            placeholder="Email Address"
            autoComplete="email"
            style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
            {...props}
            {...field}
          />
          {isFunction(handleVerify) && (
            <Button variant="outline-primary px-3 ms-2" onClick={() => handleVerify(value)}>
              Verify
            </Button>
          )}
          {isString(value) &&
            !isEmpty(value) &&
            validateEmail(value) &&
            showSecondaryEmail === false && (
              <Button
                variant="outline-secondary ms-2"
                onClick={() => isFunction(setShowSecondaryEmail) && setShowSecondaryEmail(true)}
              >
                <FaPlus />
              </Button>
            )}
        </>
      </Form.Group>
      {isErrored && <FormError>{errors[name].message}</FormError>}
    </>
  );
};

export default Email;

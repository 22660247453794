import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import FormError from "../../FormError";
import { isObject, isUndefined } from "lodash";
import DEFAULT_STYLE from "../../../styles";

export default function ReactHookInput({ validation, name, Icon, error, required, ...props }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { required: required ? "This field is required" : undefined, ...validation },
    defaultValue: ""
  });
  const errorObj = error
    ? error
    : !isUndefined(errors) && isObject(errors) && !isUndefined(errors[name])
    ? errors[name]
    : null;

  const isErrored = isObject(errorObj);

  return (
    <>
      {Icon ? (
        <InputGroup>
          <InputGroup.Text>
            <Icon size={20} />
          </InputGroup.Text>
          <Form.Control
            style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
            {...props}
            {...field}
            value={field.value || ""}
          />
        </InputGroup>
      ) : (
        <Form.Control
          style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
          {...props}
          {...field}
          value={field.value || ""}
        />
      )}
      {isErrored && <FormError error={errorObj.message} />}
    </>
  );
}

import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomDate from "../CustomDate";
import FormError from "../../FormError";
import { isString } from "lodash";
import DateParser from "date";

export default function StartEndDateInput({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showStartDate,
  showEndDate,
  errors,
  required = true
}) {
  return (
    <Row>
      {[
        {
          label: `Start Date${required ? "*" : ""}`,
          value: startDate,
          onChange: setStartDate,
          name: "startDate",
          show: showStartDate,
          maxDate: new Date()
        },
        {
          label: `End Date${required ? "*" : ""}`,
          value: endDate,
          onChange: setEndDate,
          name: "endDate",
          show: showEndDate,
          minDate: isString(startDate) ? DateParser.parseFromYYYYMMDD(startDate) : startDate,
          maxDate: new Date()
        }
      ].map(
        (field, index) =>
          field.show && (
            <Col key={index}>
              <Form.Group>
                <Form.Label>{field.label}</Form.Label>
                <CustomDate
                  value={field.value}
                  format="dd-MM-yyyy"
                  onChange={field.onChange}
                  useReactHook={false}
                  maxDate={field.maxDate}
                  minDate={field.minDate}
                  required={required}
                />
                {errors && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
              </Form.Group>
            </Col>
          )
      )}
    </Row>
  );
}

const SPECIALISATIONS = [
  "Urban Planning",
  "Town Planning",
  "Architectural Design",
  "Interior Design",
  "Landscape Design",
  "Lighting Design",
  "Facade Design",
  "Civil Engineering",
  "Mechanical Engineering",
  "Electrical Engineering",
  "Structural Engineering"
].map((specialization) => ({
  value: specialization,
  label: specialization
}));

export default SPECIALISATIONS;

import React, { useState } from "react";
import { Card, Form, Modal, Stack } from "react-bootstrap";
import { isFunction } from "lodash";
import BaseModal from "components/Modals/Base";
import COLORS from "constants/colors";
import { DATE_TEXT_STYLE, TITLE_TEXT_STYLE } from "components/Cards/default-styles";
import { useNavigationInfo } from "hooks";
import BookmarkIcon from "components/Icons/BookmarkIcon";
import IdeationBoardIcon from "components/Icons/IdeationBoardIcon";
import EllipsisText from "components/EllipsisText";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

function RejectionReasonModal({ show, onHide, reason }) {
  return (
    <BaseModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Reason for Rejection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>{reason}</div>
          <div className="mt-5 fw-bold">
            Have any Query?{" "}
            <span style={{ color: COLORS.PRIMARY_ORANGE }} className="fw-bold">
              Contact Us!
            </span>
          </div>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

export function ReviewRejected({
  is_posted,
  under_review,
  rejected,
  review_not_accepted_response
}) {
  const [showReason, setShowReason] = useState(false);

  return (
    <div>
      {is_posted === false && under_review === true && rejected === false && (
        <p className="mb-0 text-success fw-bold" style={DATE_TEXT_STYLE}>
          Under Review
        </p>
      )}
      {is_posted === false && rejected === true && (
        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 text-danger fw-bold" style={DATE_TEXT_STYLE}>
            Not Approved
          </p>
          <p
            className="mb-0 text-decoration-underline text-secondary cursor-pointer"
            onClick={() => setShowReason(true)}
            style={DATE_TEXT_STYLE}
          >
            Know Why?
          </p>
          <RejectionReasonModal
            show={showReason}
            onHide={() => setShowReason(false)}
            reason={review_not_accepted_response}
          />
        </div>
      )}
    </div>
  );
}

export default function BaseContentCard({ children, onClick, className, style }) {
  var classN = "content-card mx-auto position-relative h-100";
  if (isFunction(onClick)) {
    classN += " cursor-pointer";
  }
  if (className) {
    classN += ` ${className}`;
  }
  return (
    <Card className={classN} style={{ ...style }} onClick={() => isFunction(onClick) && onClick()}>
      <Card.Body className="p-0 h-100">{children}</Card.Body>
    </Card>
  );
}

export function BaseContentCardDetailsSection({ children, style }) {
  return (
    <div
      className="d-flex flex-column justify-content-between content-card-details py-2 px-3"
      style={{ height: "32.5%", ...style }}
    >
      {children}
    </div>
  );
}

export function CardTitle({ children, extraChildren, style = {}, numOfLines = 2 }) {
  return (
    <Card.Title style={{ ...TITLE_TEXT_STYLE, ...style }} className="w-100">
      <EllipsisText style={{ maxWidth: "95%", minWidth: "92.5%" }} numOfLines={numOfLines}>
        {children}
      </EllipsisText>
      {extraChildren}
    </Card.Title>
  );
}

export function BookmarkIconContainer({ children }) {
  return (
    <div style={{ top: "0.5rem", right: "0.5rem", zIndex: "10" }} className="position-absolute">
      {children}
    </div>
  );
}

export function CardImage({ thumbnail, alt }) {
  return (
    <Card.Img
      variant="top"
      src={thumbnail}
      alt={alt}
      className="content-card-image w-100 d-block rounded-bottom-0"
      style={{ objectFit: "cover" }}
    />
  );
}

export function RenderIdeationBoardIcon({
  user,
  type,
  onAddedToIdeationBoard,
  obj,
  buttonStyle,
  width = "2rem",
  height = "2rem"
}) {
  return (
    ["image", "video", "project", "product"].includes(type) &&
    user &&
    (user.is_student || user.is_studio) && (
      <IdeationBoardIcon
        onAddedToIdeationBoard={onAddedToIdeationBoard}
        obj={{ ...obj, contentType: type }}
        width={width}
        height={height}
        buttonStyle={buttonStyle}
      />
    )
  );
}

export function RenderBookmarkIcon({ obj, type, onBookmarked, onAddedToIdeationBoard }) {
  const { isSampleProfile } = useNavigationInfo();
  const user = useSelector((state) => state.authReducer.user);
  return (
    !isSampleProfile && (
      <BookmarkIconContainer>
        <Stack gap={2}>
          <BookmarkIcon obj={obj} type={type} onBookmarked={onBookmarked} />
          <RenderIdeationBoardIcon
            user={user}
            onAddedToIdeationBoard={onAddedToIdeationBoard}
            type={type}
            obj={obj}
          />
        </Stack>
      </BookmarkIconContainer>
    )
  );
}

export function RenderSelect({ showSelect, isSelected, handleSelection, style = {} }) {
  const { isMyProfileOrActivity } = useNavigationInfo();

  return (
    isMyProfileOrActivity &&
    showSelect === true && (
      <Form.Check
        type="checkbox"
        checked={isSelected}
        onClick={(e) => e.stopPropagation()}
        onChange={handleSelection}
        style={{ top: "0.5rem", left: "0.5rem", zIndex: "100", position: "absolute", ...style }}
      />
    )
  );
}
export function getCardHeight(type) {
  const { isProfiles, isJob, isUnderReview, isFavourites } = useNavigationInfo();

  return isProfiles || type === "profile"
    ? isMobile
      ? "175px"
      : "320px"
    : isJob
    ? isUnderReview
      ? "13rem"
      : "184px"
    : isUnderReview
    ? "30rem"
    : isFavourites
    ? "470px"
    : "26rem";
}

import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

export const DropDownCheckboxMenuItem = ({ item, handleItemClick, checked }) => {
  return (
    <Dropdown.Item
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleItemClick(item.value);
      }}
      className="py-2"
      style={{ whiteSpace: "break-spaces" }}
    >
      <Form.Check
        type="checkbox"
        label={item.label}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onChange={() => handleItemClick(item.value)}
        checked={checked}
      />
    </Dropdown.Item>
  );
};

const DropdownCheckbox = ({ title, name, value = [], setFilter, options = [] }) => {
  const [dropdownTitle, setDropdownTitle] = useState("Select Items");

  useEffect(() => {
    handleDropdownToggle();
  }, [value]);

  const handleItemClick = (itemValue) =>
    setFilter(
      name,
      value.includes(itemValue) ? value.filter((el) => el !== itemValue) : [...value, itemValue]
    );

  const handleDropdownToggle = () =>
    setDropdownTitle(value.length === 0 ? title : `Selected: ${value.length}`);

  return (
    <Dropdown className="w-100" placement="center">
      <Dropdown.Toggle
        variant="outline-secondary"
        className="w-100 d-flex justify-content-between align-items-center"
      >
        {dropdownTitle}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: "35vh", overflow: "auto", width: "max-content" }}>
        {options.map((item) => (
          <DropDownCheckboxMenuItem
            key={`${title}-${item.value}`}
            item={item}
            checked={isArray(value) && value.includes(item.value)}
            handleItemClick={handleItemClick}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownCheckbox;

import React, { useEffect } from "react";
import ContentDetailsNavigator from "../../../screens/ContentDetails";
import { CloseButton, Modal } from "react-bootstrap";
import BaseModal from "../Base";
import LoadingContainer from "../../Containers/Loading";
import { isEmpty, isUndefined } from "lodash";
import { useNavigationInfo } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "hooks/searchParams";

export default function ContentDetailModal({
  show,
  onHide,
  list,
  startIndex,
  type,
  onAction,
  onViewed
}) {
  const navigate = useNavigate();
  const { queryString } = useSearchParams();
  useEffect(() => {
    if (isEmpty(list) && show === true) {
      onHide();
    }
  }, [list]);

  const { isLearn } = useNavigationInfo();
  const learnNavigate = (id) => navigate(`/learn/${type}s/${id}?${queryString}`, { replace: true });

  const onNext = (index) => {
    if (isLearn) {
      learnNavigate(index);
    }
  };

  const onPrevious = (index) => {
    if (isLearn) {
      learnNavigate(index);
    }
  };

  return (
    <BaseModal show={show} size="xl">
      <Modal.Body>
        <div className="py-1">
          <CloseButton onClick={onHide} className="ms-auto d-block" />
        </div>
        <LoadingContainer loading={isUndefined(startIndex) || isEmpty(list)}>
          <ContentDetailsNavigator
            list={list}
            startIndex={startIndex}
            type={type}
            onAction={onAction}
            onNext={onNext}
            onPrevious={onPrevious}
            onViewed={onViewed}
          />
        </LoadingContainer>
      </Modal.Body>
    </BaseModal>
  );
}

import React, { useEffect } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { isUndefined } from "lodash";
import { useAdvertiseWithUsModal } from "hooks/advertiseWithUs";
import RequestsClient from "apiClients/Requests";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Phone from "components/Inputs/Phone";
import Email from "components/Inputs/Email";
import Select from "components/Inputs/Select";

function AdvertiseWithUsForm({ user }) {
  const { hideAdvertiseWithUsModal } = useAdvertiseWithUsModal();

  const { handleSubmit, setValue } = useFormContext();
  const onSubmit = (data) => {
    const promise = new RequestsClient()
      .createRequest({ ...data, reason: data.reason.value })
      .then(hideAdvertiseWithUsModal);
    showPromiseToast(promise, {
      pending: "Saving your request",
      success: "Request saved successfully",
      error: renderErrorToast()
    });
  };

  useEffect(() => {
    if (user) {
      ["name", "mobile", "email"].forEach((el) => setValue(el, user[el]));
    }
  }, [user]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Form.Group>
          <Form.Label>Full Name*</Form.Label>
          <ReactHookInput name="name" required disabled={!isUndefined(user)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Contact*</Form.Label>
          <Phone name="mobile" required disabled={!isUndefined(user)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email*</Form.Label>
          <Email required disabled={!isUndefined(user)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Purpose</Form.Label>
          <Select
            options={[
              { value: "Get Information", label: "Get Information" },
              { value: "Quotation", label: "Quotation" }
            ]}
            name="reason"
            required
            placeholder="Quotation"
          />
        </Form.Group>
        <div>
          <Button type="submit" className="w-25 d-block ms-auto">
            SEND
          </Button>
        </div>
      </Stack>
    </Form>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(AdvertiseWithUsForm);

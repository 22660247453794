import React from "react";
import ContentImages from "../../screens/UserContent/Images";
import ContentVideos from "../../screens/UserContent/Videos";
import ContentProjects from "../../screens/UserContent/Projects";
import ContentThesis from "../../screens/UserContent/Thesis";
import ContentPortfolios from "../../screens/UserContent/Portfolios";
import ContentProfiles from "../../screens/UserContent/Profiles";
import ContentJobs from "../../screens/UserContent/Jobs";

import { SIDEBAR_ICONS } from "../../constants/icons";
import { FavouriteProducts } from "../../screens/UserContent/Products";
import PostedBlogs from "screens/UserContent/Blogs/Posted";

const FAVOURITES_ROUTES = {
  label: "My Favourites",
  path: "favourites",
  subMenu: [
    {
      label: "Images",
      path: "/favourites/images",
      menuPath: "/my-activity/favourites/images",
      component: <ContentImages />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Videos",
      path: "/favourites/videos",
      menuPath: "/my-activity/favourites/videos",
      component: <ContentVideos />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Projects",
      path: "/favourites/projects",
      menuPath: "/my-activity/favourites/projects",
      component: <ContentProjects />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Thesis & Dissertations",
      path: "/favourites/thesis",
      menuPath: "/my-activity/favourites/thesis",
      component: <ContentThesis />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Profiles",
      path: "/favourites/profiles",
      menuPath: "/my-activity/favourites/profiles",
      component: <ContentProfiles />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Portfolios",
      path: "/favourites/portfolio",
      menuPath: "/my-activity/favourites/portfolio",
      component: <ContentPortfolios />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Products",
      path: "/favourites/products",
      menuPath: "/my-activity/favourites/products",
      component: <FavouriteProducts />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Jobs",
      path: "/favourites/jobs",
      menuPath: "/my-activity/favourites/jobs",
      component: <ContentJobs />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Blogs",
      path: "/favourites/blog",
      menuPath: "/my-activity/favourites/blog",
      component: (
        <PostedBlogs title="Blogs" subTitle="All your saved blogs will be visible here." />
      ),
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    }
  ],
  icon: SIDEBAR_ICONS.MY_FAVOURITES_SIDEBAR_ICON,
  notFoundRoute: "/my-activity/favourites/images"
};

export default FAVOURITES_ROUTES;

import React from "react";
import BaseModal from "../Base";
import { Button, Modal } from "react-bootstrap";
import COLORS from "../../../constants/colors";
import { isFunction } from "lodash";

export default function ConfirmSignupModal({ show, entity, onHide }) {
  return (
    <BaseModal show={isFunction(show)} onHide={onHide}>
      <Modal.Body>
        <div className="p-2">
          <h5 className="my-1 fw-bold">
            You have chosen{" "}
            <span style={{ color: COLORS.PRIMARY_ORANGE }} className="text-uppercase">
              {entity}
            </span>{" "}
            as your user role
          </h5>
          <p className="mt-3">
            Please click ‘CONFIRM’ for this user role. Click ‘CHANGE’ to edit your user role.
          </p>
          <div className="d-flex mt-4">
            <Button variant="outline-primary w-50" onClick={onHide}>
              CHANGE
            </Button>
            <Button variant="primary w-50 mx-2" onClick={show}>
              CONFIRM
            </Button>
          </div>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { isFunction } from "lodash";
import ContentFactory from "apiClients/ContentFactory";
import Dropdown from "components/Dropdown";

export default function LeadStatus({ info, type, onUpdate, style }) {
  const [loading, setLoading] = useState(false);
  const leadCilent = ContentFactory.createRequest(type);

  const getOptions = () => {
    var options = [];

    function updateStatus(status) {
      setLoading(true);
      leadCilent
        .partialUpdate({ status, id: info.id }, false)
        .then(() => isFunction(onUpdate) && onUpdate())
        .then(() => setLoading(false));
    }

    if (info.status === "Pending") {
      options = [
        { label: "Pending", value: "Pending", props: { disabled: true } },
        {
          value: "Mark As Resolved",
          label: "Mark As Resolved",
          onClick: () => updateStatus("Resolved")
        }
      ];
    } else if (info.status === "Cancelled" || info.status === "Resolved") {
      options = [
        { label: "Pending", value: "Pending", props: { disabled: true } },
        { value: "Cancelled", label: "Cancelled", props: { disabled: true } },
        { value: "Resolved", label: "Resolved", props: { disabled: true } }
      ];
    }
    return options;
  };

  return loading ? (
    <Spinner animation="border" role="status" size="20" />
  ) : (
    <Dropdown value={info.status} options={getOptions()} style={{ ...style }} />
  );
}

const THESIS_CONSTANTS = {
  THESIS_CATEGORIES: [
    { value: "Architecture", label: "Architecture" },
    { value: "Interior Designing", label: "Interior Designing" },
    { value: "Landscape Design", label: "Landscape Design" },
    { value: "Facade Design", label: "Facade Design" },
    { value: "Lighting Design", label: "Lighting Design" }
  ],
  DEGREE_LEVELS: [
    { value: "Diploma", label: "Diploma" },
    { value: "Graduation", label: "Graduation" },
    { value: "Post Graduation", label: "Post Graduation" },
    { value: "Phd.", label: "Phd." }
  ],
  SOURCE_TYPE: [
    { value: "Thesis", label: "Thesis" },
    { value: "Dissertation", label: "Dissertation" }
  ]
};

export default THESIS_CONSTANTS;

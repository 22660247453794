import React from "react";
import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CardTitle, RenderBookmarkIcon, RenderSelect } from "../../Base/Card";
import { isArray } from "lodash";
import EngagementStats from "../../EngagementStats";
import { isMobile } from "react-device-detect";

export default function ProductCard({
  obj,
  onBookmarked,
  onAddedToIdeationBoard,
  isSelected,
  handleSelection,
  showSelect
}) {
  const navigate = useNavigate();

  const onClick = () => {
    var productURL = `/products/categories/${obj.categories.parent.slug}`;
    if (obj.categories.sub_category) {
      productURL += `/${obj.categories.sub_category.slug}`;
    }
    if (obj.categories.sub_sub_category) {
      productURL += `/${obj.categories.sub_sub_category.slug}`;
    }
    navigate(`${productURL}/${obj.slug}`);
  };

  const image = obj && obj.images_list && isArray(obj.images_list) ? obj.images_list[0] : "";

  return (
    <Card
      style={{ height: isMobile ? "208px" : "350px" }}
      onClick={onClick}
      className="border-0 shadow rounded-0 cursor-pointer"
    >
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      <RenderBookmarkIcon
        onBookmarked={onBookmarked}
        onAddedToIdeationBoard={onAddedToIdeationBoard}
        type="product"
        obj={obj}
      />
      <Card.Body className="p-0 h-100">
        <Image
          src={image}
          className="w-100"
          style={{ height: isMobile ? "150px" : "250px", objectFit: "contain" }}
        />
        <div
          style={{ height: isMobile ? "58px" : "100px" }}
          className="d-flex justify-content-evenly flex-column px-2"
        >
          <CardTitle
            style={{ marginBottom: 0, ...(isMobile ? { fontSize: "12px" } : {}) }}
            numOfLines={isMobile ? 1 : 2}
          >
            {obj.product_name}
          </CardTitle>
          <p className="mb-0 text-muted" style={{ fontSize: isMobile ? "10px" : "85%" }}>
            {obj.brand_name}
          </p>
          <EngagementStats saves={obj.saves} width="7" fontSize="12.5px" />
        </div>
      </Card.Body>
    </Card>
  );
}

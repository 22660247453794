import { useSearchParams as rrUseSearchParams } from "react-router-dom";
import URLParamsManager from "../managers/URLParams";
import { isArray, isString } from "lodash";

export function useSearchParams() {
  const [searchParams, setSearchParams] = rrUseSearchParams();

  const paramsManager = new URLParamsManager(searchParams);

  const addKey = (key, value) => {
    paramsManager.addOrUpdateProperty(key, value);
    setSearchParams(paramsManager.getSearchParams(), { replace: true });
  };

  const addKeys = (properties) => {
    paramsManager.addOrUpdateProperties(properties);
    setSearchParams(paramsManager.getSearchParams(), { replace: true });
  };

  const clear = () => {
    paramsManager.clearSearchParams();
    setSearchParams(paramsManager.getSearchParams(), { replace: true });
  };

  const deleteKey = (key) => {
    paramsManager.deleteProperty(key);
    setSearchParams(paramsManager.getSearchParams(), { replace: true });
  };

  const getKey = (key) => paramsManager.getSearchParam(key);

  const resetQuery = (params) => {
    paramsManager.reset(params);
    setSearchParams(paramsManager.getSearchParams(), { replace: true });
  };

  const setQueryFromObj = (obj) => {
    paramsManager.objectToQueryString(obj);
    setSearchParams(paramsManager.getSearchParams(), { replace: true });
  };

  const toQuery = (obj) => {
    return new URLParamsManager(obj).objectToQueryString(obj);
  };

  function convertSearchParamsValuesToArray() {
    const outputObject = {};
    const obj = paramsManager.getSearchParamsAsObject();

    for (const [key, value] of Object.entries(obj)) {
      if (isString(value) && value.includes(",")) {
        const valueList = value.split(",").map((item) => item.trim());
        outputObject[key] = valueList;
      } else if (isArray(value)) {
        outputObject[key] = value;
      } else {
        outputObject[key] = [value];
      }
    }

    return outputObject;
  }

  return {
    searchParams: paramsManager.searchParams,
    addKey,
    addKeys,
    clear,
    deleteKey,
    getKey,
    queryString: paramsManager.searchParams.toString(),
    searchParamsAsObject: paramsManager.getSearchParamsAsObject(),
    resetQuery,
    setQueryFromObj,
    convertSearchParamsValuesToArray,
    toQuery
  };
}

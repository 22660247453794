import React, { useEffect, useRef, useState } from "react";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

import LoadingContainer from "../components/Containers/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import ContentFactory from "../apiClients/ContentFactory";
import COLORS from "../constants/colors";
import { Col, Row, Stack } from "react-bootstrap";
import PdfPreview from "../components/PdfPreview";
import { getContentAbsoluteUrl } from "../utils";
import EngagementStats from "../components/EngagementStats";
import EngagementActions from "../components/EngagementActions";
import ProfileImage from "../components/ProfileImage";
import ThesisCard from "../components/Cards/Thesis";
import BackButton from "../components/Buttons/Back";
import { getCardHeight } from "../components/Base/Card";
import { isMobile } from "react-device-detect";

function LearnThesis() {
  const [loading, setLoading] = useState(true);
  const [thesis, setThesis] = useState(undefined);
  const { id } = useParams();
  const client = ContentFactory.createRequest("thesis");
  const [maxHeight, setMaxHeight] = useState("40rem");
  const detailsColRef = useRef(null);

  useEffect(() => {
    if (thesis) {
      if (detailsColRef.current) {
        const colHeight = detailsColRef.current.clientHeight;
        if (maxHeight !== colHeight) {
          setMaxHeight(colHeight);
        }
      }
    }
  }, [thesis, detailsColRef]);

  const handleGetThesis = () =>
    client
      .read(id)
      .then(setThesis)
      .then(() => setLoading(false));
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    handleGetThesis();
  }, [id]);

  var institute, position;
  if (thesis && thesis.about_the_author && thesis.about_the_author.user) {
    if (thesis.about_the_author.user.is_working_professional === true) {
      if (thesis.about_the_author.user.latest_experience) {
        institute = thesis.about_the_author.user.latest_experience.company;
        position = thesis.about_the_author.user.latest_experience.title;
      }
    } else if (thesis.about_the_author.user.is_student === true) {
      if (thesis.about_the_author.user.latest_education) {
        institute = thesis.about_the_author.user.latest_education.college;
        position = thesis.about_the_author.user.latest_education.degree;
      } else if (thesis.about_the_author.user.latest_experience) {
        institute = thesis.about_the_author.user.latest_experience.company;
        position = thesis.about_the_author.user.latest_experience.title;
      }
    }
  }

  const navigate = useNavigate();
  return (
    <div className="w-100">
      <LoadingContainer loading={loading}>
        {thesis && (
          <>
            <div style={{ background: COLORS.GREY1 }} className="p-4">
              <BackButton />
            </div>
            <Row className={`mx-${isMobile ? 3 : 5} py-4`} xs={1}>
              <EngagementActions type="thesis" obj={thesis.thesis} onSuccess={handleGetThesis} />
              <Col sm={4} className="mt-3" ref={detailsColRef}>
                <Row>
                  <Stack
                    className="rounded shadow p-4"
                    gap={2}
                    style={{ background: COLORS.GREY1 }}
                  >
                    <h1 className="mb-0 fw-bold">{thesis.thesis.title}</h1>
                    <p className="mb-0 fw-bold text-secondary">{thesis.thesis.source_type}</p>
                    <p className="mb-0 fw-bold" style={{ color: COLORS.PRIMARY_ORANGE }}>
                      {thesis.thesis.user}
                    </p>
                    <EngagementStats
                      likes={thesis.thesis.likes}
                      saves={thesis.thesis.saves}
                      views={thesis.thesis.views}
                    />
                    <p className="my-4">{thesis.thesis.description}</p>
                    <h5 className="text-secondary mb-0">Institute</h5>
                    <h4 className="mb-0" style={{ fontSize: "135%" }}>
                      {thesis.thesis.college}
                    </h4>
                    <h5 className="text-secondary mt-4 mb-0">Degree Level</h5>
                    <h4 className="mb-0" style={{ fontSize: "135%" }}>
                      {thesis.thesis.degree_level}
                    </h4>
                  </Stack>
                </Row>
                <Row className="mt-4">
                  <Stack
                    className="rounded shadow p-4"
                    gap={4}
                    style={{ background: COLORS.GREY1 }}
                  >
                    <h4 className="fw-bold">About the Author</h4>
                    <Stack direction="horizontal" gap={4}>
                      <div style={{ width: "20%" }}>
                        <ProfileImage customUser={thesis.about_the_author.user} />
                      </div>
                      <Stack gap={2}>
                        <p className="mb-0 fw-bold">{thesis.about_the_author.user.name}</p>
                        {institute && <p className="mb-0">{institute}</p>}
                        {position && (
                          <p className="mb-0 fw-bold" style={{ color: COLORS.PRIMARY_ORANGE }}>
                            {position}
                          </p>
                        )}
                        <Link to={`/profiles/${thesis.about_the_author.user.slug}`}>
                          View Complete Profile
                        </Link>
                        {/* <div className="mt-2">
                        <Button style={{ paddingLeft: "5%", paddingRight: "5%" }}>SUBSCRIBE</Button>
                      </div> */}
                      </Stack>
                    </Stack>
                  </Stack>
                </Row>
              </Col>
              <Col sm={8}>
                <PdfPreview file={getContentAbsoluteUrl(thesis.thesis.file)} width={492.5} />
              </Col>
              <div className="mt-5 px-0">
                <h6 className="mb-4">Related Thesis</h6>
                <Row xs={1} sm={4}>
                  {thesis.related_thesis.map((el) => (
                    <Col key={`${el.id}`} style={{ height: getCardHeight() }} className="mb-4">
                      <ThesisCard
                        obj={el}
                        onBookmarked={handleGetThesis}
                        onClick={() => navigate(`/learn/thesis/${el.slug}`)}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Row>
          </>
        )}
      </LoadingContainer>
    </div>
  );
}

export default withMobileOnlySidebar(LearnThesis);

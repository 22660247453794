import React, { useEffect } from "react";
import BaseModal from "../Base";
import { CloseButton, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import AddMoodBoard from "../../../forms/AddMoodBoard";
import { isFunction } from "lodash";

export default function AddMoodBoardModal({ show, onHide, onSuccess, ideationBoardId, moodboard }) {
  const methods = useForm({ reValidateMode: "onChange" });

  const handleSuccess = () => {
    if (isFunction(onSuccess)) {
      onSuccess();
    }
    onHide();
  };

  useEffect(() => {
    if (moodboard) {
      methods.setValue("name", moodboard.name);
    }
  }, []);
  return (
    <BaseModal show={show} onHide={onHide} size="md">
      <div className="d-flex justify-content-end align-items-center pt-3 pb-1 pe-3">
        <CloseButton onClick={onHide} />
      </div>
      <Modal.Body>
        <FormProvider {...methods}>
          <AddMoodBoard
            onCancel={onHide}
            onSuccess={handleSuccess}
            ideationBoardId={ideationBoardId}
            moodboard={moodboard}
          />
        </FormProvider>
      </Modal.Body>
    </BaseModal>
  );
}

import React from "react";
import { BsCheck } from "react-icons/bs";
import COLORS from "../../../constants/colors";

export default function FormCountContainer({ activePart, parts = [] }) {
  return (
    <div className="form-count-container">
      <div className={`form-count d-flex ${activePart === 1 ? "active" : ""}`}>
        {parts.map((el, index) => (
          <React.Fragment key={`form-count-${index}-${el}`}>
            <div>
              {activePart > index + 1 ? (
                <div
                  style={{
                    backgroundColor: COLORS.TURQUOISE,
                    width: "1.5rem",
                    height: "1.5rem"
                  }}
                  className="rounded-circle d-flex justify-content-center align-items-center"
                >
                  <BsCheck size={20} color="white" />
                </div>
              ) : activePart === index + 1 ? (
                <div>
                  <div
                    style={{
                      backgroundColor: COLORS.TURQUOISE,
                      width: "1.5rem",
                      height: "1.5rem",
                      fontSize: "85%"
                    }}
                    className="rounded-circle d-flex justify-content-center align-items-center text-white"
                  >
                    {index + 1}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      backgroundColor: "white",
                      border: `2px solid ${COLORS.PRIMARY_ORANGE}`,
                      width: "1.5rem",
                      height: "1.5rem",
                      color: COLORS.PRIMARY_ORANGE,
                      fontSize: "85%"
                    }}
                    className="rounded-circle d-flex justify-content-center align-items-center"
                  >
                    {index + 1}
                  </div>
                </div>
              )}
            </div>
            <div
              key={`form-count-2-${index}`}
              className="ms-2 me-5"
              style={{
                color:
                  activePart > index + 1 || activePart === index + 1
                    ? COLORS.TURQUOISE
                    : COLORS.PRIMARY_ORANGE
              }}
            >
              <p>{el}</p>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentFactory from "../../apiClients/ContentFactory";
import LoadingContainer from "../../components/Containers/Loading";
import COLORS, { getApplicationStatusColors } from "../../constants/colors";
import {
  Accordion,
  Button,
  CloseButton,
  Col,
  Dropdown as RBDropdown,
  Form,
  Image,
  Row,
  Stack,
  Tab,
  Tabs
} from "react-bootstrap";
import LocationFilters from "../../components/Filters/Location";
import ExperienceLevelFilter from "../../components/Filters/ExperienceLevelFilter";
import EducationLevelFilter from "../../components/Filters/EducationLevelFilter";
import { isArray, isEmpty, isObject, isString, isUndefined } from "lodash";
import DateRangeFormatter from "../../components/DateRangeFormatter";
import { SavedExperience } from "../../forms/Content/AddResume/Sections/Experience";
import { SavedEducation } from "../../forms/Content/AddResume/Sections/Education";
import { SavedCertificationCourses } from "../../forms/Content/AddResume/Sections/Courses";
import { SavedAwardsAchievement } from "../../forms/Content/AddResume/Sections/AwardsAchievements";
import "./applications.scss";
import { downloadFile, renderErrorToast, showPromiseToast } from "../../utils";
import { useSearchParams } from "../../hooks/searchParams";
import { MoreFiltersDropdown } from "../../components/Filters/MoreFilters";
import { DropDownCheckboxMenuItem } from "../../components/Inputs/DropdownCheckbox";
import ContentHeader from "components/Base/ContentHeader";
import BottomSheet from "components/Modals/BottomSheet";
import Sheet from "react-modal-sheet";
import { isMobile } from "react-device-detect";
import FILTER_ICON from "assets/icons/filter.png";
import Dropdown from "components/Dropdown";
import JOB_CONSTANTS from "constants/Jobs";
import { STATES } from "constants/states";

function SkillsFilterButton() {
  return <RBDropdown.Toggle variant="outline-dark">Skills</RBDropdown.Toggle>;
}

function MobileFilters({ isOpen, setOpen, job }) {
  const { addKey } = useSearchParams();

  const handleItemClick = (name, value) => {
    const data = isString(value) ? value.split(",") : [];
    addKey(
      name,
      (data || []).includes(value)
        ? data.filter((el) => el !== value)
        : [...(data || []), value].join(",")
    );
  };

  const AccordionFilter = ({ item, eventKey }) => {
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <span className="fw-bold">{item.title}</span>
        </Accordion.Header>
        <Accordion.Body className="px-0 pb-0 pt-2">
          {item.options.map((el, itemIndex) => (
            <DropDownCheckboxMenuItem
              key={`${itemIndex + el.label}-dropdown-menu-item`}
              item={el}
              handleItemClick={() => handleItemClick(item.key, item.value || [], el.value)}
              checked={(item.value || []).includes(el.value)}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const FiltersInAccordion = ({ list }) => {
    return (
      <Accordion defaultActiveKey="0">
        {list.map((item, index) => (
          <AccordionFilter
            key={`${index + item.title}-accordion-item`}
            item={item}
            eventKey={index}
          />
        ))}
      </Accordion>
    );
  };

  const {
    searchParamsAsObject: {
      technical_skills,
      management_skills,
      personal_skills,
      experience_level,
      education_level,
      location
    }
  } = useSearchParams();

  return (
    <BottomSheet open={isOpen} close={() => setOpen(false)}>
      <div className="mx-3 mb-3 d-flex justify-content-between cursor-pointer">
        <h5 className="fw-bold">Filters</h5>
        <CloseButton onClick={() => setOpen(false)} />
      </div>
      <Sheet.Scroller>
        <FiltersInAccordion
          list={[
            ...[
              {
                title: "Location",
                key: "location",
                options: STATES,
                value: location
              },
              {
                title: "Technical Skills",
                key: "technical_skills",
                options: job.technical_skills.map((el) => ({ value: el, label: el })),
                value: technical_skills
              },
              {
                title: "Management Skills",
                key: "management_skills",
                options: job.management_skills.map((el) => ({ value: el, label: el })),
                value: management_skills
              },
              {
                title: "Personal Skills",
                key: "personal_skills",
                options: job.personal_skills.map((el) => ({ value: el, label: el })),
                value: personal_skills
              },
              {
                title: "Experience Level",
                key: "experience_level",
                options: JOB_CONSTANTS.EXPERIENCE_LEVEL,
                value: experience_level
              },
              {
                title: "Education Level",
                key: "education_level",
                options: [
                  { label: "PhD", value: "PhD" },
                  { label: "Post Graduation", value: "Post Graduation" },
                  { label: "Graduation", value: "Graduation" },
                  { label: "Diploma", value: "Diploma" }
                ],
                value: education_level
              }
            ]
          ]}
        />
      </Sheet.Scroller>
    </BottomSheet>
  );
}

function Filters({ job }) {
  const {
    searchParamsAsObject: { technical_skills, management_skills, personal_skills }
  } = useSearchParams();
  const { addKey } = useSearchParams();
  const handleSetFilters = (name, value) => addKey(name, value);
  const [isOpen, setOpen] = useState(false);

  return (
    <Stack direction="horizontal" gap={3} className="align-items-center">
      {isMobile ? (
        <>
          <div
            onClick={() => setOpen(true)}
            className="d-flex justify-content-between align-items-center border border-dark rounded px-1 py-2"
          >
            <Image src={FILTER_ICON} style={{ width: "1.25rem", objectFit: "contain" }} />
          </div>
          <MobileFilters isOpen={isOpen} setOpen={setOpen} job={job} />
        </>
      ) : (
        <>
          <h6 className="mb-0">Filters</h6>
          <LocationFilters />
          <MoreFiltersDropdown Component={SkillsFilterButton}>
            <Accordion defaultActiveKey="0">
              {[
                {
                  title: "Technical Skills",
                  key: "technical_skills",
                  options: job.technical_skills.map((el) => ({ value: el, label: el })),
                  value: technical_skills
                },
                {
                  title: "Management Skills",
                  key: "management_skills",
                  options: job.management_skills.map((el) => ({ value: el, label: el })),
                  value: management_skills
                },
                {
                  title: "Personal Skills",
                  key: "personal_skills",
                  options: job.personal_skills.map((el) => ({ value: el, label: el })),
                  value: personal_skills
                }
              ].map((item, index) => (
                <Accordion.Item key={`${index + item.title}-accordion-item`} eventKey={index}>
                  <Accordion.Header>
                    <span className="fw-bold">{item.title}</span>
                  </Accordion.Header>
                  <Accordion.Body className="px-0 pb-0 pt-2">
                    {item.options.map((el, itemIndex) => (
                      <DropDownCheckboxMenuItem
                        key={`${itemIndex + el.label}-dropdown-menu-item`}
                        item={el}
                        handleItemClick={(value) => {
                          const data = isString(item.value) ? item.value.split(",") : [];
                          handleSetFilters(
                            item.key,
                            (data || []).includes(value)
                              ? data.filter((el) => el !== value)
                              : [...(data || []), value].join(",")
                          );
                        }}
                        checked={(item.value || []).includes(el.value)}
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </MoreFiltersDropdown>
          <ExperienceLevelFilter />
          <EducationLevelFilter />
        </>
      )}
    </Stack>
  );
}

function ActionButtons({ application, onSuccess }) {
  const { id, status } = application;
  const [submitting, setSubmitting] = useState(false);
  const client = ContentFactory.createRequest("job-application");

  const updateState = (status) => {
    setSubmitting(true);
    const promise = client
      .update({ id, status })
      .then(onSuccess)
      .then(() => setSubmitting(false));
    showPromiseToast(promise, {
      pending: "Updating job application status",
      success: "Job application status updated successfully",
      error: renderErrorToast()
    });
  };
  return (
    <Stack direction="horizontal" gap={3}>
      {[
        {
          text: "NOT SELECTED",
          variant: "outline-dark",
          onClick: () => updateState("Not Selected"),
          show: !["Not Selected", "Hired"].includes(status)
        },
        {
          text: "SHORTLIST",
          variant: "outline-primary",
          onClick: () => updateState("Shortlisted"),
          show: !["Not Selected", "Shortlisted", "Hired"].includes(status)
        },
        {
          text: "HIRE",
          onClick: () => updateState("Hired"),
          show: !["Not Selected", "Hired"].includes(status)
        }
      ].map(
        (button, index) =>
          button.show && (
            <Col key={index}>
              <Button
                variant={button.variant}
                className="w-100 fw-bold"
                onClick={button.onClick}
                disabled={submitting}
              >
                {button.text}
              </Button>
            </Col>
          )
      )}
    </Stack>
  );
}

function Applicant({ obj, onActionSuccess }) {
  const navigate = useNavigate();
  const {
    question_answers,
    status,
    profile: {
      user: { location, name },
      resume: { employment, education, technical_skills, management_skills, personal_skills }
    }
  } = obj;
  return (
    <div className="mb-3">
      <Row className="border py-4 px-2 mx-1 rounded">
        <Col sm={2}>
          <div>
            <h5>{name}</h5>
            <Form.Label className="text-muted">{location}</Form.Label>
            <h6 style={{ marginBottom: "20px" }}>
              Status: <span style={{ color: getApplicationStatusColors(status) }}>{status}</span>
            </h6>
          </div>
        </Col>
        <Col>
          <Stack gap={isMobile ? 2 : 4}>
            <div>
              <Form.Label className="text-muted">Experience</Form.Label>
              {isArray(employment) &&
                !isEmpty(employment) &&
                employment.map((el) => (
                  <Stack
                    key={`${el.id}-employment`}
                    direction={isMobile ? "vertical" : "horizontal"}
                    gap={isMobile ? 1 : 4}
                    style={{ flexWrap: "wrap", marginBottom: isMobile ? "1.5rem" : "" }}
                  >
                    <div className="fw-bold">{el.title}</div>
                    <div>{el.employment_type}</div>
                    <div className="d-flex">
                      <span className="me-2">{el.company},</span>
                      <DateRangeFormatter
                        startDate={el.start_date}
                        endDate={el.end_date}
                        isPresent={el.currently_working_here}
                      />
                    </div>
                  </Stack>
                ))}
            </div>
            <div className="w-75">
              <Form.Label className="text-muted">Education</Form.Label>
              {isArray(education) &&
                !isEmpty(education) &&
                education.map((el) => (
                  <Stack
                    key={`${el.id}-education`}
                    direction="horizontal"
                    gap={4}
                    style={{ flexWrap: "wrap" }}
                  >
                    <Stack className="fw-bold" direction="horizontal">
                      {el.degree},{" "}
                      {el.specialization && <div className="ms-2">{el.specialization}</div>}
                    </Stack>
                    <div>{el.college}</div>
                    <div>
                      <DateRangeFormatter
                        startDate={el.start_date}
                        endDate={el.end_date}
                        isPresent={el.currently_working_here}
                      />
                    </div>
                  </Stack>
                ))}
            </div>
            <div>
              <Form.Label className="text-muted">Skills</Form.Label>
              <Stack
                direction={isMobile ? "vertical" : "horizontal"}
                gap={2}
                style={{ flexWrap: "wrap" }}
                className="w-100"
              >
                {[...personal_skills, ...management_skills, ...technical_skills].map((el) => (
                  <p
                    key={`skill-${el}`}
                    className="rounded bg-orange text-white py-1 px-3 mb-1"
                    style={{ width: "max-content", whiteSpace: "nowrap" }}
                  >
                    {el}
                  </p>
                ))}
              </Stack>
            </div>
            <div>
              <Form.Label className="text-muted">Answer to your question</Form.Label>
              {question_answers.map((el, index) => (
                <Row key={`question-answer-${index}`} className="mx-0" xs={1}>
                  <Col sm={1} className="px-0">
                    <p style={{ fontSize: "85%" }} className="mb-2">
                      Answer {index + 1}.
                    </p>
                  </Col>
                  <Col>
                    <p style={{ fontSize: "95%" }} className="mb-2">
                      {el.answer}
                    </p>
                  </Col>
                </Row>
              ))}
            </div>
          </Stack>
        </Col>
        {!isMobile ? (
          <Row className="mt-4 mb-2">
            <Col>
              <ActionButtons application={obj} onSuccess={onActionSuccess} />
            </Col>
            <Col className="d-flex justify-content-end">
              <p
                style={{ color: COLORS.PRIMARY_ORANGE }}
                className="fw-bold cursor-pointer"
                onClick={() => navigate(`${obj.id}`)}
              >
                VIEW FULL APPLICATION
              </p>
            </Col>
          </Row>
        ) : (
          <div>
            <Dropdown
              style={{
                backgroundColor: COLORS.PRIMARY_ORANGE,
                border: "none",
                color: "white",
                fontWeight: "bold",
                width: "100%"
              }}
              label="Actions"
              options={[
                { label: "Hire", value: "Hired" },
                { label: "Shortlist", value: "Shortlist" },
                { label: "Not Selected", value: "Not Selected" }
              ]}
            />
          </div>
        )}
      </Row>
    </div>
  );
}

export function SelectedApplication({ application, onActionSuccess, noActionButtons = false }) {
  const {
    status,
    question_answers,
    resume_file,
    profile: {
      user: { name, location },
      resume: {
        about,
        employment,
        education,
        courses,
        awards,
        hobbies,
        technical_skills,
        management_skills,
        personal_skills
      }
    }
  } = application;
  const downloadResume = () => {
    if (resume_file) {
      downloadFile(resume_file, "Resume.pdf");
    }
  };
  const [part, setPart] = useState(0);
  return (
    <div className="border rounded">
      <div style={{ backgroundColor: COLORS.GREY3 }} className="py-4 px-4">
        <Row>
          <Col>
            <h5>{name}</h5>
            <p className="text-muted">{location}</p>
            <h6>
              Status: <span style={{ color: getApplicationStatusColors(status) }}>{status}</span>
            </h6>
          </Col>
          {!noActionButtons && (
            <Col>
              <ActionButtons application={application} onSuccess={onActionSuccess} />
            </Col>
          )}
        </Row>
      </div>
      <div className="border">
        <Row className="px-4 py-3 border mx-0">
          <Col>
            <Stack direction="horizontal" gap={5}>
              <p
                className="mb-0 cursor-pointer"
                style={{
                  color: part === 0 ? COLORS.PRIMARY_ORANGE : "black",
                  fontWeight: part === 0 ? "bold" : "normal"
                }}
                onClick={() => setPart(0)}
              >
                PROFILE DETAILS
              </p>
              {isArray(question_answers) && !isEmpty(question_answers) && (
                <p
                  className="mb-0 cursor-pointer"
                  style={{
                    color: part === 1 ? COLORS.PRIMARY_ORANGE : "black",
                    fontWeight: part === 1 ? "bold" : "normal"
                  }}
                  onClick={() => setPart(1)}
                >
                  ANSWERS
                </p>
              )}
            </Stack>
          </Col>
        </Row>
      </div>
      {part === 0 ? (
        <div>
          <Row className="p-4" xs={1} sm={3}>
            <Col sm={3}>
              <h5>About</h5>
            </Col>
            <Col>{about}</Col>
          </Row>
          {[
            {
              title: "Experience",
              component: SavedExperience,
              data: employment,
              name: "experience"
            },
            {
              title: "Education",
              component: SavedEducation,
              data: education,
              name: "education"
            },
            {
              title: "Courses & Certifications",
              component: SavedCertificationCourses,
              data: courses,
              name: "certificationCourse"
            },
            {
              title: "Awards & Achievements",
              component: SavedAwardsAchievement,
              data: awards,
              name: "awardsAchievement"
            }
          ].map(
            (section, index) =>
              isArray(section.data) &&
              !isEmpty(section.data) && (
                <Row className="px-4 py-3" key={`${section.title}-${index}`} xs={1} sm={3}>
                  <Col sm={3}>
                    <h5>{section.title}</h5>
                  </Col>
                  <Col>
                    {section.data.map((el) => (
                      <section.component
                        key={`${el.id}-${section.title.toLowerCase()}`}
                        {...{ [section.name]: el }}
                      />
                    ))}
                  </Col>
                </Row>
              )
          )}
          {[
            { title: "Hobbies", data: hobbies },
            { title: "Technical Skills", data: technical_skills },
            { title: "Management Skills", data: management_skills },
            { title: "Personal Skills", data: personal_skills }
          ].map(
            (section, index) =>
              isArray(section.data) &&
              !isEmpty(section.data) && (
                <Row className="px-4 py-3" key={`${section.title}-${index}`} xs={1} sm={3}>
                  <Col sm={3}>
                    <h5>{section.title}</h5>
                  </Col>
                  <Col sm={9}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ flexWrap: "wrap" }}
                      className="w-100"
                    >
                      {section.data.map((el, elIndex) => (
                        <p
                          key={`${section.title}-${elIndex}-${el}`}
                          className="rounded bg-orange text-white py-1 px-3 mb-1"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {el}
                        </p>
                      ))}
                    </Stack>
                  </Col>
                </Row>
              )
          )}

          {resume_file && (
            <Row className="px-4 py-3">
              <Col xs={2}>
                <h6>Resume</h6>
              </Col>
              <Col>
                <p onClick={downloadResume}>Resume.pdf</p>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        isArray(question_answers) &&
        !isEmpty(question_answers) && (
          <Stack className="p-4" gap={4}>
            {question_answers.map((el, index) => (
              <div key={el.question}>
                <h6>
                  Question {index + 1}. {el.question}
                </h6>
                <p>{el.answer}</p>
              </div>
            ))}
          </Stack>
        )
      )}
    </div>
  );
}

export default function JobApplications() {
  const { id, applicationId } = useParams();

  const [job, setJob] = useState(null);
  const [count, setCount] = useState({});
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const client = ContentFactory.createRequest("job");
  const jobApplicationsClient = ContentFactory.createRequest("job-application");
  const { getKey, searchParamsAsObject, addKey, queryString } = useSearchParams();

  const handleGet = () =>
    Promise.all([
      jobApplicationsClient.list({ job: id, ...searchParamsAsObject }),
      client.read(id),
      client.getApplicantsCount(id)
    ]).then((result) => {
      setApplications(result[0]);
      setJob(result[1]);
      setCount(result[2]);
    });

  const isFullApplication = !isUndefined(applicationId);
  const status = getKey("status");

  useEffect(() => {
    handleGet().then(() => setLoading(false));
  }, [queryString]);

  useEffect(() => {
    if (isArray(applications) && !isEmpty(applications)) {
      const application = applications.find((el) => el.id === Number(applicationId));
      if (isObject(application)) {
        setSelectedApplicant(application);
      }
    }
  }, [applicationId, applications, isFullApplication]);

  function TabContent() {
    return (
      <div className="px-3 py-1">
        <Filters job={job} />
        <div className="d-flex justify-content-between mt-4 mb-1">
          <Form.Group>
            <Form.Label className="text-muted">{applications.length} Applicants</Form.Label>
          </Form.Group>
          {isMobile && applications.length > 1 && (
            <div>
              <Form.Group>
                <Form.Label className="text-muted">Swipe for next -&gt;</Form.Label>
              </Form.Group>
            </div>
          )}
        </div>
        <div style={isMobile ? { overflowX: "auto", whiteSpace: "nowrap" } : {}}>
          {applications.map((el) => (
            <div key={el.id} style={isMobile ? { display: "inline-block", width: "97.5%" } : {}}>
              <Applicant obj={el} onActionSuccess={handleGet} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="pb-5">
      <LoadingContainer loading={loading || (isFullApplication && !selectedApplicant)}>
        {job && (
          <div className="mt-4">
            <div className={isMobile ? "" : "mx-4"}>
              <ContentHeader title={`Applications for ${job.position}`} />
            </div>
            {isFullApplication && selectedApplicant ? (
              <div className="mx-4">
                <SelectedApplication application={selectedApplicant} onActionSuccess={handleGet} />
              </div>
            ) : (
              <Tabs
                activeKey={status || "All Applicants"}
                onSelect={(k) => addKey("status", k === "All Applicants" ? null : k)}
                className="mb-3 applications-tabs"
                style={{ paddingLeft: "1.5%" }}
              >
                {[
                  { eventKey: "All Applicants", title: `All Applicants (${count.All || 0})` },
                  { eventKey: "Shortlisted", title: `Shortlisted (${count.Shortlisted || 0})` },
                  {
                    eventKey: "Not Selected",
                    title: `Not Selected (${count["Not Selected"] || 0})`
                  },
                  { eventKey: "Hired", title: `Hired (${count["Hired"] || 0})` }
                ].map((tab) => (
                  <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}>
                    <TabContent />
                  </Tab>
                ))}
              </Tabs>
            )}
          </div>
        )}
      </LoadingContainer>
    </div>
  );
}

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import COLORS from "../../../constants/colors";

export default function Search({ placeholder, searchString, setSearchString, background }) {
  const [searchFocused, setSearchFocused] = useState(false);

  return (
    <div className="search-input w-100">
      <BsSearch className="search-icon" />
      <Form.Control
        type="text"
        placeholder={placeholder || "Search"}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        style={{
          background: background || searchFocused ? "white" : COLORS.GREY1,
          border: "none",
          paddingLeft: "2rem"
        }}
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
      />
    </div>
  );
}

import React from "react";
import { useController, useFormContext } from "react-hook-form";
import OtpInput from "react-otp-input";
import FormError from "../../FormError";
import VALIDATORS from "../../../validators";

export default function OTP({ required, name = "otp", numInputs = 6 }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { field } = useController({ name, control, rules: VALIDATORS.otp(6, required) });
  return (
    <>
      <OtpInput
        {...field}
        inputType="number"
        name={name}
        numInputs={numInputs}
        shouldAutoFocus
        isInputNum
        containerStyle="otp-container mb-0"
        inputStyle="otp-input"
        renderInput={(props) => <input {...props} />}
      />

      {errors && errors[name] && (
        <FormError className="d-block text-center mt-2">{errors[name].message}</FormError>
      )}
    </>
  );
}

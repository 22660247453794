import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { isDesktop } from "react-device-detect";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import { CITIES } from "constants/states";
import { STATES } from "constants/states";

const AddressForm = () => {
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const state = watch("state");
  const pincode = watch("pincode");
  const country = watch("country");
  const [selectedState, setSelectedState] = useState(state);

  useEffect(() => {
    setSelectedState(state);

    if (
      state &&
      state.value &&
      selectedState &&
      selectedState.value &&
      state.value !== selectedState.value
    ) {
      setValue("pincode", "");
      setValue("city", null);
    }
  }, [state]);

  useEffect(() => {
    if (!country) {
      setValue("country", "India");
    }
  }, []);

  useEffect(() => {
    const fetchAddressDetails = async () => {
      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);

        const { PostOffice } = response.data[0];

        if (PostOffice && PostOffice.length > 0) {
          const { District, State, Country } = PostOffice[0];
          if (State !== selectedState.value) {
            setError("pincode", { message: "Invalid Pincode" });
          } else {
            setValue("state", { value: State, label: State });
            setValue("city", { value: District, label: District });
            setValue("country", Country);
            clearErrors("pincode");
          }
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
      }
    };

    if (pincode && pincode.length === 6) {
      fetchAddressDetails();
    }
  }, [pincode]);

  // Conditionally render form elements in rows and columns based on the device type
  const renderFormElements = () => {
    if (isDesktop) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <Form.Group>
                <ReactHookInput
                  name="address_line1"
                  type="text"
                  placeholder="#Flat No, Block, Street, Area"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <ReactHookInput name="country" type="text" placeholder="Country" required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Select name="state" placeholder="Select state" options={STATES} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Select
                  name="city"
                  placeholder="Select a city"
                  options={
                    (selectedState &&
                      selectedState.value &&
                      selectedState.value in CITIES &&
                      CITIES[selectedState.value].map((el) => ({
                        value: el,
                        label: el
                      }))) ||
                    []
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <ReactHookInput name="pincode" type="text" placeholder="Pincode" required />
              </Form.Group>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      // Render form elements without rows and columns for non-desktop devices
      return (
        <React.Fragment>
          <Form.Group>
            <ReactHookInput
              name="address_line1"
              type="text"
              placeholder="#Flat No, Block, Street, Area"
              required
            />
          </Form.Group>
          <Form.Group>
            <ReactHookInput name="country" type="text" placeholder="Country" required />
          </Form.Group>
          <Form.Group>
            <Select
              name="city"
              placeholder="Select a city"
              options={
                (selectedState &&
                  selectedState.value &&
                  selectedState.value in CITIES &&
                  CITIES[selectedState.value].map((el) => ({
                    value: el,
                    label: el
                  }))) ||
                []
              }
            />
          </Form.Group>
          <Form.Group>
            <Select name="state" placeholder="Select state" options={STATES} />
          </Form.Group>
          <Form.Group>
            <ReactHookInput name="pincode" type="text" placeholder="Pincode" required />
          </Form.Group>
        </React.Fragment>
      );
    }
  };

  return (
    <Form.Group>
      <Form.Label>Registered Address*</Form.Label>
      <Stack gap={2}>{renderFormElements()}</Stack>
    </Form.Group>
  );
};

export default AddressForm;

import React from "react";
import { useNavigationInfo } from "../../../hooks";
import { isMobile } from "react-device-detect";
import Breadcrumbs from "components/Breadcrumbs";
import BackButton from "components/Buttons/Back";

export default function ContentHeader({ subTitle, type, title, doNotShowSubtitle = false }) {
  const { isActivity, isMyProfileOrActivity, isAll } = useNavigationInfo();

  return (
    <div className="mx-auto w-100">
      {isMobile && (
        <div className="mb-3 ms-2">
          <Breadcrumbs />
        </div>
      )}
      <div className={`d-flex align-items-center mb-3 px-${isMobile ? 2 : 0}`}>
        {isMobile && (
          <div style={{ marginTop: "-15px" }}>
            <BackButton doNotShowText />
          </div>
        )}
        <h4 className="bordered-heading text-capitalize mb-0">
          {isAll ? "All " : ""}
          {title || `${type}s`}
        </h4>
      </div>
      {isMyProfileOrActivity && !doNotShowSubtitle && (
        <p>
          {subTitle
            ? subTitle
            : isActivity
            ? `All your saved ${type}s will be visible here`
            : `Display your ${type}s`}
        </p>
      )}
    </div>
  );
}

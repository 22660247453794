import { SIDEBAR_ICONS } from "../../constants/icons";
import ContentRequests from "../../screens/UserContent/Requests";

export default {
  label: "My Requests",
  path: "my-requests",
  menuPath: "/my-activity/my-requests/",
  component: <ContentRequests />,
  icon: SIDEBAR_ICONS.MY_REQUESTS_SIDEBAR_ICON
};

import SideBar from "components/SideBar";
import React from "react";
import { isMobile } from "react-device-detect";

function SidebarContainer({ children }) {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <SideBar />
      {children}
    </div>
  );
}

const withSidebar = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <SidebarContainer>
      <Component {...props} />
    </SidebarContainer>
  );
};

export default withSidebar;

const withMobileOnlySidebar = (Component) => {
  if (isMobile) {
    // eslint-disable-next-line react/display-name
    return (props) => (
      <SidebarContainer>
        <Component {...props} />
      </SidebarContainer>
    );
  } else {
    // eslint-disable-next-line react/display-name
    return (props) => <Component {...props} />;
  }
};

export { withMobileOnlySidebar };

import React from "react";
import { Form } from "react-bootstrap";
import ReactHookInput from "../ReactHookInput";
import VALIDATORS from "../../../validators";

export default function PanInput({ required = false, name = "pan" }) {
  return (
    <Form.Group>
      <Form.Label>
        PAN <span className="fst-italic">(Permanent Account Number)</span>
        {required === true ? " *" : ""}
      </Form.Label>
      <ReactHookInput
        name={name}
        type="text"
        placeholder="ABCD1234A"
        validation={VALIDATORS.pan(required)}
      />
    </Form.Group>
  );
}

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import { isUndefined } from "lodash";
import { useAuth } from "hooks";
import Phone from "components/Inputs/Phone";
import Email from "components/Inputs/Email";
import Password from "components/Inputs/Password";
import FormError from "components/FormError";
import HorizontalLineWithText from "components/HorizontalLineWithText";
import OtpVerification from "components/Modals/OtpVerification";
import ForgotPasswordModal from "components/Modals/ForgotPassword";

function LoginForm() {
  const { handleSubmit } = useFormContext();
  const { loginWithEmail, loginWithPhone, sendOTP } = useAuth();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [error, setError] = useState("");
  const [showVerifyOTP, setShowVerifyOTP] = useState(undefined);
  const [usePhone, setUsePhone] = useState(false);

  const submit = (data) => {
    const isVerificationOpen = !isUndefined(showVerifyOTP);

    setError("");
    if (isVerificationOpen) {
      loginWithPhone(data);
    } else if (usePhone === true) {
      sendOTP(data.phone, setShowVerifyOTP);
    } else {
      loginWithEmail(data);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        <Stack gap={3}>
          {usePhone === true ? (
            <Form.Group>
              <Form.Label>Phone Number*</Form.Label>
              <Phone required />
            </Form.Group>
          ) : (
            <Form.Group>
              <Form.Label>Email Address*</Form.Label>
              <Email required />
            </Form.Group>
          )}
          {usePhone === false && (
            <Form.Group>
              <Form.Label>Password *</Form.Label>
              <Password label="Password*" required />
            </Form.Group>
          )}
          {usePhone === false && (
            <Link
              href="#forgot-password"
              onClick={() => setShowForgotPassword(true)}
              style={{ textAlign: "right" }}
            >
              Forgot Password
            </Link>
          )}
          <FormError>{error}</FormError>
          <Button variant="primary" type="submit" className="text-uppercase">
            Sign in
          </Button>
          <HorizontalLineWithText text={"or"} />
          <Button variant="outline-primary" onClick={() => setUsePhone(!usePhone)}>
            Sign In Using {usePhone === false ? "Phone Number" : "Email"}
          </Button>
          <Row className="align-items-center align-self-center">
            <Col className="helper-text">
              <span>Don&apos;t have an account?</span>
              <Link to="/signup" className="text-decoration-underline ms-2 fw-bold">
                Sign up
              </Link>
            </Col>
          </Row>
        </Stack>
        <OtpVerification show={showVerifyOTP} setShow={setShowVerifyOTP} />
      </Form>
      <ForgotPasswordModal show={showForgotPassword} onHide={() => setShowForgotPassword(false)} />
    </>
  );
}

export default LoginForm;

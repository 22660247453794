import ContentFactory from "apiClients/ContentFactory";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ACTION_TYPES from "constants/action-types";
import React from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";

function AddMoodBoardForm({ ideationBoardId, getIdeationBoards, onSuccess, moodboard, onCancel }) {
  const { handleSubmit } = useFormContext();
  const client = ContentFactory.createRequest("ideation-board");
  const createMoodBoard = async (data) => await client.createMoodBoard(ideationBoardId, data);

  const updateMoodBoard = async (data) =>
    await client.updateMoodBoard(ideationBoardId, moodboard.id, data);

  const onSubmit = async (data) => {
    if (moodboard) {
      await updateMoodBoard(data);
      onSuccess(false);
    } else {
      await createMoodBoard(data);
      onSuccess();
    }
    getIdeationBoards();
    onSuccess();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Mood Board Name*</Form.Label>
          <ReactHookInput name="name" placeholder="Folder Name" required />
        </Form.Group>
        <Stack direction="horizontal" className="mt-3">
          <Button variant="outline-primary" className="w-50 mx-3" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" className="w-50 mx-3">
            Save
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getIdeationBoards: () =>
    dispatch({ type: ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_REQUEST })
});

export default connect(null, mapDispatchToProps)(AddMoodBoardForm);

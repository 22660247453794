import React from "react";
import BaseMenu from "../../BaseMenu";
import { useNavigationInfo } from "../../../../hooks";
import BRAND_PROFILE_ROUTES from "../../../../routes/MyProfile/Brand";
import BRAND_ACTIVITY_ROUTES from "../../../../routes/MyActivity/Brand";

export default function BrandMenu() {
  const { isActivity } = useNavigationInfo();

  return <BaseMenu options={isActivity ? BRAND_ACTIVITY_ROUTES : BRAND_PROFILE_ROUTES} />;
}

import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";
import FormError from "../../FormError";
import VALIDATORS from "../../../validators";
import DEFAULT_STYLE from "../../../styles";
import { isNumber } from "lodash";
import { formatPrice, formatToIndianNumberMask } from "../../../utils";
import Select from "../Select";

export default function Area({ name, required }) {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { ...(required ? VALIDATORS.required : {}) }
  });
  const value = watch(name);
  const isErrored =
    errors &&
    ((errors[name] && errors[name].message) ||
      (errors[`${name}_unit`] && errors[`${name}_unit`].message));

  const handleInputChange = (e) => field.onChange(formatToIndianNumberMask(e.target.value));

  return (
    <>
      <InputGroup>
        <Form.Control
          {...field}
          value={isNumber(field.value) ? formatPrice(field.value) : ""}
          onChange={handleInputChange}
          type="text"
          placeholder="2000"
          style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
        />
        <Select
          name={`${name}_unit`}
          rules={required || value ? VALIDATORS.required : {}}
          showError={false}
          isCreateable={false}
          isSearchable={false}
          options={[
            { label: "sq.yd", value: "sq.yd" },
            { label: "sq.ft", value: "sq.ft" },
            { label: "sq.mt", value: "sq.mt" },
            { label: "acre", value: "acre" }
          ]}
          placeholder="Unit"
          required={false}
        />
      </InputGroup>
      {isErrored && (
        <FormError>
          {errors[name] ? errors[name].message : errors[`${name}_unit`].message}
        </FormError>
      )}
    </>
  );
}

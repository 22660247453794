import React, { createContext, useContext, useState } from "react";

const AdvertiseWithUsModalContext = createContext();

export function useAdvertiseWithUsModal() {
  return useContext(AdvertiseWithUsModalContext);
}

export function AdvertiseWithUsModalProvider({ children }) {
  const [show, setShow] = useState(undefined);

  const showAdvertiseWithUsModal = (obj) => {
    setShow(obj);
  };

  const hideAdvertiseWithUsModal = () => {
    setShow(undefined);
  };

  const contextValue = {
    show,
    showAdvertiseWithUsModal,
    hideAdvertiseWithUsModal
  };

  return (
    <AdvertiseWithUsModalContext.Provider value={contextValue}>
      {children}
    </AdvertiseWithUsModalContext.Provider>
  );
}

import BaseContentCard, { BaseContentCardDetailsSection, RenderSelect } from "components/Base/Card";
import LeadStatus from "components/LeadsStatus";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactShowMoreText from "react-show-more-text";

export default function MobileRequestCard({ obj, isSelected, handleSelection, onUpdateStatus }) {
  return (
    <BaseContentCard>
      <div className="d-flex justify-content-end mt-3 me-2">
        <RenderSelect
          showSelect
          style={{ position: null }}
          isSelected={isSelected}
          handleSelection={handleSelection}
        />
      </div>
      <BaseContentCardDetailsSection>
        {[
          { title: "Name", value: obj.recipient_name },
          { title: "Email", value: obj.email },
          { title: "Contact Number", value: obj.mobile },
          { title: "Connecting For", value: obj.reason },
          { title: "Required By", value: obj.required_by },
          {
            title: "Message",
            value: (
              <ReactShowMoreText
                lines={2}
                more="View"
                less="Show less"
                expanded={false}
                width={375}
                truncatedEndingComponent={"... "}
              >
                {obj.message}
              </ReactShowMoreText>
            )
          }
        ].map((el) => (
          <Row key={el.title} xs={2} className="my-2">
            <Col className="fw-bold">{el.title}</Col>
            <Col>{el.value}</Col>
          </Row>
        ))}
        <Row className="my-2">
          <Col className="fw-bold">Status</Col>
          <Col className="w-100">
            <LeadStatus
              info={obj}
              type="request"
              onUpdate={onUpdateStatus}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

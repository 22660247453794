import BaseContentCard, { BaseContentCardDetailsSection, RenderSelect } from "components/Base/Card";
import LeadStatus from "components/LeadsStatus";
import { useSearchParams } from "hooks/searchParams";
import React from "react";
import { Row, Col } from "react-bootstrap";
import ReactShowMoreText from "react-show-more-text";

export default function MobileLeadCard({ obj, isSelected, handleSelection, onUpdateStatus }) {
  const { getKey } = useSearchParams();
  const reason = getKey("reason");

  const leadDetails = [
    { title: "Name", value: obj.name },
    { title: "Email", value: obj.email },
    { title: "Contact Number", value: obj.mobile },
    { title: "Connecting For", value: obj.reason },
    ...(reason === "Quotation"
      ? [
          { title: "Required Quantity", value: obj.required_quantity },
          { title: "Required By", value: obj.required_by }
        ]
      : reason === "Sample"
      ? [{ title: "Required By", value: obj.required_by }]
      : [
          { title: "Appointment Date", value: obj.appointment_date },
          { title: "Appointment Type", value: obj.appointment_type }
        ]),
    {
      title: "Message",
      value: (
        <ReactShowMoreText
          lines={2}
          more="View"
          less="Show less"
          expanded={false}
          width={375}
          truncatedEndingComponent={"... "}
        >
          {obj.message}
        </ReactShowMoreText>
      )
    }
  ];

  return (
    <BaseContentCard>
      <div className="d-flex justify-content-end mt-3 me-2">
        <RenderSelect
          showSelect
          style={{ position: null }}
          isSelected={isSelected}
          handleSelection={handleSelection}
        />
      </div>
      <BaseContentCardDetailsSection>
        {leadDetails.map((el) => (
          <Row key={el.title} xs={2} className="my-2">
            <Col className="fw-bold">{el.title}</Col>
            <Col>{el.value}</Col>
          </Row>
        ))}
        <Row className="my-2">
          <Col className="fw-bold">Status</Col>
          <Col className="w-100">
            <LeadStatus
              info={obj}
              type="lead"
              onUpdate={onUpdateStatus}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

import React from "react";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";

export default function CSVHelp({ show, onHide }) {
  const STEPS = ["Asdadasd", "werwrwer", "asdasd"];
  return (
    <BaseModal show={show} onHide={onHide}>
      <Modal.Title>
        <Modal.Header closeButton>How does CSV work?</Modal.Header>
      </Modal.Title>
      <Modal.Body>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry&apos;s standard dummy text ever since the 1500s.
        </p>
        {STEPS.map((el, index) => (
          <p key={index}>
            Step-{index + 1}. {el}
          </p>
        ))}
      </Modal.Body>
    </BaseModal>
  );
}

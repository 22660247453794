import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentList from "components/Base/ContentList";
import Blog from "components/Cards/Blog";

function PostedBlogs({ title, subTitle }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.authReducer.user);

  return (
    <ContentList
      Card={Blog}
      type="blog"
      title={title || "Posted Blogs"}
      subTitle={subTitle || "All your posted blogs will be visible here"}
      handleAdd={() => navigate(`/my-activity/my-blogs/posted/create-new-blog`)}
      initialFilters={{
        is_posted: true,
        author: user.id
      }}
      cardProps={{ showSelect: true }}
    />
  );
}

export default PostedBlogs;

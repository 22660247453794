import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { isArray, isEmpty, isObject } from "lodash";
import React from "react";
import { getContentAbsoluteUrl } from "utils";
import DEFAULT_IMAGE from "../../assets/sidebar-profile-bg.png";
import COLORS from "constants/colors";
import moment from "moment";
// import { getUserProfileImage } from "components/ProfileImage";
// import Avatar from "react-avatar";

Font.register({
  family: "Helvetica, Arial, sans-serif"
});

export default function ResumePDF({ resume, user }) {
  const { employment, education, courses, awards } = resume;
  const {
    name,
    is_student,
    latest_education,
    latest_experience,
    is_working_professional,
    banner_images
  } = user;
  var image = (banner_images || []).filter((el) => el.is_selected === true);

  var instituition, employment_type;
  if (is_student) {
    if (is_working_professional && latest_experience) {
      instituition = `${latest_experience.employment_type}, ${latest_experience.company}`;
    } else if (latest_education && latest_education.degree && latest_education.college) {
      instituition = `${latest_education.degree}, ${latest_education.college}`;
    } else if (latest_experience) {
      instituition = `${latest_experience.employment_type}, ${latest_experience.company}`;
    }

    if (latest_experience) {
      employment_type = latest_experience.employment_type;
    }
  }
  //   const profile_image = getUserProfileImage(user);

  const styles = StyleSheet.create({
    container: { backgroundColor: COLORS.GREY1 },
    detailsContainer: {
      backgroundColor: "white",
      padding: 15
    },
    orangeText: { color: COLORS.PRIMARY_ORANGE },
    userProfileHeader: {
      fontSize: 14,
      paddingTop: 10,
      paddingBottom: 10,
      color: COLORS.PRIMARY_ORANGE
    },
    userName: { fontSize: 16 },
    bannerImage: { objectFit: "contain", width: "100%" },
    profileImage: { objectFit: "contain" },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    locationContainer: { display: "flex" },
    locationIcon: { width: 10, height: 10 },
    sectionContainer: {
      backgroundColor: "white",
      marginBottom: 12
    },
    sectionTitle: {
      fontSize: 14,
      fontWeight: "extrabold",
      marginBottom: 8
    },
    skillContainer: {
      marginBottom: 8
    },
    skillTitle: {
      fontSize: 12,
      fontWeight: "bold",
      marginBottom: 0
    },
    skillList: {
      fontSize: 10,
      marginTop: 2,
      marginBottom: 0
    },
    experienceContainer: {
      marginBottom: 12
    },
    experienceTitle: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 4
    },
    employmentType: {
      fontSize: 12,
      marginBottom: 4
    },
    employmentTypeHeader: { marginTop: 5, fontSize: 12, marginBottom: 5 },
    dateRange: {
      fontSize: 12,
      marginBottom: 4
    },
    description: {
      fontSize: 10,
      color: "gray"
    },
    educationContainer: {
      marginBottom: 12
    },
    college: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 4
    },
    degree: {
      fontSize: 12,
      marginBottom: 4
    },
    specialization: {
      fontSize: 12,
      marginBottom: 4
    },
    score: {
      fontSize: 12,
      marginBottom: 4
    },

    title: {
      fontSize: 12,
      fontWeight: "bold",
      marginBottom: 4
    }
  });

  function DetailsContainer({ children }) {
    return <View style={styles.detailsContainer}>{children}</View>;
  }

  const SectionContainer = ({ title, children }) => (
    <View style={styles.sectionContainer}>
      {title && <Text style={styles.sectionTitle}>{title}</Text>}
      {children}
    </View>
  );

  const Skill = ({ title, list }) => {
    if (isObject(list[0])) {
      list = list.map((el) => el.value);
    }

    return (
      <View style={styles.skillContainer}>
        <Text style={styles.skillTitle}>{title}</Text>
        <Text style={styles.skillList}>{list.join(", ")}</Text>
      </View>
    );
  };

  const DateRangeFormatter = ({ startDate, endDate, isPresent }) => {
    const startMoment = moment(startDate);
    const endMoment = isPresent ? null : moment(endDate);

    return (
      <Text style={styles.dateRange}>
        {startMoment.format("MM/YYYY")} - {isPresent ? "Present" : endMoment.format("MM/YYYY")}
      </Text>
    );
  };

  const SavedExperience = ({ experience }) => (
    <View style={styles.experienceContainer}>
      <Text style={styles.experienceTitle}>{experience.title}</Text>
      <Text style={styles.employmentType}>{experience.employment_type}</Text>
      <DateRangeFormatter
        startDate={experience.start_date}
        endDate={experience.end_date}
        isPresent={experience.currently_working_here}
      />
      <Text style={styles.description}>{experience.description}</Text>
    </View>
  );

  const SavedEducation = ({ education }) => (
    <View style={styles.educationContainer}>
      <Text style={styles.college}>{education.college}</Text>
      <Text style={styles.degree}>{education.degree}</Text>
      <DateRangeFormatter
        startDate={education.start_date}
        endDate={education.end_date}
        isPresent={education.is_pursuing}
      />
      {education.specialization && (
        <Text style={styles.specialization}>{education.specialization}</Text>
      )}
      {education.score && <Text style={styles.score}>{education.score}</Text>}
      {education.description && <Text style={styles.description}>{education.description}</Text>}
    </View>
  );

  const SavedCertificationCourses = ({ certificationCourse }) => (
    <View style={styles.certificationContainer}>
      <Text style={styles.trainingProgram}>{certificationCourse.training_program}</Text>
      <Text style={styles.organization}>
        {certificationCourse.organisation} ({certificationCourse.is_online ? "Online" : "Offline"})
      </Text>
      <DateRangeFormatter
        startDate={certificationCourse.start_date}
        endDate={certificationCourse.end_date}
        dateFormat="MM/YYYY"
      />
      {certificationCourse.associateAlliance && (
        <Text style={styles.associateAlliance}>{certificationCourse.associate_alliance}</Text>
      )}
      {certificationCourse.description && (
        <Text style={styles.description}>{certificationCourse.description}</Text>
      )}
    </View>
  );

  const AwardsAchievement = ({ awardsAchievement }) => (
    <View>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={styles.title}>{awardsAchievement.title}</Text>
      </View>
      {awardsAchievement.description && (
        <Text style={styles.description}>{awardsAchievement.description}</Text>
      )}
    </View>
  );

  const { hobbies, technical_skills, management_skills, personal_skills, languages } = resume;

  return (
    <Document>
      <Page size="A4">
        <DetailsContainer>
          <Image
            src={
              isArray(image) && !isEmpty(image)
                ? getContentAbsoluteUrl(image[0].image)
                : DEFAULT_IMAGE
            }
            style={styles.bannerImage}
          />
          {/* {profile_image ? (
            <Avatar style={styles.objectFit} src={profile_image} round />
          ) : (
            <Avatar name={name || "ML User"} round />
          )} */}
          <Text className="fw-bold" style={styles.userProfileHeader}>
            {is_working_professional ? "Professional" : "Student"}
          </Text>
          <Text style={styles.userName}>{name}</Text>
          {is_student && is_working_professional && employment_type && (
            <Text style={styles.employmentTypeHeader}>{employment_type}</Text>
          )}
          {is_student && instituition && <Text Text>{instituition}</Text>}
        </DetailsContainer>
        <DetailsContainer>
          {isArray(employment) && !isEmpty(employment) && (
            <SectionContainer title="Experience">
              {(employment || []).map((el) => (
                <SavedExperience experience={el} key={el.id} />
              ))}
            </SectionContainer>
          )}
          {isArray(education) && !isEmpty(education) && (
            <SectionContainer title="Education">
              {(education || []).map((el) => (
                <SavedEducation education={el} key={el.id} />
              ))}
            </SectionContainer>
          )}
          {isArray(courses) && !isEmpty(courses) && (
            <SectionContainer title="Courses & Certification">
              {(courses || []).map((el) => (
                <SavedCertificationCourses certificationCourse={el} key={el.id} />
              ))}
            </SectionContainer>
          )}
          {isArray(awards) && !isEmpty(awards) && (
            <SectionContainer title="Awards & Achievemets">
              {(awards || []).map((el) => (
                <AwardsAchievement awardsAchievement={el} key={el.id} />
              ))}
            </SectionContainer>
          )}
          <SectionContainer title="Skills, Languages & Hobbies">
            <View>
              {isArray(technical_skills) && !isEmpty(technical_skills) && (
                <Skill title="Technical Skills" list={technical_skills} />
              )}
              {isArray(management_skills) && !isEmpty(management_skills) && (
                <Skill title="Management Skills" list={management_skills} />
              )}
              {isArray(personal_skills) && !isEmpty(personal_skills) && (
                <Skill title="Personal Skills" list={personal_skills} />
              )}
              {isArray(languages) && !isEmpty(languages) && (
                <Skill title="Languages" list={languages} />
              )}
              {isArray(hobbies) && !isEmpty(hobbies) && <Skill title="Hobbies" list={hobbies} />}
            </View>
          </SectionContainer>
        </DetailsContainer>
      </Page>
    </Document>
  );
}

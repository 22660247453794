import React from "react";
import Card from "react-bootstrap/esm/Card";
import EngagementStats from "../../EngagementStats";
import COLORS from "../../../constants/colors";
import { useNavigationInfo } from "../../../hooks/route";
import { DATE_TEXT_STYLE, SUBTITLE_TEXT_STYLE } from "../default-styles";
import BaseContentCard, {
  BaseContentCardDetailsSection,
  CardImage,
  CardTitle,
  RenderBookmarkIcon,
  RenderSelect
} from "../../Base/Card";
import DateParser from "../../../date";
import { isFunction } from "lodash";

export default function Thesis({
  obj,
  onBookmarked,
  isSelected,
  handleSelection,
  onClick,
  cardStyle = {},
  showSelect = true
}) {
  const { isMyProfileOrActivity } = useNavigationInfo();
  return (
    <BaseContentCard onClick={isFunction(onClick) && onClick} style={{ ...cardStyle }}>
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      <RenderBookmarkIcon obj={obj} onBookmarked={onBookmarked} type="thesis" />
      <CardImage thumbnail={obj.thumbnail} alt={obj.title} />

      <BaseContentCardDetailsSection>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Text className="text-muted" style={DATE_TEXT_STYLE}>
            {DateParser.contentCardDateTimeString(obj.created)}
          </Card.Text>
          <Card.Text className="mb-0" style={{ color: COLORS.PRIMARY_ORANGE, ...DATE_TEXT_STYLE }}>
            {obj.source_type}
          </Card.Text>
        </div>
        <CardTitle>{obj.title}</CardTitle>
        <Card.Subtitle className="text-muted" style={SUBTITLE_TEXT_STYLE}>
          {isMyProfileOrActivity ? obj.category : obj.user}
        </Card.Subtitle>
        <EngagementStats likes={obj.likes} saves={obj.saves} views={obj.views} />
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

import React from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { isArray, isEmpty, isFunction } from "lodash";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default function Dropdown({
  options,
  value,
  label,
  onClick,
  disabled,
  textCapitalizeMenuItem = true,
  style = {},
  overflow = "auto",
  position = "initial",
  align = "start"
}) {
  var selectedLabel;
  if (value && isArray(options)) {
    selectedLabel = options.filter((el) => el.value === value);
  }
  if (isArray(selectedLabel) && !isEmpty(selectedLabel)) {
    selectedLabel = selectedLabel[0].label;
  }
  return (
    <Menu
      portal
      align={align}
      overflow={overflow}
      position={position}
      menuStyle={{ height: "max-content" }}
      menuButton={
        <BootstrapDropdown.Toggle
          variant="success"
          style={{
            backgroundColor: "transparent",
            fontSize: isMobile ? "80%" : "90%",
            color: "black",
            ...style
          }}
          className="px-4 border-dark"
          disabled={disabled}
        >
          {selectedLabel || label}
        </BootstrapDropdown.Toggle>
      }
    >
      {options.map((item) => (
        <MenuItem
          key={`${item.label}-index`}
          active={item.value === value}
          onClick={(e) => (isFunction(item.onClick) ? item.onClick(e) : onClick(item.value))}
          className={textCapitalizeMenuItem ? "text-capitalize" : ""}
          {...item.props}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

import React from "react";
import { Form, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useAuth } from "hooks";
import { useScrollToTop } from "hooks";
import JobPreferencesAPI from "apiClients/Profile/Student/JobPreferences";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import { AREAS_OF_INTERESTS } from "constants/Profile/Student";
import { LOCATION_PREFERENCES } from "constants/Profile/Student";
import { CURRENTLY_LOOKING_FOR } from "constants/Profile/Student";
import { WORKING_MODES } from "constants/Profile/Student";
import Select from "components/Inputs/Select";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";

export default function JobPreferencesForm() {
  const { getValues } = useFormContext();
  const { getUserInfo } = useAuth();
  const { scrollToTop } = useScrollToTop();

  const onSubmit = (data) => {
    const payload = {};
    ["areas_of_interests", "preferred_locations", "currently_looking_for", "work_mode"].forEach(
      (el) => (payload[el] = data[el].map((el) => el.value))
    );
    const promise = new JobPreferencesAPI().update(payload).then(scrollToTop).then(getUserInfo);
    showPromiseToast(promise, {
      success: "Job preferences saved successfully",
      pending: "Saving job preferences",
      error: renderErrorToast()
    });
  };

  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Stack gap={4} className="mt-3">
        {[
          {
            name: "areas_of_interests",
            label: "Area(s) of Interest",
            maxLength: 10,
            options: AREAS_OF_INTERESTS,
            createable: true,
            inputLabel: "Add Area(s) of Interest",
            minLength: 1
          },
          {
            name: "preferred_locations",
            label: "Prefered Locations",
            maxLength: 5,
            options: LOCATION_PREFERENCES,
            inputLabel: "Location",
            minLength: 1
          },
          {
            name: "currently_looking_for",
            label: "Currently Looking For",
            options: CURRENTLY_LOOKING_FOR,
            inputLabel: "Looking For",
            minLength: 0
          },
          {
            name: "work_mode",
            label: "Work Mode",
            options: WORKING_MODES,
            inputLabel: "Work Mode",
            minLength: 0
          }
        ].map((el) => (
          <Form.Group key={el.label}>
            <Form.Label>
              {el.label}
              {el.maxLength && (
                <span className="text-muted fst-italic ms-2">(Max {el.maxLength})</span>
              )}
            </Form.Label>
            <div
              style={{ border: "2px grey dashed", width: "max-content" }}
              className={`w-100 p-3`}
            >
              <Form.Text className="text-muted">{el.inputLabel}</Form.Text>
              <Select
                className="w-100 mt-2"
                name={el.name}
                isMulti
                isOptionDisabled={() => {
                  const value = getValues(el.name);
                  return el.maxLength && value && el.maxLength === value.length;
                }}
                isCreateable={el.createable}
                options={el.options}
                minLength={el.minLength}
              />
            </div>
          </Form.Group>
        ))}
      </Stack>
    </DetailsFormContainer>
  );
}

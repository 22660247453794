import React, { useState } from "react";
import SHARE_ICON from "../../../assets/icons/share.svg";
import { Image } from "react-bootstrap";
import ShareModal from "../../Modals/Share";

export default function ShareButton({ url }) {
  const [showShare, setShowShare] = useState(false);

  return (
    <>
      <Image
        className="cursor-pointer w-100 h-100"
        src={SHARE_ICON}
        onClick={() => setShowShare(true)}
      />
      <ShareModal show={showShare} url={url} onHide={() => setShowShare(false)} />
    </>
  );
}

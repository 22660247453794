import MANAGE_BLOGS_ROUTES from "./ManageBlogs";
import FAVOURITES_ROUTES from "./Favourites";
import MANAGE_JOBS_ROUTES from "./ManageJobs";
import MANAGE_LEADS from "./ManageLeads";
import MANAGE_REQUESTS from "./ManageRequests";
import IDEATION_BOARD_ROUTES from "./IdeationBoard";

const STUDIO_ACTIVITY_ROUTES = [
  FAVOURITES_ROUTES,
  IDEATION_BOARD_ROUTES,
  MANAGE_BLOGS_ROUTES,
  MANAGE_JOBS_ROUTES,
  MANAGE_LEADS,
  MANAGE_REQUESTS
];

export default STUDIO_ACTIVITY_ROUTES;

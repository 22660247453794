/* eslint-disable no-unused-vars */
import { isFunction } from "lodash";
import React, { useState } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import COLORS from "../../../constants/colors";
import FavouriteFactory from "../../../apiClients/FavouriteFactory";
import WhiteIconContainer from "../WhiteContainer";
import { useSelector } from "react-redux";
import { useBookmarkModal } from "../../../hooks/bookmarkModal";
import { Spinner } from "react-bootstrap";

const BookmarkIcon = ({ obj, type, onBookmarked }) => {
  const [processing, setProcessing] = useState(false);
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const client = FavouriteFactory.createRequest(type);
  const addBookmark = () => {
    setProcessing(true);
    return client
      .add(obj.id)
      .then(() => isFunction(onBookmarked) && onBookmarked())
      .then(() => setProcessing(false));
  };

  const removeBookmark = () => {
    setProcessing(true);
    client
      .remove(obj.is_favourited)
      .then(() => isFunction(onBookmarked) && onBookmarked(true))
      .then(() => setProcessing(false));
  };

  const { showBookmarkNotLoggedInModal } = useBookmarkModal();
  const style = {
    width: "1rem",
    height: "1rem"
  };

  return (
    <WhiteIconContainer
      onClick={(e) => {
        e.stopPropagation();
        if (isLoggedIn) {
          if (!obj.is_favourited) {
            addBookmark();
          } else {
            removeBookmark();
          }
        } else {
          showBookmarkNotLoggedInModal();
        }
      }}
    >
      {processing ? (
        <Spinner color={COLORS.PRIMARY_ORANGE} size="sm" animation="grow" />
      ) : obj.is_favourited ? (
        <BsBookmarkFill style={style} color={COLORS.PRIMARY_ORANGE} />
      ) : (
        <BsBookmark style={style} />
      )}
    </WhiteIconContainer>
  );
};

export default BookmarkIcon;

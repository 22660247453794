import React, { useState, useEffect } from "react";
import BaseContainer from "components/Containers/BaseContainer";
import { Button, Col, Form, Image, Row, Stack } from "react-bootstrap";
import CAREER_1 from "assets/career-1.png";
import CAREER_2 from "assets/career-2.png";
import Search from "components/Inputs/Search";
import { FormProvider, useForm } from "react-hook-form";
import ApplyCareerForm from "forms/ApplyCareerForm";
import COLORS from "constants/colors";
import ContentFactory from "apiClients/ContentFactory";
import LoadingContainer from "components/Containers/Loading";
import DateParser from "date";
import { useSearchParams } from "hooks/searchParams";
import { Element, scroller } from "react-scroll";

function RenderCareers({ careers, setValue }) {
  return careers.map((el, index) => (
    <Row key={`career-${index}`} className="py-4">
      <Col className="d-flex align-items-center">
        <div>
          <h3>{el.title}</h3>
          <p>{el.description}</p>
        </div>
      </Col>
      <Col className="d-flex justify-content-center align-items-center fw-bold">
        {el.experience_level}
      </Col>
      <Col className="d-flex justify-content-center align-items-center fw-bold">
        {el.required_by
          ? `Required By ${DateParser.moment(el.required_by, "YYYY-MM-DD").format()}`
          : "(Immediate Joiner)"}
      </Col>
      <Col className="d-flex justify-content-end align-items-center">
        <p
          className="fw-bold cursor-pointer"
          onClick={() => {
            setValue("career", { value: el.id, label: el.title });
            scroller.scrollTo("form");
          }}
        >
          Apply
        </p>
      </Col>
    </Row>
  ));
}

export default function Careers() {
  const [search, setSearch] = useState("");
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = ContentFactory.createRequest("career");
  const { addKey, queryString, searchParamsAsObject } = useSearchParams();

  useEffect(() => {
    addKey("search", search);
  }, [search]);

  const getCareers = () => client.list({ ...searchParamsAsObject }).then(setCareers);

  useEffect(() => {
    getCareers().then(() => setLoading(false));
  }, [queryString]);

  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue } = methods;
  return (
    <LoadingContainer loading={loading}>
      <BaseContainer style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
        <Stack gap={5}>
          <Stack>
            <h1>Careers</h1>
            <Form.Text className="fw-bold w-50">
              Join us on a transformative journey, where indiviuality merges into a collaborative
              force shaping success together.
            </Form.Text>
          </Stack>
          <Row>
            <Col>
              <Image src={CAREER_1} className="w-100" />
            </Col>
            <Col>
              <Image src={CAREER_2} className="w-100" />
            </Col>
          </Row>
          <div className="mt-5">
            <h2 className="text-center">Just two simple steps</h2>
            <div>
              <Row className="w-75 mx-auto">
                <Col>
                  <Stack gap={2}>
                    <h2 className="fw-bold text-center">1.</h2>
                    <h2 className="text-center">Search</h2>
                    <p className="text-center">
                      Search, apply, track and post a job all here on one platform. You dont need to
                      worry anymore.
                    </p>
                  </Stack>
                </Col>
                <Col>
                  <Stack gap={2}>
                    <h2 className="fw-bold text-center">2.</h2>
                    <h2 className="text-center">Apply</h2>
                    <p className="text-center">
                      Search, apply, track and post a job all here on one platform. You dont need to
                      worry anymore.
                    </p>
                  </Stack>
                </Col>
              </Row>
            </div>
          </div>
          <Button className="d-block mx-auto px-5" onClick={() => scroller.scrollTo("form")}>
            APPLY NOW
          </Button>
          <div className="w-75 d-block mx-auto mt-5">
            <h1 className="text-center">Open Positions</h1>
            <div className="my-4 w-50">
              <Search
                placeholder="Search Positions"
                searchString={search}
                setSearchString={setSearch}
              />
            </div>
            <RenderCareers careers={careers} setValue={setValue} />
          </div>
          <Element name="form" style={{ background: COLORS.GREY1 }}>
            <h2 className="text-center py-5">Apply Now</h2>
            <div name="form" className="w-75 d-block mx-auto pb-5">
              <FormProvider {...methods}>
                <ApplyCareerForm
                  positions={careers.map((el) => ({ label: el.title, value: el.id }))}
                />
              </FormProvider>
            </div>
          </Element>
        </Stack>
      </BaseContainer>
    </LoadingContainer>
  );
}

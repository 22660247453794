const ACTION_TYPES = {
  AUTH: { LOGIN: "LOGIN", LOGOUT: "LOGOUT", UPDATE_USER: "UPDATE_USER " },
  CONTENT: {
    IDEATION_BOARD: {
      GET_IDEATION_BOARD_REQUEST: "GET_IDEATION_BOARD_REQUEST",
      GET_IDEATION_BOARD_SUCCESS: "GET_IDEATION_BOARD_SUCCESS",
      GET_IDEATION_BOARD_FAILURE: "GET_IDEATION_BOARD_FAILURE"
    },
    BLOGS: {
      GET_PINNED_BLOGS_REQUEST: "GET_PINNED_BLOGS_REQUEST",
      GET_PINNED_BLOGS_SUCCESS: "GET_PINNED_BLOGS_SUCCESS",
      GET_PINNED_BLOGS_FAILURE: "GET_PINNED_BLOGS_FAILURE"
    },
    PRODUCTS: {
      FILTERS: {
        GET_FILTERS_REQUEST: "GET_FILTERS_REQUEST",
        GET_FILTERS_SUCCESS: "GET_FILTERS_SUCCESS",
        GET_FILTERS_FAILURE: "GET_FILTERS_FAILURE"
      },
      CATEGORIES: {
        GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
        GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
        GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE"
      }
    }
  }
};

export default ACTION_TYPES;

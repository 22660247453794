import React from "react";

export default function WhiteIconContainer({ children, width = "2rem", height = "2rem", onClick }) {
  return (
    <div
      className="bg-white rounded-circle d-flex justify-content-center align-items-center cursor-pointer"
      style={{
        width,
        height
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

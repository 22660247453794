const NATURE_OF_ORGANISATION = ["Manufacturer", "Importer", "Exporter"].map((el) => ({
  value: el,
  label: el
}));

const GSTIN_STATUS = [
  "I have a GSTIN",
  "I don't have a GSTIN",
  "I only deal in GST exempt categories"
].map((el) => ({ label: el, value: el }));

const REGISTRATION_UNDER_MSME = ["Yes", "No"].map((el) => ({ value: el, label: el }));

const KYC_CONSTANTS = { NATURE_OF_ORGANISATION, GSTIN_STATUS, REGISTRATION_UNDER_MSME };

export default KYC_CONSTANTS;

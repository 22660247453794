import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useController, useFormContext } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import FormError from "../../FormError";
import DEFAULT_STYLE from "../../../styles";
import { isEmpty } from "lodash";

const Password = ({ name = "password", required, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: {
      validate: (value) => {
        if (required && isEmpty(value)) {
          return "This field is mandatory";
        }
        const strongPasswordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return (
          strongPasswordRegex.test(value) ||
          "Password should have a minimum of 8 characters, including 1 capital letter, 1 small letter, 1 special character & 1 numeric value."
        );
      }
    }
  });

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const isErrored = errors && errors[name];

  return (
    <>
      <InputGroup>
        <Form.Control
          {...field}
          {...props}
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
        />
        <InputGroup.Text onClick={handlePasswordToggle}>
          {showPassword ? <BsEyeSlash /> : <BsEye />}
        </InputGroup.Text>
      </InputGroup>
      {errors && errors[name] && <FormError>{errors[name].message}</FormError>}
    </>
  );
};

export default Password;

import { forOwn, isArray, isEmpty, isFunction, isObject, isString } from "lodash";
import { toast } from "react-toastify";
import { CONTENT_BASE_HOST, CONTENT_BASE_URL } from "./config/api";
import MINIMUM_PROFILE_COMPLETION from "constants/profile-completion";

export const validateFileSize = (file, maxSizeMB) => file.size <= maxSizeMB * 1024 * 1024;

export const showPromiseToast = (promise, options) => toast.promise(promise, options);

function convertSerializerErrorsToString(errors) {
  try {
    if (!errors || typeof errors !== "object") {
      throw new Error("Invalid error object");
    }

    let errorString = "";

    forOwn(errors, (fieldErrors, field) => {
      errorString += `${capitalizeFirstLetter(field)} `;

      if (Array.isArray(fieldErrors)) {
        fieldErrors.forEach((error) => {
          errorString += `- ${error} `;
        });
      } else {
        errorString += `- ${fieldErrors} `;
      }
    });

    return errorString;
  } catch (error) {
    console.error("Error converting serializer errors:", error);
    return "Internal Server Error";
  }
}

export const getResponeErrorMessage = (error) => {
  var message =
    error &&
    error.response &&
    error.response.data &&
    (error.response.data.detail || error.response.data.error);

  if (!message) {
    if (isObject(error.response.data)) {
      var errorKeys = Object.keys(error.response.data);

      if (errorKeys.length > 0) {
        var errorKey = errorKeys[0];

        if (isString(error.response.data[errorKey])) {
          message = error.response.data[errorKey];
        } else if (isArray(error.response.data[errorKey])) {
          // Use the convertSerializerErrorsToString function here
          message = convertSerializerErrorsToString({
            [errorKey]: error.response.data[errorKey]
          });
        }
      }
    }
  }

  return message;
};

export const renderErrorToast = (setFunc) => ({
  render({ data }) {
    const errorMessage = getResponeErrorMessage(data);
    if (isFunction(setFunc)) {
      setFunc(errorMessage);
    }
    return errorMessage;
  }
});

function base64ToBlob(base64Data, mimeType) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

export const createFormData = (payload) => {
  const formData = new FormData();

  Object.entries(payload).forEach(([key, value]) => {
    if (
      key.includes("image") ||
      key.includes("file") ||
      key.includes("thumbnail") ||
      key.includes("banner") ||
      key.includes("registration_certificate") ||
      key.includes("resume_file")
    ) {
      if (isArray(value)) {
        value.forEach((el) => {
          const [fileType, base64Data] = el.split(";base64,");
          const mimeType = fileType.split(":")[1];
          const blob = base64ToBlob(base64Data, mimeType);
          formData.append(key, blob, `${key}.${mimeType.split("/")[1]}`);
        });
      }
    } else if (isArray(value)) {
      value.forEach((item) => {
        formData.append(`${key}`, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export function capitalizeFirstLetter(str) {
  if (str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isBase64 = (str) => /^data:([A-Za-z0-9+/]+);base64,/i.test(str);

export const getContentAbsoluteUrl = (src) => {
  if (src) {
    if (src.includes("sample-profile")) {
      return src;
    }
    var retval = src;

    const includesContentHost = retval.includes(CONTENT_BASE_HOST);
    const includesMediaDir = retval.includes("media");
    if (!includesContentHost && !retval.startsWith("/")) {
      retval = "/" + retval;
    }
    if (!includesMediaDir && !includesContentHost) {
      retval = "/media" + retval;
    }

    if (!includesContentHost) {
      retval = CONTENT_BASE_URL + retval;
    }
    return retval;
  }
};

export const convertDataUrlToBase64 = (imageUrl) =>
  new Promise((resolve, reject) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
      .catch(reject);
  });

export function formatToIndianNumberMask(value) {
  const inputValue = String(value).replace(/,/g, "");
  const parsedValue = parseInt(inputValue);

  if (!isNaN(parsedValue)) {
    return parsedValue;
  } else {
    return null;
  }
}

export async function downloadContent(path, fileName) {
  try {
    const response = await fetch(path);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the PDF:", error);
  }
}

export function getStudentDesignation({
  latest_experience,
  latest_education,
  is_working_professional
}) {
  var instituition, employment_type;
  if (is_working_professional && latest_experience) {
    instituition = `${latest_experience.employment_type}, ${latest_experience.company}`;
  } else if (latest_education) {
    instituition = `${latest_education.degree}, ${latest_education.college}`;
  } else if (latest_experience) {
    instituition = `${latest_experience.employment_type}, ${latest_experience.company}`;
  }

  if (latest_experience) {
    employment_type = latest_experience.employment_type;
  }
  return { instituition, employment_type };
}

export function copyToClipboard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed";
  textarea.style.opacity = 0;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

export function downloadFile(file, name) {
  const downloadLink = document.createElement("a");
  downloadLink.href = file;
  downloadLink.download = name;
  downloadLink.click();
}

export const formatPrice = (price) => parseInt(price).toLocaleString("en-IN");

export function getProfileInitRoute(user) {
  if (user.is_studio) {
    return "/my-profile/business-details";
  } else if (user.is_student) {
    return "/my-profile/personal-details";
  }
  return "/my-profile/profile-details/basic-details";
}

export const getInitRoute = (user) => {
  const completed = isProfileCompletionPercentageReached(user);
  return completed ? "/dashboard" : getProfileInitRoute(user);
};

export const getUserMinimumProfileCompletionRequied = (user) => {
  if (user.is_studio) {
    return MINIMUM_PROFILE_COMPLETION.STUDIO;
  } else if (user.is_student) {
    return MINIMUM_PROFILE_COMPLETION.STUDENT;
  }
  return MINIMUM_PROFILE_COMPLETION.BRAND;
};

export function isProfileCompletionPercentageReached(user) {
  return user.profile_completion_percentage >= getUserMinimumProfileCompletionRequied(user);
}

export function isValidURL(url) {
  var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(url);
}

export const getUserProfilePayload = (user, data, name) => {
  var userProfileUpdatePayload = {};
  if (user.email !== data.email) {
    userProfileUpdatePayload["email"] = data.email;
    userProfileUpdatePayload["is_email_verified"] = false;
  }
  if (user.mobile !== data.phone) {
    userProfileUpdatePayload["mobile"] = data.phone;
    userProfileUpdatePayload["is_mobile_verified"] = false;
  }
  if (user.secondary_mobile !== data.secondary_mobile) {
    userProfileUpdatePayload["secondary_mobile"] = data.secondary_mobile;
    userProfileUpdatePayload["is_secondary_mobile_verified"] = false;
  }
  if (user.secondary_email !== data.secondary_email) {
    userProfileUpdatePayload["secondary_email"] = data.secondary_email;
    userProfileUpdatePayload["is_secondary_email_verified"] =
      data.is_secondary_email_verified || false;
  }
  if (user.name !== name) {
    userProfileUpdatePayload["name"] = name;
  }
  return userProfileUpdatePayload;
};

export const hasSubCategoryFromObj = (category) => {
  return isArray(category.children) && !isEmpty(category.children);
};

export function isValidListOfStrings(str) {
  try {
    const array = JSON.parse(str.replace(/'/g, '"'));
    if (!Array.isArray(array)) {
      return false; // Not an array
    }
    for (const item of array) {
      if (typeof item !== "string") {
        return false; // Not all items are strings
      }
    }
    return true; // All items are strings
  } catch (error) {
    return false; // Parsing error
  }
}

import React from "react";
import { Card } from "react-bootstrap";
import COLORS from "../../../constants/colors";
import { BsPlus } from "react-icons/bs";
import BaseContentCard from "../../Base/Card";

export default function AddContent({ onClick, addText }) {
  return (
    <BaseContentCard
      style={{
        backgroundColor: COLORS.GREY1,
        cursor: "pointer",
        display: "block",
        margin: "auto",
        borderStyle: "dashed"
      }}
      onClick={onClick}
    >
      <Card.Body className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <BsPlus className="add-icon" size={64} color="#696969" />
          <p style={{ fontSize: "1.5rem" }} className="text-capitalize">
            {addText}
          </p>
        </div>
      </Card.Body>
    </BaseContentCard>
  );
}

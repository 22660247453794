import React from "react";
import COLORS from "../../constants/colors";
import { useSearchParams } from "../../hooks/searchParams";
import { isFunction } from "lodash";

export default function TypeFilter({ types, filterName, value, onSelect }) {
  const { getKey, addKey, resetQuery } = useSearchParams();
  const filterValue = getKey(filterName);

  const handleFilterSelect = (selectedValue) => {
    if (isFunction(onSelect)) {
      onSelect(selectedValue);
    } else {
      resetQuery([filterName, "ordering"]);

      addKey(filterName, selectedValue);
    }
  };
  return (
    <div
      className="d-flex align-items-center py-3 px-1"
      style={{ backgroundColor: COLORS.GREY1, overflowX: "scroll" }}
    >
      {types.map((el, index) => (
        <p
          key={`${index}-${el.label}`}
          style={{
            width: "max-content",
            color:
              (value && el.value === value) || filterValue === el.value
                ? COLORS.PRIMARY_ORANGE
                : "black"
          }}
          className="px-4 mb-0 fw-bold cursor-pointer text-nowrap"
          onClick={() => handleFilterSelect(el.value)}
        >
          {el.label}
        </p>
      ))}
    </div>
  );
}

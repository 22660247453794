import React from "react";
import { Modal } from "react-bootstrap";
import BaseModal from "../Base";
import UploadProfileImageForm from "../../../forms/UploadProfileImage";

export default function UploadProfileImageModal({ show, onHide }) {
  return (
    <BaseModal centered show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton className="fw-bold" style={{ fontSize: "1.5rem" }}>
        Choose Profile Image
      </Modal.Header>
      <UploadProfileImageForm onHide={onHide} />
    </BaseModal>
  );
}

import React from "react";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";
import PdfPreview from "components/PdfPreview";

export default function PdfPreviewModal({ show, onHide, file }) {
  return (
    <BaseModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PdfPreview file={file} />
      </Modal.Body>
    </BaseModal>
  );
}

import ContentFactory from "apiClients/ContentFactory";
import Email from "components/Inputs/Email";
import Phone from "components/Inputs/Phone";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import Select from "components/Inputs/Select";
import { useScrollToTop } from "hooks";
import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { renderErrorToast, showPromiseToast } from "utils";

export default function ApplyCareerForm({ positions }) {
  const { scrollToTop } = useScrollToTop();
  const { handleSubmit, reset } = useFormContext();
  const client = ContentFactory.createRequest("career");
  const onSubmit = (data) => {
    const payload = { ...data, career: data.career.value };
    const promise = client.apply(payload).then(reset).then(scrollToTop);
    return showPromiseToast(promise, {
      pending: "Applying",
      success: "Your application has been recorded successfully",
      error: renderErrorToast()
    });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Name *</Form.Label>
              <ReactHookInput name="name" required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Email *</Form.Label>
              <Email required />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Phone *</Form.Label>
              <Phone name="phone_number" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Position *</Form.Label>
              <Select name="career" required options={positions} />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Upload Your CV *</Form.Label>
          <ReactHookUpload name="resume_file" type="pdf,image" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <ReactHookInput as="textarea" name="message" />
        </Form.Group>
        <Button className="d-block mx-auto w-25 mt-4" type="submit">
          APPLY
        </Button>
      </Stack>
    </Form>
  );
}

import React from "react";
import { ReactSVG } from "react-svg";
import {
  IDEATION_BOARD_FILLED,
  IDEATION_BOARD_UNFILLED,
  IDEATION_BOARD_UNFILLED_BG
} from "../../../constants/icons";
import WhiteIconContainer from "../WhiteContainer";
import { useIdeationBoardModal } from "../../../hooks/addToIdeationBoard";
import MoodBoardContentFactory from "../../../apiClients/MoodBoardContentFactory";
import { isArray, isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useNavigationInfo } from "hooks";

export default function IdeationBoardIcon({
  obj,
  onAddedToIdeationBoard,
  buttonStyle = "1",
  width = "2rem",
  height = "2rem"
}) {
  const { showAddToIdeationBoardModal } = useIdeationBoardModal();
  const { isMoodBoard } = useNavigationInfo();
  const { id, moodBoardId } = useParams();
  const openIdeationBoardModal = () => showAddToIdeationBoardModal(obj, onAddedToIdeationBoard);

  const getMoodBoardLinkId = () => {
    const moodBoardIndex = obj.mood_board_id.findIndex((el) => el === Number(moodBoardId));
    return obj.mood_board_link_id[moodBoardIndex];
  };

  const removeFromIdeationBoard = () => {
    if (isMoodBoard) {
      MoodBoardContentFactory.createRequest(obj.contentType, id, moodBoardId)
        .remove(getMoodBoardLinkId())
        .then(() => onAddedToIdeationBoard(true));
    } else {
      openIdeationBoardModal();
    }
  };

  const isInIdeationBoard =
    isArray(obj.ideation_board_id) &&
    !isEmpty(obj.ideation_board_id) &&
    isArray(obj.mood_board_id) &&
    !isEmpty(obj.mood_board_id) &&
    isArray(obj.mood_board_link_id) &&
    !isEmpty(obj.mood_board_link_id);

  return (
    <WhiteIconContainer
      width={width}
      height={height}
      onClick={(e) => {
        e.stopPropagation();
        if (!isInIdeationBoard) {
          openIdeationBoardModal();
        } else {
          removeFromIdeationBoard();
        }
      }}
    >
      <ReactSVG
        src={
          isInIdeationBoard
            ? buttonStyle === "2"
              ? IDEATION_BOARD_FILLED
              : IDEATION_BOARD_FILLED
            : buttonStyle === "2"
            ? IDEATION_BOARD_UNFILLED_BG
            : IDEATION_BOARD_UNFILLED
        }
      />
    </WhiteIconContainer>
  );
}

import "./styles.scss";
import React, { useEffect, useState } from "react";
import BaseModal from "../Base";
import { Accordion, Button, Modal, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { difference, isArray, isEmpty, isObject, isUndefined } from "lodash";
import { ReactSVG } from "react-svg";
import { useIdeationBoardModal } from "hooks/addToIdeationBoard";
import MoodBoardContentFactory from "apiClients/MoodBoardContentFactory";
import { PROJECT_ICON } from "constants/icons";
import COLORS from "constants/colors";
import ACTION_TYPES from "constants/action-types";

function AddToIdeationBoardModal() {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const dispatch = useDispatch();
  const getIdeationBoards = () =>
    dispatch({ type: ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_REQUEST });
  const ideationBoards = useSelector((state) => state.contentReducer.ideationBoards);
  const { show, hideAddToIdeationBoardModal, addedToIdeationBoardOnSuccess } =
    useIdeationBoardModal();

  const [activeIdeationBoard, setActiveIdeationBoard] = useState(undefined);
  const [selectedMoodBoard, setSelectedMoodBoard] = useState([]);

  const getIdeationBoardId = (moodBoardId) => {
    if (isArray(ideationBoards)) {
      for (var j = 0; j < ideationBoards.length; j++) {
        const { mood_boards } = ideationBoards[j];
        if (isArray(mood_boards)) {
          for (var i = 0; i < mood_boards.length; i++) {
            if (moodBoardId === mood_boards[i].id) {
              return ideationBoards[j].id;
            }
          }
        }
      }
    }
  };

  const getMoodBoardLinkId = (moodBoardId) => {
    if (isObject(show) && isArray(show.mood_board_id)) {
      const { mood_board_id, mood_board_link_id } = show;
      for (let i = 0; i < mood_board_id.length; i++) {
        if (mood_board_id[i] === moodBoardId) {
          return mood_board_link_id[i];
        }
      }
    }
  };

  const onSave = () => {
    const promises = [];

    const removeMoodId = difference(show.mood_board_id, selectedMoodBoard);
    for (let i = 0; i < removeMoodId.length; i++) {
      const ideationBoardId = getIdeationBoardId(selectedMoodBoard[i]);
      const moodBoardLinkId = getMoodBoardLinkId(removeMoodId[i]);
      promises.push(
        MoodBoardContentFactory.createRequest(
          show.contentType,
          ideationBoardId,
          removeMoodId[i]
        ).remove(moodBoardLinkId)
      );
    }
    for (let i = 0; i < selectedMoodBoard.length; i++) {
      const { mood_board_id } = show;
      if (!mood_board_id.includes(selectedMoodBoard[i])) {
        const ideationBoardId = getIdeationBoardId(selectedMoodBoard[i]);
        promises.push(
          MoodBoardContentFactory.createRequest(
            show.contentType,
            ideationBoardId,
            selectedMoodBoard[i]
          ).add(show.id)
        );
      }
    }
    Promise.all(promises).then(addedToIdeationBoardOnSuccess).then(hideAddToIdeationBoardModal);
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      getIdeationBoards();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isUndefined(show)) {
      setActiveIdeationBoard(undefined);
      setSelectedMoodBoard(undefined);
    } else {
      setSelectedMoodBoard(show.mood_board_id);
    }
  }, [show]);

  useEffect(() => {
    if (isObject(show) && isArray(ideationBoards) && !isEmpty(ideationBoards)) {
      setActiveIdeationBoard(0);
    }
  }, [ideationBoards, show]);

  const checkMoodBoard = (id) => {
    var newSelectedMoodBoard = [...selectedMoodBoard];

    if (isArray(selectedMoodBoard) && selectedMoodBoard.includes(id)) {
      const index = selectedMoodBoard.findIndex((el) => id === el);
      if (index > -1) {
        newSelectedMoodBoard.splice(index, 1);
      }
    } else {
      newSelectedMoodBoard.push(id);
    }
    setSelectedMoodBoard(newSelectedMoodBoard);
  };

  return (
    <BaseModal show={!isUndefined(show)} onHide={hideAddToIdeationBoardModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add To Mood Board</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion
          onSelect={(activeIndex) => setActiveIdeationBoard(activeIndex)}
          activeKey={activeIdeationBoard}
          defaultActiveKey={activeIdeationBoard}
        >
          {ideationBoards.map(
            (el, index) =>
              isArray(el.mood_boards) &&
              !isEmpty(el.mood_boards) && (
                <Accordion.Item
                  eventKey={index}
                  key={`add-to-ideation-board-${el.id}`}
                  style={{
                    backgroundColor: activeIdeationBoard === index ? "white" : "transparent",
                    color: activeIdeationBoard === index ? "red" : "black",
                    border: "none"
                  }}
                >
                  <Accordion.Header>
                    <ReactSVG
                      src={PROJECT_ICON}
                      color={activeIdeationBoard === index ? COLORS.PRIMARY_ORANGE : "black"}
                    />
                    <span className="ms-2">{el.folder_name}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="mb-0">
                      <Stack gap={3}>
                        {el.mood_boards.map((moodBoard) => (
                          <li
                            className="cursor-pointer"
                            key={moodBoard.id}
                            onClick={() => checkMoodBoard(moodBoard.id)}
                            style={{
                              color:
                                isArray(selectedMoodBoard) &&
                                selectedMoodBoard.includes(moodBoard.id)
                                  ? COLORS.PRIMARY_ORANGE
                                  : "black"
                            }}
                          >
                            {moodBoard.name}
                          </li>
                        ))}
                      </Stack>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              )
          )}
        </Accordion>
        <Button className="w-100 mt-4" onClick={onSave}>
          SAVE
        </Button>
      </Modal.Body>
    </BaseModal>
  );
}

export default AddToIdeationBoardModal;

import Api, { ApiHeaders } from "./api";
import { createFormData } from "../utils";

class ContentBase extends ApiHeaders {
  constructor(endpoint, type) {
    super();
    this.endpoint = endpoint;
    this.type = type;
  }

  filters = async () => Api.get(`${this.endpoint}/filters/`).then((result) => result.data);

  async create(payload, useFormData = true) {
    return await Api.post(
      `/${this.endpoint}/`,
      useFormData === true ? createFormData(payload) : payload,
      this.authenticationHeaders
    ).then((response) => response.data);
  }

  async list(filters) {
    var params = {};

    if (filters) {
      params = { ...params, ...filters };
    }

    const response = await Api.get(`/${this.endpoint}/`, params, this.authenticationHeaders);
    return response.data;
  }

  async read(item) {
    const response = await Api.get(`/${this.endpoint}/${item}/`, {}, this.authenticationHeaders);
    return response.data;
  }

  async update(item, useFormData = true) {
    return await Api.put(
      `/${this.endpoint}/${item.id}/`,
      useFormData === true ? createFormData(item) : item,
      this.authenticationHeaders
    ).then((response) => response.data);
  }

  async partialUpdate(item, useFormData = true) {
    const response = await Api.patch(
      `/${this.endpoint}/${item.id || item.slug}/`,
      useFormData === true ? createFormData(item) : item,
      this.authenticationHeaders
    );
    return response.data;
  }

  async remove(item) {
    const response = await Api.delete(
      `/${this.endpoint}/${item}/`,
      null,
      this.authenticationHeaders
    );
    return response.data;
  }
  async like(id) {
    const response = await Api.post(`/likes/${this.type}/${id}/`, null, this.authenticationHeaders);
    return response.data;
  }
  async view(id) {
    const response = await Api.post(`/views/${this.type}/${id}/`, null, this.authenticationHeaders);
    return response.status;
  }
}

class Image extends ContentBase {
  constructor(type) {
    super("content/images", type);
  }
}

class Video extends ContentBase {
  constructor(type) {
    super("content/videos", type);
  }
}

class Portfolio extends ContentBase {
  constructor(type) {
    super("content/portfolios", type);
  }
}

class ARVR extends ContentBase {
  constructor(type) {
    super("content/arvrs", type);
  }
}

class Project extends ContentBase {
  constructor(type) {
    super("content/projects", type);
  }
}

class Thesis extends ContentBase {
  constructor(type) {
    super("content/thesis", type);
  }
}

class Blog extends ContentBase {
  constructor(type) {
    super("blogs", type);
  }
  async get(id) {
    const response = await Api.get(`/${this.endpoint}/${id}/`, null, this.authenticationHeaders);
    return response.data;
  }

  async getTemplates() {
    const response = await Api.get(`/${this.endpoint}/templates/`, null, this.defaultHeaders);
    return response.data;
  }
  async getPins() {
    return await Api.get(`/${this.endpoint}/pinned/`, null, this.authenticationHeaders).then(
      (result) => result.data
    );
  }
  async deletePin(blog_id) {
    return await Api.delete(
      `/${this.endpoint}/pinned/${blog_id}/`,
      {},
      this.authenticationHeaders
    ).then((result) => result.data);
  }
  async pin(blog_id) {
    return await Api.post(
      `/${this.endpoint}/pinned/`,
      { blog_id },
      this.authenticationHeaders
    ).then((result) => result.data);
  }
}

class Resume extends ContentBase {
  constructor(type) {
    super("student/resume", type);
  }
  async updateImage(id, image) {
    const data = createFormData({ image });
    const response = await Api.patch(
      `/${this.endpoint}/${id}/update-image/`,
      data,
      this.authenticationHeaders
    );
    return response.data;
  }
  update(item) {
    return new Promise((resolve, reject) => {
      let image;

      if (item.image) {
        image = item.image;
        delete item.image;
      }

      super
        .update(item, false)
        .then(() => {
          if (image) {
            return this.updateImage(item.id, image);
          }
        })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  async makeDefault(item, isFile) {
    const response = await Api.post(
      `/${this.endpoint}/${item}/default/`,
      { is_file: isFile },
      this.authenticationHeaders
    );
    return response.data;
  }

  create(item) {
    return new Promise((resolve, reject) => {
      let image;

      if (item.image) {
        image = item.image;
        delete item.image;
      }

      super
        .create(item, false)
        .then(() => {
          if (image) {
            return this.updateImage(item.id, image);
          }
        })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }
}

class ResumeFile extends ContentBase {
  constructor(type) {
    super("student/resume-files", type);
  }
}

class Profile extends ContentBase {
  constructor(type) {
    super("profile", type);
  }
  async relatedProfiles(slug) {
    return await Api.get(
      `${this.endpoint}/related-profiles/${slug}`,
      {},
      this.authenticationHeaders
    ).then((result) => result.data);
  }
}

class Job extends ContentBase {
  constructor(type) {
    super("jobs", type);
  }
  async homeListings() {
    return await Api.get(`${this.endpoint}/home/`, {}, this.authenticationHeaders).then(
      (result) => result.data
    );
  }

  async getApplicantsCount(id) {
    return await Api.get(
      `${this.endpoint}/${id}/applicants/count/`,
      {},
      this.authenticationHeaders
    ).then((result) => result.data);
  }
}

class JobApplications extends ContentBase {
  constructor(type) {
    super("jobs/applications", type);
  }
}

class Leads extends ContentBase {
  constructor(type) {
    super("leads/leads", type);
  }
}

class Requests extends ContentBase {
  constructor(type) {
    super("leads/requests", type);
  }
}

class Categories extends ContentBase {
  constructor(type) {
    super("content/categories", type);
  }
}

class UserCategories extends ContentBase {
  constructor(type) {
    super("content/user-categories", type);
  }
}

class FAQs extends ContentBase {
  constructor(type) {
    super("faqs", type);
  }

  help = (question) =>
    Api.post(`${this.endpoint}/help/`, { question }, this.authenticationHeaders).then(
      (result) => result.data
    );
}

class Catalogues extends ContentBase {
  constructor(type) {
    super("content/catalogues", type);
  }
}
class IdeationBoard extends ContentBase {
  constructor(type) {
    super("content/ideation-board", type);
  }

  async createMoodBoard(ideationBordId, payload) {
    const response = await Api.post(
      `${this.endpoint}/${ideationBordId}/mood-board/`,
      payload,
      this.authenticationHeaders
    );
    return response.data;
  }

  async updateMoodBoard(ideationBordId, moodboardId, payload) {
    const response = await Api.put(
      `${this.endpoint}/${ideationBordId}/mood-board/${moodboardId}/`,
      payload,
      this.authenticationHeaders
    );
    return response.data;
  }

  async getMoodBoard(ideationBordId, moodBoardId) {
    const response = await Api.get(
      `/${this.endpoint}/${ideationBordId}/mood-board/${moodBoardId}/`,
      {},
      this.authenticationHeaders
    );
    return response.data;
  }

  async deleteMoodBoard(ideationBoardId, moodBoardId) {
    const response = await Api.delete(
      `/${this.endpoint}/${ideationBoardId}/mood-board/${moodBoardId}/`,
      null,
      this.authenticationHeaders
    );
    return response.data;
  }
}

class Product extends ContentBase {
  constructor(type) {
    super("content/products", type);
  }
  async columns() {
    return (await Api.get(`/${this.endpoint}/columns/`, {}, this.authenticationHeaders)).data;
  }
  async requestHelp() {
    return (await Api.post(`/${this.endpoint}/help/`, {}, new ApiHeaders().authenticationHeaders))
      .data;
  }
}

class Careers extends ContentBase {
  constructor(type) {
    super("career", type);
  }
  async apply(data) {
    return await Api.post(`/${this.endpoint}/apply/`, createFormData(data), {});
  }
}

class ContentFactory {
  static createRequest(type) {
    switch (type) {
      case "image":
        return new Image(type);
      case "video":
        return new Video(type);
      case "portfolio":
        return new Portfolio(type);
      case "project":
        return new Project(type);
      case "thesis":
        return new Thesis(type);
      case "blog":
        return new Blog(type);
      case "resume":
        return new Resume(type);
      case "resume-file":
        return new ResumeFile(type);
      case "profile":
        return new Profile(type);
      case "job":
        return new Job(type);
      case "lead":
        return new Leads(type);
      case "request":
        return new Requests(type);
      case "ideation-board":
        return new IdeationBoard(type);
      case "categories":
        return new Categories(type);
      case "user-categories":
        return new UserCategories(type);
      case "catalogue":
        return new Catalogues(type);
      case "arvr":
        return new ARVR(type);
      case "job-application":
        return new JobApplications(type);
      case "product":
        return new Product(type);
      case "faqs":
        return new FAQs(type);
      case "career":
        return new Careers(type);
      default:
        throw new Error(`Unsupported request type: ${type}`);
    }
  }
}

export default ContentFactory;

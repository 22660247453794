import ContentFactory from "apiClients/ContentFactory";
import GreyedAccordion from "components/Accordions/GreyedAccordion";
import Dropdown from "components/Dropdown";
import Search from "components/Inputs/Search";
import NeedHelpModal from "components/Modals/NeedHelp";
import { useSearchParams } from "hooks/searchParams";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

export default function FAQs() {
  const [search, setSearch] = useState("");
  const [help, setHelp] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const FAQS_RELATED_TO_OPTIONS = [
    { label: "Sign In/Sign Up", value: "Sign In/Sign Up" },
    { label: "Account Related", value: "Account Related" },
    { label: "Project", value: "Project" },
    { label: "Events", value: "Events" },
    { label: "Jobs", value: "Jobs" },
    { label: "Blogs", value: "Blogs" },
    { label: "Brands", value: "Brands" }
  ];

  const client = ContentFactory.createRequest("faqs");
  const { addKey, getKey, queryString, searchParamsAsObject } = useSearchParams();

  const getFaqs = () => client.list(searchParamsAsObject).then(setFaqs);

  useEffect(() => {
    addKey("search", search);
  }, [search]);

  useEffect(() => {
    getFaqs();
  }, [queryString]);

  const submitHelp = (e) => {
    e.preventDefault();
    client
      .help(help)
      .then(() => setHelp(""))
      .then(() => setShowSuccess(true));
  };

  return (
    <div className="mx-5 mt-4">
      <h2 className="mb-3">FAQs</h2>
      <h4 className="fw-bold">Frequently Asked Questions</h4>
      <p>Check out this section to get answers to all the frequently asked questions</p>
      {isLoggedIn && (
        <Form onSubmit={submitHelp}>
          <h5 className="fw-bold mt-5">Need Help?</h5>
          <Form.Group className="mt-2">
            <Form.Control
              type="text"
              as="textarea"
              value={help}
              placeholder="Enter your query and we will get back to you shortly"
              onChange={(e) => setHelp(e.target.value)}
              maxLength={250}
              style={{ minHeight: "7.5rem" }}
            />
            <Form.Text>Max limit: 250 words*</Form.Text>
          </Form.Group>
          <Button className="px-5 ms-auto d-block mt-1 mb-5" type="submit">
            SUBMIT
          </Button>
        </Form>
      )}
      <Row>
        <Col className={`w-${isMobile ? "100" : "50"}`}>
          <Search searchString={search} setSearchString={setSearch} />
        </Col>
        <Col className="px-4 ms-auto">
          <Dropdown
            align="end"
            value={getKey("related_to")}
            options={[{ label: "All", value: null }, ...FAQS_RELATED_TO_OPTIONS]}
            label="Related To"
            onClick={(value) => addKey("related_to", value)}
          />
        </Col>
      </Row>
      <div className="mt-4 w-100">
        {faqs.map((el, index) => (
          <GreyedAccordion
            title={el.question}
            className="w-100"
            key={index}
            defaultActiveKey={index}
          >
            <p>{el.answer}</p>
          </GreyedAccordion>
        ))}
      </div>
      <NeedHelpModal show={showSuccess} onHide={() => setShowSuccess(false)} />
    </div>
  );
}

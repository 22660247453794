import Dropdown from "components/Dropdown";
import { STATES } from "constants/states";
import { useSearchParams } from "hooks/searchParams";
import React from "react";

export default function LocationFilters() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "location";

  const value = getKey(KEY);
  return (
    <Dropdown
      value={value}
      options={STATES}
      label="Location"
      onClick={(value) => addKey(KEY, value)}
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
    />
  );
}

import React from "react";
import ImageGallery from "react-image-gallery";
import "./styles.scss";
import { isMobile } from "react-device-detect";

export default function Gallery({ images = [] }) {
  return (
    <div>
      <ImageGallery
        slideInterval={6000}
        autoPlay
        lazyLoad
        showFullscreenButton={false}
        items={(images || []).map((el) => ({
          original: el,
          thumbnail: el,
          originalClass: "product-gallery-image"
        }))}
        thumbnailPosition={isMobile ? "bottom" : "left"}
        additionalClass="image"
      />
    </div>
  );
}

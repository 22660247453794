import React from "react";
import { Button, Form, Image, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FaUserAlt, FaRegImage, FaGraduationCap } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import SkillsHobbiesLanguagesSection from "./Sections/SkillsHobbiesLangauges";
import AvatarBasedImageUpload from "components/Uploaders/AvatarBasedImageUpload";
import { useScrollToTop } from "hooks";
import { isBase64 } from "utils";
import ContentFactory from "apiClients/ContentFactory";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ResumeFormContainer from "components/Containers/ResumeForm";
import ExperienceSection from "./Sections/Experience";
import EducationSection from "./Sections/Education";
import CertificationCourseSection from "./Sections/Courses";
import AwardsAchievementsSection from "./Sections/AwardsAchievements";
import ResumeAccordion from "components/Accordions/ResumeAccordion";
import CERTIFICATES_COURSES_ICON from "../../../assets/icons/resume/courses-certificate.png";
import AWARDS_ACHIEVEMENTS_ICON from "../../../assets/icons/resume/awards-achievements.png";
import SKILLS_HOBBIES_LANGUAGES_ICON from "../../../assets/icons/resume/skills-hobbies-languages.png";
import { isMobile } from "react-device-detect";
import { Element } from "react-scroll";

function ResumeImage() {
  return (
    <Form.Group>
      <div className="d-flex align-items-center">
        <FaRegImage />
        <h6 className="mb-0 ms-2">Upload Resume Image</h6>
      </div>
      <Form.Text muted className="mt-3 ms-4">
        Please provide a professional profile photo of yourself to make your resume more
        professional.
      </Form.Text>
      <AvatarBasedImageUpload name="image" width={125} height={125} />
    </Form.Group>
  );
}

export default function AddResumeForm() {
  const { id } = useParams();
  const { handleSubmit } = useFormContext();
  const navigate = useNavigate();
  const { scrollToTop } = useScrollToTop();

  const onSubmit = (data) => {
    var hobbies = (data.hobbies || []).map((el) => el.value);
    var technical_skills = (data.technical_skills || []).map((el) => el.value);
    var management_skills = (data.management_skills || []).map((el) => el.value);
    var personal_skills = (data.personal_skills || []).map((el) => el.value);
    var languages = (data.languages || []).map((el) => el.value);

    const payload = {
      ...data,
      hobbies,
      id,
      technical_skills,
      languages,
      personal_skills,
      management_skills
    };

    if (payload.image && !isBase64(payload.image)) {
      delete payload.image;
    }
    const resumeClient = ContentFactory.createRequest("resume");
    if (id) {
      showPromiseToast(
        resumeClient.update(payload).then(() => scrollToTop()),
        {
          pending: "Saving resume",
          success: "Resume saved successfully",
          error: renderErrorToast()
        }
      );
    } else {
      showPromiseToast(
        resumeClient
          .create(payload)
          .then(() => navigate(-1))
          .then(() => scrollToTop())
      ),
        {
          pending: "Saving resume",
          success: "Resume saved successfully",
          error: renderErrorToast()
        };
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
      <h6>Create Resume</h6>
      <Stack gap={3} className="mt-4">
        <ResumeImage />
        <Form.Group>
          <hr />
          <Form.Label className="fw-bold mt-2">Resume Title*</Form.Label>
          <ReactHookInput name="title" required />
          <hr className="mt-4" />
        </Form.Group>
        <Form.Group>
          <Form.Label className="mb-0 fw-bold">Headline*</Form.Label>
          <div className="mb-2">
            <Form.Text muted style={{ fontSize: "70%" }}>
              This optional section can help you stand out to recruiters. If this section is empty,
              it will not appear on your resume.
            </Form.Text>
          </div>
          <ReactHookInput name="headline" required type="textarea" />
        </Form.Group>
        <div>
          <hr className="mb-0" />
          {[
            {
              title: "About",
              subTitle:
                "This optional section can help you stand out to recruiters. If this section is empty, it will not appear on your resume.",
              icon: <FaUserAlt size={15} />,
              component: (
                <ResumeFormContainer>
                  <ReactHookInput name="about" as="textarea" required />
                </ResumeFormContainer>
              )
            },
            {
              title: "Experience",
              subTitle:
                "This optional section can help you stand out to recruiters. If this section is empty, it will not appear on your resume.",
              icon: <FaBriefcase size={15} />,
              component: <ExperienceSection />
            },
            {
              title: "Education",
              subTitle:
                "This optional section can help you stand out to recruiters. If this section is empty, it will not appear on your resume.",
              icon: <FaGraduationCap size={19} />,
              component: <EducationSection />
            },
            {
              title: "Courses & Certifications",
              subTitle:
                "This optional section can help you stand out to recruiters. If this section is empty, it will not appear on your resume.",

              icon: <Image src={CERTIFICATES_COURSES_ICON} style={{ width: 18, height: 18 }} />,
              component: <CertificationCourseSection />
            },
            {
              title: "Awards & Achievements",
              subTitle:
                "This optional section can help you stand out to recruiters. If this section is empty, it will not appear on your resume.",
              icon: <Image src={AWARDS_ACHIEVEMENTS_ICON} style={{ width: 18, height: 18 }} />,
              component: <AwardsAchievementsSection />
            },
            {
              title: "Skills, Languages & Hobbies",
              subTitle: "This section is empty and won’t appear in your resume.",
              icon: <Image src={SKILLS_HOBBIES_LANGUAGES_ICON} style={{ width: 18, height: 18 }} />,
              component: <SkillsHobbiesLanguagesSection />
            }
          ].map((section, index) => (
            <Element name={section.title} key={index}>
              <ResumeAccordion
                eventKey={index}
                title={section.title}
                subTitle={section.subTitle}
                icon={section.icon}
              >
                {section.component}
              </ResumeAccordion>
            </Element>
          ))}
        </div>
        <div>
          <Button className={`w-${isMobile ? "100" : "25"} d-block ms-auto mt-2`} type="submit">
            Save
          </Button>
        </div>
      </Stack>
    </Form>
  );
}

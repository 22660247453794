import React, { useState } from "react";
import LeadStatus from "../../components/LeadsStatus";
import CheckBox from "../../components/Inputs/CheckBox";
import { createColumnHelper } from "@tanstack/react-table";
import EditDeleteIcons from "../../components/Icons/EditDeleteIcons";
import DateParser from "../../date";
import REVIEW_JOB_APPLICATIONS from "../../assets/icons/review-application.png";
import VIEW_JOB_DESCRIPTION from "../../assets/icons/view-job-description.png";
import { Button, Form, Image, Modal } from "react-bootstrap";
import COLORS, { getApplicationStatusColors } from "../../constants/colors";
import { ThreeDotsDropdown } from "../../components/Buttons/ThreeDotsVertical";
import ReactShowMoreText from "react-show-more-text";
import ContentFactory from "apiClients/ContentFactory";
import { isValidListOfStrings, renderErrorToast, showPromiseToast } from "utils";
import { isArray, isBoolean, isEmpty, isFunction, isNumber, isString } from "lodash";
import { GREEN_FILLED_TICK_ICON } from "constants/icons";
import { ReactSVG } from "react-svg";
import Dropdown from "components/Dropdown";
import CreatableSelect from "react-select/creatable";

export default class TableColumnsFactory {
  constructor() {
    this.columns = [];
    this.accessor = createColumnHelper().accessor;
  }

  updateSelectedResults = (value, selectedResults) => {
    var newSelectedResults = [...selectedResults];
    if (selectedResults.filter((el) => el.id === value.id).length === 0) {
      newSelectedResults.push(value);
    } else {
      newSelectedResults = [...selectedResults.filter((el) => el !== value)];
    }
    return newSelectedResults;
  };

  createColumnsForAppliedJob(onReview, onJobDescription) {
    return [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No."
      }),
      this.accessor("job_details.user.name", {
        header: "Company"
      }),
      this.accessor("job_details.position", {
        header: "Position"
      }),
      this.accessor("job_details.location", {
        header: "Location"
      }),
      this.accessor((row) => `${DateParser.formatDateToDDMMMYYYY(new Date(row.created))}`, {
        header: "Date Applied"
      }),
      this.accessor("status", {
        header: "Application Status",
        cell: (info) => (
          <div
            style={{
              color: getApplicationStatusColors(info.row.original.status)
            }}
          >
            {info.row.original.status}
          </div>
        )
      }),
      this.accessor(() => "s", {
        id: "action",
        header: "Review Application",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer py-1"
              onClick={() => onReview(row.id)}
            >
              <Image
                src={REVIEW_JOB_APPLICATIONS}
                style={{ width: "1.25rem", height: "1.25rem", objectFit: "contain" }}
              />
            </div>
          );
        }
      }),
      this.accessor(() => "s", {
        id: "job-description",
        header: "View Job Description",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer py-1"
              onClick={() => onJobDescription(row.job_details.slug)}
            >
              <Image
                src={VIEW_JOB_DESCRIPTION}
                style={{ width: "1.25rem", height: "1.25rem", objectFit: "contain" }}
              />
            </div>
          );
        }
      })
    ];
  }

  createColumnForBasicDetails(selectedResults, setSelectedResults, onEdit, onDelete) {
    return [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No.",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div className="d-flex justify-content-center">
              <CheckBox
                isChecked={selectedResults.filter((el) => el.id === row.id).length !== 0}
                label={info.row.index + 1}
                useReactHook={false}
                setChecked={() =>
                  setSelectedResults(this.updateSelectedResults(info.row.original, selectedResults))
                }
              />
            </div>
          );
        }
      }),
      this.accessor("branch_contact_person_name", {
        header: "Contact Person Name"
      }),
      this.accessor("designation", {
        header: "Designation"
      }),
      this.accessor("contact_number", {
        header: "Contact Number"
      }),
      this.accessor("email", {
        header: "Email ID"
      }),
      this.accessor(
        (row) => `${row.address_line1}, ${row.city}, ${row.state} - ${row.pincode}, ${row.country}`,
        {
          id: "address",
          header: "Branch Address"
        }
      ),
      this.accessor(() => "s", {
        id: "action",
        header: "Actions",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div className="d-flex justify-content-center">
              <EditDeleteIcons onDelete={() => onDelete(row)} onEdit={() => onEdit(row)} />
            </div>
          );
        }
      })
    ];
  }

  createColumnForProfileBasicDetails() {
    return [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No."
      }),
      this.accessor("branch_contact_person_name", {
        header: "Contact Person Name"
      }),
      this.accessor("designation", {
        header: "Designation"
      }),
      this.accessor("contact_number", {
        header: "Contact Number"
      }),
      this.accessor("email", {
        header: "Email ID"
      }),
      this.accessor(
        (row) => `${row.address_line1}, ${row.city}, ${row.state} - ${row.pincode}, ${row.country}`,
        {
          id: "address",
          header: "Branch Address"
        }
      )
    ];
  }

  createColumnForProfileDistributionNetwork() {
    return [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No."
      }),
      this.accessor("firm_name", {
        header: "Firm Name"
      }),
      this.accessor("network_type", {
        header: "Network Type"
      }),
      this.accessor("contact_number", {
        header: "Contact Number"
      }),
      this.accessor("email", {
        header: "Email ID"
      }),
      this.accessor(
        (row) => `${row.address_line1}, ${row.city}, ${row.state} - ${row.pincode}, ${row.country}`,
        {
          id: "address",
          header: "Address"
        }
      )
    ];
  }

  createColumnForDistributionNetwork(selectedResults, setSelectedResults, onEdit, onDelete) {
    return [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No.",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div className="d-flex justify-content-center">
              <CheckBox
                isChecked={selectedResults.filter((el) => el.id === row.id).length !== 0}
                label={info.row.index + 1}
                useReactHook={false}
                setChecked={() =>
                  setSelectedResults(this.updateSelectedResults(info.row.original, selectedResults))
                }
              />
            </div>
          );
        }
      }),
      this.accessor("firm_name", {
        header: "Firm Name"
      }),
      this.accessor("network_type", {
        header: "Network Type"
      }),
      this.accessor("contact_number", {
        header: "Contact Number"
      }),
      this.accessor("email", {
        header: "Email ID"
      }),
      this.accessor(
        (row) => `${row.address_line1}, ${row.city}, ${row.state} - ${row.pincode}, ${row.country}`,
        {
          id: "address",
          header: "Address"
        }
      ),
      this.accessor(() => "s", {
        id: "action",
        header: "Actions",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div className="d-flex justify-content-center">
              <EditDeleteIcons onDelete={() => onDelete(row)} onEdit={() => onEdit(row)} />
            </div>
          );
        }
      })
    ];
  }

  createColumnsForRequests(
    selectedResults,
    setSelectedResults,
    startIndex,
    onUpdate,
    user,
    onCancel
  ) {
    const { is_student, is_brand, is_studio } = user;
    var columns = [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No.",
        cell: (info) => {
          const [show, setShow] = useState(false);
          const {
            row: { original, index }
          } = info;
          return (
            <div className="d-flex justify-content-center align-items-center">
              {show && (
                <Modal show={show} onHide={() => setShow(false)} centered>
                  <Modal.Header>
                    <Modal.Title>Cancel Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="text-center">Are you sure you want to cancel the request?</p>
                    <div className="mt-4 d-flex align-items-center justify-content-between mx-4">
                      <Button variant="outline-primary px-4" onClick={() => setShow(false)}>
                        CANCEL
                      </Button>
                      <Button
                        className="px-4"
                        onClick={() => {
                          const promise = ContentFactory.createRequest("request")
                            .partialUpdate({ id: info.row.original.id, status: "Cancelled" })
                            .then(onCancel)
                            .then(() => setShow(false));
                          showPromiseToast(promise, {
                            pending: "Cancelling Request",
                            success: "Your request has been cancelled successfully",
                            error: renderErrorToast()
                          });
                        }}
                      >
                        CONFIRM
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              <ThreeDotsDropdown
                options={[
                  {
                    disabled: info.row.original.status === "Cancelled",
                    label: "Cancel Request",
                    onClick: () => setShow(true)
                  }
                ]}
                className="me-1"
                size={20}
              />
              <CheckBox
                isChecked={selectedResults.filter((el) => el.id === original.id).length !== 0}
                label={index + 1 + startIndex}
                useReactHook={false}
                setChecked={() =>
                  setSelectedResults(this.updateSelectedResults(original, selectedResults))
                }
              />
            </div>
          );
        }
      }),
      this.accessor("recipient_name", {
        header: "Name"
      }),
      this.accessor("email", {
        header: "Email ID"
      }),
      this.accessor("mobile", {
        header: "Contact Number"
      }),
      this.accessor("reason", {
        header: "Connecting For?"
      }),
      this.accessor("message", {
        header: "Message",
        cell: (info) => {
          const {
            row: { original }
          } = info;
          return (
            original.message && (
              <div style={{ width: "200px" }} className="d-block mx-auto">
                <ReactShowMoreText
                  lines={2}
                  more="View"
                  less="Show less"
                  expanded={false}
                  width={200}
                  truncatedEndingComponent={"... "}
                >
                  {original.message}
                </ReactShowMoreText>
              </div>
            )
          );
        }
      }),
      this.accessor("status", {
        header: "Status",
        cell: (info) => <LeadStatus info={info.row.original} type="request" onUpdate={onUpdate} />
      })
    ];
    if (is_student || is_studio) {
      columns.splice(2, 2);
    }
    if (is_brand) {
      columns.splice(
        2,
        0,
        this.accessor("required_by", {
          header: "Required By"
        })
      );
      columns.splice(5, 2);
    }
    return columns;
  }

  createColumnsForLeads(
    selectedResults,
    setSelectedResults,
    startIndex,
    onUpdateStatus,
    user,
    reason
  ) {
    const { is_brand } = user;

    var columns = [
      this.accessor((row, index) => index + 1, {
        id: "id",
        header: "S.No.",
        cell: (info) => {
          const {
            row: { original, index }
          } = info;
          return (
            <div className="d-flex justify-content-center">
              <CheckBox
                isChecked={selectedResults.filter((el) => el.id === original.id).length !== 0}
                label={index + 1 + startIndex}
                useReactHook={false}
                setChecked={() =>
                  setSelectedResults(this.updateSelectedResults(original, selectedResults))
                }
              />
            </div>
          );
        }
      }),
      this.accessor("name", {
        header: "Name"
      }),
      this.accessor("email", {
        header: "Email ID"
      }),
      this.accessor("mobile", {
        header: "Contact Number"
      }),
      this.accessor("reason", {
        header: "Connecting For?"
      }),
      this.accessor("message", {
        header: "Message",
        cell: (info) => {
          const {
            row: { original }
          } = info;
          return (
            original.message && (
              <div style={{ width: "200px" }}>
                <ReactShowMoreText
                  lines={2}
                  more="View"
                  less="Show less"
                  expanded={false}
                  width={200}
                  truncatedEndingComponent={"... "}
                >
                  {original.message}
                </ReactShowMoreText>
              </div>
            )
          );
        }
      }),
      this.accessor("status", {
        header: "Status",
        cell: (info) => (
          <LeadStatus info={info.row.original} type="lead" onUpdate={onUpdateStatus} />
        )
      })
    ];
    if (is_brand) {
      if (reason === "Quotation") {
        columns.splice(
          5,
          0,
          this.accessor("required_by", {
            header: "Required By"
          })
        );
        columns.splice(
          5,
          0,
          this.accessor("required_quantity", {
            header: "Required Quantity"
          })
        );
      }
      if (reason === "Sample") {
        columns.splice(
          5,
          0,
          this.accessor("required_by", {
            header: "Required By"
          })
        );
      }
      if (reason === "Appointment") {
        columns.splice(
          5,
          0,
          this.accessor("appointment_date", {
            header: "Appointment Date"
          })
        );
        columns.splice(
          6,
          0,
          this.accessor("appointment_type", {
            header: "Appointment Type"
          })
        );
      }
    }

    return columns;
  }

  createColumnsForProducts(
    columns,
    selectedResults,
    setSelectedResults,
    showMedia,
    onDelete,
    onEdit,
    editProduct,
    productAttributeChoices
  ) {
    var renderColumns = [
      this.accessor((row, index) => index + 1, {
        header: "Actions.",
        cell: (info) => {
          const row = info.row.original;
          return (
            <div className="d-flex justify-content-center">
              <CheckBox
                isChecked={selectedResults.filter((el) => el.id === row.id).length !== 0}
                useReactHook={false}
                setChecked={() =>
                  setSelectedResults(this.updateSelectedResults(info.row.original, selectedResults))
                }
              />
              <ThreeDotsDropdown
                options={[{ label: "Delete", onClick: () => onDelete(row) }]}
                color="black"
                size="25"
              />
            </div>
          );
        }
      }),
      this.accessor((row, index) => `media-${index + 1}`, {
        id: "media",
        header: "Media",
        isPlaceholder: true,
        cell: (info) => {
          const row = info.row.original;
          return (
            <div>
              {row && isArray(row.images_list) && !isEmpty(row.images_list) && (
                <div>
                  <Image
                    src={row.images_list[0]}
                    className="py-3 rounded"
                    style={{ width: "10rem", height: "10rem", objectFit: "contain" }}
                  />

                  <p
                    style={{ color: COLORS.PRIMARY_ORANGE }}
                    className="mb-0 fw-bold cursor-pointer"
                    onClick={() => showMedia(row.images_list)}
                  >
                    VIEW ALL MEDIA
                  </p>
                </div>
              )}
            </div>
          );
        }
      }),
      this.accessor("sku_number", {
        header: "sku_number"
      }),
      this.accessor("categories.parent.title", {
        header: "category"
      }),
      this.accessor("categories.sub_category.title", {
        header: "sub_category"
      }),
      this.accessor("categories.sub_sub_category.title", {
        header: "sub_sub_category"
      })
    ];

    for (var i = 0; i < columns.length; i++) {
      const currentColumn = columns[i]; // Create a separate variable inside the loop

      renderColumns.push(
        this.accessor(currentColumn, {
          header: currentColumn,
          cell: (info) => {
            // const updateData = (rowIndex, columnId, value) => {
            //   setData((old) => {
            //     return old.map((row, index) => {
            //       if (index === rowIndex) {
            //         return {
            //           ...old[rowIndex],
            //           [columnId]: value
            //         };
            //       }
            //       return row;
            //     });
            //   });
            // };
            return (
              <ProductRenderElement
                onEdit={onEdit}
                currentColumn={currentColumn}
                row={info.row.original}
                editProduct={editProduct}
                productAttributeChoices={productAttributeChoices}
              />
            );
          }
        })
      );
    }

    return renderColumns;
  }
}

function ProductRenderElement({
  onEdit,
  currentColumn,
  row,
  editProduct,
  productAttributeChoices
}) {
  const [edit, setEdit] = React.useState(false);
  const showMoreRef = React.useRef(null);
  const inputRef = React.useRef();
  const [value, setValue] = React.useState(
    editProduct[row.id] && editProduct[row.id][currentColumn]
      ? editProduct[row.id][currentColumn]
      : row[currentColumn]
  );
  const isChoice = Object.keys(productAttributeChoices).includes(currentColumn);
  const isList = isValidListOfStrings(value);

  React.useEffect(() => {
    if (edit === true && inputRef && inputRef.current && isFunction(inputRef.current.focus)) {
      inputRef.current.focus();
    }
  }, [edit]);

  const onBlur = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (value !== row[currentColumn]) {
      onEdit(row.id, currentColumn, value);
      // updateData(info.row.index, currentColumn, value);
    }
    setEdit(false);
  };
  function EditIcon() {
    return (
      <div
        className="d-flex justify-content-center position-absolute"
        style={{ top: 0, right: -20 }}
      >
        <EditDeleteIcons onEdit={() => setEdit(true)} />
      </div>
    );
  }

  return edit === true ? (
    <div>
      {isChoice ? (
        <Dropdown
          options={productAttributeChoices[currentColumn]}
          value={value}
          textCapitalizeMenuItem={false}
          onClick={(val) => setValue(val)}
          label={`Select`}
        />
      ) : isList ? (
        <CreatableSelect
          isMulti
          value={
            value
              ? JSON.parse(value.replace(/'/g, '"')).map((el) => ({ value: el, label: el }))
              : []
          }
          onChange={(newValue) => setValue(JSON.stringify(newValue.map((el) => el.value)))}
        />
      ) : isBoolean(value) ? (
        <Form.Check checked={value} onChange={() => setValue(!value)} />
      ) : (
        <Form.Control
          ref={inputRef}
          type={isNumber(value) ? "number" : "text"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      <ReactSVG
        src={GREEN_FILLED_TICK_ICON}
        onClick={onBlur}
        className="cursor-pointer"
        beforeInjection={(svg) => {
          svg.setAttribute("style", "width: 25");
          svg.setAttribute("style", "height: 25");
        }}
      />
    </div>
  ) : isString(value) ? (
    <div className="w-100 position-relative px-4">
      <ReactShowMoreText
        lines={2}
        ref={showMoreRef}
        less="Show less"
        anchorClass="cursor-pointer fw-bold"
        className="text-center"
        truncatedEndingComponent={"..."}
      >
        {isList ? JSON.parse(value.replace(/'/g, '"')).join("\n") : value}
      </ReactShowMoreText>
      <EditIcon />
    </div>
  ) : (
    <div className="position-relative">
      <div onClick={() => setEdit(true)} className="text-center w-100">
        {isBoolean(value) ? (value === true ? "Yes" : "No") : value || " "}
      </div>
      <EditIcon />
    </div>
  );
}

import React from "react";
import BaseModal from "../Base";
import { Modal, Stack } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IoMdClose } from "react-icons/io";
import { GREEN_FILLED_TICK_ICON } from "constants/icons";

export default function NeedHelpModal({ show, onHide }) {
  return (
    <BaseModal show={show} onHide={onHide} size="md">
      <Modal.Body>
        <IoMdClose size="30" className="ms-auto d-block cursor-pointer" onClick={onHide} />
        <Stack className="text-center py-4 px-3" gap={4}>
          <h6>Thank you for submitting your request! We will assist you at the earliest.</h6>
          <ReactSVG src={GREEN_FILLED_TICK_ICON} width="55" height="55" />
          <p>One of our team members will contact you soon!</p>
        </Stack>
      </Modal.Body>
    </BaseModal>
  );
}

import { CardTitle } from "components/Base/Card";
import { useNavigationInfo, useScrollToTop } from "hooks";
import React from "react";
import { Card, Image } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getContentAbsoluteUrl } from "utils";

export default function CategoryCard({ obj }) {
  const navigate = useNavigate();
  const { isCategories } = useNavigationInfo();
  const { scrollToTop } = useScrollToTop();

  const onClick = () => {
    scrollToTop();
    navigate(isCategories ? obj.slug : `/products/categories/${obj.slug}`);
  };

  return (
    <Card
      style={{ height: isMobile ? "175px" : "350px" }}
      onClick={onClick}
      className="border-0 shadow rounded-0 cursor-pointer"
    >
      <Card.Body className="p-0 h-100">
        <Image
          src={getContentAbsoluteUrl(obj.image)}
          className="w-100"
          style={{ height: isMobile ? "150px" : "300px", objectFit: "cover" }}
        />
        <div
          style={{ height: isMobile ? "25px" : "50px" }}
          className="d-flex align-items-center ms-2"
        >
          <CardTitle style={{ marginBottom: 0, ...(isMobile ? { fontSize: "12px" } : {}) }}>
            {obj.title}
          </CardTitle>
        </div>
      </Card.Body>
    </Card>
  );
}

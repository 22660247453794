import React from "react";
import OtpVerificationContainer from "../../Containers/OtpVerificationContainer";
import { isUndefined } from "lodash";
import BaseModal from "../Base";
import { validateEmail } from "../../../validators";
import UserProfileAPIClient from "../../../apiClients/Profile/User";
import { toast } from "react-toastify";
import { renderErrorToast } from "../../../utils";
import { useFormContext } from "react-hook-form";

export default function OtpVerification({ show, setShow, handleSubmit, handleResend }) {
  const { setValue } = useFormContext();
  const resendOtp = () => {
    const promise = new UserProfileAPIClient().sendOTP(show, true);
    toast.promise(promise, {
      pending: "Resending OTP",
      success: "OTP resent succesfully",
      error: renderErrorToast()
    });
  };

  return (
    <BaseModal
      show={!isUndefined(show)}
      onHide={() => {
        setShow(undefined);
        setValue("otp", "");
      }}
    >
      <OtpVerificationContainer
        usePhone={!validateEmail(show)}
        handleSubmit={handleSubmit}
        handleResend={handleResend || resendOtp}
      />
    </BaseModal>
  );
}

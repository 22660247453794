import React from "react";
import { Form } from "react-bootstrap";
import ReactHookInput from "../ReactHookInput";

export default function GSTINInput({ name = "gstin", required = false, disabled = false }) {
  return (
    <Form.Group>
      <Form.Label>GSTIN{required ? "*" : ""}</Form.Label>
      <ReactHookInput
        name={name}
        type="text"
        placeholder="22 ABCD1234A 1Z5"
        disabled={disabled}
        required={required}
      />
    </Form.Group>
  );
}

import BaseContentCard, { BaseContentCardDetailsSection, RenderSelect } from "components/Base/Card";
import { ThreeDotsDropdown } from "components/Buttons/ThreeDotsVertical";
import React from "react";
import { Col, Row } from "react-bootstrap";

export default function MobileDistributionNetwork({
  obj,
  handleSelection,
  isSelected,
  dropdownOptions
}) {
  return (
    <BaseContentCard>
      <div className="d-flex justify-content-end mt-3 me-2">
        <RenderSelect
          showSelect
          style={{ position: null }}
          isSelected={isSelected}
          handleSelection={handleSelection}
        />
        {dropdownOptions && (
          <ThreeDotsDropdown options={dropdownOptions} size={30} className="ms-2" />
        )}
      </div>
      <BaseContentCardDetailsSection>
        {[
          { title: "Firm Name", value: obj.firm_name },
          { title: "Network Type", value: obj.network_type },
          { title: "Email ID", value: obj.email },
          { title: "Contact Number", value: obj.contact_number },
          {
            title: "Address",
            value: `${obj.address_line1}, ${obj.city}, ${obj.state}, ${obj.country} - ${obj.pincode}`
          }
        ].map((el) => (
          <Row key={el.title} xs={2} className="my-2">
            <Col className="fw-bold">{el.title}</Col>
            <Col>{el.value}</Col>
          </Row>
        ))}
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

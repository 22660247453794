import React, { memo } from "react";
import BaseContentCard, {
  BaseContentCardDetailsSection,
  CardImage,
  CardTitle,
  RenderSelect
} from "components/Base/Card";
import { Card } from "react-bootstrap";
import DateParser from "date";
import { DATE_TEXT_STYLE, SUBTITLE_TEXT_STYLE } from "../default-styles";

function CatalogueCard({
  isSelected,
  obj,
  handleSelection,
  onClick,
  cardStyle,
  showSelect = true
}) {
  return (
    <BaseContentCard onClick={onClick} style={{ ...cardStyle }}>
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      <CardImage thumbnail={obj.thumbnail} alt={obj.title} />
      <BaseContentCardDetailsSection>
        <Card.Text className="mb-0 text-muted" style={DATE_TEXT_STYLE}>
          {DateParser.contentCardDateTimeString(obj.created)}
        </Card.Text>
        <CardTitle>{obj.title}</CardTitle>
        <Card.Subtitle className="text-muted" style={SUBTITLE_TEXT_STYLE}>
          {obj.type} Catalogue
        </Card.Subtitle>
        <p className="text-muted mb-1" style={SUBTITLE_TEXT_STYLE}>
          {obj.user}
        </p>
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

export default memo(CatalogueCard);

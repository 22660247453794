import { isEmpty, isString } from "lodash";

class URLParamsManager {
  constructor(searchParams) {
    this.searchParams = new URLSearchParams(searchParams);
  }

  objectToQueryString(obj) {
    const queryString = Object.entries(obj)
      .map(([key, value]) => {
        if (value && !isEmpty(value)) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
        delete obj[key];
      })
      .filter(Boolean)
      .join("&");
    this.searchParams = new URLSearchParams(queryString);
    return queryString;
  }
  addOrUpdateProperties(properties) {
    for (const key in properties) {
      if (Object.hasOwnProperty.call(properties, key)) {
        this.searchParams.set(key, properties[key]);
      }
    }
  }

  addOrUpdateProperty(key, value) {
    if (value) {
      this.searchParams.set(key, value);
    } else if (this.searchParams.has(key)) {
      this.deleteProperty(key);
    }
  }

  clearSearchParams() {
    this.searchParams = new URLSearchParams();
  }

  deleteProperty(key) {
    if (this.searchParams.has(key)) {
      this.searchParams.delete(key);
    }
  }

  getSearchParam(key) {
    return this.searchParams.get(key);
  }
  getSearchParams() {
    return this.searchParams;
  }
  reset(paramsToKeep = []) {
    if (isString(paramsToKeep)) {
      paramsToKeep = [paramsToKeep];
    }
    const currentParams = Array.from(this.searchParams.keys());
    const paramsToDelete = currentParams.filter((param) => !paramsToKeep.includes(param));
    for (const param of paramsToDelete) {
      this.deleteProperty(param);
    }
  }

  getSearchParamsAsObject() {
    const paramsObject = {};
    for (const [key, value] of this.searchParams) {
      paramsObject[key] = value;
    }
    return paramsObject;
  }
}

export default URLParamsManager;

import React from "react";
import ContentList from "../../components/Base/ContentList";
import { connect } from "react-redux";

function ContentProjects({ user }) {
  return <ContentList type="project" initialFilters={{ user: user.id }} />;
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(ContentProjects);

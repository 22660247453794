import React, { useState } from "react";
import { Image, Stack } from "react-bootstrap";
import ShareButton from "../../components/Buttons/Share";
import FavouriteButton from "../../components/Buttons/Favourite";
import ContentFactory from "../../apiClients/ContentFactory";
import { connect, useSelector } from "react-redux";
import ACTION_TYPES from "../../constants/action-types";
import { PINNED_ICON, PIN_ICON, SAVED_ICON, SAVE_ICON } from "../../constants/icons";
import MaxPinsReachedModal from "../Modals/MaxPinsReached";
import { isArray, isEmpty } from "lodash";
import { useNavigationInfo } from "../../hooks/route";
import { RenderIdeationBoardIcon } from "components/Base/Card";

function EngagementActions({
  obj,
  type,
  onSuccess,
  pinnedBlogs,
  getPinnedBlogs,
  buttonStyle,
  showIdeationBoardIcon = false,
  showPins = false
}) {
  const user = useSelector((state) => state.authReducer.user);
  const { isFavourites } = useNavigationInfo();
  const client = ContentFactory.createRequest(type);
  const { id, is_liked, is_pinned } = obj;
  const [showUnpinWarning, setShowUnpinWarning] = useState(false);
  var shareUrl;
  if (isFavourites) {
    if (type === "image") {
      shareUrl = `${window.location.origin}/learn/images/${id}`;
    } else if (type === "video") {
      shareUrl = `${window.location.origin}/learn/videos/${id}`;
    } else if (type === "thesis") {
      shareUrl = `${window.location.origin}/learn/thesis/${id}`;
    }
  }
  const like = () => client.like(id).then(onSuccess);
  var options = [
    {
      src: is_liked ? SAVED_ICON : SAVE_ICON,
      onClick: like
    },
    {
      component: <FavouriteButton type={type} obj={obj} onSuccess={onSuccess} />
    },
    {
      component: <ShareButton url={shareUrl} />
    }
  ];
  if (showIdeationBoardIcon) {
    options.push({
      component: (
        <RenderIdeationBoardIcon
          type={type}
          obj={obj}
          onAddedToIdeationBoard={onSuccess}
          user={user}
          buttonStyle={buttonStyle}
          width="2.5rem"
          height="2.5rem"
        />
      )
    });
  }
  if (showPins) {
    options.push({
      src: is_pinned ? PINNED_ICON : PIN_ICON,
      onClick: () => {
        if (
          isArray(pinnedBlogs) &&
          !isEmpty(pinnedBlogs) &&
          pinnedBlogs.length === 3 &&
          !is_pinned
        ) {
          setShowUnpinWarning(true);
        } else {
          (is_pinned ? client.deletePin(obj.id) : client.pin(obj.id))
            .then(getPinnedBlogs)
            .then(onSuccess);
        }
      }
    });
  }

  return (
    <Stack direction="horizontal" gap={3}>
      {options.map((action, index) => (
        <div key={`engagement-action-${index}`} style={{ width: "2.5rem", height: "2.5rem" }}>
          {action.component || (
            <Image
              className="cursor-pointer w-100 h-100 object-fit-contain"
              src={action.src}
              onClick={action.onClick}
            />
          )}
        </div>
      ))}
      <MaxPinsReachedModal show={showUnpinWarning} onHide={() => setShowUnpinWarning(false)} />
    </Stack>
  );
}
const mapStateToProps = (state) => ({ pinnedBlogs: state.contentReducer.blogs.pinnedBlogs });

const mapDispatchToProps = (dispatch) => ({
  getPinnedBlogs: () => dispatch({ type: ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_REQUEST })
});

export default connect(mapStateToProps, mapDispatchToProps)(EngagementActions);

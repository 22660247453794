import React from "react";
import ContentList from "../../components/Base/ContentList";
import { connect } from "react-redux";

function ContentVideos({ user }) {
  return <ContentList type="video" initialFilters={{ user: user.id }} addModalSize="lg" />;
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(ContentVideos);

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProfileDetailsContainer from "../../../../components/Containers/ProfileDetails";
import BasicDetailsForm from "../../../../forms/Brand/BasicDetails";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../hooks";
import { BrandBasicDetailsAPI } from "../../../../apiClients/Profile/Brand/ProfileDetails";
import ContentHeader from "components/Base/ContentHeader";

function BasicDetails() {
  const user = useSelector((state) => state.authReducer.user);
  const { getUserInfo } = useAuth();
  const [loading, setLoading] = useState(true);
  var defaultValues = {
    email: user.email,
    secondary_email: user.secondary_email,
    secondary_mobile: user.secondary_mobile
  };
  if (user) {
    if (user.name) {
      defaultValues["name"] = user.name;
    }
    if (user.mobile) {
      defaultValues["phone"] = user.mobile;
    }
  }

  const methods = useForm({
    reValidateMode: "onChange",
    defaultValues
  });

  const { setValue } = methods;

  const handleSetValues = (data) => {
    [
      "about",
      "country",
      "address_line1",
      "pincode",
      "website_link",
      "market_capitalization",
      "contact_person_first_name",
      "contact_person_last_name"
    ].forEach((el) => data[el] && setValue(el, data[el]));
    ["company_type", "establishment_year", "city", "state"].forEach(
      (el) =>
        data[el] &&
        setValue(el, {
          value: data[el],
          label: data[el]
        })
    );
  };
  const client = new BrandBasicDetailsAPI();

  const getBasicDetails = () => client.read();

  useEffect(() => {
    Promise.all([getBasicDetails(), getUserInfo()]).then((result) => {
      handleSetValues(result[0]);
      setLoading(false);
    });
  }, []);

  return (
    <ProfileDetailsContainer loading={loading} methods={methods}>
      <ContentHeader title="Basic Details" doNotShowSubtitle />
      <BasicDetailsForm handleSetValues={handleSetValues} />
    </ProfileDetailsContainer>
  );
}

export default BasicDetails;

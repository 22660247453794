const RESUME_CONSTANTS = {
  EMPLOYMENT_TYPES: [
    { value: "Full Time", label: "Full Time" },
    { value: "Contract", label: "Contract" },
    { value: "Part Time", label: "Part Time" },
    { value: "Freelancer", label: "Freelancer" },
    { value: "Intern", label: "Intern" },
    { value: "Volunteer", label: "Volunteer" }
  ]
};

export default RESUME_CONSTANTS;

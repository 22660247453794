import React from "react";
import { Stack } from "react-bootstrap";
import { BACK_ICON } from "../../../constants/icons";
import { ReactSVG } from "react-svg";
import { isFunction } from "lodash";
import { useNavigate } from "react-router-dom";

export default function BackButton({ onBack, children, doNotShowText }) {
  const navigate = useNavigate();
  const handleBack = () => {
    if (isFunction(onBack)) {
      onBack();
    } else {
      navigate(-1);
    }
  };
  return (
    <Stack direction="horizontal" gap={3} onClick={handleBack} className="cursor-pointer">
      <ReactSVG src={BACK_ICON} />
      <div className="fw-bold">{children ? children : doNotShowText ? null : "Back"}</div>
    </Stack>
  );
}

import { Navigate, Route, Routes } from "react-router-dom";
import IdeationBoard from "../../screens/UserContent/IdeationBoard";
import MoodBoard from "../../screens/UserContent/MoodBoard";
import { IDEATION_BOARD_UNFILLED } from "../../constants/icons";

const IDEATION_BOARD_ROUTES = (
  <Routes>
    <Route path=":id" element={<IdeationBoard />} />
    <Route path=":id/mood-board/:moodBoardId" element={<MoodBoard />} />
    <Route path="*" element={<Navigate to={"/my-activity/ideation-board/create"} replace />} />
  </Routes>
);

export default {
  label: "My Ideation Board",
  path: "/ideation-board/*",
  icon: IDEATION_BOARD_UNFILLED,
  component: IDEATION_BOARD_ROUTES,
  menuPath: "/my-activity/ideation-board/create",
  includeDefaultPath: true
};

import { isFunction } from "lodash";
import React from "react";
import { ReactSVG } from "react-svg";
import { DELETE_ICON, EDIT_ICON } from "../../constants/icons";

export default function EditDeleteIcons({ onDelete, onEdit, size = 25 }) {
  return (
    (isFunction(onDelete) || isFunction(onEdit)) && (
      <div className="d-flex">
        {[
          { src: EDIT_ICON, onClick: onEdit, show: isFunction(onEdit) },
          { src: DELETE_ICON, onClick: onDelete, show: isFunction(onDelete) }
        ].map(
          (icon, index) =>
            icon.show && (
              <ReactSVG
                key={index}
                src={icon.src}
                width={10}
                className={`me-3 cursor-pointer${index === 0 ? "" : " cursor-pointer"}`}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", `width: ${size}`);
                  svg.removeAttribute("height");
                }}
                onClick={icon.onClick}
              />
            )
        )}
      </div>
    )
  );
}

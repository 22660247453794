import React, { useEffect, useState } from "react";
import BaseContainer from "../../../components/Containers/BaseContainer";
import { FormProvider, useForm } from "react-hook-form";
import AddJobForm from "../../../forms/Content/AddJob";
import { useParams } from "react-router-dom";
import ContentFactory from "../../../apiClients/ContentFactory";
import { isEmpty, isObject, isString, isUndefined } from "lodash";
import LoadingContainer from "../../../components/Containers/Loading";
import DateParser from "../../../date";
import { isMobile } from "react-device-detect";
import ContentHeader from "components/Base/ContentHeader";

export default function Add() {
  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue } = methods;
  const { id } = useParams();
  const [loading, setLoading] = useState(!isUndefined(id));

  useEffect(() => {
    if (id) {
      ContentFactory.createRequest("job")
        .read(id)
        .then((result) => {
          if (isObject(result)) {
            setValue("urgently_hiring", result.urgently_hiring);
            result.valid_till &&
              setValue("valid_till", DateParser.parseFromYYYYMMDD(result.valid_till));
            const isRange = result.salary_type === "Range";
            if (isRange) {
              setValue("is_range", isRange);
              setValue("salary_min_amount", result.salary_min_amount);
              setValue("salary_max_amount", result.salary_max_amount);
            }
            const isFixed = result.salary_type === "Fixed";
            if (isFixed) {
              setValue("is_fixed_amount", true);
              setValue("fixed_amount", result.fixed_amount);
            }
            const isNotDisclosed = result.salary_type === "Not Disclosed";
            if (isNotDisclosed) {
              setValue("is_not_disclosed", isNotDisclosed);
            }
            ["title", "vacancies", "email_address_to_receive_response", "description"].forEach(
              (el) => setValue(el, result[el])
            );
            [
              "category",
              "posted",
              "position",
              "employment_type",
              "location",
              "workplace_type",
              "min_experience_required",
              "city",
              "state"
            ].map(
              (el) =>
                isString(result[el]) &&
                !isEmpty(result[el]) &&
                setValue(el, { value: result[el], label: result[el] })
            );
            [
              "management_skills",
              "personal_skills",
              "core_competencies",
              "questions_to_ask",
              "technical_skills"
            ].map((el) =>
              setValue(
                el,
                result[el].map((le) => ({
                  value: le,
                  label: le
                }))
              )
            );
          }
        })
        .then(() => setLoading(false));
    }
  }, []);

  return (
    <BaseContainer className="py-4">
      <LoadingContainer loading={loading}>
        <ContentHeader title="Create New Job" subTitle="Create your job here" />
        <div className={`w-${isMobile ? 100 : 75}`}>
          <FormProvider {...methods}>
            <AddJobForm id={id} />
          </FormProvider>
        </div>
      </LoadingContainer>
    </BaseContainer>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Col, Row, Stack, Table } from "react-bootstrap";
import { isArray, isEmpty, isNull, isObject, isUndefined } from "lodash";
import { SPECIFICATIONS_DISPLAY } from "./constants";
import Gallery from "components/Gallery";
import ContentFactory from "apiClients/ContentFactory";
import { Link, useParams } from "react-router-dom";
import ProductCard from "components/Cards/Product";
import ShareButton from "components/Buttons/Share";
import { useSelector } from "react-redux";
import ResumeAccordion from "components/Accordions/ResumeAccordion";
import COLORS from "constants/colors";
import ConnectModal from "components/Modals/Connect";
import { ReactSVG } from "react-svg";
import { OPEN_IN_NEW } from "constants/icons";
import FavouriteButton from "components/Buttons/Favourite";
import { formatPrice } from "utils";

const PriceDisplay = ({ detail }) => {
  const { offer_price, maximum_retail_price, discount_percentage } = detail;
  const hasDiscount = offer_price && offer_price < maximum_retail_price;

  return (
    <div>
      {hasDiscount ? (
        <Stack direction="horizontal" gap={3}>
          <span className="fw-bold">Rs. {formatPrice(offer_price)}</span>
          <span style={{ textDecoration: "line-through", fontSize: "90%" }} className="text-muted">
            Rs. {formatPrice(maximum_retail_price)}
          </span>
          <span style={{ color: COLORS.PRIMARY_ORANGE }} className="fw-bold">
            {discount_percentage}% OFF
          </span>
        </Stack>
      ) : (
        <div>
          <span>Rs. {formatPrice(maximum_retail_price)}</span>
        </div>
      )}
    </div>
  );
};

export default function ProductDetail({ detail, onAction }) {
  const [showConnect, setShowConnect] = useState(false);
  const [more, setMore] = useState([]);
  const client = ContentFactory.createRequest("product");
  const { productSlug } = useParams();
  const user = useSelector((state) => state.authReducer.user);

  const noMoreRowCols = 6;

  const getMoreProducts = () =>
    client
      .list({
        exclude_slug: productSlug,
        user__name: detail.brand_name,
        page_size: noMoreRowCols * 2
      })
      .then(setMore);

  useEffect(() => {
    getMoreProducts();
  }, []);

  function SpecificationsTable() {
    const initialKeysPresent = SPECIFICATIONS_DISPLAY.filter(
      (key) => !isUndefined(detail[key]) && !isNull(detail[key])
    );
    const [keysPresent] = useState(initialKeysPresent);
    const processedKeys = [];

    const renderRows = () => {
      return keysPresent.map((key) => {
        const uomKey = `${key}_uom`;

        const hasUOM = uomKey in detail;

        const value = detail[key];

        const displayValue = hasUOM ? `${value} ${detail[uomKey]}` : value;

        if (hasUOM) {
          processedKeys.push(uomKey);
        }

        if (!processedKeys.includes(key)) {
          return (
            <tr key={key} className="py-3">
              <td className="text-capitalize border-0">{key.replace(/_/g, " ")}</td>
              <td className="border-0">{displayValue}</td>
            </tr>
          );
        }

        return null;
      });
    };
    return (
      <Table>
        <tbody>{renderRows()}</tbody>
      </Table>
    );
  }

  const BulkOrderTable = () => {
    const bulkOrderKeys = [
      "bulk_order_quantity_1",
      "discount_on_offer_price_bulk_oq1",
      "bulk_order_quantity_2",
      "discount_on_offer_price_bulk_oq2"
    ];

    const renderRows = () => {
      const rows = [];
      for (let i = 0; i < bulkOrderKeys.length; i += 2) {
        const quantityKey = bulkOrderKeys[i];
        const discountKey = bulkOrderKeys[i + 1];

        const quantity = detail[quantityKey];
        const discount = detail[discountKey];

        const offer = detail.offer_price || detail.maximum_retail_price;
        const pricePerUnit = offer * (1 - discount / 100);

        rows.push(
          <tr key={quantityKey} className="border-0">
            <td className="text-capitalize border-0 py-3">Bulk OQ {i / 2 + 1}</td>
            <td className="border-0 py-3">{quantity}</td>
            <td className="border-0 py-3">{discount}%</td>
            <td className="border-0 py-3">Rs. {formatPrice(pricePerUnit)}</td>
          </tr>
        );
      }

      return rows;
    };

    return (
      <Table bordered striped responsive className="border">
        <thead>
          <tr className="border-0">
            <th className="border-0">Bulk Order</th>
            <th className="border-0">Quantity</th>
            <th className="border-0">Discount</th>
            <th className="border-0">Price Per Unit</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    );
  };
  return (
    <div>
      <div className="border mt-4 rounded">
        <Row className="py-4 px-2" xs={1} sm={2}>
          <Col>
            <div className="w-100">
              <Gallery images={detail.images_list} />
              <p className="text-muted text-center mt-3" style={{ marginLeft: "10%" }}>
                Disclaimer - images may differ from the actual product
              </p>
            </div>
          </Col>
          <Col>
            <Stack gap={2}>
              <Stack gap={2}>
                <Stack direction="horizontal" className="justify-content-between">
                  <div className="w-100">
                    <div className="d-flex justify-content-between w-100">
                      <h4 className="mb-0">{detail.product_name}</h4>
                      <Stack direction="horizontal" className="me-3" gap={3}>
                        <div style={{ width: "50%", height: "2rem" }}>
                          <FavouriteButton type="product" obj={detail} onSuccess={onAction} />
                        </div>
                        <div style={{ width: "50%", height: "2rem" }}>
                          <ShareButton />
                        </div>
                      </Stack>
                    </div>
                    <Link
                      to={`/profiles/${detail.user_details.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Stack
                        direction="horizontal"
                        className="align-items-center mt-2 cursor-pointer"
                        gap={2}
                      >
                        <h6 className="mb-0 fw-bold" style={{ color: COLORS.PRIMARY_ORANGE }}>
                          {detail.brand_name}
                        </h6>
                        <ReactSVG src={OPEN_IN_NEW} style={{ width: "1rem" }} />
                      </Stack>
                    </Link>
                  </div>
                </Stack>
              </Stack>
              <PriceDisplay detail={detail} />
              <p className="mb-0">Country of Origin: {detail.country_of_origin}</p>
              <p>
                Warranty : {detail.warranty} {detail.warranty_uom}
              </p>
            </Stack>
            <BulkOrderTable />
            {user && detail.user_details.id !== user.id && (
              <Button className="px-4 my-4" onClick={() => setShowConnect(true)}>
                CONNECT WITH SELLER
              </Button>
            )}
            <hr />
            <ResumeAccordion
              title="Specifications"
              titleStyle={{ fontSize: "1rem" }}
              eventKey="0"
              openByDefault={false}
            >
              <div className="ms-3">
                <SpecificationsTable />
              </div>
            </ResumeAccordion>
            {!isEmpty(detail.product_description) && (
              <ResumeAccordion
                title="Product Description"
                titleStyle={{ fontSize: "1rem", paddingTop: "1rem" }}
                eventKey="0"
                openByDefault={false}
              >
                <p className="ms-4">{detail.product_description}</p>
              </ResumeAccordion>
            )}
          </Col>
        </Row>
      </div>
      <h6 className="pb-4 mt-5">More Products From Same Provider</h6>
      <Row xs={2} sm={noMoreRowCols}>
        {isObject(more) &&
          isArray(more.result) &&
          more.result.map((el) => (
            <Col key={el.id} className="mb-4">
              <ProductCard
                obj={el}
                onBookmarked={getMoreProducts}
                onAddedToIdeationBoard={getMoreProducts}
              />
            </Col>
          ))}
      </Row>
      <ConnectModal
        show={showConnect}
        onHide={() => setShowConnect(false)}
        user={detail.user_details}
      />
    </div>
  );
}

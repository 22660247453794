import React from "react";
import { Modal } from "react-bootstrap";
import BaseModal from "../Base";
import UploadBannerImageForm from "../../../forms/UploadBannerImage";

export default function UploadBannerImageModal({ show, onHide }) {
  return (
    <BaseModal centered show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton className="fw-bold" style={{ fontSize: "1.5rem" }}>
        Choose Background Image
      </Modal.Header>
      <UploadBannerImageForm onHide={onHide} />
    </BaseModal>
  );
}

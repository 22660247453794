import "./styles.scss";
import { isArray, isEmpty } from "lodash";
import React from "react";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

export default function ProductCategoriesMultilevelFilter({ categories, onClick }) {
  return (
    <Menu
      menuButton={
        <MenuButton className="border-0 bg-transparent custom-multilevel-dropdown">
          Categories
        </MenuButton>
      }
      boundingBoxPadding="100"
      overflow="auto"
      position="auto"
    >
      {categories.map((el, index) => {
        return isArray(el.children) && !isEmpty(el.children) ? (
          <SubMenu
            label={el.title}
            position="auto"
            overflow="auto"
            key={`${index}-${el.title}-${el.id}`}
          >
            {el.children.map((child) =>
              isArray(child.children) && !isEmpty(child.children) ? (
                <SubMenu label={child.title} key={child.title} position="auto" overflow="auto">
                  {isArray(child.children) &&
                    !isEmpty(child.children) &&
                    child.children.map((subChild) => (
                      <MenuItem
                        key={`${index}-${subChild.title}-${subChild.id}`}
                        onClick={() =>
                          onClick({ parent: el, subParent: child, category: subChild }, 2)
                        }
                      >
                        {subChild.title}
                      </MenuItem>
                    ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={`${index}-${el.title}-${el.id}`}
                  onClick={() => onClick({ parent: el, category: child }, 1)}
                >
                  {child.title}
                </MenuItem>
              )
            )}
          </SubMenu>
        ) : (
          <MenuItem
            key={`${index}-${el.title}-${el.id}`}
            onClick={() => onClick({ category: el }, 0)}
          >
            {el.title}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

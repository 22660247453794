import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import RequestsClient from "apiClients/Requests";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Phone from "components/Inputs/Phone";
import Email from "components/Inputs/Email";
import Select from "components/Inputs/Select";
import CustomDate from "components/Inputs/CustomDate";
import DateParser from "date";
import { isMobile } from "react-device-detect";

export default function ConnectForm({ user }) {
  const { handleSubmit, setValue, watch } = useFormContext();
  const [sent, setSent] = useState(false);
  const loggedInUser = useSelector((state) => state.authReducer.user);

  const onSubmit = (data) => {
    if (data.required_by) {
      data["required_by"] = DateParser.formatDateToYYYYMMDD(data.required_by);
    }
    if (data.appointment_date) {
      data["appointment_date"] = DateParser.formatDateToYYYYMMDD(data.appointment_date);
    }
    new RequestsClient()
      .createRequest({
        ...data,
        reason: data.reason.value,
        appointment_type: data.appointment_type ? data.appointment_type.value : undefined,
        recipient: user.id
      })
      .then(() => setSent(true));
  };

  useEffect(() => {
    const { name, mobile, email } = loggedInUser;
    setValue("name", name);
    setValue("mobile", mobile);
    setValue("email", email);
  }, [loggedInUser]);

  function getConnectingForDropdownOptions() {
    if (user.is_student) {
      return [
        { value: "Collaboration", label: "Connecting for collaboration" },
        { value: "Quotation", label: "Architectural services quotes" },
        { value: "Project", label: "Want to meet & discuss a project" },
        { value: "Job Offers", label: "Job offer" },
        {
          value: "Others",
          label: "Others (Please specify in message box)"
        }
      ];
    }
    if (user.is_studio) {
      return [
        { value: "Quotation", label: "Architectural services quotes" },
        { value: "Project", label: "Want to meet & discuss a project" },
        { value: "Collaboration", label: "Connecting for collaboration" },
        {
          value: "Others",
          label: "Others (Please specify in message box)"
        }
      ];
    }
    if (user.is_brand) {
      return [
        { value: "Quotation", label: "Request For Quotation" },
        { value: "Sample", label: "Request For Sample" },
        { value: "Appointment", label: "Request For Appointment" }
      ];
    }
  }

  const reasonValue = watch("reason");

  useEffect(() => {
    ["required_quantity", "required_by", "appointment_date", "appointment_type"].forEach((el) =>
      setValue(el, null)
    );
  }, [reasonValue]);

  useEffect(() => {
    if (reasonValue && reasonValue.value === "Quotation" && reasonValue.value === "Sample") {
      setValue("required_by", new Date());
    }
  }, [reasonValue]);

  return sent ? (
    <h6 className="text-center pb-4">
      Your connection request has been successfully sent. You can review it from the “My Request”
      section
    </h6>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Row xs={1} sm={1}>
          <Col>
            <Form.Group>
              <Form.Label>Full Name*</Form.Label>
              <ReactHookInput name="name" required />
            </Form.Group>
          </Col>
          <Col className={isMobile ? "mt-3" : ""}>
            <Form.Group>
              <Form.Label>Contact*</Form.Label>
              <Phone name="mobile" required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Email*</Form.Label>
          <Email name="email" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Connecting For*</Form.Label>
          <Select options={getConnectingForDropdownOptions()} name="reason" required />
        </Form.Group>
        {reasonValue && reasonValue.value === "Quotation" && (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Required Quantity*</Form.Label>
                <ReactHookInput
                  name="required_quantity"
                  required
                  placeholder="MOQ1 (Upto 50 boxes)"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Required By</Form.Label>
                <CustomDate name="required_by" format="dd/MM/yyyy" />
              </Form.Group>
            </Col>
          </Row>
        )}
        {reasonValue && reasonValue.value === "Sample" && (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Required By</Form.Label>
                <CustomDate name="required_by" format="dd/MM/yyyy" minDate={new Date()} />
              </Form.Group>
            </Col>
          </Row>
        )}
        {reasonValue && reasonValue.value === "Appointment" && (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Appointment Type*</Form.Label>
                <Select
                  name="appointment_type"
                  required
                  options={[
                    { value: "Online", label: "Online" },
                    { value: "Offline", label: "Offline" }
                  ]}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <CustomDate name="appointment_date" format="dd/MM/yyyy" minDate={new Date()} />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <ReactHookInput as="textarea" name="message" />
        </Form.Group>
        <div>
          <Button type="submit" className="w-25 d-block ms-auto">
            SEND
          </Button>
        </div>
      </Stack>
    </Form>
  );
}

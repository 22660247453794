import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useSearchParams } from "hooks/searchParams";
import ContentFactory from "apiClients/ContentFactory";
import LoadingContainer from "components/Containers/Loading";
import ProfileCard from "components/Cards/Profile";
import TypeFilter from "components/TypeFilter";
import SearchList from "components/Base/SearchList";
import { isMobile } from "react-device-detect";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

const PROFILE_TYPES = [
  { value: "brand", label: "Brand" },
  { value: "studio", label: "Studio" },
  { value: "professional", label: "Professional" }
];

function Profiles() {
  const { getKey } = useSearchParams();
  const typeFilterName = "group";
  const filterValue = getKey(typeFilterName);
  const [topProfiles, setTopProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const client = ContentFactory.createRequest("profile");

  useEffect(() => {
    handleGetTopProfiles().then(() => setLoading(false));
  }, [filterValue]);

  const handleGetTopProfiles = () =>
    client
      .list({ ordering: "-top", page: 1, page_size: 10 })
      .then((result) => setTopProfiles(result.result));

  return (
    <div className="w-100">
      <LoadingContainer loading={loading}>
        <div className="mt-4 mx-2">
          <h6 className="mb-3">Top Profiles</h6>
          <Carousel
            className="pb-4"
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 2000 },
                items: 7
              },
              desktop: {
                breakpoint: { max: 2000, min: 1024 },
                items: 5
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2
              }
            }}
          >
            {topProfiles.map((el) => (
              <div
                key={`top-profiles-${el.id}`}
                className={`me-${isMobile ? 1 : 4}`}
                style={{ height: isMobile ? "175px" : "320px" }}
              >
                <ProfileCard
                  obj={el}
                  onBookmarked={handleGetTopProfiles}
                  containerClassName="shadow border-0"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <Stack gap={3}>
          <TypeFilter
            types={[{ value: null, label: "All" }, ...PROFILE_TYPES]}
            filterName={typeFilterName}
          />
          <Container>
            <SearchList
              type="profile"
              CardComponent={ProfileCard}
              cardProps={{ showSelect: false }}
              searchPlaceholder="Search Profiles..."
            />
          </Container>
        </Stack>
      </LoadingContainer>
    </div>
  );
}

export default withMobileOnlySidebar(Profiles);

import React from "react";
import ContentList from "../../../components/Base/ContentList";
import { connect } from "react-redux";

const ContentJobs = ({ user }) => (
  <ContentList
    type="job"
    initialFilters={{ user: user.id }}
    doNotShowAdd
    cardProps={{ showSelect: false }}
  />
);

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(ContentJobs);

import React from "react";
import { useSelector } from "react-redux";
import { Routes } from "react-router-dom";
import withSidebar from "../../components/Containers/Sidebar";
import getStudentProfileRoutes from "./Student";
import STUDIO_PROFILE_ROUTES from "./Studio";
import BRAND_PROFILE_ROUTES from "./Brand";
import { getRoutes } from "../utils";

function MyProfileRoutes() {
  const user = useSelector((state) => state.authReducer.user);

  var profileRoutes = user.is_studio
    ? STUDIO_PROFILE_ROUTES
    : user.is_student
    ? getStudentProfileRoutes(user)
    : BRAND_PROFILE_ROUTES;

  return <Routes>{getRoutes(profileRoutes)}</Routes>;
}

export default withSidebar(MyProfileRoutes);

import React from "react";
import { Route, Routes } from "react-router-dom";
import Add from "../../screens/UserContent/Jobs/Add";
import { SIDEBAR_ICONS } from "../../constants/icons";
import Posted from "../../screens/UserContent/Jobs/Posted";
import Drafted from "../../screens/UserContent/Jobs/Drafted";
import UnderReview from "../../screens/UserContent/Jobs/UnderReview";
import Closed from "../../screens/UserContent/Jobs/Closed";

const POSTED_JOBS_ROUTES = (
  <Routes>
    <Route path="" element={<Posted />} />
    <Route path="/create-new-job" element={<Add />} />
    <Route path="/:id/edit" element={<Add />} />
  </Routes>
);

const DRAFT_JOBS_ROUTES = (
  <Routes>
    <Route path="" element={<Drafted />} />
    <Route path="/:id/edit" element={<Add />} />
  </Routes>
);

export default {
  label: "My Jobs",
  path: "my-jobs",
  subMenu: [
    {
      label: "Posted",
      path: "/my-jobs/posted/*",
      menuPath: "/my-activity/my-jobs/posted",
      component: POSTED_JOBS_ROUTES,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Drafted",
      path: "/my-jobs/drafted/*",
      menuPath: "/my-activity/my-jobs/drafted",
      component: DRAFT_JOBS_ROUTES,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Closed",
      path: "/my-jobs/closed",
      menuPath: "/my-activity/my-jobs/closed",
      component: <Closed />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Under Review",
      path: "/my-jobs/under-review",
      menuPath: "/my-activity/my-jobs/under-review",
      component: <UnderReview />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    }
  ],
  icon: SIDEBAR_ICONS.MY_JOBS_SIDEBAR_ICON,
  notFoundRoute: "/my-activity/my-jobs/drafted"
};

import React from "react";
import { ReactSVG } from "react-svg";
import { MAP_PIN_ICON } from "../../constants/icons";
import { Stack } from "react-bootstrap";
import EllipsisText from "components/EllipsisText";
import { isMobile } from "react-device-detect";

export default function Location({ children, style, iconStyle }) {
  return (
    <Stack gap={2} direction="horizontal" className="align-items-center w-100">
      <ReactSVG src={MAP_PIN_ICON} style={{ width: "18px", ...iconStyle }} />
      <EllipsisText
        numOfLines={isMobile ? 1 : 2}
        style={{ fontSize: "85%", width: "85%", ...style }}
      >
        {children}
      </EllipsisText>
    </Stack>
  );
}

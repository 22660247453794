import React, { useEffect, useRef } from "react";
import { Carousel } from "react-bootstrap";

export default function AutoPlayCarousel({ children, interval }) {
  const carouselRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next();
    }, interval);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Carousel ref={carouselRef} interval={null} pause={false}>
      {children}
    </Carousel>
  );
}

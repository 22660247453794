import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import AddressForm from "../../Address";
import { useFormContext } from "react-hook-form";
import { BrandDistributionNetworksAPI } from "apiClients/Profile/Brand/ProfileDetails";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Phone from "components/Inputs/Phone";
import Email from "components/Inputs/Email";
import Select from "components/Inputs/Select";
import { useScrollToTop } from "hooks";

export default function DistributionNetworksForm({ onSuccess, detail }) {
  const { setValue, reset } = useFormContext();
  const client = new BrandDistributionNetworksAPI();
  const { scrollToTop } = useScrollToTop();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (detail) {
      ["firm_name", "contact_number", "email", "country", "pincode", "address_line1"].forEach(
        (el) => setValue(el, detail[el])
      );
      ["network_type"].forEach((el) => setValue(el, { label: detail[el], value: detail[el] }));
      ["city", "state"].forEach((el) =>
        setValue(el, {
          value: detail[el],
          label: detail[el]
        })
      );
    }
  }, []);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      city: data.city.value,
      state: data.state.value,
      network_type: data.network_type.value
    };
    (detail ? client.update({ ...payload, id: detail.id }) : client.create(payload))
      .then(onSuccess)
      .then(reset)
      .then(() => scrollToTop());
  };

  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Firm Name*</Form.Label>
        <ReactHookInput name="firm_name" required />
      </Form.Group>
      <Form.Group>
        <Form.Label>Network Type*</Form.Label>
        <Select
          name="network_type"
          required
          options={["Dealer", "Distributor"].map((el) => ({ label: el, value: el }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Number*</Form.Label>
        <Phone name="contact_number" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Email ID*</Form.Label>
        <Email required />
      </Form.Group>
      <AddressForm />
    </DetailsFormContainer>
  );
}

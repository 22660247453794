import React from "react";
import { isNull } from "lodash";
import { useSearchParams } from "hooks/searchParams";
import Dropdown from "components/Dropdown";
import { CITIES } from "constants/states";

export default function CityFilter() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "city";

  const value = getKey(KEY);
  const state = getKey("state");

  return (
    <Dropdown
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
      value={value}
      options={!isNull(state) ? CITIES[state].map((el) => ({ value: el, label: el })) : []}
      label="City"
      disabled={isNull(state)}
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

import React, { useEffect, useState } from "react";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import ApplyJobForm from "../../../forms/ApplyJob";
import ContentFactory from "../../../apiClients/ContentFactory";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const resumeClient = ContentFactory.createRequest("resume");
const resumeFileClient = ContentFactory.createRequest("resume-file");

export default function ApplyJobModal({ job, show, onHide, onSuccess }) {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const methods = useForm({ reValidateMode: "onChange" });
  const [resumes, setResumes] = useState([]);
  const [resumeFiles, setResumeFiles] = useState([]);

  const getResumes = () => resumeClient.list().then(setResumes);

  const getResumeFiles = () => resumeFileClient.list().then(setResumeFiles);

  const getAllResumes = () => Promise.all([getResumes(), getResumeFiles()]);

  useEffect(() => {
    if (isLoggedIn) {
      getAllResumes();
    }
  }, [isLoggedIn]);

  return (
    <BaseModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Apply Now</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoggedIn ? (
          <FormProvider {...methods}>
            <ApplyJobForm
              job={job}
              resumes={resumes}
              resumeFiles={resumeFiles}
              onSuccess={onSuccess}
              getAllResumes={getAllResumes}
            />
          </FormProvider>
        ) : (
          <div className="text-center py-4">
            <h6 className="text-center">
              Please <Link to="/signip">register</Link> as a student to apply for this job
            </h6>
          </div>
        )}
      </Modal.Body>
    </BaseModal>
  );
}

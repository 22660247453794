import React, { useEffect, useState } from "react";
import LoadingContainer from "../../Containers/Loading";
import { Button, Col, Image, Modal, Row, Stack } from "react-bootstrap";
import Search from "../../Inputs/Search";
import FilterPills from "../../Filters/FilterPills";
import { useAuth, useNavigationInfo, useScrollToTop } from "../../../hooks";
import TotalResult from "../../TotalResult";
import Table from "../../Table";
import FavouriteFactory from "../../../apiClients/FavouriteFactory";
import ContentFactory from "../../../apiClients/ContentFactory";
import { useSearchParams } from "../../../hooks/searchParams";
import DOWNLOAD_ICON from "../../../assets/icons/download.png";
import REFRESH_ICON from "../../../assets/icons/refresh.png";
import PRINT_ICON from "../../../assets/icons/print.png";
import { CSVLink } from "react-csv";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { isArray, isEmpty, isNil, isString, isUndefined } from "lodash";
import TableColumnsFactory from "../../../factory/TableColumns";
import HandleMultipleDeletion from "../HandleMultipleDeletion";
import ConfirmDeletionWithProgressModal from "../../Modals/ConfirmDeletionWithProgressModal";
import GreyedAccordion from "../../Accordions/GreyedAccordion";
import BranchDetailsForm from "../../../forms/Brand/BranchDetails";
import DistributionNetworksForm from "../../../forms/Brand/DistributionNetworks";
import CSVHelpContainer from "../../../components/Containers/CSVHelpContainer";
import CSVUploader from "../../../components/Uploaders/CSVUploader";
import CSV_TEMPLATE_IDS from "../../../constants/csv-template-ids";
import BaseModal from "../../Modals/Base";
import COLORS from "../../../constants/colors";
import CityFilter from "../../Filters/CityFilter";
import StateFilter from "../../Filters/StateFilter";
import { ReactSVG } from "react-svg";
import NeedHelpModal from "../../Modals/NeedHelp";
import AddProductsModal from "../../Modals/AddProductsModal";
import ViewProductMediaModal from "../../Modals/ViewProductMediaModal";
import SortByFilter from "components/Filters/SortBy";
import { useSelector } from "react-redux";
import { downloadFile } from "utils";
import { isMobile } from "react-device-detect";
import { FROWN_ICON } from "constants/icons";
import EditProductModal from "components/Modals/EditProductModal";
import Api from "apiClients/api";
import "./styles.scss";
import CustomPagination from "components/Pagination";
import ProductCategoriesMultilevelFilter from "components/Filters/ProductCategoriesMultilevelFilter";
import EditableTable from "components/Table/Editable";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf"
  }
};

function SearchTable({
  title,
  placeholder,
  filterPillsOptions,
  type,
  FilterComponent,
  client,
  MobileCard,
  initialFilters = {}
}) {
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(undefined);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showAddProductsModal, setShowAddProductsModal] = useState(false);
  const [viewAllMedia, setViewAllMedia] = useState(undefined);
  const [columns, setColumns] = useState(undefined);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const page_size = 10;
  const user = useSelector((state) => state.authReducer.user);
  const [editProduct, setEditProduct] = useState({});
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
  const [productAttributeChoices, setProductAttributeChoices] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedProductCategory, setSelectedProductCategory] = useState(undefined);

  const { getUserInfo } = useAuth();
  const { isActivity, isFavourites } = useNavigationInfo();
  const { searchParamsAsObject, addKey, queryString, getKey, clear, deleteKey } = useSearchParams();

  const contentClient =
    client || (isFavourites ? FavouriteFactory : ContentFactory).createRequest(type);

  const columnsFactory = new TableColumnsFactory();

  const ordering = getKey("ordering");
  const category = getKey("category");
  const state = getKey("state");
  const city = getKey("city");

  const reason = getKey("reason");
  const { scrollToTop } = useScrollToTop();

  const getUserCategories = () =>
    ContentFactory.createRequest("user-categories")
      .list({ has_products: true })
      .then(setSelectedCategories);

  useEffect(() => {
    scrollToTop();
  }, [page]);

  useEffect(() => {
    if (!reason && user.is_brand && type === "lead") {
      addKey("reason", filterPillsOptions[0].value);
    }
  }, [reason]);

  const fetchData = (onSuccess, fetchPage, pageSize) =>
    contentClient
      .list({
        ...searchParamsAsObject,
        ...initialFilters,
        page: fetchPage || page,
        page_size: pageSize || page_size
      })
      .then(onSuccess)
      .then(() => setLoading(false))
      .catch((error) => console.error("Error fetching search results:", error));

  // useEffect(() => {
  //   if (!data || isEmpty(data)) {
  //     deleteKey("category");
  //   }
  // }, [data]);

  const handleGetSearch = () =>
    fetchData((result) => {
      setPage(1);
      setCount(result.count);
      setData(result.result);
    }, 1);

  const fetchPage = (pageNo) =>
    fetchData((result) => {
      setPage(pageNo);
      setCount(result.count);
      setData(result.result);
    }, pageNo);

  const getAttributeChoices = () =>
    Api.get("/nosql/choices/")
      .then((result) => result.data)
      .then(setProductAttributeChoices);

  useEffect(() => {
    if (type === "product") {
      setLoading(true);
      getAttributeChoices();
      getUserCategories();
    }
  }, [type, category]);

  useEffect(() => {
    if (isArray(selectedCategories) && !isEmpty(selectedCategories)) {
      if (!category) {
        const parent = selectedCategories[0];
        if (isArray(parent.children) && !isEmpty(parent.children)) {
          const sub = parent.children[0];
          setSelectedProductCategory(
            isArray(sub.children) && !isEmpty(sub.children) ? sub.children[0] : sub
          );
        }
      } else if (category && isUndefined(selectedProductCategory)) {
        for (var i = 0; i < selectedCategories.length; i++) {
          const parent = selectedCategories[i];
          if (isArray(parent.children) && !isEmpty(parent.children)) {
            for (var j = 0; j < parent.children.length; j++) {
              const sub = parent.children[j];
              if (sub && sub.slug === category) {
                setSelectedProductCategory(sub);
                break;
              } else {
                if (sub && isArray(sub.children) && !isEmpty(sub.children)) {
                  for (var k = 0; k < sub.children.length; k++) {
                    const leaf = sub.children[k];
                    if (leaf.slug === category) {
                      setSelectedProductCategory(leaf);
                      break;
                    }
                  }
                }
              }
            }
            deleteKey("category");
          }
        }
      }
    } else {
      setLoading(false);
      setColumns([]);
    }
  }, [selectedCategories]);

  console.log("Loading: ", loading, columns);

  useEffect(() => {
    if (selectedProductCategory && category !== selectedProductCategory.slug) {
      addKey("category", selectedProductCategory.slug);
    }
  }, [selectedProductCategory, category]);

  useEffect(() => {
    if (!ordering) {
      addKey("ordering", "-created");
    }
  }, [ordering]);

  useEffect(() => {
    setSelectedData([]);
  }, [queryString]);

  const updateSelectedResults = (value, selectedResults) => {
    var newSelectedResults = [...selectedResults];
    if (selectedResults.filter((el) => el.id === value.id).length === 0) {
      newSelectedResults.push(value);
    } else {
      newSelectedResults = [...selectedResults.filter((el) => el !== value)];
    }
    setSelectedData(newSelectedResults);
  };

  useEffect(() => {
    if (type == "request") {
      setColumns(
        columnsFactory.createColumnsForRequests(
          selectedData,
          setSelectedData,
          (page - 1) * page_size,
          () => fetchPage(page),
          user,
          () => fetchPage(page)
        )
      );
    } else if (type === "lead") {
      setColumns(
        columnsFactory.createColumnsForLeads(
          selectedData,
          setSelectedData,
          (page - 1) * page_size,
          () => fetchPage(page),
          user,
          reason
        )
      );
    } else if (type === "branch-detail") {
      setColumns(
        columnsFactory.createColumnForBasicDetails(
          selectedData,
          setSelectedData,
          (obj) => setShowEditModal(obj),
          (obj) => setShowDeleteModal(obj)
        )
      );
    } else if (type === "distribution-network") {
      setColumns(
        columnsFactory.createColumnForDistributionNetwork(
          selectedData,
          setSelectedData,
          (obj) => setShowEditModal(obj),
          (obj) => setShowDeleteModal(obj)
        )
      );
    } else if (type === "product") {
      if (
        category &&
        !isEmpty(selectedCategories) &&
        selectedProductCategory &&
        isString(selectedProductCategory.columns) &&
        !isEmpty(selectedProductCategory.columns)
      ) {
        setColumns(
          columnsFactory.createColumnsForProducts(
            selectedProductCategory.columns.split(","),
            selectedData,
            setSelectedData,
            setViewAllMedia,
            (obj) => setShowDeleteModal(obj),
            (id, columnName, value) => {
              const newEdit = { ...editProduct };
              if (Object.keys(editProduct).includes(id)) {
                newEdit[id] = { ...newEdit[id], [columnName]: value };
              } else {
                newEdit[id] = { [columnName]: value };
              }
              setEditProduct(newEdit);
            },
            editProduct,
            productAttributeChoices
          )
        );
      } else {
        if (category) {
          deleteKey(category);
        }
      }
    }
  }, [category, selectedData, page, editProduct, selectedProductCategory]);

  useEffect(() => {
    addKey("search", searchString);
  }, [searchString]);

  useEffect(() => {
    if (type !== "product" || category) {
      if (loading === false) {
        setLoading(true);
      }
      handleGetSearch();
    }
  }, [queryString]);

  const generateTableAndPrintPDF = (data, columns) => {
    const documentDefinition = {
      content: [
        { text: title, style: "header" },
        {
          table: {
            headerRows: 1,
            widths: columns.map(() => "auto"),
            body: [
              columns.map((column) => ({ text: column.label, style: "tableHeader" })),
              ...data.map((row) => columns.map((column) => row[column.key]))
            ]
          }
        }
      ],
      styles: {
        header: { fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
        tableHeader: { bold: true, fillColor: "#f2f2f2" }
      }
    };

    pdfMake.createPdf(documentDefinition).print();
  };

  const needHelp = () => contentClient.requestHelp().then(() => setShowHelpModal(true));

  const isDeleteButtonDisabled = selectedData.length === 0;
  const isSelectAllDisabled = isArray(data) && data.length === 0;
  const isAllSelected =
    isArray(selectedData) &&
    data.length > 0 &&
    selectedData.length > 0 &&
    selectedData.length === data.length;

  const handleSelectAll = () => {
    if (data.length === selectedData.length) {
      setSelectedData([]);
    } else {
      setSelectedData(data.map((result) => result));
    }
  };

  const onSuccessFormComplete = () => {
    setLoading(true);
    handleGetSearch().then(() => {
      getUserInfo();
      if (!isUndefined(showEditModal)) {
        setShowEditModal(undefined);
      }
      if (showAddModal === true) {
        setShowAddModal(false);
      }
      setLoading(false);
    });
  };

  const downloadBranchDetailsTemplate = () =>
    downloadFile(
      `${window.location.origin}/Branch Details Template.csv`,
      "Branch Details Template.csv"
    );

  const downloadDistributionNetworksTemplate = () =>
    downloadFile(
      `${window.location.origin}/Distribution Network Template.csv`,
      "Distribution Network Template.csv"
    );

  const EditForm = {
    "branch-detail": <BranchDetailsForm onSuccess={onSuccessFormComplete} detail={showEditModal} />,
    "distribution-network": (
      <DistributionNetworksForm onSuccess={onSuccessFormComplete} detail={showEditModal} />
    )
  };

  const EmptyComponent = {
    "branch-detail": (
      <div className="me-5 mt-4">
        <div>
          <GreyedAccordion
            defaultActiveKey={0}
            eventKey={0}
            title="Have Many Branches?"
            subTitle="Download our CSV file and input all your branch details in just minutes."
          >
            <CSVHelpContainer>
              <CSVUploader
                licenseKey={CSV_TEMPLATE_IDS.BRANCH_DETAILS}
                onSuccess={handleGetSearch}
                downloadCSV={downloadBranchDetailsTemplate}
              />
            </CSVHelpContainer>
          </GreyedAccordion>
        </div>
        <div>
          <GreyedAccordion
            defaultActiveKey={0}
            eventKey={0}
            title="One at a time"
            subTitle="Have only one or few branches, use this option."
          >
            {EditForm[type]}
          </GreyedAccordion>
        </div>
      </div>
    ),
    "distribution-network": (
      <div className="me-5 mt-4">
        <div>
          <GreyedAccordion
            defaultActiveKey={0}
            eventKey={0}
            title="Have Many Networks?"
            subTitle="Download our CSV file and input all your distribution networks in just minutes."
          >
            <CSVHelpContainer>
              <CSVUploader
                licenseKey={CSV_TEMPLATE_IDS.DISTRIBUTION_NETWORK}
                onSuccess={handleGetSearch}
                downloadCSV={downloadDistributionNetworksTemplate}
              />
            </CSVHelpContainer>
          </GreyedAccordion>
        </div>
        <div>
          <GreyedAccordion
            defaultActiveKey={0}
            eventKey={0}
            title="One at a time"
            subTitle="Have only one or few networks, use this option."
          >
            {EditForm[type]}
          </GreyedAccordion>
        </div>
      </div>
    ),
    product: (
      <div>
        <Stack direction="horizontal" gap={3}>
          <Button
            variant="outline-primary"
            className="px-4"
            onClick={() => setShowAddProductsModal(true)}
          >
            ADD MULTIPLE PRODUCTS
          </Button>
          <Button className="px-5" onClick={needHelp}>
            NEED HELP?
          </Button>
        </Stack>
        <div
          style={{
            backgroundColor: "#EEEEEE",
            height: "30vh"
          }}
          className="d-flex text-center justify-content-center align-items-center mt-4 rounded"
        >
          <div>
            <ReactSVG src={FROWN_ICON} width={25} height={25} className="mb-3" />
            <p className="mb-0">You have not added any products yet</p>
          </div>
        </div>
      </div>
    )
  };

  const exportData = () => {
    var toExport = isArray(selectedData) && !isEmpty(selectedData) ? selectedData : data;
    if (type === "product") {
      toExport = toExport.map((el) => ({
        ...el,
        images: isArray(el.images_list) ? el.images_list.join(",") : []
      }));
    }
    return toExport;
  };

  const exportHeaders = () => {
    return [...columns, { header: "Images", key: "images", accessorKey: "images" }]
      .filter((el) => !["Media", "Actions."].includes(el.header))
      .map((col) => ({
        label: col.header,
        key: col.accessorKey || col.id || ""
      }));
  };

  function ActionButtons() {
    const sizingStyle = { width: "2.5rem", height: "2.5rem" };
    return (
      <Stack direction="horizontal" gap={3}>
        <Image
          className="cursor-pointer"
          src={REFRESH_ICON}
          style={{ ...sizingStyle }}
          onClick={() => {
            clear();
            window.location.reload();
          }}
        />

        {columns && (
          <CSVLink
            data={isArray(selectedData) && !isEmpty(selectedData) ? selectedData : data}
            headers={columns.map((col) => ({
              label: col.header,
              key: col.accessorKey || col.id
            }))}
            filename="my-requests.csv"
          >
            <Image src={DOWNLOAD_ICON} style={{ ...sizingStyle }} />
          </CSVLink>
        )}
        <Image
          className="cursor-pointer"
          src={PRINT_ICON}
          style={{ ...sizingStyle }}
          onClick={() =>
            generateTableAndPrintPDF(
              isArray(selectedData) && !isEmpty(selectedData) ? selectedData : data,
              columns.map((col) => ({
                label: col.header,
                key: col.accessorKey || col.id
              }))
            )
          }
        />
      </Stack>
    );
  }
  console.log("Selected Cat: ", selectedCategories, category, data, searchString);

  return (
    <LoadingContainer
      loading={
        loading ||
        !ordering ||
        !columns ||
        (type === "lead" && user.is_brand && isNil(getKey("reason")))
      }
    >
      {isEmpty(data) &&
      EmptyComponent[type] &&
      searchString === "" &&
      ((type === "distribution-network" && !city && !state) ||
        (type === "branch-detail" && !city && !state) ||
        (type === "product" && isEmpty(selectedCategories) && !category)) ? (
        EmptyComponent[type]
      ) : (
        <>
          {isActivity && (
            <Row className="mx-0 mt-1 px-0 mb-4">
              <Col className="px-0 mx-0">
                {filterPillsOptions.length > 0 && (
                  <FilterPills options={filterPillsOptions} name="reason" />
                )}
              </Col>
              {!isMobile && ["request", "lead"].includes(type) && (
                <Col className="d-flex justify-content-end">
                  <ActionButtons />
                </Col>
              )}
            </Row>
          )}
          <Row className="mx-0" xs={1} sm={2}>
            <Col className="px-0">
              <Search
                placeholder={placeholder}
                searchString={searchString}
                setSearchString={setSearchString}
              />
            </Col>
            <Col className="px-0" style={{ marginTop: isMobile ? "20px" : 0 }}>
              <Stack
                className={`justify-content-${isMobile ? "evenly" : "end"} px-0 m`}
                direction="horizontal"
                gap={3}
              >
                <TotalResult count={count} />
                {["branch-detail", "distribution-network", "product"].includes(type) && (
                  <HandleMultipleDeletion
                    selected={selectedData}
                    setShowDeleteModal={setShowDeleteModal}
                    isAllSelected={isAllSelected}
                    isDeleteButtonDisabled={isDeleteButtonDisabled}
                    isSelectAllDisabled={isSelectAllDisabled}
                    handleSelectAll={handleSelectAll}
                  />
                )}
                {!isMobile && FilterComponent}
                {isMobile && ["request", "lead"].includes(type) && (
                  <Col className="d-flex justify-content-end">
                    <ActionButtons />
                  </Col>
                )}
              </Stack>
            </Col>
          </Row>
          {isMobile && FilterComponent && (
            <div
              className="justify-content-between d-flex"
              style={{ marginTop: isMobile ? "20px" : 0 }}
            >
              {FilterComponent}
            </div>
          )}

          {["branch-detail", "distribution-network"].includes(type) && (
            <div className="mt-4">
              <Stack
                className="justify-content-end align-items-center"
                direction="horizontal"
                gap={3}
              >
                <div
                  className="fw-bold cursor-pointer"
                  style={{ color: COLORS.PRIMARY_ORANGE }}
                  onClick={() => setShowAddModal(true)}
                >
                  + ADD NEW
                </div>
                <StateFilter />
                <CityFilter />
              </Stack>
            </div>
          )}
          {type === "product" && (
            <Stack direction="horizontal" gap={3} className="mt-3 justify-content-between">
              <Stack gap={3} direction="horizontal">
                <Button className="px-4" onClick={() => setShowAddProductsModal(true)}>
                  ADD MORE PRODUCTS
                </Button>
                <Button className="px-5" onClick={needHelp}>
                  NEED HELP?
                </Button>
                {columns && data.length > 0 && (
                  <CSVLink data={exportData()} headers={exportHeaders()} filename="my-products.csv">
                    <Button className="px-4" variant="outline-primary">
                      <Image
                        src={DOWNLOAD_ICON}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                        className="me-3"
                      />
                      DOWNLOAD SELECTED
                    </Button>
                  </CSVLink>
                )}
              </Stack>
              <div>
                <SortByFilter />
              </div>
            </Stack>
          )}

          {type === "product" &&
          !isEmpty(selectedCategories) &&
          category &&
          !isUndefined(selectedProductCategory) ? (
            <div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <ProductCategoriesMultilevelFilter
                  categories={selectedCategories}
                  onClick={(val) => {
                    if (category !== val.category.slug) {
                      setLoading(true);
                      setSelectedProductCategory(val.category);
                    }
                  }}
                />
                <Stack direction="horizontal" className="justify-content-end" gap={3}>
                  <Button
                    className="d-block mb-2"
                    disabled={Object.keys(editProduct).length === 0}
                    onClick={() => setEditProduct({})}
                  >
                    Clear All Edits
                  </Button>
                  <Button
                    className="d-block mb-2"
                    disabled={Object.keys(editProduct).length === 0}
                    onClick={() => setShowConfirmEditModal(true)}
                  >
                    Save Edit
                  </Button>
                </Stack>
              </div>

              <div className="mt-4" style={{ overflowX: "scroll", width: "80vw" }}>
                <EditableTable data={data} columns={columns} setData={setData} />
                <EditProductModal
                  onUpdate={() => fetchPage(page)}
                  show={showConfirmEditModal}
                  onHide={() => setShowConfirmEditModal(false)}
                  data={data}
                  editProduct={editProduct}
                />
              </div>
            </div>
          ) : isMobile &&
            ["lead", "request", "branch-detail", "distribution-network"].includes(type) ? (
            <div>
              {data.map((el) => (
                <div key={el.id} className="pt-3">
                  <MobileCard
                    obj={el}
                    handleSelection={() => updateSelectedResults(el, selectedData)}
                    isSelected={selectedData.filter((s) => el.id === s.id).length !== 0}
                    dropdownOptions={
                      ["branch-detail", "distribution-network"].includes(type)
                        ? [
                            { label: "Edit", onClick: () => setShowEditModal(el) },
                            { label: "Delete", onClick: () => setShowDeleteModal(el) }
                          ]
                        : null
                    }
                    onUpdateStatus={() => fetchPage(page)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-4" style={{ overflowX: "scroll", width: "80vw" }}>
              <Table data={data} columns={columns} />
            </div>
          )}
          <div className="d-flex justify-content-center">
            <CustomPagination
              pagesCount={Math.ceil((count || 0) / page_size)}
              currentPage={page}
              setCurrentPage={fetchPage}
            />
          </div>

          <ConfirmDeletionWithProgressModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            type={type}
            onSuccessDelete={async () => {
              if (type === "product") {
                await getUserCategories();
              }
              await fetchPage(page);
              setShowDeleteModal(false);
              setSelectedData([]);
            }}
            selectedData={selectedData}
            useSlug
          />
          {EditForm[type] && (
            <BaseModal
              show={!isUndefined(showEditModal)}
              onHide={() => setShowEditModal(undefined)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit {type.replace("-", " ")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="mb-4">{EditForm[type]}</Modal.Body>
            </BaseModal>
          )}
          {EditForm[type] && (
            <BaseModal show={showAddModal} onHide={() => setShowAddModal(false)} size="xl">
              <Modal.Header closeButton className="d-flex justify-content-between">
                <Modal.Title>Add {type.replace("-", " ")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="mb-4">
                <div className="mb-4">
                  <CSVHelpContainer>
                    <CSVUploader
                      licenseKey={
                        type === "branch-detail"
                          ? CSV_TEMPLATE_IDS.BRANCH_DETAILS
                          : CSV_TEMPLATE_IDS.DISTRIBUTION_NETWORK
                      }
                      downloadCSV={
                        type === "branch-detail"
                          ? downloadBranchDetailsTemplate
                          : downloadDistributionNetworksTemplate
                      }
                      onSuccess={onSuccessFormComplete}
                    />
                  </CSVHelpContainer>
                </div>
                {EditForm[type]}
              </Modal.Body>
            </BaseModal>
          )}
        </>
      )}
      {type === "product" && (
        <>
          <NeedHelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />
          <AddProductsModal
            show={showAddProductsModal}
            onHide={() => setShowAddProductsModal(false)}
            onSuccess={() => {
              setLoading(true);
              getUserCategories()
                .then(handleGetSearch)
                .then(() => {
                  setShowAddProductsModal(false);
                  setLoading(false);
                });
            }}
          />
          <ViewProductMediaModal show={viewAllMedia} onHide={() => setViewAllMedia(undefined)} />
        </>
      )}
    </LoadingContainer>
  );
}
export default SearchTable;

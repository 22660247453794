import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { pdfjs } from "react-pdf";
import isDevelopment from "config";

var url;

if (isDevelopment) {
  url = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
} else {
  url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
}
pdfjs.GlobalWorkerOptions.workerSrc = url;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import BaseContentCard, {
  BaseContentCardDetailsSection,
  CardTitle,
  RenderBookmarkIcon,
  RenderSelect
} from "../../Base/Card";
import { Card, Stack } from "react-bootstrap";
import { SUBTITLE_TEXT_STYLE } from "../default-styles";
import EngagementStats from "../../EngagementStats";
import ProfileBannerImage from "../../ProfileBannerImage";
import ProfileImage from "../../ProfileImage";
import COLORS from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
import { useNavigationInfo } from "../../../hooks";
import Location from "../../Location";
import { ReactSVG } from "react-svg";
import { ML_VERIFIED } from "../../../constants/icons";
import { isFunction } from "lodash";
import { isMobile } from "react-device-detect";
import EllipsisText from "components/EllipsisText";

function Banner({ children }) {
  return (
    <div
      style={{
        background: COLORS.LINEAR_GRADIENTS.GREEN,
        width: "88%",
        top: "-1.55rem",
        left: "12%"
      }}
      className="position-absolute pl-5"
    >
      <p
        className="text-white mb-0 fw-bold ms-5 py-1"
        style={{ width: "max-content", fontSize: "75%" }}
      >
        {children}
      </p>
    </div>
  );
}

export default function ProfileCard({
  obj,
  onBookmarked,
  isSelected,
  handleSelection,
  containerClassName,
  onClick,
  cartStyle = {},
  showSelect = true
}) {
  const navigate = useNavigate();
  const { isFavourites } = useNavigationInfo();

  const {
    name,
    is_studio,
    is_student,
    open_to_work,
    ml_verified,
    offers_status,
    field_of_studies,
    company_type,
    saves,
    location,
    slug
  } = obj;
  const navigateToProfile = () => navigate(`/profiles/${slug}`);

  const isLookingForOffers = is_studio && offers_status === "Looking for new offers";

  return (
    <BaseContentCard
      onClick={isFunction(onClick) ? onClick : navigateToProfile}
      className={containerClassName}
      style={{ width: isMobile ? "95%" : "100%", ...cartStyle }}
    >
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      <RenderBookmarkIcon onBookmarked={onBookmarked} obj={obj} type="profile" />
      <ProfileBannerImage customUser={obj} style={{ height: isMobile ? "72px" : "150px" }} />
      <BaseContentCardDetailsSection
        style={{ position: "relative" }}
        className="justify-content-center"
      >
        <div
          style={{ width: "3.5rem", height: "3.5rem", top: "-2rem", zIndex: 10 }}
          className="position-absolute"
        >
          <ProfileImage customUser={obj} />
        </div>
        {isLookingForOffers && <Banner>OPEN TO OFFERS</Banner>}
        {is_student && open_to_work && <Banner>OPEN TO WORK</Banner>}

        <Stack
          direction="horizontal"
          gap={isMobile ? 1 : 2}
          className="align-items-center mt-4 w-100"
        >
          <CardTitle
            numOfLines={isMobile ? 1 : 2}
            style={{
              display: "flex",
              ...(isMobile ? { fontSize: "12px", marginBottom: "1px" } : {})
            }}
            extraChildren={
              ml_verified && (
                <div style={{ marginLeft: "0.5rem", ...(isMobile ? { marginTop: -1 } : {}) }}>
                  <ReactSVG src={ML_VERIFIED} />
                </div>
              )
            }
          >
            {name || ""}
          </CardTitle>
        </Stack>

        <Card.Subtitle
          className={`mt-${isMobile ? 0 : 1} mb-${isMobile ? 1 : 2}`}
          style={isMobile ? { fontSize: "10px" } : {}}
        >
          <EllipsisText
            numOfLines={1}
            style={{ ...SUBTITLE_TEXT_STYLE, color: COLORS.PRIMARY_ORANGE }}
          >
            {is_student ? field_of_studies : company_type || ""}
          </EllipsisText>
        </Card.Subtitle>
        <Card.Subtitle
          className={`text-muted mb-${isMobile ? 1 : 2} d-flex align-items-center w-100`}
          style={{
            ...SUBTITLE_TEXT_STYLE,
            marginTop: "0.15%",
            ...(isMobile ? { fontSize: "10px" } : {})
          }}
        >
          <Location iconStyle={isMobile ? { width: "12px", height: "12px" } : null}>
            {location || ""}
          </Location>
        </Card.Subtitle>
        {!isFavourites && (
          <div style={{ marginLeft: "0.15rem" }}>
            <EngagementStats
              saves={saves}
              width={isMobile ? "0.45rem" : null}
              fontSize={isMobile ? "0.75rem" : null}
            />
          </div>
        )}
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

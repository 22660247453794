import FormCountContainer from "components/Containers/FormCount";
import { isArray, isEmpty } from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export function FormActionButtons({ parts, part, setPart }) {
  return (
    <div className={`mt-1 w-100 d-flex justify-content-between`}>
      <div style={{ width: "47.5%" }}>
        {part && part > 1 && (
          <Button
            variant="outline-primary"
            className="fw-bold w-100"
            onClick={() => setPart(part - 1)}
          >
            BACK
          </Button>
        )}
      </div>
      <Button
        type="submit"
        variant="primary"
        style={{ width: isMobile ? null : "47.5%", marginTop: !part ? "1rem" : undefined }}
        className="fw-bold"
      >
        {part && part === parts.length ? "SUBMIT" : "PROCEED"}
      </Button>
    </div>
  );
}

export default function FormFooter({ parts, part, setPart }) {
  return (
    <>
      {isArray(parts) && !isEmpty(parts) && (
        <div
          className={`${isMobile ? "" : "d-flex justify-content-between"} mt-${isMobile ? 3 : 5}`}
        >
          <FormCountContainer activePart={part} parts={parts} />
        </div>
      )}
      <div className="w-50 ms-auto">
        <FormActionButtons parts={parts} part={part} setPart={setPart} />
      </div>
    </>
  );
}

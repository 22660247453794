import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Stack, Image } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { isArray, isEmpty } from "lodash";
import { BsFillCheckCircleFill } from "react-icons/bs";
import ProfileImage from "components/ProfileImage";
import Location from "components/Location";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Phone from "components/Inputs/Phone";
import UploadResumeFileModal from "components/Modals/UploadResumeFileModal";
import COLORS from "constants/colors";
import FormError from "components/FormError";
import EditDeleteIcons from "components/Icons/EditDeleteIcons";
import { convertDataUrlToBase64 } from "utils";
import ContentFactory from "apiClients/ContentFactory";
import { showPromiseToast } from "utils";
import FormCountContainer from "components/Containers/FormCount";
import { FormActionButtons } from "forms/Footer";
import { renderErrorToast } from "utils";
import { isMobile } from "react-device-detect";
import DEFAULT_RESUME from "../../assets/icons/resume/default-resume.png";

const ApplyJobForm = ({ job, resumes, resumeFiles, onSuccess, getAllResumes }) => {
  const [part, setPart] = useState(1);
  const [resume, setResume] = useState(null);
  const [isResumeFile, setIsResumeFile] = useState(false);
  const [showAddResumeFile, setShowAddResumeFile] = useState(false);
  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
    clearErrors,
    watch
  } = useFormContext();
  const user = useSelector((state) => state.authReducer.user);
  const hasQuestionsToAsk = isArray(job.questions_to_ask) && !isEmpty(job.questions_to_ask);
  useFieldArray({
    control,
    name: "answers",
    rules: { minLength: hasQuestionsToAsk ? job.questions_to_ask.length : 0 }
  });

  const answers = watch("answers");

  useEffect(() => {
    setValue("email", user.email);
    setValue("phone", user.mobile);
  }, []);

  var parts = ["Contact", "Resume", "Overall View"];
  if (hasQuestionsToAsk) {
    parts.splice(2, 0, "Questions");
  }

  useEffect(() => {
    clearErrors();
  }, [part]);

  const UserInfo = (
    <Stack gap={4} className="my-4" direction="horizontal">
      <div style={{ width: "5rem", height: "5rem" }}>
        <ProfileImage />
      </div>
      <div className="w-75">
        <h6 className="mb-1">{user.name}</h6>
        <Location>{user.location}</Location>
      </div>
    </Stack>
  );

  const FormPartOne = (
    <Stack gap={3}>
      {UserInfo}
      <Form.Group>
        <Form.Label>Email*</Form.Label>
        <ReactHookInput name="email" type="email" required disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Number*</Form.Label>
        <Phone required disabled />
      </Form.Group>
    </Stack>
  );

  const FormPartTwo = (
    <Stack gap={3}>
      <UploadResumeFileModal
        showAddResumeFile={showAddResumeFile}
        setShowAddResumeFile={setShowAddResumeFile}
        onSuccess={getAllResumes}
      />
      <h6 className="mt-2 mb-0">Choose Your Resume*</h6>
      <Container>
        <Row xs={1} sm={2}>
          {resumes.map((el, index) => (
            <Col key={`resumes-${el.id}-${index}`} className="px-0">
              <div
                className="rounded py-2 px-3 mb-3 cursor-pointer d-flex justify-content-between align-items-center position-relative"
                style={{
                  border: `1px solid ${
                    !isResumeFile && resume && resume.id === el.id ? COLORS.PRIMARY_ORANGE : "black"
                  }`,
                  ...(!isMobile && index + (1 % 2) !== 0 ? { marginRight: "0.5rem" } : {})
                }}
                onClick={() => {
                  if (errors.resume) {
                    clearErrors("resume");
                  }
                  setIsResumeFile(false);
                  setResume(el);
                }}
              >
                {el.default && (
                  <Image
                    src={DEFAULT_RESUME}
                    style={{ position: "absolute", right: "-0.6rem", top: "-0.8rem" }}
                  />
                )}
                {el.title}
                {!isResumeFile && resume && resume.id === el.id && (
                  <div
                    style={{ right: el.default === true ? 40 : 10, top: "17%" }}
                    className="position-absolute"
                  >
                    <BsFillCheckCircleFill color={COLORS.PRIMARY_ORANGE} />
                  </div>
                )}
              </div>
            </Col>
          ))}
          {resumeFiles.map((el, index) => (
            <Col key={`resumes-filtes-${el.id}-${index}`} className="px-0">
              <div
                className="rounded py-2 px-3 mb-3 cursor-pointer d-flex justify-content-between align-items-center position-relative"
                style={{
                  border: `1px solid ${
                    isResumeFile && resume && resume.id === el.id ? COLORS.PRIMARY_ORANGE : "black"
                  }`,
                  ...(isMobile
                    ? {}
                    : (index + 1) % 2 !== 0
                    ? { marginLeft: "0.5rem" }
                    : { marginRight: "0.5rem" })
                }}
                onClick={() => {
                  if (errors.resume) {
                    clearErrors("resume");
                  }
                  setIsResumeFile(true);
                  setResume(el);
                }}
              >
                {el.default === true && (
                  <Image
                    src={DEFAULT_RESUME}
                    style={{ position: "absolute", right: "-0.6rem", top: "-0.8rem" }}
                  />
                )}
                {el.title}
                {isResumeFile && resume && resume.id === el.id && (
                  <div
                    style={{ right: el.default === true ? 40 : 10, top: "17%" }}
                    className="position-absolute p-0 m-0"
                  >
                    <BsFillCheckCircleFill color={COLORS.PRIMARY_ORANGE} />
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      {errors && errors.resume && (
        <FormError className="text-center">{errors.resume.message}</FormError>
      )}
      <div className="d-flex justify-content-center align-items-center py-4">
        <Button className="px-5" onClick={() => setShowAddResumeFile(true)}>
          Upload New Resume File
        </Button>
      </div>
    </Stack>
  );

  const FormPartThree = job.questions_to_ask.map((el, index) => (
    <Form.Group className="mb-4" key={el}>
      <Form.Label>
        Question {index + 1}. {el}
      </Form.Label>
      <ReactHookInput
        name={`answers.${index}`}
        required
        error={
          isArray(errors.answers) && !isEmpty(errors.answers) && errors.answers.length >= index
            ? errors.answers[index]
            : null
        }
      />
    </Form.Group>
  ));
  const FormPartFour = (
    <div className="mb-4">
      {UserInfo}
      <Stack gap={4}>
        <div>
          <h6>Contact Details</h6>
          <p className="mb-0">
            <span className="fw-bold">Email: </span> {watch("email")}
          </p>
          <p className="mb-0">
            <span className="fw-bold">Contact: </span> {watch("phone")}
          </p>
        </div>
        {resume && (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>Resume</h6>
              <EditDeleteIcons onEdit={() => setPart(2)} />
            </div>
            <div
              className="rounded py-2 px-3 cursor-pointer d-flex justify-content-between align-items-center mt-2"
              style={{
                border: "1px solid black"
              }}
            >
              {resume.title}
            </div>
          </div>
        )}
        {hasQuestionsToAsk && (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>Questions</h6>
              <EditDeleteIcons onEdit={() => setPart(3)} />
            </div>
            <div>
              {job.questions_to_ask.map((el, index) => (
                <div key={el}>
                  <p className="mb-0 fw-bold">
                    Question {index + 1}. {el}
                  </p>
                  {isArray(answers) && answers.length === job.questions_to_ask.length && (
                    <p>{answers[index]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </Stack>
    </div>
  );

  const handleFormOneSubmit = () => setPart(part + 1);

  const handleFormThreeSubmit = () => setPart(part + 1);

  const handleFormTwoSubmit = () => {
    if (resume) {
      setPart(part + 1);
    } else {
      setError("resume", { message: "Please select a resume or upload a new one" });
    }
  };

  const handleFormFourSubmit = async (data) => {
    if ("answers" in Object.keys(data) && !hasQuestionsToAsk) {
      delete data.answers;
    }
    var payload = { ...data, contact: data.phone, job: job.id };
    if (isResumeFile) {
      const result = await convertDataUrlToBase64(resume.file);
      payload["resume_file"] = [result];
    } else {
      payload["resume"] = resume.id;
    }
    const promise = ContentFactory.createRequest("job-application").create(payload).then(onSuccess);

    showPromiseToast(promise, {
      pending: "Saving job application",
      success: "Job application saved successfully",
      error: renderErrorToast()
    });
  };

  var form = FormPartOne;
  var submit = handleFormOneSubmit;

  switch (part) {
    case 1:
      form = FormPartOne;
      submit = handleFormOneSubmit;
      break;
    case 2:
      form = FormPartTwo;
      submit = handleFormTwoSubmit;
      break;
    case 3:
      form = hasQuestionsToAsk ? FormPartThree : FormPartFour;
      submit = hasQuestionsToAsk ? handleFormThreeSubmit : handleFormFourSubmit;
      break;
    case 4:
      form = FormPartFour;
      submit = handleFormFourSubmit;
      break;
    default:
      throw new Error("Invalid form part");
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <FormCountContainer activePart={part} setPart={setPart} parts={parts} />
      {form}
      <div className="mt-4">
        <FormActionButtons setPart={setPart} part={part} parts={parts} />
      </div>
    </Form>
  );
};

export default ApplyJobForm;

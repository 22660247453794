import React, { Fragment } from "react";
import AuthContainer from "../components/Containers/AuthRight";
import LoginForm from "../forms/Login";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function Login() {
  return (
    <AuthContainer
      Form={LoginForm}
      HeaderComponent={
        <Fragment>
          <h1>Welcome Back!</h1>
          <p className="helper-text">
            Please enter your details below. Sign In using Email or Phone number.
          </p>
        </Fragment>
      }
    />
  );
}

export default withMobileOnlySidebar(Login);

const ACCEPTED_FILE_TYPES = {
  image: {
    "image/jpeg": [],
    "image/png": [],
    "image/jpg": [],
    "image/webp": []
  },
  video: { "video/mp4": [], "video/webm": [] },
  pdf: { "application/pdf": [] },
  "image/pdf": {}
};

export default ACCEPTED_FILE_TYPES;

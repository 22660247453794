import React from "react";
import { useController, useFormContext } from "react-hook-form";
import BaseUpload from "../../Uploaders/BaseUpload";
import { capitalizeFirstLetter } from "../../../utils";

export default function ReactHookUpload({
  name,
  type,
  requiredMessage,
  required,
  preferredText,
  multiple,
  maxSizeMB = 2
}) {
  const { control } = useFormContext();
  const { field } = useController({
    control,
    name,
    rules: required
      ? {
          required: requiredMessage || capitalizeFirstLetter(`${type} file is required`)
        }
      : {}
  });
  return (
    <BaseUpload
      name={name}
      type={type}
      setFile={field.onChange}
      file={field.value}
      multiple={multiple}
      maxSizeMB={maxSizeMB}
      preferredText={preferredText}
    />
  );
}

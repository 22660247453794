import React from "react";
import { Image } from "react-bootstrap";
import FAVOURITE_ICON from "../../../assets/icons/favourite.svg";
import FAVOURITED_ICON from "../../../assets/icons/favourited.svg";
import FavouriteFactory from "../../../apiClients/FavouriteFactory";
import { isFunction } from "lodash";

export default function FavouriteButton({ type, obj, onSuccess }) {
  const { is_favourited } = obj;
  const client = FavouriteFactory.createRequest(type);
  const addBookmark = () => client.add(obj.id).then(() => isFunction(onSuccess) && onSuccess());

  const removeBookmark = () =>
    client.remove(obj.is_favourited).then(() => isFunction(onSuccess) && onSuccess());

  const onClick = () => {
    if (is_favourited) {
      removeBookmark();
    } else {
      addBookmark();
    }
  };

  return (
    <>
      <Image
        className="cursor-pointer w-100 h-100 object-fit-contain"
        src={is_favourited ? FAVOURITED_ICON : FAVOURITE_ICON}
        onClick={onClick}
      />
    </>
  );
}

import React, { useState } from "react";
import BaseModal from "../Base";
import { Button, Modal, Stack, Spinner } from "react-bootstrap";
import ContentFactory from "apiClients/ContentFactory";
import { isArray, isEmpty, isNull, isNumber, isUndefined } from "lodash";

function EditProductTable({ editObj, original, errors }) {
  const editedKeys = Object.keys(editObj);

  function listDifference(list1, list2) {
    const difference = list2.length - list1.length;
    if (difference > 0) {
      const addedUrls = list2.slice(list1.length).join(", ");
      return `+${difference} (${addedUrls} added)`;
    } else if (difference < 0) {
      const missingUrls = list1.slice(list2.length).join(", ");
      return `${difference} (${missingUrls} removed)`;
    }
  }

  return (
    <div>
      <h5>{original.product_name}</h5>
      {editedKeys.map((el) => {
        return (
          <div key={`${original.id}-${el}`}>
            <p className="mb-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                <span className="fw-bold text-capitalize">{el.replace(/_/g, " ")}: </span>
                <span style={{ textDecoration: "line-through" }}>
                  <p className="mb-0">{original[el]}</p>
                </span>

                {editObj && editObj[el] && (
                  <span>
                    {editObj[el].startsWith('["') || isArray(original[el]) ? (
                      listDifference(
                        isArray(original[el])
                          ? original[el]
                          : JSON.parse(original[el].replace(/'/g, '"')),
                        isArray(editObj[el])
                          ? editObj[el]
                          : JSON.parse(editObj[el].replace(/'/g, '"'))
                      )
                    ) : (
                      <p className="mb-0">{editObj[el]}</p>
                    )}
                  </span>
                )}

                {errors && errors[el] && (
                  <span
                    style={{ color: "red", overflowWrap: "break-word" }}
                    className="fw-bold ms-4"
                  >
                    {errors[el]}
                  </span>
                )}
              </Stack>
            </p>
          </div>
        );
      })}
    </div>
  );
}
export default function EditProductModal({ show, onHide, editProduct, data, onUpdate }) {
  const client = ContentFactory.createRequest("product");
  const editedProducts = Object.keys(editProduct);
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState({});

  React.useEffect(() => {
    if (show === false) {
      if (updating === true) {
        setUpdating(false);
      }
      if (Object.keys(errors).length > 0) {
        setErrors({});
      }
    }
  }, [show]);

  function extractAFromA_uom(a_uom) {
    const index = a_uom.indexOf("_uom");
    if (index !== -1) {
      return a_uom.substring(0, index);
    }
    return null;
  }

  const validate = (row, original) => {
    const rowKeys = Object.keys(row);
    var rowErrors = {};
    const originalKeys = Object.keys(original);
    rowKeys.forEach((el) => {
      const isUOM = el.includes("_uom");
      const hasUOM = originalKeys.includes(`${el}_uom`);
      if (el.includes("percent") && (row[el] > 100 || row[el] < 0)) {
        rowErrors[el] = "Invalid Percetage value";
      } else if (isNumber(original[el]) && (!isNumber(Number(row[el])) || isNaN(Number(row[el])))) {
        rowErrors[el] = "This value should be a valid number";
      } else if (isUOM) {
        const attributeName = extractAFromA_uom(el);
        if (
          !rowKeys.includes(attributeName) &&
          (isEmpty(original[attributeName]) ||
            isUndefined(original[attributeName]) ||
            isNull(original[attributeName]))
        ) {
          rowErrors[el] = `Please enter ${attributeName}`;
        }
      } else if (
        hasUOM &&
        !rowKeys.includes(`${el}_uom`) &&
        (isEmpty(original[`${el}_uom`]) ||
          isUndefined(original[`${el}_uom`]) ||
          isNull(original[`${el}_uom`]))
      ) {
        rowErrors[el] = `Please select the unit of measurement for ${el}`;
      }
    });
    console.log("Row errors here: ", rowErrors);
    return Object.keys(rowErrors).length > 0 ? rowErrors : null;
  };

  const confirmEdit = async () => {
    var updates = [];
    var allErrors = {};
    for (var i = 0; i < editedProducts.length; i++) {
      const original = (data || []).filter((oel) => oel.id === editedProducts[i])[0];
      const validationResult = validate(editProduct[editedProducts[i]], original);
      console.log("Validation: ", validationResult);
      if (validationResult) {
        allErrors[editedProducts[i]] = { ...validationResult };
      } else if (isEmpty(allErrors)) {
        updates.push(client.update({ ...editProduct[editedProducts[i]], id: original.slug }));
      }
    }
    if (isEmpty(allErrors)) {
      try {
        setUpdating(true);

        const result = await Promise.all(updates);
        console.log("Result: ", result);
        onUpdate()
          .then(() => setUpdating(false))
          .then(() => onHide());
      } catch (er) {
        console.log(er);
        setUpdating(false);
      }
    } else {
      setErrors(allErrors);
    }
  };

  return (
    <BaseModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{updating ? "Confirming" : "Confirm"} Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updating ? (
          <div className="d-flex justify-content-center py-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div>
              {editedProducts.map((el) => (
                <>
                  <EditProductTable
                    editObj={editProduct[el]}
                    key={el}
                    original={(data || []).filter((oel) => oel.id === el)[0]}
                    errors={errors[el]}
                  />
                  <hr />
                </>
              ))}
            </div>
            <Stack direction="horizontal" gap={4} className="justify-content-end">
              <Button onClick={confirmEdit}>Confirm</Button>
            </Stack>
          </>
        )}
      </Modal.Body>
    </BaseModal>
  );
}

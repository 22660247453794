import BackButton from "components/Buttons/Back";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function RelatedContainer({ FirstCol, SecondCol, showBack, onBack }) {
  return (
    <Container>
      <Row xs={1} sm={SecondCol ? 2 : 1}>
        <Col sm={SecondCol ? 9 : 12}>
          {showBack && (
            <div className="py-4">
              <BackButton onBack={onBack} />
            </div>
          )}
          {FirstCol}
        </Col>
        {SecondCol && (
          <Col sm={3} className="mt-2">
            {SecondCol}
          </Col>
        )}
      </Row>
    </Container>
  );
}

import React from "react";
import { Accordion, Card, Form, useAccordionButton } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import COLORS from "../../../constants/colors";

function CustomToggle({ title, subTitle, icon, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <div
      className="p-3 d-flex justify-content-between align-items-center cursor-pointer"
      onClick={decoratedOnClick}
    >
      <div className="d-flex align-items-center">
        <div>
          <div>
            {icon}
            <Form.Label className="fw-bold m-0">{title}</Form.Label>
          </div>
          {subTitle && (
            <p className="text-black" style={{ fontSize: "80%" }}>
              {subTitle}
            </p>
          )}
        </div>
      </div>
      <BsChevronDown size={15} />
    </div>
  );
}

export default function GreyedAccordion({
  children,
  defaultActiveKey,
  title,
  subTitle,
  icon,
  cardClass,
  eventKey
}) {
  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      <Card
        className={`border-0 pb-3${cardClass ? ` ${cardClass}` : ""}`}
        style={{ borderRadius: 0 }}
      >
        <Card.Header
          className="border-bottom-0 px-2 cursor-pointer"
          style={{ background: COLORS.GREY3 }}
        >
          <CustomToggle title={title} subTitle={subTitle} icon={icon} eventKey={eventKey} />
        </Card.Header>

        <Accordion.Collapse eventKey={eventKey} className="border rounded-bottom p-3">
          {children}
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

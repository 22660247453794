import { CSVBoxButton } from "@csvbox/react";
import { isFunction } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

export default function UploadCSVButton({
  licenseKey,
  onSuccess,
  containerStyle,
  buttonStyle,
  disabled,
  supportExternalValidation = false,
  customAttributes = {}
}) {
  const user = useSelector((state) => state.authReducer.user);
  return (
    <div
      className="d-flex upload-file-btn-container"
      style={{ justifyContent: "center", ...containerStyle }}
    >
      <CSVBoxButton
        licenseKey={licenseKey}
        user={{
          user_id: user.id,
          ...customAttributes
        }}
        loadStarted={() => console.debug("loadStarted")}
        onReady={() => console.debug("onReady")}
        onImport={(result, data) => {
          if (result) {
            if (
              (isFunction(onSuccess) && !supportExternalValidation) ||
              (supportExternalValidation && data.external_validations_failed_requests === 0)
            ) {
              onSuccess(result, data);
            }
          } else {
            console.debug("fail");
          }
        }}
        render={(launch, isLoading) =>
          isLoading ? (
            <div className="mt-3">
              <Spinner />
            </div>
          ) : (
            <Button
              disabled={isLoading || disabled}
              onClick={launch}
              style={buttonStyle || {}}
              className="mt-3"
            >
              Upload
            </Button>
          )
        }
      >
        Upload File
      </CSVBoxButton>
    </div>
  );
}

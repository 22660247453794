import React, { useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { useFieldArray, useController, useFormContext } from "react-hook-form";
import VALIDATORS from "../../../validators";
import { isFunction, isObject, isUndefined } from "lodash";
import FormError from "../../FormError";

const TagInput = ({ name, maxLen, required, minLen = 0, validator, message }) => {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const [input, setInput] = useState("");

  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  const handleAddTag = () => {
    if (isFunction(validator) && !validator(input)) {
      setError(name, { message });
    } else {
      if (input.length > 0) {
        append({ tag: input });
        setInput("");
        if (errors[name]) {
          clearErrors(name);
        }
      }
    }
  };

  const handleRemoveTag = (index) => remove(index);

  const { field } = useController({
    name,
    control,
    rules: VALIDATORS.minLengthArray(minLen, required)
  });

  return (
    <div className="w-100">
      <Form.Group key={field.id} className="position-relative w-100">
        <Form.Control
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={fields.length === maxLen}
        />
        {maxLen !== fields.length && (
          <div className="position-absolute" style={{ right: 10, top: "20%" }}>
            <a type="button" onClick={handleAddTag} className="fw-bold">
              Add+
            </a>
          </div>
        )}
      </Form.Group>
      {!isUndefined(errors) &&
        isObject(errors) &&
        errors[name] &&
        (errors[name].message || errors[name].root.message) && (
          <FormError error={errors[name].message || errors[name].root.message} />
        )}
      <ListGroup horizontal className="flex-wrap" xs={2}>
        {fields.map((field, index) => (
          <ListGroup.Item
            key={field.id}
            variant="warning"
            className="px-3 me-2 mt-2 d-flex rounded bg-orange text-white"
          >
            {field.tag}
            <div className="fw-bold ms-2 cursor-pointer" onClick={() => handleRemoveTag(index)}>
              X
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default TagInput;

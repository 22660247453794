import React from "react";
import { Route, Routes } from "react-router-dom";
import Profiles from "../../screens/Profiles";
import Profile from "../../screens/Profile";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

export default function ProfilesRoutes() {
  const NewProfile = withMobileOnlySidebar(Profile);

  return (
    <Routes>
      <Route path="" element={<Profiles />} />
      <Route path=":id" element={<NewProfile />} />
      <Route path=":id/:contentType/:contentId" element={<NewProfile />} />
    </Routes>
  );
}

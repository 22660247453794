import { BrandKYCDetailsAPI } from "apiClients/Profile/Brand/ProfileDetails";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";
import GSTINInput from "components/Inputs/GSTIN";
import PanInput from "components/Inputs/Pan";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import Select from "components/Inputs/Select";
import KYC_CONSTANTS from "constants/kyc";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useFormContext } from "react-hook-form";
import { renderErrorToast } from "utils";
import { showPromiseToast } from "utils";

export default function KycDetailsForm() {
  const onSubmit = (data) => {
    const client = new BrandKYCDetailsAPI();
    const payload = {
      ...data,
      nature_of_orgranisation: data.nature_of_orgranisation.value,
      gstin_status: data.gstin_status.value,
      registration_under_msme: data.registration_under_msme.value
    };
    const promise = client.update(payload);
    showPromiseToast(promise, {
      pending: "Saving KYC Details",
      success: "KYC Details saved successfully",
      error: renderErrorToast()
    });
  };
  const { watch, setValue } = useFormContext();
  var gstAvailable = watch("gstin_status");

  var msmeAvailable = watch("registration_under_msme");

  useEffect(() => {
    if (!msmeAvailable) {
      setValue("msme_number", "");
    }
  }, [msmeAvailable]);

  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Nature of organisation*</Form.Label>
        <Select
          name="nature_of_orgranisation"
          options={KYC_CONSTANTS.NATURE_OF_ORGANISATION}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Registration Certificate*</Form.Label>
        <ReactHookUpload name="registration_certificate" type="image,pdf" required maxSizeMB={1} />
      </Form.Group>
      <PanInput required />
      <Row xs={1} sm={2}>
        <Col>
          <Form.Group>
            <Form.Label>GSTIN Status*</Form.Label>
            <Select options={KYC_CONSTANTS.GSTIN_STATUS} name="gstin_status" />
          </Form.Group>
        </Col>
        <Col style={{ marginTop: isMobile ? "1.25rem" : "" }}>
          <GSTINInput
            required={gstAvailable && gstAvailable.value === "I have a GSTIN"}
            disabled={!(gstAvailable && gstAvailable.value === "I have a GSTIN")}
          />
        </Col>
      </Row>
      <Row xs={1} sm={2}>
        <Col>
          <Form.Group>
            <Form.Label>Registration Under MSME*</Form.Label>
            <Select
              options={KYC_CONSTANTS.REGISTRATION_UNDER_MSME}
              name="registration_under_msme"
              required
            />
          </Form.Group>
        </Col>
        <Col style={{ marginTop: isMobile ? "1.25rem" : "" }}>
          <Form.Group>
            <Form.Label>MSME Number*</Form.Label>
            <ReactHookInput
              name="msme_number"
              required={msmeAvailable && msmeAvailable.value === "Yes"}
              disabled={!(msmeAvailable && msmeAvailable.value === "Yes")}
            />
          </Form.Group>
        </Col>
      </Row>
    </DetailsFormContainer>
  );
}

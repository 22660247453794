import React, { useState } from "react";
import { Card, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isFunction } from "lodash";
import TimeAgo from "timeago-react";
import { useNavigationInfo } from "hooks";
import ContentFactory from "apiClients/ContentFactory";
import { renderErrorToast, showPromiseToast } from "utils";
import FavouriteFactory from "apiClients/FavouriteFactory";
import BaseContentCard, {
  BookmarkIconContainer,
  CardTitle,
  RenderSelect,
  ReviewRejected
} from "components/Base/Card";
import { ThreeDotsDropdown } from "components/Buttons/ThreeDotsVertical";
import { DATE_TEXT_STYLE, SUBTITLE_TEXT_STYLE } from "../default-styles";
import ShareModal from "components/Modals/Share";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";
import ProfileImage from "components/ProfileImage";

export default function Job({
  obj,
  isSelected,
  handleSelection,
  onClick,
  onBookmarked,
  onDelete,
  cardStyle = {},
  showSelect = true
}) {
  const [showShare, setShowShare] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { isUnderReview, isPostedJobs, isActivity, isFavourites, isJobs, isClosed, isDrafted } =
    useNavigationInfo();
  const { under_review, rejected, review_not_accepted_response, is_favourited, id, slug } = obj;
  const navigate = useNavigate();

  const handleEdit = () =>
    navigate(`/my-activity/my-jobs/${isPostedJobs ? "posted" : "drafted"}/${obj.slug}/edit`);

  const client = ContentFactory.createRequest("job");

  const handleDelete = () => {
    const promise = client
      .remove(obj.slug)
      .then(onDelete)
      .then(() => setShowDeleteModal(false))
      .then(() => navigate("/my-activity/my-jobs/posted"));
    return showPromiseToast(promise, {
      pending: "Deleting job",
      success: "Job deleted successfully",
      error: renderErrorToast()
    });
  };

  const favClient = FavouriteFactory.createRequest("job");

  const save = () => favClient.add(id).then(onBookmarked);

  const unsave = () => favClient.remove(is_favourited).then(onBookmarked);

  console.log(onBookmarked);
  const close = () => client.update({ id: slug, closed: true, is_posted: false }).then(onDelete);

  const share = () => setShowShare(true);

  const viewApplications = () => navigate(`/jobs/${obj.slug}/applicants`);

  const repost = () => navigate(`/my-activity/my-jobs/posted/${obj.slug}/edit`);

  return (
    <BaseContentCard onClick={isFunction(onClick) && onClick} style={{ ...cardStyle }}>
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      {(isActivity || isJobs) && (
        <BookmarkIconContainer>
          <ThreeDotsDropdown
            options={
              isPostedJobs
                ? [
                    { onClick: handleEdit, label: "Edit" },
                    { onClick: () => setShowDeleteModal(true), label: "Delete" },
                    { onClick: viewApplications, label: "View Applicants" },
                    { label: "Close Role", onClick: close },
                    { label: "Share" }
                  ]
                : isClosed
                ? [
                    { label: "Delete", onClick: () => setShowDeleteModal(true) },
                    { label: "Re-Post", onClick: repost }
                  ]
                : isDrafted || isUnderReview
                ? [
                    { onClick: handleEdit, label: "Edit" },
                    { onClick: () => setShowDeleteModal(true), label: "Delete" }
                  ]
                : isFavourites || isJobs
                ? [
                    { label: "Share", onClick: share },
                    {
                      label: is_favourited ? "Unsave" : "Save",
                      onClick: is_favourited ? unsave : save
                    }
                  ]
                : [
                    { onClick: handleEdit, label: "Edit" },
                    { onClick: () => setShowDeleteModal(true), label: "Delete" }
                  ]
            }
            color="black"
            size="30"
          />
        </BookmarkIconContainer>
      )}
      <Card.Body className="h-100">
        <Stack
          direction="horizontal"
          gap={3}
          className="align-items-start justify-content-start h-100"
        >
          <div style={{ width: "40px", height: "40px", objectFit: "contain" }}>
            <ProfileImage customUser={obj.user} />
          </div>
          <div
            style={{ width: "80%" }}
            className="d-flex flex-column justify-content-between h-100"
          >
            <div>
              <CardTitle numOfLines={1}>{obj.title || ""}</CardTitle>
              <Card.Text className="mb-0 text-muted mt-1" style={DATE_TEXT_STYLE}>
                {obj.user.name}
              </Card.Text>
              <Card.Text className="mb-0 text-muted mt-1" style={DATE_TEXT_STYLE}>
                {obj.location || ""}
              </Card.Text>
            </div>
            <div>
              <Card.Subtitle
                className="text-muted"
                style={{ fontSize: "85%", fontWeight: "normal", marginTop: "10%" }}
              >
                {obj.no_of_applicants || 0} Applicants
              </Card.Subtitle>
              <Card.Text
                className="mt-1"
                style={{ ...SUBTITLE_TEXT_STYLE, marginBottom: "0.5rem" }}
              >
                Posted <TimeAgo datetime={obj.created} />
              </Card.Text>
            </div>
            <div>
              {isUnderReview && (
                <ReviewRejected
                  is_posted={isPostedJobs}
                  under_review={under_review}
                  rejected={rejected}
                  review_not_accepted_response={review_not_accepted_response}
                />
              )}
            </div>
          </div>
        </Stack>
      </Card.Body>
      {showShare === true && <ShareModal show={showShare} onHide={() => setShowShare(false)} />}
      {showDeleteModal && (
        <ConfirmDeletionModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          type="job"
          onConfirm={handleDelete}
          selectedData={[obj]}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </BaseContentCard>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import ContentList from "components/Base/ContentList";
import Blog from "components/Cards/Blog";

function UnderReviewBlogs() {
  const user = useSelector((state) => state.authReducer.user);

  return (
    <ContentList
      Card={Blog}
      type="blog"
      title="Under Review Blogs"
      subTitle="All your under review blogs will be visible here"
      initialFilters={{
        under_review_rejected: true,
        author: user.id
      }}
      doNotShowAdd
    />
  );
}

export default UnderReviewBlogs;

import React from "react";
import ContentList from "../../../components/Base/ContentList";
import { connect } from "react-redux";
import Job from "../../../components/Cards/Job";
import { useNavigate } from "react-router-dom";

function Posted({ user }) {
  const navigate = useNavigate();
  return (
    <ContentList
      Card={Job}
      type="job"
      title="Posted Jobs"
      subTitle="All your posted jobs will be visible here"
      handleAdd={() => navigate("/my-activity/my-jobs/posted/create-new-job")}
      initialFilters={{
        is_posted: true,
        user: user.id
      }}
      cardProps={{ showSelect: false }}
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(Posted);

import ContentFactoryBase from "./ContentFactoryBase";

class Image extends ContentFactoryBase {
  constructor() {
    super("/favourite/images/");
  }
}

class Video extends ContentFactoryBase {
  constructor() {
    super("/favourite/videos/");
  }
}

class Portfolio extends ContentFactoryBase {
  constructor() {
    super("/favourite/portfolios/");
  }
}

class Project extends ContentFactoryBase {
  constructor() {
    super("/favourite/projects/");
  }
}

class Thesis extends ContentFactoryBase {
  constructor() {
    super("/favourite/thesis/");
  }
}

class Blog extends ContentFactoryBase {
  constructor() {
    super("/favourite/blogs/");
  }
}

class Profile extends ContentFactoryBase {
  constructor() {
    super("/favourite/profiles/");
  }
}

class Job extends ContentFactoryBase {
  constructor() {
    super("/favourite/jobs/");
  }
}

class ARVR extends ContentFactoryBase {
  constructor() {
    super("/favourite/arvrs/");
  }
}

class Product extends ContentFactoryBase {
  constructor() {
    super("/favourite/products/");
  }
}

class FavouriteFactory {
  static createRequest(type) {
    switch (type) {
      case "image":
        return new Image();
      case "video":
        return new Video();
      case "portfolio":
        return new Portfolio();
      case "project":
        return new Project();
      case "thesis":
        return new Thesis();
      case "blog":
        return new Blog();
      case "profile":
        return new Profile();
      case "job":
        return new Job();
      case "arvr":
        return new ARVR();
      case "product":
        return new Product();
      default:
        throw new Error(`Unsupported request type: ${type}`);
    }
  }
}

export default FavouriteFactory;

import ContentFactoryBase from "./ContentFactoryBase";
import Api from "./api";

class Image extends ContentFactoryBase {
  constructor(type, ideationBoardId, moodBoardId) {
    super(`content/ideation-board/${ideationBoardId}/mood-board/${moodBoardId}/images/`, type);
  }
  async add(id) {
    return await Api.post(`${this.path}`, { image: id }, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

class Video extends ContentFactoryBase {
  constructor(type, ideationBoardId, moodBoardId) {
    super(`content/ideation-board/${ideationBoardId}/mood-board/${moodBoardId}/videos/`, type);
  }
  async add(id) {
    return await Api.post(`${this.path}`, { video: id }, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

class Project extends ContentFactoryBase {
  constructor(type, ideationBoardId, moodBoardId) {
    super(`content/ideation-board/${ideationBoardId}/mood-board/${moodBoardId}/projects/`, type);
  }
  async add(id) {
    return await Api.post(`${this.path}`, { project: id }, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

class Product extends ContentFactoryBase {
  constructor(type, ideationBoardId, moodBoardId) {
    super(`content/ideation-board/${ideationBoardId}/mood-board/${moodBoardId}/products/`, type);
  }
  async add(id) {
    return await Api.post(`${this.path}`, { type: id }, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

class MoodBoardContentFactory {
  static createRequest(type, ideationBoardId, moodBoardId) {
    switch (type) {
      case "image":
        return new Image(type, ideationBoardId, moodBoardId);
      case "video":
        return new Video(type, ideationBoardId, moodBoardId);
      case "project":
        return new Project(type, ideationBoardId, moodBoardId);
      case "product":
        return new Product(type, ideationBoardId, moodBoardId);
      default:
        throw new Error(`Unsupported request type: ${type}`);
    }
  }
}

export default MoodBoardContentFactory;

import React from "react";
import ContentList from "../../components/Base/ContentList";
import { connect } from "react-redux";

const ContentImages = ({ user }) => (
  <ContentList type="image" initialFilters={{ user: user.id }} addModalSize="lg" />
);

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(ContentImages);

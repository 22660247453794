import React from "react";
import COLORS from "../../../constants/colors";

export default function BorderedButton({ onClick, children, style = {} }) {
  return (
    <div
      className="d-flex w-100 cursor-pointer"
      style={{
        border: "1px dashed #696969",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.GREY1,
        ...style
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

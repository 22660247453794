import React from "react";
import Form from "react-bootstrap/esm/Form";
import Stack from "react-bootstrap/esm/Stack";
import { useFormContext } from "react-hook-form";
import { Button } from "react-bootstrap";
import Phone from "components/Inputs/Phone";
import Email from "components/Inputs/Email";
import ResetPasswordForm from "forms/ResetPassword";
import OtpVerificationForm from "forms/OtpVerification";

export default function ForgotPasswordForm({ usePhone, onSubmit, showVerification }) {
  const { handleSubmit } = useFormContext();
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        {showVerification === undefined ? (
          <>
            {usePhone === true ? (
              <Form.Group>
                <Form.Label>Phone Number*</Form.Label>
                <Phone required />
              </Form.Group>
            ) : (
              <Form.Group>
                <Form.Label>Email Address*</Form.Label>
                <Email required />
              </Form.Group>
            )}
            <Button type="submit">CONTINUE</Button>
          </>
        ) : (
          <>
            <p className="mb-0">Enter new password</p>
            <ResetPasswordForm />
            <div className="mt-4 mb-3">
              <p className="p-0 mb-0">Please verify your phone number*</p>
              <Form.Text muted className="m-0 p-0">
                An OTP is sent to your phone number. Please input that OTP here. Please wait for
                minimum 5 min. If you did not receive it then click resend button.
              </Form.Text>
            </div>
            <OtpVerificationForm />
          </>
        )}
      </Stack>
    </Form>
  );
}

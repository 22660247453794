import React, { memo } from "react";
import Card from "react-bootstrap/esm/Card";
import EngagementStats from "../../EngagementStats";
import { useNavigationInfo } from "../../../hooks/route";
import { DATE_TEXT_STYLE, SUBTITLE_TEXT_STYLE } from "../default-styles";
import BaseContentCard, {
  BaseContentCardDetailsSection,
  CardImage,
  CardTitle,
  RenderBookmarkIcon,
  RenderSelect
} from "../../Base/Card";
import DateParser from "../../../date";
import { isFunction } from "lodash";

function Project({
  obj,
  onBookmarked,
  onAddedToIdeationBoard,
  isSelected,
  handleSelection,
  onClick,
  cardStyle = {},
  showSelect = true
}) {
  const { isMyProfileOrActivity } = useNavigationInfo();
  return (
    <BaseContentCard onClick={isFunction(onClick) && onClick} style={{ ...cardStyle }}>
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      <RenderBookmarkIcon
        onAddedToIdeationBoard={onAddedToIdeationBoard}
        obj={obj}
        onBookmarked={onBookmarked}
        type="project"
      />
      <CardImage thumbnail={obj.thumbnail} alt={obj.title} />
      <BaseContentCardDetailsSection>
        <Card.Text className="text-muted" style={DATE_TEXT_STYLE}>
          {DateParser.contentCardDateTimeString(obj.created)}
        </Card.Text>
        <CardTitle>{obj.title}</CardTitle>

        <Card.Subtitle className="text-muted" style={SUBTITLE_TEXT_STYLE}>
          {isMyProfileOrActivity ? obj.type : obj.user}
        </Card.Subtitle>
        <EngagementStats likes={obj.likes} saves={obj.saves} views={obj.views} />
      </BaseContentCardDetailsSection>
    </BaseContentCard>
  );
}

export default memo(Project);

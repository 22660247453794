import "./styles.scss";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import React from "react";
import { InputGroup } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useController, useFormContext } from "react-hook-form";
import { BiCalendar } from "react-icons/bi";
import FormError from "../../FormError";

export default function CustomDate({
  name,
  required,
  value,
  onChange,
  maxDate,
  minDate,
  format = "dd-MMM-yyyy",
  useReactHook = true
}) {
  var field;
  var errors;
  if (useReactHook === true) {
    const {
      control,
      formState: { errors: formErrors }
    } = useFormContext();
    const { field: hookField } = useController({
      name,
      control,
      rules: { required: required ? "This field is required" : undefined }
    });
    errors = formErrors;
    field = hookField;
  }
  return (
    <>
      <InputGroup>
        <InputGroup.Text>
          <BiCalendar size={22} />
        </InputGroup.Text>
        <DatePicker
          value={useReactHook === true ? field.value : value}
          onChange={useReactHook === true ? field.onChange : onChange}
          clearIcon={null}
          format={format}
          calendarIcon={null}
          maxDate={maxDate}
          className="form-control"
          minDate={minDate}
        />
      </InputGroup>
      {errors && errors[name] && <FormError>{errors[name].message}</FormError>}
    </>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { isProfileCompletionPercentageReached } from "utils";

function LockedContent({ children }) {
  const user = useSelector((state) => state.authReducer.user);
  const completed = isProfileCompletionPercentageReached(user);
  const { is_student, is_studio } = user;

  if (completed) {
    return children;
  } else {
    var warningContent = "";
    if (is_studio) {
      warningContent = "Please fill your Business Details to access this.";
    } else if (is_student) {
      warningContent = "Please fill your Personal Details to access this.";
    } else {
      warningContent = "Please fill your Basic and KYC Details to access this.";
    }
    return (
      <div className="d-flex justify-content-center mt-5 text-danger fw-bold">{warningContent}</div>
    );
  }
}

export default LockedContent;

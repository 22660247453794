import { call, put } from "@redux-saga/core/effects";
import { getIdeationBoardService } from "./services";
import ACTION_TYPES from "constants/action-types";

export function* getIdeationBoard() {
  try {
    const result = yield call(getIdeationBoardService);
    yield put({ type: ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_SUCCESS, result });
  } catch (error) {
    console.error(error);
    yield put({ type: ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_FAILURE, error });
  }
}

import ContentFactory from "apiClients/ContentFactory";
import CategoryCard from "components/Cards/Category";
import LoadingContainer from "components/Containers/Loading";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ProductsContainer from "./Container";
import { useSelector } from "react-redux";
import { useSearchParams } from "hooks/searchParams";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function AllCategories() {
  const [listings, setListings] = useState([]);
  const categoryClient = ContentFactory.createRequest("categories");
  const isLoadingFilters = useSelector((state) => state.productsReducer.isLoadingFilters);
  const { searchParamsAsObject, queryString } = useSearchParams();
  const getHomeCategories = () =>
    categoryClient.list({ ...searchParamsAsObject }).then(setListings);

  const categories = useSelector((state) => state.productsReducer.categories);
  const isLoadingCategories = useSelector((state) => state.productsReducer.isLoadingCategories);

  useEffect(() => {
    getHomeCategories();
  }, [queryString]);

  useEffect(() => {
    if (categories) {
      setListings(categories);
    }
  }, [categories]);

  return (
    <div className="w-100">
      <LoadingContainer loading={isLoadingCategories || isLoadingFilters}>
        <ProductsContainer className="pb-5" showBrandsWithProduct>
          <h6 className="py-4 mb-0">Filter By Categories</h6>
          <Row xs={2} sm={5}>
            {listings.map((el) => (
              <Col key={el.id} className="mb-4">
                <CategoryCard obj={el} />
              </Col>
            ))}
          </Row>
        </ProductsContainer>
      </LoadingContainer>
    </div>
  );
}
export default withMobileOnlySidebar(AllCategories);

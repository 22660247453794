import React, { useEffect } from "react";
import BaseModal from "../Base";
import { CloseButton, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import AddIdeationBoardForm from "../../../forms/AddIdeationBoard";
import { isNil } from "lodash";

export default function AddIdeationBoardModal({ show, onHide, ideationBoard }) {
  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue } = methods;

  useEffect(() => {
    if (!show) {
      methods.reset();
    }
    return () => {
      methods.reset();
    };
  }, [show]);

  useEffect(() => {
    if (ideationBoard) {
      setValue("folder_name", ideationBoard.folder_name);
      setValue("project_type", {
        label: ideationBoard.project_type,
        value: ideationBoard.project_type
      });
      setValue("project_stage", {
        label: ideationBoard.project_stage,
        value: ideationBoard.project_stage
      });
      setValue("location", ideationBoard.location);
      if (ideationBoard.input_project_area) {
        setValue("input_project_area", ideationBoard.input_project_area);
        setValue("input_project_area_unit", ideationBoard.input_project_area_unit);
      }
      setValue("description", ideationBoard.description);
    }
  }, [ideationBoard]);
  return (
    <BaseModal show={show} onHide={onHide} size="md">
      <div className="d-flex justify-content-end align-items-center pt-3 pb-1 pe-3">
        <CloseButton onClick={onHide} />
      </div>
      <Modal.Body>
        <FormProvider {...methods}>
          <AddIdeationBoardForm
            onSuccess={onHide}
            edit={!isNil(ideationBoard) ? ideationBoard.id : undefined}
            onCancel={onHide}
          />
        </FormProvider>
      </Modal.Body>
    </BaseModal>
  );
}

import React from "react";
import { useSearchParams } from "../../../hooks/searchParams";
import Dropdown from "../../Dropdown";

export default function EducationLevelFilter() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "education_level";

  const value = getKey(KEY);

  return (
    <Dropdown
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
      value={value}
      options={[
        { label: "PhD", value: "PhD" },
        { label: "Post Graduation", value: "Post Graduation" },
        { label: "Graduation", value: "Graduation" },
        { label: "Diploma", value: "Diploma" }
      ]}
      label="Education Level"
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

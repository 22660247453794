import BrandsWithProducts from "components/BrandsWithProducts";
import CategoryCard from "components/Cards/Category";
import LoadingContainer from "components/Containers/Loading";
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProductsContainer from "./Container";
import { isMobile } from "react-device-detect";
import withSidebar from "components/Containers/Sidebar";
import { useSelector } from "react-redux";
import { isArray } from "lodash";

function ProductsHome() {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const categories = useSelector((state) => state.productsReducer.categories);
  const isLoadingCategories = useSelector((state) => state.productsReducer.isLoadingCategories);

  useEffect(() => {
    if (categories && isArray(categories)) {
      setListings(categories.slice(0, 15));
    }
  }, [categories]);

  return (
    <div className="w-100">
      <LoadingContainer loading={isLoadingCategories}>
        <ProductsContainer className="pb-5" noBreadcrumbs>
          <h6 className="py-4 mb-0">Filter By Categories</h6>

          <Row xs={2} sm={5}>
            {listings.map((el) => (
              <Col key={el.id} className="mb-4">
                <CategoryCard obj={el} />
              </Col>
            ))}
          </Row>
          <Button
            className="d-block mx-auto rounded-0 px-5 mt-4"
            onClick={() => navigate("/products/categories")}
          >
            VIEW ALL CATEGORIES
          </Button>
          <div>
            <h6 className="mt-5 mb-1">Filter By Brands</h6>
            <BrandsWithProducts page_size={10} />
            <Button
              className="d-block mx-auto rounded-0 px-5 mt-4"
              onClick={() => navigate("/products/brands")}
            >
              VIEW ALL BRANDS
            </Button>
          </div>
        </ProductsContainer>
      </LoadingContainer>
    </div>
  );
}

let retval = ProductsHome;
if (isMobile) {
  retval = withSidebar(ProductsHome);
}

export default retval;

import { isArray, isEmpty, isObject, isUndefined } from "lodash";
import React from "react";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ACCEPTED_FILE_TYPES from "../../../constants/accepted-file-types";
import PdfPreview from "../../PdfPreview";
import ReactPlayer from "react-player";
import FormError from "../../FormError";
import { useFormContext } from "react-hook-form";
import COLORS from "../../../constants/colors";
import UPLOAD_ICON from "../../../assets/icons/upload.png";
import { DELETE_ICON } from "constants/icons";
import { ReactSVG } from "react-svg";

export const checkFileRejections = (setError, maxSizeMB, name, type, fileRejections) => {
  var rejected = false;
  if (fileRejections.length > 0) {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          setError(name, {
            type: "manual",
            message: `File size of ${file.file.name} exceeds ${maxSizeMB}MB limit.`
          });
          rejected = true;
        }

        if (err.code === "file-invalid-type") {
          setError(name, {
            type: "manual",
            message: `Invalid file type for ${file.file.name}. Allowed types: ${type}.`
          });
          rejected = true;
        }
      });
    });
  }
  return rejected;
};

export default function BaseUpload({
  name,
  file,
  setFile,
  type,
  containerHeight,
  multiple,
  maxSizeMB,
  preferredText
}) {
  const {
    formState: { errors },
    clearErrors,
    setError
  } = useFormContext();

  const handleFileUpload = (obj) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(obj);
    });

  const onDrop = async (acceptedFiles, fileRejections) => {
    var files = [];
    if (isArray(file)) {
      files = [...file];
    }

    const isFileRejected = checkFileRejections(setError, maxSizeMB, name, type, fileRejections);
    if (isFileRejected) {
      return;
    } else if (errors && isObject(errors[name])) {
      clearErrors(name);
    }
    for (var i = 0; i < acceptedFiles.length; i++) {
      const obj = await handleFileUpload(acceptedFiles[i]);
      if (multiple) {
        files.push(obj);
      } else {
        files = [obj];
      }
    }
    setFile(files);
  };
  var types = type.split(",");
  var accept = {};
  types.forEach((el) => {
    accept = { ...accept, ...ACCEPTED_FILE_TYPES[el] };
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
    maxSize: maxSizeMB * 1024 * 1024
  });

  return (
    <div className="w-100 mb-3">
      <Container
        style={{
          backgroundColor: COLORS.GREY1,
          border: `2px dashed ${COLORS.GREY2}`,
          minHeight: file ? "max-content" : containerHeight || "12rem"
        }}
        {...getRootProps()}
        className={`d-flex rounded justify-content-center cursor-pointer align-items-center drop-box w-100 h-100 ${
          isDragActive ? "drag-active" : ""
        }`}
      >
        <input {...getInputProps()} />

        {file && file.length > 0 ? (
          <Row className="w-100 mx-0">
            {file.map((fileItem, index) => (
              <Col key={`${fileItem}-${index}`} className="w-100 px-0">
                {isArray(file) && !isEmpty(file) && file[0].includes("image") ? (
                  <div className="position-relative">
                    {multiple && (
                      <ReactSVG
                        src={DELETE_ICON}
                        style={{ width: "2rem", position: "absolute", top: 0, right: 0 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          var newFile = [...file];
                          newFile.splice(index, 1);
                          setFile(newFile);
                        }}
                      />
                    )}
                    <div>
                      <Image
                        src={fileItem}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        className="d-block mx-auto"
                      />
                    </div>
                  </div>
                ) : isArray(file) && !isEmpty(file) && file[0].includes("pdf") ? (
                  <PdfPreview file={fileItem} />
                ) : (
                  <ReactPlayer url={fileItem} loop width="100%" height="100%" controls />
                )}
              </Col>
            ))}
          </Row>
        ) : (
          <Row style={{ height: "15rem" }}>
            <Col className="my-auto d-block">
              <Image src={UPLOAD_ICON} className="d-block mx-auto mb-2" style={{ width: "3rem" }} />
              {isDragActive ? (
                <p>Drop the files here</p>
              ) : (
                <p>
                  Drop files or{" "}
                  <span className="text-decoration-underline text-primary cursor-pointer">
                    Choose Files
                  </span>
                </p>
              )}
            </Col>
          </Row>
        )}
      </Container>
      {type && (
        <Form.Text>
          <span className="text-capitalize"> {type}</span> Format:{" "}
          {Object.keys(accept)
            .map((el) => (el.includes("/") === true ? el.split("/")[1] : el.split(".")[1]))
            .join(", ")}
          {maxSizeMB && <span className="ms-3">Max Size: {maxSizeMB}MB</span>}
          {preferredText && <span className="ms-3">{preferredText}</span>}
        </Form.Text>
      )}
      {!isUndefined(errors) && isObject(errors) && errors[name] && (
        <FormError error={errors[name].message} className="d-block text-center mt-1" />
      )}
    </div>
  );
}

import React from "react";
import { Table as BootstrapTable, Form } from "react-bootstrap";
import { flexRender, useReactTable, getCoreRowModel } from "@tanstack/react-table";
import COLORS from "constants/colors";

function EditableTable({ data, columns, setData, ...props }) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const initialValue = getValue();
        const [value, setValue] = React.useState(initialValue);
        const [edit, setEdit] = React.useState(false);
        const inputRef = React.useRef();

        const onBlur = (e) => {
          e.stopPropagation();
          setEdit(false);
          table.options.meta.updateData(index, id, value);
        };

        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        React.useEffect(() => {
          if (edit === true) {
            inputRef.current.focus();
          }
        }, [edit]);
        return edit === true ? (
          <Form.Control
            ref={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        ) : (
          <div onClick={() => setEdit(true)}>{getValue()}</div>
        );
      }
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setData((old) => {
          return old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              };
            }
            return row;
          });
        });
      }
    },
    debugTable: true
  });

  return (
    <BootstrapTable striped="columns" {...props}>
      <thead>
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={`header-${index}-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="text-center text-nowrap px-4 text-capitalize"
                style={{ fontWeight: "600", color: COLORS.GREY2 }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext()).replace(
                      /_/g,
                      " "
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr key={`row-${rowIndex}-${row.id}`} className="text-center w-100">
            {row.getAllCells().map((cell) => {
              return (
                <td
                  style={{ minWidth: cell.id.includes("Action") ? undefined : "15rem" }}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </BootstrapTable>
  );
}

export default EditableTable;

const BLOGS_CONSTANTS = {
  CATEGORIES: [
    { label: "Architectural", value: "Architectural" },
    { label: "Interior Designing", value: "Interior Designing" },
    { label: "Civil Engineering", value: "Civil Engineering" },
    { label: "Photography", value: "Photography" },
    { label: "Construction", value: "Construction" },
    { label: "Manufacturing", value: "Manufacturing" }
  ]
};

export default BLOGS_CONSTANTS;

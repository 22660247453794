import React from "react";
import ContentList from "../../components/Base/ContentList";
import AddThesisForm from "../../forms/Content/AddThesis";
import { connect } from "react-redux";

function ContentThesis({ user }) {
  return (
    <ContentList
      AddForm={AddThesisForm}
      type="thesis"
      addText="Add New File"
      title="Theses/Dissertations"
      subTitle="Display your theses/dissertations"
      initialFilters={{ user: user.id }}
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(ContentThesis);

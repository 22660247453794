import COLORS from "constants/colors";
import React from "react";

export default function Pill({
  label,
  onClick,
  backgroundColor = COLORS.PRIMARY_ORANGE,
  color = "white"
}) {
  return (
    <p
      style={{
        width: "max-content",
        color,
        backgroundColor,
        fontWeight: "600",
        whiteSpace: "nowrap"
      }}
      className="px-4 mb-0 cursor-pointer py-2 rounded-pill"
      onClick={onClick}
    >
      {label}
    </p>
  );
}

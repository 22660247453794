import React from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useFormContext } from "react-hook-form";

export default function DetailsFormContainer({ children, onSubmit }) {
  const { handleSubmit } = useFormContext();
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4} className="mt-2">
        {children}
        <div className="d-block">
          <div className="mb-3"></div>
          <Button
            variant="primary"
            type="submit"
            className={isMobile ? "w-100" : "d-block ms-auto px-5"}
          >
            SAVE
          </Button>
        </div>
      </Stack>
    </Form>
  );
}

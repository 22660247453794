import React, { useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isString, isUndefined } from "lodash";
import { useAuth } from "hooks";
import Email from "components/Inputs/Email";
import { validateEmail } from "validators";
import Phone from "components/Inputs/Phone";
import { validatePhoneNumber } from "validators";
import OtpVerification from "components/Modals/OtpVerification";
import { Element } from "react-scroll";

function ProfileContact({
  user,
  setShowEmailVerifyOTP,
  setShowMobileVerifyOTP,
  setShowSecondaryEmailVerifyOTP,
  setShowSecondaryMobileVerifyOTP,
  showEmailVerifyOTP,
  showMobileVerifyOTP,
  showSecondaryEmailVerifyOTP,
  showSecondaryMobileVerifyOTP
}) {
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(user.secondary_email ? true : false);
  const [showSecondaryPhone, setShowSecondaryPhone] = useState(
    user.secondary_mobile ? true : false
  );
  const { sendOTP } = useAuth();

  return (
    <Element name="verification">
      <Stack gap={4}>
        <Form.Group>
          <Form.Label>Email ID*</Form.Label>
          <Email
            name="email"
            handleVerify={
              user.is_email_verified === false
                ? () => sendOTP(user.email, setShowEmailVerifyOTP)
                : undefined
            }
            showSecondaryEmail={showSecondaryEmail}
            setShowSecondaryEmail={setShowSecondaryEmail}
          />
        </Form.Group>
        {showSecondaryEmail === true && (
          <Form.Group>
            <Form.Label>Secondary Email ID</Form.Label>
            <Email
              name="secondary_email"
              handleVerify={
                isString(user.secondary_email) &&
                !isEmpty(user.secondary_email) &&
                validateEmail(user.secondary_email) &&
                user.is_secondary_email_verified === false
                  ? () => sendOTP(user.secondary_email, setShowSecondaryEmailVerifyOTP)
                  : undefined
              }
              required={false}
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Contact Number*</Form.Label>
          <Phone
            handleVerify={
              user.is_mobile_verified === false
                ? () => sendOTP(user.mobile, setShowMobileVerifyOTP)
                : undefined
            }
            showSecondaryPhone={showSecondaryPhone}
            setShowSecondaryPhone={setShowSecondaryPhone}
          />
        </Form.Group>
        {showSecondaryPhone === true && (
          <Form.Group>
            <Form.Label>Secondary Contact Number</Form.Label>
            <Phone
              name="secondary_mobile"
              handleVerify={
                isString(user.secondary_mobile) &&
                !isEmpty(user.secondary_mobile) &&
                validatePhoneNumber(user.secondary_mobile) &&
                !user.is_secondary_mobile_verified
                  ? () => sendOTP(user.secondary_mobile, setShowSecondaryMobileVerifyOTP)
                  : undefined
              }
              required={false}
            />
          </Form.Group>
        )}
        <OtpVerification
          show={
            showEmailVerifyOTP ||
            showMobileVerifyOTP ||
            showSecondaryEmailVerifyOTP ||
            showSecondaryMobileVerifyOTP
          }
          setShow={
            !isUndefined(showEmailVerifyOTP)
              ? setShowEmailVerifyOTP
              : !isUndefined(showSecondaryEmailVerifyOTP)
              ? setShowSecondaryEmailVerifyOTP
              : !isUndefined(showMobileVerifyOTP)
              ? setShowMobileVerifyOTP
              : !isUndefined(showSecondaryMobileVerifyOTP)
              ? setShowSecondaryMobileVerifyOTP
              : undefined
          }
        />
      </Stack>
    </Element>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

export default connect(mapStateToProps)(ProfileContact);

import React, { memo } from "react";
import BaseContainer from "../../Containers/BaseContainer";
import { useNavigationInfo } from "../../../hooks/route";
import SearchList from "../SearchList";
import LockedContent from "../../Containers/LockedContent";
import ContentHeader from "../ContentHeader";
import { isMobile } from "react-device-detect";

function ContentList({
  doNotShowAdd,
  type,
  addText,
  title,
  subTitle,
  handleAdd,
  addModalSize,
  initialFilters = {},
  cardProps = {}
}) {
  const { isActivity } = useNavigationInfo();
  return (
    <BaseContainer className="py-4">
      <div className={`px-${isMobile ? 0 : 3}`}>
        <ContentHeader title={title} subTitle={subTitle} type={type} />
      </div>
      <LockedContent>
        <SearchList
          cardProps={cardProps}
          type={type}
          addText={addText || `Add ${isActivity ? "More" : "New"} ${type}${isActivity ? "s" : ""}`}
          initialFilters={{ ...initialFilters }}
          handleAdd={handleAdd}
          doNotShowAdd={doNotShowAdd}
          addModalSize={addModalSize}
        />
      </LockedContent>
    </BaseContainer>
  );
}

export default memo(ContentList);

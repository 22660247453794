import React, { useEffect } from "react";
import { Container, Stack } from "react-bootstrap";
import Image from "../components/Cards/Image";
import Video from "../components/Cards/Video";
import Thesis from "../components/Cards/Thesis";
import Blog from "../components/Cards/Blog";
import TypeFilter from "../components/TypeFilter";
import SearchList from "../components/Base/SearchList";
import AdvertisementCarousel from "../components/AdvertisementCarousel";
import { connect, useSelector } from "react-redux";
import ACTION_TYPES from "../constants/action-types";
import PinnedBlogs from "../components/PinnedBlogs";
import { useNavigate } from "react-router-dom";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function Learn({ getPinnedBlogs, type }) {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const contentCards = { image: Image, video: Video, thesis: Thesis, blog: Blog };
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn === true) {
      getPinnedBlogs();
    }
  }, [isLoggedIn]);

  return (
    <div className="w-100">
      <div className="w-100">
        <AdvertisementCarousel where="learn" />
      </div>
      <Stack gap={3}>
        <TypeFilter
          types={[
            { value: "image", label: "Images" },
            { value: "video", label: "Videos" },
            { value: "blog", label: "Blogs" },
            { value: "thesis", label: "Thesis & Dissertations" }
          ]}
          value={type}
          onSelect={(value) => navigate(`/learn/${value}${value === "thesis" ? "" : "s"}`)}
        />
        <Container className="mt-1 px-3">
          <SearchList
            type={type}
            CardComponent={contentCards[type]}
            initialFilters={{ is_posted: true }}
          />
        </Container>
      </Stack>
      {type === "blog" && <PinnedBlogs />}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPinnedBlogs: () => dispatch({ type: ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_REQUEST })
});

export default connect(null, mapDispatchToProps)(withMobileOnlySidebar(Learn));

import ProductCategoriesMultilevelFilter from "components/Filters/ProductCategoriesMultilevelFilter";
import React, { useEffect } from "react";
import { Stack } from "react-bootstrap";
import BrandsFilter from "components/Filters/BrandsFilter";
import AsyncSearchFilter from "components/Filters/AsyncSearchFilter";
import { useScrollToTop } from "hooks";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ACTION_TYPES from "constants/action-types";
import ContentFactory from "apiClients/ContentFactory";
import { isMobile } from "react-device-detect";

export default function ProductFilters() {
  const filters = useSelector((state) => state.productsReducer.filters);
  const categories = useSelector((state) => state.productsReducer.categories);

  console.log("Categories: ", categories);

  const dispatch = useDispatch();
  const { scrollToTop } = useScrollToTop();
  const navigate = useNavigate();

  useEffect(() => {
    if (!filters) {
      dispatch({ type: ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_REQUEST });
    }
    if (!categories) {
      dispatch({ type: ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_REQUEST });
    }
  }, []);

  const categoryClick = (obj, level) => {
    const { category, parent, subParent } = obj;
    scrollToTop();

    switch (level) {
      case 0:
        navigate(`/products/categories/${category.slug}`);
        break;
      case 1:
        navigate(`/products/categories/${parent.slug}/${category.slug}`);
        break;
      case 2:
        navigate(`/products/categories/${parent.slug}/${subParent.slug}/${category.slug}`);
        break;
      default:
        console.debug("DEFAULT");
    }
  };

  const SearchComponent = () => (
    <AsyncSearchFilter
      client={ContentFactory.createRequest("product")}
      labelKey="product_name"
      maxResults={10}
      renderMenuItemChildren={(option) => {
        const { product_name, categories, slug } = option;
        var url = "/products/categories";
        if (categories) {
          if (categories.parent && categories.parent.slug) {
            url = url + `/${categories.parent.slug}`;
          }
          if (categories.sub_category) {
            url = url + `/${categories.sub_category.slug}`;
          }
          if (categories.sub_sub_category) {
            url = url + `/${categories.sub_sub_category.slug}`;
          }
        }
        url = url + `/${slug}`;
        return (
          <div className="py-3" onClick={() => navigate(url)}>
            <h6>{product_name}</h6>
          </div>
        );
      }}
    />
  );
  return (
    filters && (
      <>
        <div
          style={{ background: "#F5F5F5", height: "80px" }}
          className="d-flex align-items-center justify-content-between px-4"
        >
          {categories && (
            <Stack direction="horizontal" gap={5}>
              {categories && (
                <ProductCategoriesMultilevelFilter
                  categories={categories}
                  onClick={categoryClick}
                />
              )}
              {filters.brands && <BrandsFilter brands={filters.brands} />}
            </Stack>
          )}
          {!isMobile && (
            <div className="w-50">
              <SearchComponent />
            </div>
          )}
        </div>
        {isMobile && (
          <div style={{ width: "95%", margin: "auto" }} className="mt-3">
            <SearchComponent />
          </div>
        )}
      </>
    )
  );
}

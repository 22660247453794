const DEGREES = {
  Graduation: [
    "Bachelor of Architecture(B.Arch)",
    "Bachelor of Interior Design/Bachelor of Design",
    "B.E/B.Tech in Civil Engineering",
    "B.E/B.Tech in Mechanical Engineering",
    "B.E/B.Tech in Electrical Engineering",
    "B.E/B.Tech in Structural Engineering"
  ].map((program) => ({ value: program, label: program })),
  "Post Graduation": [
    "Master of Architecture(M.Arch)",
    "Master of Interior Design/Master of Design",
    "Master of Engg./Tech(M.E/M.Tech)"
  ].map((program) => ({ value: program, label: program })),
  PhD: ["PhD"].map((program) => ({ value: program, label: program })),
  Diploma: [
    "Architecture",
    "Interior Designing/Design",
    "Civil Engineering",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Structural Engineering"
  ].map((program) => ({ value: program, label: program }))
};

export default DEGREES;

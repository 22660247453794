import React from "react";
import BaseModal from "../Base";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmDeletionModal({
  show,
  type,
  onHide,
  isPlural,
  onCancel,
  onConfirm,
  hideSubTitle = false,
  size = "md"
}) {
  return (
    <BaseModal show={show} onHide={onHide} centered size={size}>
      <Modal.Body>
        <Modal.Title className="text-center mb-4">
          Are you sure you want to delete the selected{" "}
          <span className="text-lowercase fw-bold">
            {type}
            {isPlural ? "s" : ""}
          </span>
          ?
        </Modal.Title>
        {!hideSubTitle && (
          <p className="text-center mx-auto">
            Please note that it will take some time to process your request.
          </p>
        )}
        <div className="d-flex justify-content-evenly mt-4">
          <Button
            variant="outline-primary"
            style={{ width: "45%" }}
            className="text-uppercase"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: "45%" }}
            className="text-uppercase"
            onClick={onConfirm}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import AllImages from "../../screens/AllContent/Image";
import AllVideos from "../../screens/AllContent/Video";
import AllProjects from "../../screens/AllContent/Project";
import AllThesis from "../../screens/AllContent/Thesis";
import AllPortfolios from "../../screens/AllContent/Portfolio";
import AllBlogs from "../../screens/AllContent/Blog";
import AllProducts from "../../screens/AllContent/Product";

export default function AllContentRoutes() {
  return (
    <Routes>
      <Route path="images" element={<AllImages />} />
      <Route path="videos" element={<AllVideos />} />
      <Route path="projects" element={<AllProjects />} />
      <Route path="products" element={<AllProducts />} />
      <Route path="thesiss" element={<AllThesis />} />
      <Route path="portfolios" element={<AllPortfolios />} />
      <Route path="blogs" element={<AllBlogs />} />
    </Routes>
  );
}

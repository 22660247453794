import React, { memo, useState } from "react";
import Card from "react-bootstrap/esm/Card";
import EngagementStats from "../../EngagementStats";
import BookmarkIcon from "../../Icons/BookmarkIcon/index.jsx";
import { useNavigationInfo } from "../../../hooks/route";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import COLORS from "../../../constants/colors";
import BaseContentCard, {
  BaseContentCardDetailsSection,
  BookmarkIconContainer,
  CardImage,
  CardTitle,
  RenderSelect,
  ReviewRejected
} from "../../Base/Card";
import { DATE_TEXT_STYLE, SUBTITLE_TEXT_STYLE } from "../default-styles";
import { ThreeDotsDropdown } from "../../Buttons/ThreeDotsVertical";
import DateParser from "../../../date";
import ContentFactory from "../../../apiClients/ContentFactory";
import { renderErrorToast, showPromiseToast } from "../../../utils";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";

function DraftBlogDropdown({ handleDelete, handleEdit }) {
  return (
    <ThreeDotsDropdown
      size={40}
      options={[
        { onClick: handleEdit, label: "Edit" },
        { onClick: handleDelete, label: "Delete" }
      ]}
    />
  );
}

function Blog({
  obj,
  isSelected,
  handleSelection,
  onBookmarked,
  onDelete,
  engagementStatsIconWidth,
  engagementStatsIconHeight,
  cardStyle = {},
  showSelect = true
}) {
  const { isLearn, isUnderReview, isMyProfile, isSampleProfile } = useNavigationInfo();
  const navigate = useNavigate();
  const { is_posted, under_review, rejected, review_not_accepted_response } = obj;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEdit = () => navigate(`/my-activity/my-blogs/drafted/update/${obj.slug}`);

  const handleDelete = () => {
    const promise = ContentFactory.createRequest("blog")
      .remove(obj.slug)
      .then(onDelete)
      .then(() => setShowDeleteModal(false))
      .then(() => navigate("/my-activity/my-blogs/posted"));
    return showPromiseToast(promise, {
      pending: "Deleting blog",
      success: "Blog deleted successfully",
      error: renderErrorToast()
    });
  };
  const goToBlog = () => navigate(`/blogs/${obj.slug}`);

  return (
    <BaseContentCard
      onClick={obj.is_posted === true && !isSampleProfile && goToBlog}
      style={{ ...cardStyle }}
    >
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      {!isSampleProfile && (
        <BookmarkIconContainer>
          {!isLearn && !isMyProfile && (under_review || rejected) ? (
            <div
              className="rounded-circle px-1 cursor-pointer"
              onClick={() => setShowDeleteModal(true)}
              style={{ backgroundColor: COLORS.GREY1 }}
            >
              <RxCross2 color={COLORS.GREY2} />
            </div>
          ) : is_posted ? (
            <BookmarkIcon obj={obj} type="blog" onBookmarked={onBookmarked} />
          ) : (
            <DraftBlogDropdown
              handleDelete={() => setShowDeleteModal(true)}
              handleEdit={handleEdit}
            />
          )}
        </BookmarkIconContainer>
      )}
      <CardImage thumbnail={obj.thumbnail} alt={obj.title} />
      <BaseContentCardDetailsSection>
        <Card.Text className="text-muted" style={DATE_TEXT_STYLE}>
          {DateParser.contentCardDateTimeString(obj.created)}
        </Card.Text>
        <CardTitle>{obj.title}</CardTitle>
        <Card.Subtitle className="text-muted" style={SUBTITLE_TEXT_STYLE}>
          {obj.author}
        </Card.Subtitle>
        <EngagementStats
          likes={obj.likes}
          saves={obj.saves}
          views={obj.views}
          width={engagementStatsIconWidth}
          height={engagementStatsIconHeight}
        />
        {isUnderReview && (
          <ReviewRejected
            is_posted={is_posted}
            under_review={under_review}
            rejected={rejected}
            review_not_accepted_response={review_not_accepted_response}
          />
        )}
      </BaseContentCardDetailsSection>
      <ConfirmDeletionModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        type="blog"
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />
    </BaseContentCard>
  );
}

export default memo(Blog);

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import BaseModal from "../Base";
import COLORS from "../../../constants/colors";
import ContentFactory from "../../../apiClients/ContentFactory";
import { FaCheck } from "react-icons/fa";
import { convertDataUrlToBase64, getContentAbsoluteUrl } from "../../../utils";
import { isArray, isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";

export default function BlogTemplatesModal({ show, onHide }) {
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const { setValue } = useFormContext();
  useEffect(() => {
    if (show === true) {
      ContentFactory.createRequest("blog").getTemplates().then(setTemplates);
    }
  }, [show]);

  const onSubmit = async () => {
    const image = templates.filter((el) => el.id === selected);
    if (isArray(image) && !isEmpty(image)) {
      const data = await convertDataUrlToBase64(image[0].image);
      setValue("thumbnail", [data]);
      onHide();
    }
  };
  const solidBackgrounds = templates.filter((el) => el.is_solid === true);
  const imageBackgrounds = templates.filter((el) => !el.is_solid);

  const solidBackgroundsRow = (
    <Row xs={6} className="pb-5">
      {solidBackgrounds.map((el, index) => (
        <Col
          key={`${el}-${index}`}
          className="p-1 d-flex justify-content-center align-items-center me-3"
        >
          <div
            style={selected === el.id ? { border: `1.5px solid ${COLORS.PRIMARY_ORANGE}` } : {}}
            onClick={() => setSelected(el.id)}
            className="cursor-pointer position-relative p-1  w-100 h-100"
          >
            {el.id === selected && (
              <div
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  right: "0",
                  top: "0"
                }}
                className="bg-orange position-absolute rounded-circle d-flex justify-content-center align-items-center"
              >
                <FaCheck color="white" size={12} />
              </div>
            )}
            <Image
              src={getContentAbsoluteUrl(el.image)}
              className="w-100 h-100 object-fit-contain"
            />
          </div>
        </Col>
      ))}
    </Row>
  );

  const imageBackgroundsRow = (
    <Row xs={6} className="pb-4">
      {imageBackgrounds.map((el, index) => (
        <Col
          key={`${el}-${index}`}
          className="p-1 d-flex justify-content-center align-items-center"
        >
          <div
            style={selected === el.id ? { border: `1.5px solid ${COLORS.PRIMARY_ORANGE}` } : {}}
            onClick={() => setSelected(el.id)}
            className="cursor-pointer position-relative p-1  w-100 h-100"
          >
            {el.id === selected && (
              <div
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  right: "0",
                  top: "0"
                }}
                className="bg-orange position-absolute rounded-circle d-flex justify-content-center align-items-center"
              >
                <FaCheck color="white" size={12} />
              </div>
            )}
            <Image
              src={getContentAbsoluteUrl(el.image)}
              className="w-100 h-100 object-fit-contain"
            />
          </div>
        </Col>
      ))}
    </Row>
  );
  return (
    <BaseModal centered show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton className="fw-bold" style={{ fontSize: "1.5rem" }}>
        Choose Thumbnail
      </Modal.Header>
      <Modal.Body>
        <Container className="px-5 pt-4">
          <h6>Image Thumbnail</h6>
          {imageBackgroundsRow}
          <h6>Solid Backgrounds</h6>
          {solidBackgroundsRow}
          <Button className="d-block px-4 ms-auto mb-4" onClick={onSubmit}>
            APPLY
          </Button>
        </Container>
      </Modal.Body>
    </BaseModal>
  );
}

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isString } from "lodash";
import { useScrollToTop } from "hooks";
import { useAuth } from "hooks";
import UserProfileAPIClient from "apiClients/Profile/User";
import StudioBusinessDetailsAPI from "apiClients/Profile/Studio/BusinessDetails";
import { getUserProfilePayload, renderErrorToast } from "utils";
import { showPromiseToast } from "utils";
import DetailsFormContainer from "components/Containers/ProfileDetailsForm";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import { COMPANY_TYPES } from "constants/Profile/Studio";
import { YEARS } from "constants/Profile/Studio";
import ProfileContact from "forms/ProfileContact";
import AddressForm from "forms/Address";
import GSTINInput from "components/Inputs/GSTIN";
import PanInput from "components/Inputs/Pan";
import SocialMediaLinks from "forms/SocialMediaLinks";
import ACTION_TYPES from "constants/action-types";
import { scroller } from "react-scroll";
import { useFormContext } from "react-hook-form";

function BusinessDetailsForm({ user, handleSetValues, updateUser }) {
  const { scrollToTop } = useScrollToTop();
  const { validateContactInfo } = useAuth();
  const { setError } = useFormContext();

  const [showMobileVerifyOTP, setShowMobileVerifyOTP] = useState(undefined);
  const [showSecondaryMobileVerifyOTP, setShowSecondaryMobileVerifyOTP] = useState(undefined);
  const [showEmailVerifyOTP, setShowEmailVerifyOTP] = useState(undefined);
  const [showSecondaryEmailVerifyOTP, setShowSecondaryEmailVerifyOTP] = useState(undefined);

  const userClient = new UserProfileAPIClient();

  const update = (data) => {
    var payload = {
      ...data
    };
    ["company_type", "establishment_year", "city", "state"].forEach(
      (i) => (payload[i] = payload[i].value)
    );

    Object.keys(payload).forEach((el) => {
      if (isString(payload[el]) && isEmpty(payload[el])) {
        payload[el] = null;
      }
    });

    const userProfilePayload = getUserProfilePayload(user, data, data.name);
    var promises = [new StudioBusinessDetailsAPI().update(payload)];
    if (Object.keys(userProfilePayload).length > 0) {
      promises.push(userClient.updateProfile(userProfilePayload));
    }
    const promise = Promise.all(promises).then((result) => {
      handleSetValues(result[0]);
      if (result.length == 2) {
        updateUser(result[1]);
      }
      scrollToTop();
    });
    showPromiseToast(promise, {
      pending: "Saving business details",
      success: "Business details have been saved successfully",
      error: renderErrorToast()
    });
  };

  const onSubmit = (data) => {
    const isEmailVerificationOpen = showEmailVerifyOTP !== undefined;
    const isSecondaryEmailVerificationOpen = showSecondaryEmailVerifyOTP !== undefined;
    const isMobileVerificationOpen = showMobileVerifyOTP !== undefined;
    const isSecondaryMobileVerificationOpen = showSecondaryMobileVerifyOTP !== undefined;

    if (isEmailVerificationOpen) {
      validateContactInfo({ email: showEmailVerifyOTP, otp: data.otp }, () =>
        setShowEmailVerifyOTP(undefined)
      );
    } else if (isSecondaryEmailVerificationOpen) {
      validateContactInfo({ secondary_email: showSecondaryEmailVerifyOTP, otp: data.otp }, () =>
        setShowSecondaryEmailVerifyOTP(undefined)
      );
    } else if (isMobileVerificationOpen) {
      validateContactInfo({ mobile: showMobileVerifyOTP, otp: data.otp }, () =>
        setShowMobileVerifyOTP(undefined)
      );
    } else if (isSecondaryMobileVerificationOpen) {
      validateContactInfo({ secondary_mobile: showSecondaryMobileVerifyOTP, otp: data.otp }, () =>
        setShowSecondaryMobileVerifyOTP(undefined)
      );
    } else {
      if (user.is_email_verified !== true) {
        setError("email", { message: "Please verify your primary email to contiue" });
        scroller.scrollTo("verification");
      } else {
        update(data);
      }
    }
  };
  return (
    <DetailsFormContainer onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Company Name*</Form.Label>
        <ReactHookInput
          name="name"
          type="text"
          placeholder="Company Name"
          required={user.is_email_verified}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Company Type*</Form.Label>
        <Select options={COMPANY_TYPES} name="company_type" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Company Description*</Form.Label>
        <ReactHookInput
          name="company_description"
          as="textarea"
          required={user.is_email_verified}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Principal Designer*</Form.Label>
        <ReactHookInput
          name="principal_designer"
          type="text"
          placeholder="Principal Designer"
          required={user.is_email_verified}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Company Website Link</Form.Label>
        <ReactHookInput name="website_link" type="url" placeholder="Company Website Link" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Established on*</Form.Label>
        <Select options={YEARS} name="establishment_year" />
      </Form.Group>
      {user && (
        <ProfileContact
          setShowEmailVerifyOTP={setShowEmailVerifyOTP}
          setShowSecondaryEmailVerifyOTP={setShowSecondaryEmailVerifyOTP}
          setShowMobileVerifyOTP={setShowMobileVerifyOTP}
          setShowSecondaryMobileVerifyOTP={setShowSecondaryMobileVerifyOTP}
          showEmailVerifyOTP={showEmailVerifyOTP}
          showSecondaryEmailVerifyOTP={showSecondaryEmailVerifyOTP}
          showMobileVerifyOTP={showMobileVerifyOTP}
          showSecondaryMobileVerifyOTP={showSecondaryMobileVerifyOTP}
        />
      )}
      <AddressForm />
      <GSTINInput />
      <PanInput required={user.is_email_verified} />
      <Form.Group>
        <Form.Label>
          Social Media Links* <span>(at least one required)</span>
        </Form.Label>
        <SocialMediaLinks />
      </Form.Group>
    </DetailsFormContainer>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch({ type: ACTION_TYPES.AUTH.UPDATE_USER, user })
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetailsForm);

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";
import isString from "lodash/isString";

export default function HorizontalLineWithText({ text }) {
  return (
    <Row className="vertical-line">
      <Col>
        <div className="line"></div>
      </Col>
      {isString(text) && (
        <Col xs="auto" className="or-text">
          {text}
        </Col>
      )}
      <Col>
        <div className="line"></div>
      </Col>
    </Row>
  );
}

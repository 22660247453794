import Dropdown from "components/Dropdown";
import { useSearchParams } from "hooks/searchParams";
import React from "react";

export default function CategoryFilter({ options }) {
  const { addKey, getKey } = useSearchParams();
  const KEY = "category";

  const value = getKey(KEY);

  return (
    <Dropdown
      value={value}
      options={options}
      label="Category"
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

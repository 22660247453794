import React from "react";
import ContentVideos from "../../screens/UserContent/Videos";
import ContentPortfolio from "../../screens/UserContent/Portfolios";
import { SIDEBAR_ICONS } from "../../constants/icons";
import ROUTE_CONSTANTS from "../../constants/routes";
import BasicDetails from "../../screens/ProfileDetails/Brand/BasicDetails";
import KycDetails from "../../screens/ProfileDetails/Brand/KycDetails";
import BranchDetails from "../../screens/ProfileDetails/Brand/BranchDetails";
import DistributionNetworks from "../../screens/ProfileDetails/Brand/DistributionNetworks";
import Categories from "../../screens/ProfileDetails/Brand/Categories";
import Catalogues from "../../screens/UserContent/Catalogues";
import ContentARVR from "../../screens/UserContent/ARVR";
import ContentProducts from "../../screens/UserContent/Products";

const {
  ROUTE_PREFIXES: { MY_PROFILE }
} = ROUTE_CONSTANTS;

var BRAND_PROFILE_ROUTES = [
  {
    label: "Profile Details",
    path: "profile-details",
    icon: SIDEBAR_ICONS.PERSONAL_DETAILS_SIDEBAR_ICON,
    notFoundRoute: `/${MY_PROFILE}/profile-details/basic-details`,
    defaultRoute: `/${MY_PROFILE}/profile-details/basic-details`,
    subMenu: [
      {
        label: "Basic Details",
        component: <BasicDetails />,
        path: "/profile-details/basic-details",
        menuPath: `/${MY_PROFILE}/profile-details/basic-details`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "KYC Details",
        component: <KycDetails />,
        path: "/profile-details/kyc-details",
        menuPath: `/${MY_PROFILE}/profile-details/kyc-details`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "Branch Details",
        component: <BranchDetails />,
        path: "/profile-details/branch-details",
        menuPath: `/${MY_PROFILE}/profile-details/branch-details`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "Distribution Networks",
        component: <DistributionNetworks />,
        path: "/profile-details/distribution-networks",
        menuPath: `/${MY_PROFILE}/profile-details/distribution-networks`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "Categories",
        component: <Categories />,
        path: "/profile-details/categories",
        menuPath: `/${MY_PROFILE}/profile-details/categories`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "Portfolios",
        component: <ContentPortfolio />,
        path: "/profile-details/portfolios",
        menuPath: `/${MY_PROFILE}/profile-details/portfolios`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "Videos",
        component: <ContentVideos />,
        path: "/profile-details/videos",
        menuPath: `/${MY_PROFILE}/profile-details/videos`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "AR/VR",
        component: <ContentARVR />,
        path: "/profile-details/arvr",
        menuPath: `/${MY_PROFILE}/profile-details/arvr`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      }
    ]
  },
  {
    label: "Content",
    path: "content",
    icon: SIDEBAR_ICONS.PORTFOLIO_SIDEBAR_ICON,
    notFoundRoute: `/${MY_PROFILE}/content/catalogues`,
    subMenu: [
      {
        label: "Catalogues",
        component: <Catalogues />,
        path: "/content/catalogues",
        menuPath: `/${MY_PROFILE}/content/catalogues`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      },
      {
        label: "My Products",
        component: <ContentProducts />,
        path: "/content/products",
        menuPath: `/${MY_PROFILE}/content/products`,
        icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
      }
    ]
  }
];

export default BRAND_PROFILE_ROUTES;

import { isNumber } from "lodash";
import React from "react";
import { Stack } from "react-bootstrap";
import { FaRegHeart, FaRegEye, FaRegBookmark } from "react-icons/fa";

const EngagementStats = ({
  likes,
  views,
  saves,
  colClassName,
  width = "0.85rem",
  height = "0.85rem",
  fontSize = "1rem"
}) => {
  const ICON_STYLE = { width, height, marginRight: "0.5rem" };
  const TEXT_STYLE = { fontSize };

  return (
    (isNumber(likes) || isNumber(saves) || isNumber(views)) && (
      <Stack gap={2} direction="horizontal" className={`align-items-center ${colClassName || ""}`}>
        {isNumber(likes) && (
          <div className="d-flex align-items-center justify-content-center">
            <FaRegHeart style={ICON_STYLE} />
            <span style={TEXT_STYLE}>{likes}</span>
          </div>
        )}
        {isNumber(views) && (
          <div className="d-flex align-items-center justify-content-center">
            <FaRegEye style={ICON_STYLE} />
            <span style={TEXT_STYLE}> {views}</span>
          </div>
        )}
        {isNumber(saves) && (
          <div className="d-flex align-items-center justify-content-center">
            <FaRegBookmark style={ICON_STYLE} />
            <span style={TEXT_STYLE}>{saves}</span>
          </div>
        )}
      </Stack>
    )
  );
};

export default EngagementStats;

import ARVR from "components/Cards/ARVR";
import Blog from "components/Cards/Blog";
import CatalogueCard from "components/Cards/Catalogue";
import Image from "components/Cards/Image";
import Job from "components/Cards/Job";
import Portfolio from "components/Cards/Portfolio";
import ProductCard from "components/Cards/Product";
import ProfileCard from "components/Cards/Profile";
import Project from "components/Cards/Project";
import Thesis from "components/Cards/Thesis";
import Video from "components/Cards/Video";
import BLOGS_CONSTANTS from "constants/Blogs";
import THESIS_CONSTANTS from "constants/Thesis";
import AddARVRForm from "forms/Content/AddARVR";
import AddCatalogueForm from "forms/Content/AddCatalogue";
import AddImageForm from "forms/Content/AddImage";
import AddPortfolioForm from "forms/Content/AddPortfolio";
import AddProjectForm from "forms/Content/AddProject";
import AddThesisForm from "forms/Content/AddThesis";
import AddVideo from "forms/Content/AddVideo";

export function CardComponent({ type, ...props }) {
  const Component = {
    job: Job,
    image: Image,
    video: Video,
    project: Project,
    portfolio: Portfolio,
    thesis: Thesis,
    blog: Blog,
    profile: ProfileCard,
    catalogue: CatalogueCard,
    arvr: ARVR,
    product: ProductCard
  }[type];

  return <Component type={type} {...props} />;
}

export const getAddForm = (type) => {
  const form = {
    image: AddImageForm,
    video: AddVideo,
    project: AddProjectForm,
    portfolio: AddPortfolioForm,
    thesis: AddThesisForm,
    catalogue: AddCatalogueForm,
    arvr: AddARVRForm
  }[type];
  return form;
};

export const CATEGORY_FILTER = {
  thesis: THESIS_CONSTANTS.THESIS_CATEGORIES,
  blog: BLOGS_CONSTANTS.CATEGORIES
};

export const CUSTOM_STAKEHOLDER_FILTER_OPTIONS = {
  project: [
    { value: "studio", label: "Studio/Firm" },
    { value: "brand", label: "Indiviual/Professional/Student" },
    { value: "student", label: "Builder/Contractor/End User" }
  ],
  thesis: [
    { value: "studio", label: "Studio" },
    { value: "student", label: "Professional" }
  ]
};

export const handleResultSelection = (selectedData, setSelectedData, resultId) => {
  if (selectedData.includes(resultId)) {
    setSelectedData(selectedData.filter((id) => id !== resultId));
  } else {
    setSelectedData([...selectedData, resultId]);
  }
};

const PORTFOLIO_CONSTANTS = {
  PORTFOLIO_TYPES: [
    { value: "Residential", label: "Residential" },
    { value: "Commercial & Offices", label: "Commercial & Offices" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Retail", label: "Retail" },
    { value: "Institutional", label: "Institutional" },
    { value: "Religious", label: "Religious" },
    {
      value: "Industrial & Infrastructure",
      label: "Industrial & Infrastructure"
    },
    { value: "Landscape & Urbanism", label: "Landscape & Urbanism" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Mixed type", label: "Mixed type" }
  ]
};

export default PORTFOLIO_CONSTANTS;

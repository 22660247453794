import RESUME_SIDEBAR_ICON from "../assets/icons/sidebar/resume.svg";
import PERSONAL_DETAILS_SIDEBAR_ICON from "../assets/icons/sidebar/personal-details.svg";
import JOB_PREFERENCES_SIDEBAR_ICON from "../assets/icons/sidebar/job-preferences.svg";
import PORTFOLIO_SIDEBAR_ICON from "../assets/icons/sidebar/portfolio.svg";
import PROJECT_SIDEBAR_ICON from "../assets/icons/sidebar/project.svg";
import THESIS_SIDEBAR_ICON from "../assets/icons/sidebar/thesis.svg";
import IMAGE_SIDEBAR_ICON from "../assets/icons/sidebar/image.svg";
import VIDEO_SIDEBAR_ICON from "../assets/icons/sidebar/video.svg";
import PROFILE_HEADER_ICON from "../assets/icons/header/profile.svg";
import MY_LEADS_SIDEBAR_ICON from "../assets/icons/sidebar/my-leads.svg";
import MY_BLOGS_SIDEBAR_ICON from "../assets/icons/sidebar/my-blogs.svg";
import MY_REQUESTS_SIDEBAR_ICON from "../assets/icons/sidebar/my-requests.svg";
import MY_JOBS_SIDEBAR_ICON from "../assets/icons/sidebar/my-jobs.svg";
import MY_FAVOURITES_SIDEBAR_ICON from "../assets/icons/sidebar/my-favourite.svg";
import DOT_SIDEBAR_ICON from "../assets/icons/sidebar/dot.svg";
import PLUS_ICON from "../assets/icons/plus.svg";
import EDIT_ICON from "../assets/icons/edit.svg";
import DELETE_ICON from "../assets/icons/delete.svg";
import BACK_ICON from "../assets/icons/back.svg";
import IDEATION_BOARD_FILLED from "../assets/icons/ideation-board-filled.svg";
import IDEATION_BOARD_UNFILLED from "../assets/icons/ideation-board-unfilled.svg";
import PROJECT_ICON from "../assets/icons/project.svg";
import MAP_PIN_ICON from "../assets/icons/map-pin.svg";
import DASHBOARD_IDEATION_BOARD from "../assets/icons/dashboard/ideation-board.svg";
import DASHBOARD_JOB from "../assets/icons/dashboard/job.svg";
import DASHBOARD_BLOG from "../assets/icons/dashboard/blog.svg";
import OPEN_IN_NEW from "../assets/icons/open-in-new.svg";
import DASHBOARD_INSTAGRAM from "../assets/icons/dashboard/instagram.svg";
import DASHBOARD_LINKEDIN from "../assets/icons/dashboard/linkedin.svg";
import DASHBOARD_TWITTER from "../assets/icons/dashboard/twitter.svg";
import DASHBOARD_EMAIL from "../assets/icons/dashboard/email.svg";
import ML_VERIFIED from "../assets/icons/ml-verified.svg";
import PIN_ICON from "../assets/icons/pin.svg";
import PINNED_ICON from "../assets/icons/pinned.svg";
import SAVE_ICON from "../assets/icons/love.svg";
import SAVED_ICON from "../assets/icons/saved.svg";
import CONTENT_ICON from "../assets/icons/sidebar/content.svg";
import FROWN_ICON from "../assets/icons/frown.svg";
import COPY_ICON from "../assets/icons/copy.svg";
import GREEN_FILLED_TICK_ICON from "../assets/icons/green-filled-tick.svg";
import IDEATION_BOARD_UNFILLED_BG from "../assets/icons/ideation-board-filled-bg.svg";
import SEARCH_ICON from "../assets/icons/search.png";
import DASHBOARD_MY_JOBS from "../assets/icons/dashboard/my-jobs.svg";
import DASHBOARD_MY_REQUESTS from "../assets/icons/dashboard/my-requests.svg";
import DASHBOARD_MY_LEADS from "../assets/icons/dashboard/my-leads.svg";

export const SIDEBAR_ICONS = {
  RESUME_SIDEBAR_ICON,
  PERSONAL_DETAILS_SIDEBAR_ICON,
  JOB_PREFERENCES_SIDEBAR_ICON,
  PORTFOLIO_SIDEBAR_ICON,
  PROJECT_SIDEBAR_ICON,
  THESIS_SIDEBAR_ICON,
  IMAGE_SIDEBAR_ICON,
  VIDEO_SIDEBAR_ICON,
  MY_LEADS_SIDEBAR_ICON,
  MY_REQUESTS_SIDEBAR_ICON,
  MY_JOBS_SIDEBAR_ICON,
  MY_FAVOURITES_SIDEBAR_ICON,
  DOT_SIDEBAR_ICON,
  CONTENT_ICON,
  MY_BLOGS_SIDEBAR_ICON
};

export const HEADER_ICONS = { PROFILE_HEADER_ICON };

export const DASHBOARD_ICONS = {
  DASHBOARD_IDEATION_BOARD,
  DASHBOARD_BLOG,
  DASHBOARD_JOB,
  DASHBOARD_MY_JOBS,
  DASHBOARD_MY_REQUESTS,
  DASHBOARD_MY_LEADS,

  SOCIAL_MEDIA: { DASHBOARD_EMAIL, DASHBOARD_INSTAGRAM, DASHBOARD_TWITTER, DASHBOARD_LINKEDIN }
};

export {
  PLUS_ICON,
  EDIT_ICON,
  DELETE_ICON,
  BACK_ICON,
  IDEATION_BOARD_FILLED,
  IDEATION_BOARD_UNFILLED,
  PROJECT_ICON,
  MAP_PIN_ICON,
  OPEN_IN_NEW,
  ML_VERIFIED,
  PIN_ICON,
  PINNED_ICON,
  SAVED_ICON,
  SAVE_ICON,
  FROWN_ICON,
  COPY_ICON,
  GREEN_FILLED_TICK_ICON,
  IDEATION_BOARD_UNFILLED_BG,
  SEARCH_ICON
};

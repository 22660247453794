const ORIGIN = window.location.origin;
const STUDENT_PROFILE_SAMPLE = {
  is_favourited: null,
  user: {
    id: 17,
    name: "Rjat Saini",
    username: "ML-student",
    email: "rajatsaini@gmail.com",
    mobile: "9303548585",
    is_student: true,
    is_brand: false,
    is_studio: false,
    is_email_verified: true,
    is_mobile_verified: false,
    secondary_email: "babutabhavya@gmail.com",
    secondary_mobile: "7303548585",
    is_secondary_email_verified: true,
    is_secondary_mobile_verified: true,
    profile_images: [
      {
        id: 1,
        image: `${ORIGIN}/sample-profile/student/profile/2.jpg`,
        image_type: "default",
        is_selected: true,
        is_solid: false
      }
    ],
    banner_images: [
      {
        id: 1,
        image: `${ORIGIN}/sample-profile/student/profile/1.jpg`,
        image_type: "default",
        is_selected: true,
        is_solid: true
      }
    ],
    profile_completion_percentage: 39,
    last_active: "2023-10-12T13:16:15.327083+05:30",
    open_to_work: true,
    ml_verified: false,
    promoted: false,
    allow_thesis: true,
    location: "Gurgaon, Haryana",
    is_working_professional: false
  },
  personal_details: {
    id: 5,
    address_line1: "A-55 Sushant Lok 1",
    country: "India",
    state: "Haryana",
    city: "Gurgaon",
    pincode: 122001,
    linkedin: "https://www.linkedin.com/in/rajatsaini10/",
    instagram: "https://www.instagram.com/rajat_saini_rj/",
    pinterest: "https://in.pinterest.com/pin/78601955968321848/",
    behance: "",
    date_of_birth: "2001-07-12",
    about:
      "As an aspiring Architect, I personally feel that Architectural design is basically user experience design on a physical and spatial level",
    education_level: "Graduation",
    field_of_studies: "Architecture",
    actively_looking_for: "Looking for new offers",
    is_working_professional: false,
    user: 17
  },
  resume: {
    id: 1,
    title: "test",
    headline: "UI/UX Designer",
    about:
      "I have a flexible and positive approach to employment and am willing to train to suit my next working environment.",
    user: 17,
    image: null,
    hobbies: ["Photography", "Web Design"],
    technical_skills: ["Design Techniques", "ArchiCAD", "Microsoft Excel"],
    management_skills: ["Team Management", "Organisational Skills"],
    personal_skills: ["Critical thinking", "Creativity", "Problem Solving"],
    languages: ["English", "Hindi", "Punjabi"],
    default: true,
    years_of_experience: 0.0,
    courses: [],
    education: [
      {
        college: "Sushant School of Architecture",
        degree: "Bachelor of Architecture(B.Arch)"
      }
    ],
    employment: [],
    awards: []
  },
  images: [
    {
      id: 18,
      title: "Architecture",
      description: "Modern architecture to enhance the beauty along with sustainability.",
      category: "Architecture",
      user: "Test Student",
      slug: "architecture",
      created: "2023-06-18T23:08:08.896896+05:30",
      user_id: 17,
      tags: ["design", "architecture"],
      ideation_board_id: null,
      mood_board_id: null,
      mood_board_link_id: null,
      is_favourited: 4,
      is_liked: null,
      views: 0,
      likes: 0,
      saves: 1,
      image: `${ORIGIN}/sample-profile/student/images/1.jpg`
    },
    {
      id: 20,
      title: "Virtaul theme park",
      description:
        "A theme based project which will help architectural & design student to understand the meaning of innovation in architecture.",
      category: "Project",
      user: "Test Student",
      slug: "virtual theme park",
      created: "2023-06-21T15:08:36.615629+05:30",
      user_id: 17,
      tags: ["architecture", "modern design", "innovation"],
      ideation_board_id: null,
      mood_board_id: null,
      mood_board_link_id: null,
      is_favourited: null,
      is_liked: null,
      views: 0,
      likes: 0,
      saves: 0,
      image: `${ORIGIN}/sample-profile/student/images/2.jpg`
    },
    {
      id: 21,
      title: "Building",
      description: "Highlighting each & every details for the building facade.",
      category: "Layout",
      user: "Test Student",
      slug: "building",
      created: "2023-06-30T20:03:19.684937+05:30",
      user_id: 17,
      tags: [],
      ideation_board_id: null,
      mood_board_id: null,
      mood_board_link_id: null,
      is_favourited: null,
      is_liked: null,
      views: 0,
      likes: 0,
      saves: 0,
      image: `${ORIGIN}/sample-profile/student/images/3.jpg`
    }
  ],
  videos: [
    {
      id: 1,
      title: "Smart Home",
      description: "Smart innovation for the smart home",
      category: "Design",
      user: "Test Student",
      slug: "test4",
      created: "2023-04-29T18:18:07.084296+05:30",
      user_id: 17,
      tags: ["123", "test"],
      ideation_board_id: null,
      mood_board_id: null,
      mood_board_link_id: null,
      is_favourited: 5,
      is_liked: null,
      views: 0,
      likes: 1,
      saves: 3,
      video: "https://youtu.be/kWSHdixzMco?feature=shared"
    },
    {
      id: 3,
      title: "Kitchen Design",
      description: "Smart kitchen appliances to make everyone's life easier",
      category: "Design",
      user: "Test Student",
      slug: "kitchen design",
      created: "2023-06-21T15:48:23.103524+05:30",
      user_id: 17,
      tags: ["test123", "123test"],
      ideation_board_id: null,
      mood_board_id: null,
      mood_board_link_id: null,
      is_favourited: null,
      is_liked: null,
      views: 0,
      likes: 0,
      saves: 1,
      video: "https://youtu.be/_MPs3YmRwTA?feature=shared"
    }
  ],
  portfolios: [
    {
      id: 3,
      title: "College Portfolio",
      thumbnail: `${ORIGIN}/sample-profile/student/images/1.jpg`,
      type: "Commercial & Offices",
      description:
        "Enthusiastic architecture student, excelled in CAD design coursework. Won second place in the Better Philadelphia Architecture Competition for inexpensive design. Created 3D models for a hotel made of shipping containers.",
      file: `${ORIGIN}/sample-profile/student/portfolio/1.pdf`,
      user: "Test Student",
      created: "2023-06-20T19:39:20.843562+05:30",
      user_id: 17,
      views: 0,
      likes: 0,
      saves: 2,
      is_favourited: 5,
      is_liked: null
    }
  ],
  projects: [
    {
      id: 17,
      user: "Design Xpress",
      title: "Enola Homes",
      thumbnail: `${ORIGIN}/sample-profile/student/projects/1.jpeg`,
      location: "New Delhi",
      type: "Residential",
      status: "Completed",
      start_date: null,
      end_date: null,
      total_build_up_area: null,
      total_build_up_area_unit: null,
      plot_area: null,
      plot_area_unit: null,
      cost_per_square_feet: null,
      specifications: "Designed with modern materials & style.",
      role: null,
      slug: "test-project",
      images: [
        `${ORIGIN}/sample-profile/student/projects/2.jpg`,
        `${ORIGIN}/sample-profile/student/projects/3.jpg`
      ],
      videos: "https://youtu.be/_MPs3YmRwTA?feature=shared",
      created: "2023-08-04T01:34:26.826805+05:30",
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    }
  ],
  thesis: [
    {
      id: 8,
      title: "Library Design",
      description:
        "This visionary project included creating a series of educational halls, lecture rooms, a versatile central auditorium, and a library that evolved over distinct construction and development phases.",
      user: "Test Student",
      college: "Test",
      degree_level: "Graduation",
      source_type: "Dissertation",
      file: `${ORIGIN}/sample-profile/student/thesis/1.pdf`,
      slug: "test",
      created: "2023-07-29T02:09:14.195829+05:30",
      category: "Interior Designing",
      thumbnail: "`${ORIGIN}/sample-profile/student/thesis/thesis-thumbnail.jpg`",
      user_id: 17,
      views: 0,
      likes: 1,
      saves: 2,
      is_favourited: 17,
      is_liked: 1
    }
  ],
  blogs: [
    {
      id: 14,
      title: " Different Types of Garden Lights",
      slug: "test-title",
      content: '<p style="text-align: center;"><strong></strong></p>',
      created: "2023-07-09T21:44:42.115743+05:30",
      author: "Rajat saini",
      category: "Architectural",
      is_posted: true,
      under_review: false,
      rejected: false,
      review_not_accepted_response: "",
      thumbnail: `${ORIGIN}/sample-profile/student/blog/1.jpg`,
      keywords: ["architecture", "Lighting"],
      is_drafted: false,
      is_pinned: true,
      views: 2,
      likes: 0,
      saves: 2,
      is_favourited: 31,
      is_liked: null
    }
  ]
};

export default STUDENT_PROFILE_SAMPLE;

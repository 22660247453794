import React from "react";
import ProfileDetailsContainer from "../../../../components/Containers/ProfileDetails";
import { useForm } from "react-hook-form";
import { BrandBranchDetailAPI } from "../../../../apiClients/Profile/Brand/ProfileDetails";
import SearchTable from "../../../../components/Base/SearchTable";
import LockedContent from "../../../../components/Containers/LockedContent";
import MobileBranchDetail from "components/Cards/MobileBranchDetail";
import ContentHeader from "components/Base/ContentHeader";

export default function BranchDetails() {
  const client = new BrandBranchDetailAPI();

  const methods = useForm({
    reValidateMode: "onChange"
  });

  return (
    <ProfileDetailsContainer loading={false} methods={methods} fullWidth>
      <ContentHeader
        title="Branch Details"
        subTitle="All your saved branch details are shown here"
      />
      <LockedContent>
        <div className="mt-3">
          <SearchTable client={client} type="branch-detail" MobileCard={MobileBranchDetail} />
        </div>
      </LockedContent>
    </ProfileDetailsContainer>
  );
}

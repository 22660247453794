import Api, { ApiHeaders } from "../../api";

class StudioBusinessDetailsAPI extends ApiHeaders {
  constructor() {
    super();
    this.endpoint = "/studio/business-detail/";
  }
  async read() {
    return await Api.get(this.endpoint, {}, this.authenticationHeaders).then(
      (response) => response.data
    );
  }

  async update(payload) {
    return await Api.patch(this.endpoint, payload, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

export default StudioBusinessDetailsAPI;

const ORIGIN = window.location.origin;
const STUDIO_SAMPLE_PROFILE = {
  user: {
    id: 39,
    name: "Design Xpress",

    username: "designxpress123",
    email: "design@xpress.org",
    mobile: "9303548586",
    is_student: false,
    is_brand: false,
    is_studio: true,
    is_email_verified: false,
    is_mobile_verified: false,
    secondary_email: "contact@design.org",
    secondary_mobile: "9874564568",
    is_secondary_email_verified: true,
    is_secondary_mobile_verified: false,
    profile_images: [
      {
        id: 1,
        image: `${ORIGIN}/sample-profile/studio/files/profile/Studio-profie-image-logo.jpg`,
        is_selected: true,
        is_solid: false
      }
    ],
    banner_images: [
      {
        id: 1,
        image: `${ORIGIN}/sample-profile/studio/files/profile/Studio-cover-image.png`,
        is_selected: true,
        is_solid: true
      }
    ],
    profile_completion_percentage: 24
  },
  personal_details: {
    id: 1,
    address_line1: "1225, St Thomas Marg, opp. SHALOM HILLS INTERNATIONAL SCHOOL, Sector 43",
    country: "India",
    state: "Haryana",
    city: "Gurugram",
    pincode: 122009,
    linkedin: "https://www.linkedin.com/company/design-xpress/",
    instagram: "https://www.instagram.com/design_xpress97/",
    pinterest: "https://in.pinterest.com/designxpress18/",
    behance: "https://www.behance.net/gallery/",
    company_type: "Design Studio",
    company_description:
      "Studio Materium is a Delhi and Gurugram-based group of architects, designers, urbanists, interior and landscape professionals and researchers. The office is currently involved in a large number of projects throughout North and South India.” ",
    principal_designer: "Rakesh Roshan",
    establishment_year: 1998,
    contact: null,
    website_link: null,
    gstin: "06AACCF8045D1ZM",
    pan: "HARPB0151B",
    user: 39
  },
  images: [
    {
      id: 18,
      title: "Interior Design",
      description:
        "Modern interior design for home, office, interior details, upholstered furniture against the background.",
      category: "Design",
      user: "Design Xpress",
      slug: "adasdas",
      created: "2023-06-18T23:08:08.896896+05:30",
      is_favourited: null,
      is_liked: null,
      views: 0,
      likes: 0,
      saves: 0,
      image: `${ORIGIN}/sample-profile/studio/files/image/Studio-interior-design-image.jpg`
    }
  ],
  videos: [
    {
      id: 1,
      title: "Design Innovation",
      description:
        "Boho style living room with wicker chair,sofa,table and pampas in the pot on white wall background.3d rendering",
      category: "Design",
      user: "Design Xpress",
      slug: "test4",
      created: "2023-04-29T18:18:07.084296+05:30",
      is_favourited: 5,
      is_liked: null,
      views: 0,
      likes: 1,
      saves: 2,
      video: "https://youtu.be/kWSHdixzMco?feature=shared"
    }
  ],
  portfolios: [
    {
      id: 3,
      title: "Design Xpress Work Portfolio",
      thumbnail: `${ORIGIN}/sample-profile/studio/files/portfolio/thumbnail.jpg`,
      type: "Commercial & Offices",
      description:
        "Celebrated for its commitment to sustainability, Studio Materium is a multidisciplinary Architecture and Interior Designing firm. It has a dedicated team of best interior designers and architects, who work diligently, and craft stunning spaces you’ve always dreamt of.",
      file: `${ORIGIN}/sample-profile/studio/files/portfolio/Studio-portfolio-file_.pdf`,
      user: "Design Xpress",
      created: "2023-06-20T19:39:20.843562+05:30",
      views: 0,
      likes: 0,
      saves: 1,
      is_favourited: 4,
      is_liked: null
    }
  ],
  projects: [
    {
      id: 17,
      user: "Design Xpress",
      title: "Enola Homes",
      thumbnail: `${ORIGIN}/sample-profile/studio/files/project/images/1.jpeg`,
      location: "New Delhi",
      type: "Residential",
      status: "Completed",
      start_date: null,
      end_date: null,
      total_build_up_area: null,
      total_build_up_area_unit: null,
      plot_area: null,
      plot_area_unit: null,
      cost_per_square_feet: null,
      specifications: "Designed with modern materials & style.",
      role: null,
      slug: "test-project",
      images: [
        `${ORIGIN}/sample-profile/studio/files/project/images/2.jpg`,
        `${ORIGIN}/sample-profile/studio/files/project/images/3.jpg`
      ],
      videos: "https://youtu.be/_MPs3YmRwTA?feature=shared",
      created: "2023-08-04T01:34:26.826805+05:30",
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    },
    {
      id: 18,
      user: "Design Xpress",
      title: "Grand Villa",
      thumbnail: `${ORIGIN}/sample-profile/studio/files/project/images/3.jpg`,
      location: "Gurugram",
      type: "Residential",
      status: "Completed",
      start_date: null,
      end_date: null,
      total_build_up_area: null,
      total_build_up_area_unit: null,
      plot_area: null,
      plot_area_unit: null,
      cost_per_square_feet: null,
      specifications: null,
      role: null,
      slug: "test2",
      images: [
        "static/project/images/images_6SBVkG6.jpeg",
        "static/project/images/images_VYHv7cU.jpeg",
        "static/project/images/images_dJQMQ5f.jpeg",
        "static/project/images/images_4lR5C5T.jpeg"
      ],
      videos: "https://youtu.be/kWSHdixzMco?feature=shared",
      created: "2023-09-10T20:10:42.070339+05:30",
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    }
  ],
  thesis: [
    {
      id: 8,
      title: "Sustainability in architecture",
      description:
        "Overall focus on reducing human impact on the environment. Minimal wasteful, harmful energy consumption thanks to the use of renewable energy sources, such as solar panels and natural heating, cooling, and ventilation systems.",
      user: "Design Xpress",
      college: "Delhi College of Architecture",
      degree_level: "Graduation",
      source_type: "Dissertation",
      file: "/static/content/thesis/pdf/file.pdf",
      slug: "test",
      created: "2023-07-29T02:09:14.195829+05:30",
      category: "Architecture",
      thumbnail: `${ORIGIN}/sample-profile/studio/files/portfolio/thumbnail.jpg`,
      views: 0,
      likes: 0,
      saves: 0,
      is_favourited: null,
      is_liked: null
    }
  ],
  blogs: [
    {
      id: 21,
      title: "Architecture & Human Psychology",
      slug: "reommended",
      content:
        "<p>asdadasdasda</p>\r\n<p>&nbsp;</p>\r\n<p>asdadasdasda</p>\r\n<p>&nbsp;</p>\r\n<p>asdadasdasda</p>\r\n<p>&nbsp;</p>\r\n<p>asdadasdasda</p>\r\n<p>&nbsp;</p>\r\n<p>asdadasdasda</p>",
      created: "2023-07-16T23:50:19.015809+05:30",
      author: "Design Xpress",
      category: "Architecture",
      is_posted: true,
      under_review: false,
      rejected: false,
      review_not_accepted_response: "",
      thumbnail: `${ORIGIN}/sample-profile/studio/files/blog/1.webp`,
      keywords: ["architecture, design, interior, sustainable"],
      is_drafted: false,
      views: 1,
      likes: 0,
      saves: 1,
      is_favourited: 30,
      is_liked: null
    }
  ]
};

export default STUDIO_SAMPLE_PROFILE;

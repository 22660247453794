import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function BrandsFilter({ brands }) {
  const navigate = useNavigate();
  const ref = useRef(null);

  const filter = (value) => navigate(`/products/categories?brand=${value}`);

  return (
    <Menu
      menuButton={
        <MenuButton className="border-0 bg-transparent custom-multilevel-dropdown">
          Brands
        </MenuButton>
      }
      overflow="auto"
      position="auto"
    >
      {brands.map((el, index) => {
        return (
          <MenuItem
            key={`${index}-${el.name}-${el.id}`}
            onClick={(e) => {
              filter(el.name);
              if (ref.current) {
                ref.current.toggle(e);
              }
            }}
          >
            {el.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { isNull, isUndefined } from "lodash";
import { BrandKYCDetailsAPI } from "apiClients/Profile/Brand/ProfileDetails";
import { useAuth } from "hooks";
import { convertDataUrlToBase64, getContentAbsoluteUrl } from "utils";
import ProfileDetailsContainer from "components/Containers/ProfileDetails";
import KycDetailsForm from "forms/Brand/KycDetails";
import ContentHeader from "components/Base/ContentHeader";

function KycDetails() {
  const [loading, setLoading] = useState(true);

  const client = new BrandKYCDetailsAPI();
  const { getUserInfo } = useAuth();

  const methods = useForm({
    reValidateMode: "onChange"
  });

  const { setValue } = methods;

  const handleSetValues = (data) => {
    if (data.registration_certificate) {
      convertDataUrlToBase64(getContentAbsoluteUrl(data.registration_certificate)).then((image) =>
        setValue("registration_certificate", [image])
      );
    }
    ["pan", "gstin", "msme_number"].forEach(
      (el) => !isUndefined(data[el]) && !isNull(data[el]) && setValue(el, data[el])
    );
    ["nature_of_orgranisation", "gstin_status", "registration_under_msme"].forEach(
      (el) =>
        data[el] &&
        setValue(el, {
          value: data[el],
          label: data[el]
        })
    );
  };

  const getDetails = () => client.read();

  useEffect(() => {
    Promise.all([getDetails(), getUserInfo()]).then((result) => {
      handleSetValues(result[0]);
      setLoading(false);
    });
  }, []);

  return (
    <ProfileDetailsContainer loading={loading} methods={methods}>
      <ContentHeader title="KYC Details" doNotShowSubtitle />
      <KycDetailsForm handleSetValues={handleSetValues} />
    </ProfileDetailsContainer>
  );
}

export default KycDetails;

import React, { useEffect, useState } from "react";
import { Form, Stack, Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { isObject } from "lodash";
import { convertDataUrlToBase64 } from "utils";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import PORTFOLIO_CONSTANTS from "constants/Portfolio";
import ContentFactory from "apiClients/ContentFactory";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import FormFooter from "forms/Footer";

const AddPortfolioForm = ({ onSuccess, editObj }) => {
  const [part, setPart] = useState(1);
  const { handleSubmit, setValue, reset } = useFormContext();
  const parts = ["Basic Details", "Document"];

  useEffect(() => {
    if (isObject(editObj)) {
      ["title", "description"].map((el) => setValue(el, editObj[el]));
      ["type"].map((el) => setValue(el, { label: editObj[el], value: editObj[el] }));
      convertDataUrlToBase64(editObj.thumbnail)
        .then((image) => setValue("thumbnail", [image]))
        .then(() => convertDataUrlToBase64(editObj.file))
        .then((file) => setValue("file", [file]));
    }
  }, []);

  const FormPartTwo = (
    <Form.Group className="py-3">
      <Form.Label>Add your Portfolio*</Form.Label>
      <ReactHookUpload name="file" required type="pdf" maxSizeMB={25} />
    </Form.Group>
  );

  const FormPartOne = (
    <Row className="mx-0" xs={1}>
      <Col sm={4} className="px-0">
        <Form.Label>Upload Thumbnail*</Form.Label>
        <ReactHookUpload
          name="thumbnail"
          required
          type="image"
          maxSizeMB={1}
          preferredText="Preferred Resolution: 400 X 330"
        />
      </Col>
      <Col sm={8}>
        <Stack gap={3}>
          <Form.Group>
            <Form.Label>Title*</Form.Label>
            <ReactHookInput type="text" name="title" required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Portfolio Type*</Form.Label>
            <Select options={PORTFOLIO_CONSTANTS.PORTFOLIO_TYPES} name="type" />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Brief Description <span className="fst-italic fw-light">Optional</span>
            </Form.Label>
            <ReactHookInput as="textarea" name="description" />
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  );

  const handleFormOneSubmit = () => setPart(part + 1);

  const handleFormTwoSubmit = async (data) => {
    const payload = {
      ...data,
      type: data.type.value
    };

    const client = ContentFactory.createRequest("portfolio");

    const promise = (
      editObj ? client.update({ ...payload, id: editObj.id }) : client.create(payload)
    )
      .then(onSuccess)
      .then(reset);

    showPromiseToast(promise, {
      pending: "Saving portfolio",
      success: "Portfolio saved successfully",
      error: renderErrorToast()
    });
  };

  return (
    <Form onSubmit={handleSubmit(part === 1 ? handleFormOneSubmit : handleFormTwoSubmit)}>
      {part === 1 ? FormPartOne : FormPartTwo}
      <FormFooter part={part} setPart={setPart} parts={parts} />
    </Form>
  );
};

export default AddPortfolioForm;

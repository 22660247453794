import React from "react";
import BaseModal from "../Base";
import { Modal, Stack } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";
import COLORS from "../../../constants/colors";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import { copyToClipboard } from "utils";

export default function ShareModal({ url, show, onHide }) {
  const buttonStyle = {
    borderRadius: "1rem"
  };

  var shareUrl = url || window.location.href;

  const ICON_SIZE = 45;
  return (
    <BaseModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Copy Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex align-items-center justify-content-between border rounded-pill cursor-pointer"
          style={{ padding: "0.5rem 1rem" }}
          onClick={() => copyToClipboard(shareUrl)}
        >
          <p className="mb-0 w-100 text-break">{shareUrl}</p>
          <FiCopy color={COLORS.PRIMARY_ORANGE} />
        </div>
        <div className="mt-4">
          <h6>Share Directly On</h6>
          <Stack gap={4} direction="horizontal" className="mt-4">
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={ICON_SIZE} style={buttonStyle} />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={ICON_SIZE} style={buttonStyle} />
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={ICON_SIZE} style={buttonStyle} />
            </LinkedinShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={ICON_SIZE} style={buttonStyle} />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={ICON_SIZE} style={buttonStyle} />
            </WhatsappShareButton>
          </Stack>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

const SPECIFICATIONS_DISPLAY = [
  "is_compliance",
  "certification",
  "stock_available",
  "model_number",
  "product_color",
  "grade",
  "product_length",
  "product_length_uom",
  "product_width",
  "product_width_uom",
  "product_height",
  "screen_display_size",
  "product_depth",
  "product_thickness",
  "step_width",
  "inlet_diameter",
  "outlet_diameter",
  "product_diameter",
  "trap_type",
  "paint_thickness",
  "trap_distance_length",
  "pipe_length",
  "compressor_type",
  "piping_connection",
  "product_capacity",
  "product_volume",
  "ip_rating",
  "number_of_ports",
  "material_of_construction",
  "motor_casing",
  "shaft_material",
  "product_type",
  "entrances",
  "number_of_stops",
  "mount_type",
  "bed_base_board",
  "product_finish",
  "product_series",
  "product_texture",
  "rotating_direction",
  "product_color_code_hex",
  "light_color",
  "product_shape",
  "inclination",
  "vertical_rise",
  "opacity",
  "product_style",
  "product_pattern",
  "door_type",
  "suitable_for",
  "number_of_doors",
  "number_of_drawers",
  "seating_height",
  "number_of_ply",
  "number_of_cable_strands",
  "number_of_air_jets",
  "number_of_water_jet",
  "drain_location",
  "grit_type",
  "connection",
  "moisture_removal",
  "moisture_content",
  "product_per_pack",
  "airflow_rate",
  "cutting_angle",
  "number_of_teeth",
  "latching_speed",
  "coverage_area_per_piece",
  "coverage_area_per_pack",
  "standard_weight_per_piece",
  "winding_type",
  "batteries_included",
  "max_travel",
  "control_system",
  "signalization",
  "max_group_size",
  "max_load",
  "speed",
  "motor_speed",
  "working_pressure",
  "bursting_pressure",
  "phase",
  "fault_level",
  "impeller",
  "focal_length",
  "resolution",
  "picture_element",
  "electronic_shutter_range",
  "wired_or_wireless",
  "weather_proof_rating",
  "star_rating",
  "alarm_range",
  "frequency",
  "relative_humidity",
  "power_source",
  "air_blower_power",
  "water_pump_power",
  "air_pump_power",
  "power",
  "wattage",
  "voltage",
  "unlocking_mode",
  "luminous_flux",
  "cooling",
  "rated_cooling_capacity",
  "pins",
  "socket_type",
  "operating_ambient_temperature_range",
  "temperature",
  "specific_gravity",
  "connector_type",
  "display_technology",
  "max_number_of_occupants",
  "amperage_rating",
  "breaking_capacity",
  "number_of_poles",
  "included_components",
  "standard_density",
  "standard_weight",
  "door_thickness",
  "door_type",
  "handle_type",
  "number_of_handles",
  "handle_material",
  "handle_location",
  "spout_reach",
  "valve_type",
  "installation_type",
  "max_flow_rate",
  "backset",
  "ph",
  "locking_type",
  "melting_point",
  "product_strength"
];

export { SPECIFICATIONS_DISPLAY };

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import BusinessDetailsForm from "../../../../forms/Studio/BusinessDetails";
import StudioBusinessDetailsAPI from "../../../../apiClients/Profile/Studio/BusinessDetails";
import ProfileDetailsContainer from "../../../../components/Containers/ProfileDetails";
import { useAuth } from "../../../../hooks";
import ContentHeader from "components/Base/ContentHeader";

function BusinessDetails({ user }) {
  const { getUserInfo } = useAuth();
  const [loading, setLoading] = useState(true);
  var defaultValues = {
    email: user.email,
    secondary_email: user.secondary_email,
    secondary_mobile: user.secondary_mobile
  };
  if (user) {
    if (user.name) {
      defaultValues["name"] = user.name;
    }
    if (user.mobile) {
      defaultValues["phone"] = user.mobile;
    }
  }

  const methods = useForm({
    reValidateMode: "onChange",
    defaultValues
  });

  const { setValue } = methods;

  const handleSetValues = (data) => {
    [
      "about",
      "country",
      "address_line1",
      "instagram",
      "behance",
      "pinterest",
      "linkedin",
      "pincode",
      "gstin",
      "pan",
      "principal_designer",
      "company_description",
      "website_link"
    ].forEach((el) => data[el] && setValue(el, data[el]));
    ["company_type", "establishment_year", "city", "state"].forEach(
      (el) =>
        data[el] &&
        setValue(el, {
          value: data[el],
          label: data[el]
        })
    );
    if (data.date_of_birth) {
      setValue("date_of_birth", new Date(data.date_of_birth));
    }
  };

  useEffect(() => {
    Promise.all([new StudioBusinessDetailsAPI().read(), getUserInfo()]).then((result) => {
      handleSetValues(result[0]);
      setLoading(false);
    });
  }, []);

  return (
    <ProfileDetailsContainer loading={loading} methods={methods}>
      <ContentHeader title="Business Details" doNotShowSubtitle />

      <BusinessDetailsForm handleSetValues={handleSetValues} />
    </ProfileDetailsContainer>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

export default connect(mapStateToProps, null)(BusinessDetails);

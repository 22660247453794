import isDevelopment from ".";

const API_BASE_URL = isDevelopment
  ? "http://localhost:5050/api/"
  : "https://mldev.openlogicsys.com/api/";

export const CONTENT_BASE_URL = isDevelopment
  ? "http://localhost:5050"
  : "https://mldev.openlogicsys.com";

export const CONTENT_BASE_HOST = isDevelopment ? "localhost:5050" : "mldev.openlogicsys.com";

export default API_BASE_URL;

import MANAGE_BLOGS_ROUTES from "./ManageBlogs";
import FAVOURITES_ROUTES from "./Favourites";
import IDEATION_BOARD_ROUTES from "./IdeationBoard";
import MANAGE_LEADS from "./ManageLeads";
import MANAGE_REQUESTS from "./ManageRequests";
import MANAGE_JOB_APPLICATIONS from "./ManageJobApplication";

const STUDENT_ACTIVITY_ROUTES = [
  FAVOURITES_ROUTES,
  IDEATION_BOARD_ROUTES,
  MANAGE_BLOGS_ROUTES,
  MANAGE_JOB_APPLICATIONS,
  MANAGE_LEADS,
  MANAGE_REQUESTS
];

export default STUDENT_ACTIVITY_ROUTES;

import React from "react";
import moment from "moment";
import { isString } from "lodash";

const DateRangeFormatter = ({
  startDate,
  endDate,
  formatDateStringFormat = "YYYY-MM-DD",
  dateFormat = "YYYY-MM-DD",
  isPresent = false
}) => {
  const startMoment = isString(startDate)
    ? moment(startDate, formatDateStringFormat)
    : moment(startDate);
  const endMoment =
    endDate && isString(endDate) ? moment(endDate, formatDateStringFormat) : moment(endDate);

  return (
    <div>
      {startMoment.format(dateFormat)} - {isPresent ? "Present" : endMoment.format(dateFormat)}
    </div>
  );
};

export default DateRangeFormatter;

import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { DASHBOARD_ICONS, OPEN_IN_NEW } from "../constants/icons";
import { Col, Dropdown, Image, ListGroup, ProgressBar, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { getProfileInitRoute, getStudentDesignation } from "../utils";
import TimeAgo from "timeago-react";
import StudentPersonalDetailsAPI from "apiClients/Profile/Student/PersonalDetails";
import COLORS from "constants/colors";
import ProfileImage from "components/ProfileImage";
import StudioBusinessDetailsAPI from "apiClients/Profile/Studio/BusinessDetails";
import Location from "components/Location";
import Api, { ApiHeaders } from "apiClients/api";
import { useAdvertiseWithUsModal } from "hooks/advertiseWithUs";
import BaseContainer from "components/Containers/BaseContainer";
import LockedContent from "components/Containers/LockedContent";
import LoadingContainer from "components/Containers/Loading";
import { isMobile } from "react-device-detect";
import withSidebar from "components/Containers/Sidebar";

function Heading({ children }) {
  return (
    <h4 style={{ color: COLORS.PRIMARY_ORANGE }} className="fw-bold mb-0">
      {children}
    </h4>
  );
}

const Container = ({ count, title, icon, route, showMy = true, style = {} }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        borderRadius: "1rem",
        border: "1px solid #E9E9E9",
        background: "#FFF",
        ...style
      }}
      onClick={() => (route ? navigate(route) : null)}
      className="d-flex flex-column justify-content-evenly px-3 py-1 shadow-lg cursor-pointer"
    >
      {icon && (
        <ReactSVG
          src={icon}
          beforeInjection={(svg) => {
            svg.setAttribute("style", "width: 2rem");
            svg.removeAttribute("height");
          }}
        />
      )}
      <div>
        <h4>{count}</h4>
        <p style={{ fontSize: "75%" }}>
          {showMy ? "My " : ""}
          {title}
        </p>
      </div>
    </div>
  );
};

function MyActivity({ activity, user }) {
  const navigate = useNavigate();

  var CONTENT = [
    {
      title: "Leads",
      count: activity.leads,
      icon: DASHBOARD_ICONS.DASHBOARD_MY_LEADS,
      show: true,
      route: "/my-activity/my-leads/"
    },
    {
      title: "Requests",
      count: activity.requests,
      icon: DASHBOARD_ICONS.DASHBOARD_MY_REQUESTS,
      show: true,
      route: "/my-activity/my-requests/"
    },
    {
      title: "Ideation Boards",
      count: activity.ideation_boards,
      icon: DASHBOARD_ICONS.DASHBOARD_IDEATION_BOARD,
      show: !user.is_brand,
      route: "/my-activity/ideation-board/create"
    },
    {
      title: "Jobs",
      count: activity.jobs,
      icon: DASHBOARD_ICONS.DASHBOARD_MY_JOBS,
      show: true,
      route: "/my-activity/my-jobs/posted"
    },
    {
      title: "Blogs",
      count: activity.blogs,
      icon: DASHBOARD_ICONS.DASHBOARD_BLOG,
      show: true,
      route: "/my-activity/my-blogs/posted"
    }
  ];

  return (
    <div className={`p-4 bg-white rounded shadow ${isMobile ? "mt-3" : ""}`}>
      <Stack direction="horizontal" gap={3} className="align-items-center">
        <Heading>My Activity</Heading>
        <ReactSVG
          src={OPEN_IN_NEW}
          style={{ width: "1rem" }}
          className="cursor-pointer"
          onClick={() => navigate("/my-activity")}
        />
      </Stack>

      <Row className="mt-4" xs={2} sm={CONTENT.filter((el) => el.show === true).length}>
        {CONTENT.map(
          (el, index) =>
            el.show && (
              <Col key={`activity-${index}`} style={{ marginTop: isMobile ? "1.25rem" : 0 }}>
                <Container
                  style={{
                    width: "100%",
                    height: user.is_brand || user.is_studio ? "8rem" : "9rem"
                  }}
                  {...el}
                />
              </Col>
            )
        )}
      </Row>
    </div>
  );
}

function MyContent({ content, user }) {
  const navigate = useNavigate();
  const CONTENT = [
    {
      title: "Catalogues",
      count: content.catalogues,
      icon: DASHBOARD_ICONS.DASHBOARD_IDEATION_BOARD,
      show: true,
      route: "/my-profile/content/catalogues"
    },
    {
      title: "Products",
      count: content.products,
      icon: DASHBOARD_ICONS.DASHBOARD_IDEATION_BOARD,
      show: true,
      route: "/my-profile/content/products"
    }
  ];
  return (
    <div className={`p-4 bg-white rounded shadow ${isMobile ? "mt-3" : ""}`}>
      <Stack direction="horizontal" gap={3} className="align-items-center">
        <Heading>My Content</Heading>
        <ReactSVG
          src={OPEN_IN_NEW}
          style={{ width: "1rem" }}
          className="cursor-pointer"
          onClick={() => navigate("/my-profile/content/catalogue")}
        />
      </Stack>

      <Stack direction="horizontal" gap={3} className="mt-4 justify-content-evenly">
        {CONTENT.map((el, index) => (
          <Container
            key={`activity-${index}`}
            style={{
              width: user.is_brand ? `${100 / CONTENT.length}%` : "9rem",
              height: user.is_brand ? "8rem" : "9rem"
            }}
            {...el}
          />
        ))}
      </Stack>
    </div>
  );
}

function MyProfile({ profile, profile_completion_percentage, user }) {
  const navigate = useNavigate();
  const { allow_thesis, is_brand } = user;
  var CONTENT = [
    {
      title: "Branches",
      count: profile.branches,
      show: user.is_brand,
      route: "/my-profile/profile-details/branch-details"
    },
    {
      title: "Distribution Networks",
      count: profile.distribution_networks,
      show: user.is_brand,
      route: "/my-profile/profile-details/distribution-networks"
    },
    {
      title: "Portfolios",
      count: profile.portfolios,
      show: true,
      route: `/my-profile/${is_brand ? "profile-details/" : ""}portfolios`
    },
    {
      title: "Projects",
      count: profile.projects,
      show: true,
      route: `/my-profile/${is_brand ? "profile-details/" : ""}projects`
    },
    {
      title: "Images",
      count: profile.images,
      show: !user.is_brand,
      route: `/my-profile/${is_brand ? "profile-details/" : ""}images`
    },
    {
      title: "Videos",
      count: profile.videos,
      show: true,
      route: `/my-profile/${is_brand ? "profile-details/" : ""}videos`
    },
    {
      title: "AR/VR",
      count: profile.arvr,
      show: user.is_brand,
      route: `/my-profile/${is_brand ? "profile-details/" : ""}arvr`
    },
    {
      title: "Thesis & Dissertations",
      count: profile.theses,
      show: allow_thesis,
      route: `/my-profile/${is_brand ? "profile-details/" : ""}thesis`
    },
    {
      title: "Resumes",
      count: profile.resumes,
      show: user.is_student,
      route: "/my-profile/resume"
    },
    {
      title: "Categories",
      count: profile.user_categories,
      show: user.is_brand,
      route: "/my-profile/profile-details/categories"
    }
  ];

  if (user.is_brand) {
    CONTENT.splice(3, 1);
  }

  return (
    <div className="p-4 shadow rounded h-100">
      <Row>
        <Col>
          <Stack direction="horizontal" gap={3} className="align-items-center">
            <Heading>My Profile</Heading>
            <ReactSVG
              src={OPEN_IN_NEW}
              style={{ width: "1rem" }}
              className="cursor-pointer"
              onClick={() => navigate(getProfileInitRoute(user))}
            />
          </Stack>
          <ProgressBar
            now={profile_completion_percentage}
            className="mt-3"
            color={COLORS.PRIMARY_ORANGE}
            variant="dashboard"
          />
        </Col>
        <Col xs={3}>
          <div
            style={{ backgroundColor: "#FFEDE9" }}
            className="w-100 h-100 d-flex flex-column align-items-center justify-content-center px-2 py-4"
          >
            <h3 className="mb-0 fw-bold" style={{ color: COLORS.PRIMARY_ORANGE }}>
              {profile_completion_percentage}%
            </h3>
            <p className="mb-0" style={{ fontSize: "80%" }}>
              Completed
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mt-4" xs={2}>
        {CONTENT.map(
          (el, index) =>
            el.show && (
              <Col key={`profile-${index}`} className="mb-4">
                <Container
                  style={{ width: isMobile ? null : "9rem", height: "7rem" }}
                  {...el}
                  showMy={false}
                />
              </Col>
            )
        )}
      </Row>
    </div>
  );
}

function MyFavourites({ favourites }) {
  const navigate = useNavigate();

  return (
    <div className="p-4 shadow rounded h-100">
      <Stack direction="horizontal" gap={3} className="align-items-center">
        <Heading>My Favourites</Heading>
        <ReactSVG
          src={OPEN_IN_NEW}
          style={{ width: "1rem" }}
          className="cursor-pointer"
          onClick={() => navigate("/my-activity/favourites/images")}
        />
      </Stack>
      <ListGroup className="border-0 mt-4">
        {[
          {
            title: "Projects",
            count: favourites.projects,
            route: "/my-activity/favourites/projects"
          },
          {
            title: "Portfolios",
            count: favourites.portfolios,
            route: "/my-activity/favourites/portfolios"
          },
          {
            title: "Thesis & Dissertations",
            count: favourites.theses,
            route: "/my-activity/favourites/thesis"
          },
          {
            title: "Images",
            count: favourites.images,
            route: "/my-activity/favourites/images"
          },
          {
            title: "Videos",
            count: favourites.videos,
            route: "/my-activity/favourites/videos"
          },
          {
            title: "Profiles",
            count: favourites.profiles,
            route: "/my-activity/favourites/profiles"
          },
          {
            title: "Products",
            count: favourites.products,
            route: "/my-activity/favourites/products"
          },
          {
            title: "Blogs",
            count: favourites.blogs,
            route: "/my-activity/favourites/blogs"
          },
          {
            title: "Jobs",
            count: favourites.jobs,
            route: "/my-activity/favourites/jobs"
          },
          { title: "AR & VR", count: favourites.arvr, route: "/my-activity/favourites/arvr" }
        ].map((el, index) => (
          <ListGroup.Item
            className="border-0 border-bottom"
            key={`my-favourites-${index}`}
            style={{ backgroundColor: "transparent" }}
          >
            <div className="d-flex justify-content-between">
              <p className="mb-0 d-flex align-items-center">{el.title}</p>
              <h5>{el.count}</h5>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

function StudioSpecificUserProfile({ details }) {
  return (
    <>
      <p style={{ color: "#696969", fontSize: "1.15rem", fontWeight: "600" }} className="mb-0">
        {details.principal_designer}
      </p>
      <p className="fst-italic" style={{ color: "#696969", fontSize: "1rem" }}>
        Principal Designer
      </p>
      <h6>{details.company_type}</h6>
    </>
  );
}

function StudentSpecificUserProfile({ details, latest_experience, latest_education }) {
  const { is_working_professional } = details;
  const { instituition, employment_type } = getStudentDesignation({
    latest_education,
    latest_experience,
    is_working_professional
  });

  return (
    <>
      <p style={{ color: "#696969", fontSize: "1.15rem", fontWeight: "600" }} className="mb-0">
        {details.field_of_studies}
      </p>
      <p className="fst-italic" style={{ color: "#696969", fontSize: "1rem" }}>
        {instituition}
      </p>
      <h6>{employment_type}</h6>
    </>
  );
}

function BrandSpecificUserProfile({ details }) {
  const { establishment_year, nature_of_orgranisation } = details;
  return (
    <>
      <p style={{ color: "#696969", fontSize: "1.25rem" }} className="fw-bold mb-2">
        {nature_of_orgranisation}
      </p>
      <p>Since {establishment_year}</p>
    </>
  );
}

function UserProfile({ user, details, handleGetDashboard }) {
  var socialMediaIcons = [];
  const { linkedin, instagram, website_link } = details;
  const { is_studio, latest_experience, latest_education, is_student } = user;

  if (linkedin) {
    socialMediaIcons.push({
      icon: DASHBOARD_ICONS.SOCIAL_MEDIA.DASHBOARD_LINKEDIN,
      onClick: () => window.open(linkedin, "_blank")
    });
  }
  if (instagram) {
    socialMediaIcons.push({
      icon: DASHBOARD_ICONS.SOCIAL_MEDIA.DASHBOARD_INSTAGRAM,
      onClick: () => window.open(instagram, "_blank")
    });
  }
  if (website_link)
    socialMediaIcons.push({
      icon: DASHBOARD_ICONS.SOCIAL_MEDIA.DASHBOARD_EMAIL,
      onClick: () => window.open(website_link, "_blank")
    });

  const handleChangeOpenToOffers = (value) =>
    new StudioBusinessDetailsAPI().update({ offers_status: value }).then(handleGetDashboard);

  const handleChangeOpenToWork = (value) =>
    new StudentPersonalDetailsAPI().update({ open_to_work: value }).then(handleGetDashboard);

  const openToWorkValue = details.open_to_work ? "Open to work" : "Not open to work";

  return (
    <Row
      className={`h-100 ms-1 shadow ${isMobile ? "me-1" : ""}`}
      style={{ borderRadius: "1.5rem" }}
    >
      <Col className="h-100 px-0 position-relative">
        <div
          style={{
            backgroundColor: "#E9E9E9",
            borderTopLeftRadius: "1.5rem",
            borderBottomLeftRadius: "1.5rem"
          }}
          className="h-100 d-flex justify-content-center align-items-center"
        >
          <div
            style={{
              width: "7rem",
              height: "7rem",
              top: "-15%",
              left: user.is_brand ? "27.5%" : "32.5%"
            }}
            className="mx-auto position-absolute"
          >
            <ProfileImage customUser={user} />
          </div>
          {/* <div style={{ paddingTop: "6rem" }}>
            <p className="text-center d-flex align-items-center justify-content-center">
              <div className="bg-white rounded py-1 me-2 shadow-sm" style={{ minWidth: "25%" }}>
                2550
              </div>
              Subscribers
            </p>
            <p className="text-center d-flex align-items-center justify-content-center">
              <div className="bg-white rounded py-1 me-2 shadow-sm" style={{ minWidth: "25%" }}>
                550
              </div>
              Subscribed
            </p>
          </div> */}
          <Stack direction="horizontal" gap={3} className="justify-content-center">
            {socialMediaIcons.map((el, index) => (
              <Image
                key={`social-media-${index}`}
                className="cursor-pointer"
                src={el.icon}
                onClick={el.onClick}
                style={{ width: "1.5rem" }}
              />
            ))}
          </Stack>
          {is_studio && (
            <Dropdown className="mt-3 position-absolute bottom-0 w-100">
              <Dropdown.Toggle
                className="w-100"
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: "1rem"
                }}
              >
                {details.offers_status}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[
                  "Looking for new offers",
                  "Not looking for any offer",
                  "Can consider a good opportunity"
                ].map((el) => (
                  <Dropdown.Item
                    style={{ width: "max-content !important" }}
                    active={details.offers_status === el}
                    key={el}
                    onClick={() => handleChangeOpenToOffers(el)}
                  >
                    {el}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {is_student && (
            <Dropdown className="mt-3 position-absolute bottom-0 w-100">
              <Dropdown.Toggle
                className="w-100"
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: "1rem"
                }}
              >
                {openToWorkValue}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {["Open to work", "Not open to work"].map((el) => (
                  <Dropdown.Item
                    active={el === openToWorkValue}
                    key={el}
                    onClick={() => handleChangeOpenToWork(el === "Open to work")}
                  >
                    {el}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </Col>
      <Col className="h-100 px-0">
        <div
          className="bg-white px-3 py-2 h-100 d-flex flex-column justify-content-evenly"
          style={{ borderTopRightRadius: "1.5rem", borderBottomRightRadius: "1.5rem" }}
        >
          <Stack
            direction="horizontal"
            className="align-items-center mb-1"
            style={{ marginLeft: "-3%" }}
          >
            <BsDot color="green" size={25} />
            <p className="text-success mb-0" style={{ fontSize: "80%" }}>
              Active since <TimeAgo datetime={user.last_login} />
            </p>
          </Stack>
          <div>
            <h5 className="fw-bold">{user.name}</h5>
            {user.is_studio && <StudioSpecificUserProfile details={details} />}
            {user.is_student && (
              <StudentSpecificUserProfile
                details={details}
                latest_experience={latest_experience}
                latest_education={latest_education}
              />
            )}
            {user.is_brand && (
              <BrandSpecificUserProfile
                details={details}
                latest_experience={latest_experience}
                latest_education={latest_education}
              />
            )}
          </div>
          <Location>{user.location}</Location>
        </div>
      </Col>
    </Row>
  );
}

function Dashboard() {
  const [dashboard, setDashboard] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const apiHeaders = new ApiHeaders().authenticationHeaders;
  const { showAdvertiseWithUsModal } = useAdvertiseWithUsModal();

  const handleGetDashboard = () =>
    Api.get("/dashboard/", {}, apiHeaders)
      .then((result) => result.data)
      .then(setDashboard);

  useEffect(() => {
    handleGetDashboard().then(() => setLoading(false));
  }, []);

  var details, user;

  if (dashboard) {
    details = dashboard.details;
    user = dashboard.user;
  }

  return (
    <BaseContainer style={{ backgroundColor: "white", paddingBottom: "3rem" }}>
      <LockedContent>
        <LoadingContainer loading={loading || !dashboard}>
          {dashboard && (
            <div className="pt-5">
              <Row xs={1}>
                <Col sm={user.is_brand ? 4 : 5}>
                  <UserProfile
                    user={user}
                    details={details}
                    handleGetDashboard={handleGetDashboard}
                  />
                </Col>
                {user.is_brand && (
                  <Col sm={3}>
                    <MyContent content={dashboard.content} user={user} />
                  </Col>
                )}
                <Col sm={user.is_brand ? 5 : 7}>
                  <MyActivity activity={dashboard.activity} user={user} />
                </Col>
              </Row>
              <Row className="mt-4" xs={1} sm={3}>
                <Col>
                  <MyProfile
                    profile={dashboard.profile}
                    profile_completion_percentage={dashboard.profile_completion_percentage}
                    user={user}
                  />
                </Col>
                <Col>
                  <MyFavourites favourites={dashboard.favourite} />
                </Col>
                {!user.is_student && (
                  <Col className="p-4">
                    <Stack direction="horizontal" gap={3} className="align-items-center">
                      <Heading>Advertise With Us!</Heading>
                      <ReactSVG
                        src={OPEN_IN_NEW}
                        className="cursor-pointer"
                        onClick={() => showAdvertiseWithUsModal(true)}
                        style={{ width: "1rem" }}
                      />
                    </Stack>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </LoadingContainer>
      </LockedContent>
    </BaseContainer>
  );
}

let expSideBar = Dashboard;

if (isMobile) {
  expSideBar = withSidebar(Dashboard);
}

export default expSideBar;

import ContentFactory from "../../../apiClients/ContentFactory";
import store from "../../store";

export const getIdeationBoardService = async () => {
  const {
    authReducer: {
      user: { id }
    }
  } = store.getState();
  return await ContentFactory.createRequest("ideation-board").list({ user: id });
};

import React from "react";
import BaseContainer from "../../Containers/BaseContainer";
import SearchTable from "../SearchTable";
import LockedContent from "../../Containers/LockedContent";
import ContentHeader from "../ContentHeader";

function ContentTable({
  title,
  type,
  subTitle,
  initialFilter,
  FilterComponent,
  selectedResults,
  MobileCard,
  filterPillsOptions = []
}) {
  return (
    <BaseContainer className="py-4">
      <ContentHeader title={title} subTitle={subTitle} type={type} />
      <LockedContent>
        <SearchTable
          title={title}
          filterPillsOptions={filterPillsOptions}
          type={type}
          initialFilters={initialFilter}
          FilterComponent={FilterComponent}
          selectedResults={selectedResults}
          MobileCard={MobileCard}
        />
      </LockedContent>
    </BaseContainer>
  );
}

export default ContentTable;

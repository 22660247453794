import React from "react";
import { SIDEBAR_ICONS } from "../../constants/icons";
import AppliedJobs from "../../screens/Jobs/Applied";
import { Route, Routes } from "react-router-dom";

const JOB_APPLICATION_ROUTES = (
  <Routes>
    <Route path="/" element={<AppliedJobs />} />
    <Route path="/:id/review" element={<AppliedJobs isReview />} />
    <Route path="/:id/job-description" element={<AppliedJobs isJobDetails />} />
  </Routes>
);

export default {
  label: "My Jobs",
  path: "my-jobs",
  subMenu: [
    {
      label: "Applied Jobs",
      path: "/my-jobs/applied/*",
      menuPath: "/my-activity/my-jobs/applied",
      component: JOB_APPLICATION_ROUTES,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    }
  ],
  icon: SIDEBAR_ICONS.MY_JOBS_SIDEBAR_ICON,
  notFoundRoute: "/my-activity/my-jobs/applied"
};

/* eslint-disable no-undef */
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import "./styles.scss";
import { isEmpty } from "lodash";
import { useAuth } from "hooks";
import { getContentAbsoluteUrl } from "utils";
import ACCEPTED_FILE_TYPES from "constants/accepted-file-types";
import COLORS from "constants/colors";
import { checkFileRejections } from "components/Uploaders/BaseUpload";
import FormError from "components/FormError";
import { isMobile } from "react-device-detect";

function UploadProfileImageForm({ user, onHide }) {
  const { profile_images } = user;
  const selectedProfileImage = profile_images.filter((el) => el.is_selected === true);
  const [selected, setSelected] = useState(
    selectedProfileImage && !isEmpty(selectedProfileImage) && selectedProfileImage[0].id
  );
  const { updateProfileImage } = useAuth();
  const [error, setError] = useState({});

  const onSubmit = () => updateProfileImage(selected, () => onHide());

  const UploadComponent = () => {
    const { uploadProfileImage } = useAuth();
    const handleFileUpload = (obj) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(obj);
      });

    const setCError = (name, value) => setError({ profileImage: value });

    const onDrop = async (acceptedFiles, fileRejections) => {
      const isRejected = checkFileRejections(setCError, 1, "profileImage", "image", fileRejections);
      if (isRejected) {
        return;
      }
      await handleFileUpload(acceptedFiles[0]).then(uploadProfileImage);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      accept: ACCEPTED_FILE_TYPES.image,
      maxSize: 1 * 1024 * 1024
    });

    return (
      <div
        className="rounded-circle bg-white d-flex justify-content-center align-items-center"
        onClick={open}
        {...getRootProps()}
        style={{ width: "10rem", height: "10rem", border: `1px dashed ${COLORS.GREY2}` }}
      >
        <div>
          <BsPlus
            className="d-block mx-auto my-2 rounded-circle"
            size={25}
            style={{ backgroundColor: COLORS.GREY1 }}
          />
          <input {...getInputProps()} />
          <p className="fw-bold px-4 text-center" style={{ fontSize: "75%" }}>
            Upload from Device
          </p>
        </div>
      </div>
    );
  };
  const DesktopView = () => (
    <Row xs={1} sm={6} className="pb-5">
      <Col className="d-flex justify-content-center align-items-center">
        <UploadComponent />
      </Col>
      {profile_images.map((el, index) => (
        <Col
          key={`${el}-${index}`}
          className="py-1 px-2 mx-0 d-flex justify-content-center align-items-center"
        >
          <div
            style={
              el && el.id === selected ? { border: `1.5px solid ${COLORS.PRIMARY_ORANGE}` } : {}
            }
            onClick={() => setSelected(el.id)}
            className="rounded-circle cursor-pointer position-relative px-0 w-100 h-100"
          >
            {el && el.id === selected && (
              <div
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  right: "10%",
                  top: "10%"
                }}
                className="bg-orange position-absolute rounded-circle d-flex justify-content-center align-items-center"
              >
                <FaCheck color="white" size={12} />
              </div>
            )}
            <Avatar
              src={getContentAbsoluteUrl(el.image)}
              round
              className="p-1 profile-image-uploader-avatar"
            />
          </div>
        </Col>
      ))}
    </Row>
  );
  const MobileView = () => (
    <div>
      <Col className="d-flex justify-content-center align-items-center">
        <UploadComponent />
      </Col>
      <Row xs={3} className="pb-5 mt-3">
        {profile_images.map((el, index) => (
          <Col
            key={`${el}-${index}`}
            className="py-1 px-2 mx-0 d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                ...(el && el.id === selected
                  ? { border: `1.5px solid ${COLORS.PRIMARY_ORANGE}` }
                  : {}),
                height: "7rem"
              }}
              onClick={() => setSelected(el.id)}
              className="rounded-circle cursor-pointer position-relative px-0 w-100"
            >
              {el && el.id === selected && (
                <div
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    right: "10%",
                    top: "10%"
                  }}
                  className="bg-orange position-absolute rounded-circle d-flex justify-content-center align-items-center"
                >
                  <FaCheck color="white" size={12} />
                </div>
              )}
              <Avatar
                src={getContentAbsoluteUrl(el.image)}
                round
                className="px-0 profile-image-uploader-avatar h-100 py-1 px-1"
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <Container className={`px-${isMobile ? 3 : 5} pt-${isMobile ? 3 : 5}`}>
      {isMobile ? <MobileView /> : <DesktopView />}
      {error && error.profileImage && (
        <FormError className="d-flex justify-content-center">
          {error.profileImage.message}
        </FormError>
      )}
      <Button className="d-block px-4 ms-auto mb-4" onClick={onSubmit}>
        APPLY
      </Button>
    </Container>
  );
}
const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(UploadProfileImageForm);

import Dropdown from "components/Dropdown";
import { useSearchParams } from "hooks/searchParams";
import React from "react";

export default function YearsOfExperienceFilters() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "years_of_experience";

  const value = getKey(KEY);
  return (
    <Dropdown
      value={value}
      options={[
        { value: "0-5", label: "0-5 years" },
        { value: "5.1-10", label: "5.1-10 years" },
        { value: "10.1-20", label: "10.1-20 years" },
        { value: "20+", label: "More than 20 years" }
      ]}
      label="Years of Experience"
      onClick={(value) => addKey(KEY, value)}
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
    />
  );
}

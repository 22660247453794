import COLORS from "constants/colors";
import React from "react";

export default function TotalResult({ count }) {
  return (
    <p className="fw-bold my-auto" style={{ textWrap: "nowrap" }}>
      Total:{" "}
      <span className="rounded-circle px-2 py-1" style={{ backgroundColor: COLORS.GREY1 }}>
        {count || 0}
      </span>
    </p>
  );
}

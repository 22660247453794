import React from "react";
import ContentList from "../../components/Base/ContentList";
import Blog from "../../components/Cards/Blog";

const AllBlogs = () => (
  <ContentList
    Card={Blog}
    type="blog"
    initialFilters={{ is_posted: true, rejected: false, under_review: false }}
  />
);

export default AllBlogs;

import Dropdown from "components/Dropdown";
import { useNavigationInfo } from "hooks";
import { useSearchParams } from "hooks/searchParams";
import React, { useEffect } from "react";

export default function SortByFilter({ type, azKey, zaKey }) {
  const { addKey, getKey } = useSearchParams();
  const { isActivity, isProfiles } = useNavigationInfo();
  const KEY = "ordering";
  const value = getKey(KEY);

  useEffect(() => {
    if (!value) {
      addKey(KEY, isProfiles ? "-date_joined" : "-created");
    }
  }, []);

  var OPTIONS = [
    {
      value: isProfiles ? "-date_joined" : isActivity && type ? `-${type}__created` : "-created",
      label: "Most Recent"
    },
    {
      value: isProfiles ? "date_joined" : isActivity && type ? `${type}__created` : "created",
      label: "Least Recent"
    },
    {
      value: isProfiles ? "name" : isActivity && type ? `${type}__title` : azKey || "title",
      label: "A-Z"
    },
    {
      value: isProfiles ? "-name" : isActivity && type ? `-${type}__title` : zaKey || "-title",
      label: "Z-A"
    }
  ];
  if (isProfiles) {
    OPTIONS.push({ value: "-trending", label: "Trending" });
  }

  return (
    <Dropdown
      value={value}
      options={OPTIONS}
      label="Sort By"
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

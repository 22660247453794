import React from "react";
import ContentList from "../../../components/Base/ContentList";
import { connect } from "react-redux";

function Closed({ user }) {
  return (
    <ContentList
      type="job"
      title="Closed Jobs"
      subTitle="View all your closed jobs here."
      initialFilters={{
        is_closed: true,
        user: user.id
      }}
      cardProps={{ showSelect: false }}
      doNotShowAdd
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(Closed);

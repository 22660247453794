import "./styles.scss";
import React, { useState, useEffect, memo } from "react";
import { Row, Col, Stack } from "react-bootstrap";
import { debounce, isArray, isEmpty, isFunction, isNull, isNumber } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CATEGORY_FILTER,
  CUSTOM_STAKEHOLDER_FILTER_OPTIONS,
  CardComponent,
  getAddForm,
  handleResultSelection
} from "./utils";
import { useAuth, useNavigationInfo } from "hooks";
import { useSearchParams } from "hooks/searchParams";
import MoodBoardContentFactory from "apiClients/MoodBoardContentFactory";
import FavouriteFactory from "apiClients/FavouriteFactory";
import ContentFactory from "apiClients/ContentFactory";
import { getCardHeight } from "../Card";
import AddContent from "components/Cards/AddContent";
import AddContetnBorderedButton from "components/Buttons/AddContentBordered";
import TimeCreatedFilters from "components/Filters/TimeCreated";
import StakeholdersFilters from "components/Filters/Stakeholders";
import CategoryFilter from "components/Filters/Category";
import LoadingContainer from "components/Containers/Loading";
import Search from "components/Inputs/Search";
import FilterPills from "components/Filters/FilterPills";
import TotalResult from "components/TotalResult";
import HandleMultipleDeletion from "../HandleMultipleDeletion";
import SortByFilter from "components/Filters/SortBy";
import StudioCompanyTypeFilters from "components/Filters/StudioCompanyType";
import FieldOfStudyFilter from "components/Filters/FieldOfStudy";
import YearsOfExperienceFilters from "components/Filters/YearsOfExperience";
import LocationFilters from "components/Filters/Location";
import CheckboxFilter from "components/Filters/Checkbox";
import ContentDetailModal from "components/Modals/ContentDetail";
import AddContentModal from "components/Modals/AddContent";
import ConfirmDeletionWithProgressModal from "components/Modals/ConfirmDeletionWithProgressModal";
import DropdownFactory from "apiClients/DropdownFactory";

const SearchList = ({
  type,
  addText,
  doNotShowAdd,
  handleAdd,
  initialFilters = {},
  searchPlaceholder = "Search...",
  addModalSize = "xl",
  cardProps = {}
}) => {
  const navigation = useNavigate();
  const {
    isMyProfileOrActivity,
    isMyProfile,
    isAll,
    isFavourites,
    isProfiles,
    isActivity,
    isMoodBoard,
    isSampleProfile,
    isLearn,
    isJobs
  } = useNavigationInfo();
  const { id, moodBoardId, showContentId } = useParams();
  const { addKey, queryString, searchParamsAsObject, deleteKey, getKey } = useSearchParams();
  const { getUserInfo } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailObj, setShowDetailObj] = useState(undefined);
  const [editDetailObj, setEditDetailObj] = useState(undefined);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [categories, setCategories] = useState(null);
  const page_size = 16;

  const openToWorkFilter = getKey("open_to_work");
  const openToOffersFilters = getKey("open_to_offers");

  const loadCategories = () =>
    Promise.all([
      DropdownFactory.createRequest("image-categories").list(),
      DropdownFactory.createRequest("video-categories").list()
    ]).then((result) => {
      setCategories({ ...CATEGORY_FILTER, image: result[0], video: result[1] });
    });

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    if (openToOffersFilters === "true") {
      addKey("open_to_work", null);
    }
  }, [openToOffersFilters]);

  useEffect(() => {
    if (openToWorkFilter === "true") {
      addKey("open_to_offers", null);
    }
  }, [openToWorkFilter]);

  useEffect(() => {
    setShowDetail(isNumber(showDetailObj));
  }, [showDetailObj]);

  useEffect(() => {
    if (showContentId && showDetailObj) {
      if (isArray(data) && !isEmpty(data)) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].slug === showContentId) {
            setShowDetailObj(i);
          }
        }
      }
    }
  }, [showContentId, data]);

  const getClient = () => {
    var client;
    if (isMoodBoard) {
      client = MoodBoardContentFactory.createRequest(type, id, moodBoardId);
    } else if (isFavourites) {
      client = FavouriteFactory.createRequest(type);
    } else {
      client = ContentFactory.createRequest(type);
    }
    return client;
  };

  const fetchData = async (onSuccess, fetchPage, pageSize) => {
    if (isMyProfile) {
      getUserInfo();
    }
    return await getClient()
      .list({
        ...searchParamsAsObject,
        ...initialFilters,
        page: fetchPage || nextPage,
        page_size: pageSize || page_size
      })
      .then(onSuccess)
      .then(() => {
        if (loading === true) {
          setLoading(false);
        }
      })
      .catch((error) => console.error("Error fetching search results:", error));
  };

  const fetchDataAtIndex = (index) =>
    fetchData(
      (result) => {
        var newData = [...data];
        newData[index - 1] = result.result[0];
        setData(newData);
      },
      index,
      1
    );

  const fetchAllPages = async () =>
    await fetchData(
      (result) => {
        setData(result.result);
        setCount(result.count);
        setHasMore(result.has_next);
        if (!hasMore && result.has_next) {
          setNextPage(page + 1);
        }
        setPage(1);
      },
      1,
      nextPage - 1 < 0 ? 0 : (nextPage - 1) * page_size
    );

  const handleGetSearch = async () => {
    setLoading(true);
    await fetchData((result) => {
      setPage(page + 1);
      setCount(result.count);
      setHasMore(result.has_next);
      setNextPage(result.next_page);
      setData(result.result);
      setLoading(false);
    }, 1);
  };

  const next = () =>
    fetchData((result) => {
      setPage(nextPage);
      setHasMore(result.has_next);
      setNextPage(result.next_page);
      setData((prevResults) => [...prevResults, ...result.result]);
    });

  const onBookmarked = (index, removed) => {
    if (removed) {
      if (isFavourites) {
        fetchAllPages();
      } else {
        var newData = [...data];
        newData[index].is_favourited = null;
        newData[index].saves = newData[index].saves - 1;
        setData(newData);
      }
    } else {
      fetchDataAtIndex(index + 1);
    }
  };

  const onIdeationBoarded = (index, removed) => {
    if (removed) {
      var newData = [...data];
      if (isMoodBoard || isProfiles) {
        fetchAllPages();
      } else {
        newData[index - 1].obj.ideation_board_id = null;
        newData[index - 1].obj.mood_board_id = null;
        newData[index - 1].obj.mood_board_link_id = null;
        setData(newData);
      }
    } else {
      fetchDataAtIndex(index);
    }
  };

  const debouncedSearch = debounce(async () => {
    addKey("search", searchString);
    handleGetSearch();
  }, 300);

  useEffect(() => {
    addKey("search", searchString);
  }, [searchString]);

  useEffect(() => {
    handleGetSearch();
  }, [queryString, type]);

  useEffect(() => {
    deleteKey("category");
  }, [type]);

  const selectKey = isFavourites
    ? "is_favourited"
    : ["thesis", "arvr"].includes(type)
    ? "slug"
    : "id";

  const handleSelectAll = () =>
    setSelectedData(
      data.length === selectedData.length ? [] : data.map((result) => result[selectKey])
    );

  const isDeleteButtonDisabled = selectedData.length === 0;
  const isSelectAllDisabled = isArray(data) && data.length === 0;
  const isAllSelected =
    isArray(selectedData) &&
    isArray(data) &&
    data.length > 0 &&
    selectedData.length > 0 &&
    selectedData.length === data.length;

  const onClickAdd = () => {
    if (isFavourites) {
      navigation(`/all/${type}s`);
    } else if (isFunction(handleAdd)) {
      handleAdd();
    } else if (isMyProfile) {
      setShowAddModal(true);
    }
  };

  const AddContentElement = isMyProfileOrActivity &&
    !isProfiles &&
    !isMoodBoard &&
    doNotShowAdd !== true && (
      <Col className={`mb-4 ${isMobile ? "px-0" : ""}`} sm={1} style={{ height: getCardHeight() }}>
        <AddContent onClick={onClickAdd} addText={addText} />
      </Col>
    );
  const AddMoodBoardContentElement = isMoodBoard && (
    <div className="mt-3">
      <AddContetnBorderedButton onClick={onClickAdd} label={`Add new ${type}s`} />
    </div>
  );

  function handleCardClick(obj, index) {
    if (isFavourites || isAll || isMoodBoard) {
      if (isProfiles) {
        navigate(`/profiles/${obj.slug}/`);
      }
      setShowDetailObj(index);
    } else if (isMyProfile) {
      setEditDetailObj(obj);
      setShowAddModal(true);
    } else if (isLearn) {
      if (type === "thesis") {
        navigate(`/learn/thesis/${obj.slug}?${queryString}`);
      }
      if (type == "image" || type === "video") {
        navigate(`/learn/${type}s/${obj.slug}?${queryString}`);
      }
      setShowDetailObj(index);
    } else if (isJobs && isActivity) {
      console.debug("Nothing on Click");
    } else if (isProfiles) {
      navigate(`/profiles/${obj.slug}/`);
    } else if (!isMyProfile && !isSampleProfile) {
      navigate(`${obj.user_id}/${type}${type !== "thesis" ? "s" : ""}/${obj.id}`);
    }
  }

  const SearchResult = (
    <InfiniteScroll dataLength={data} next={next} hasMore={hasMore} loader={<div>Loading</div>}>
      <Row
        xs={isProfiles ? 2 : 1}
        md={
          isProfiles ? (isFavourites ? 4 : 5) : isMyProfileOrActivity && type !== "product" ? 3 : 4
        }
        className="mx-0 mt-4"
      >
        {AddContentElement}
        {data.map((result, index) => (
          <Col
            className={`mb-4 ${isMobile ? "px-0" : ""}`}
            style={{ height: getCardHeight() }}
            key={`search-list-${result.id || result.slug}-${type}`}
          >
            <CardComponent
              {...cardProps}
              obj={isMoodBoard ? (result && result.obj ? result.obj : null) : result}
              type={type}
              isSelected={
                result && result[selectKey] ? selectedData.includes(result[selectKey]) : false
              }
              handleSelection={() =>
                handleResultSelection(selectedData, setSelectedData, result[selectKey])
              }
              onBookmarked={(removed) => onBookmarked(index, removed)}
              onAddedToIdeationBoard={(removed) => onIdeationBoarded(index + 1, removed)}
              onClick={() => handleCardClick(result, index)}
              onDelete={fetchAllPages}
            />
          </Col>
        ))}
      </Row>
    </InfiniteScroll>
  );

  function LearnFilters() {
    return (
      <>
        <TimeCreatedFilters overflow="visible" />
        <StakeholdersFilters
          label="Posted By"
          options={CUSTOM_STAKEHOLDER_FILTER_OPTIONS[type]}
          overflow="visible"
        />
      </>
    );
  }

  function AllFilters() {
    return (
      <>
        {categories && categories[type] && (
          <CategoryFilter options={[{ label: "All", value: null }, ...categories[type]]} />
        )}
        <StakeholdersFilters label="Added By" options={CUSTOM_STAKEHOLDER_FILTER_OPTIONS[type]} />
      </>
    );
  }

  const group = getKey("group");

  return (
    <>
      <Row className="mx-0" xs={1} md={2}>
        <Col sm={4} className={`px-${isMobile ? 0 : ""}`}>
          {type !== "catalogue" ? (
            <Search
              placeholder={searchPlaceholder}
              searchString={searchString}
              setSearchString={setSearchString}
            />
          ) : (
            <FilterPills
              options={[
                { label: "Price Catalogue", value: "Price" },
                { label: "Product Catalogue", value: "Product" }
              ]}
              name="type"
            />
          )}
        </Col>
      </Row>
      <div
        style={{ marginTop: "1.25rem", overflow: "auto" }}
        className={`pb-2 px-${isMobile ? 0 : 3}`}
      >
        <Stack direction="horizontal" gap={3} className="justify-content-start">
          <TotalResult count={count} />
          {((isMyProfileOrActivity && !isProfiles && type !== "job") || isFavourites) && (
            <HandleMultipleDeletion
              selected={selectedData}
              setShowDeleteModal={setShowDeleteModal}
              isAllSelected={isAllSelected}
              isDeleteButtonDisabled={isDeleteButtonDisabled}
              isSelectAllDisabled={isSelectAllDisabled}
              handleSelectAll={handleSelectAll}
            />
          )}
          {(!isMobile || type === "job") && isActivity && !isMoodBoard && (
            <SortByFilter type={type} />
          )}
          {isAll && <AllFilters />}
          {isProfiles && !isFavourites && (
            <>
              {group && group === "studio" ? (
                <>
                  <StudioCompanyTypeFilters />
                </>
              ) : group && group === "student" ? (
                <>
                  <FieldOfStudyFilter />
                </>
              ) : (
                <></>
              )}
              {group && group !== "brand" && <YearsOfExperienceFilters />}
              {group && <LocationFilters />}
              {group && <SortByFilter />}
              {group !== "brand" && (
                <>
                  {(!group || group === "professional") && (
                    <CheckboxFilter label="Open To Work" attribute="open_to_work" />
                  )}
                  {(!group || group === "studio") && (
                    <CheckboxFilter label="Open To Offers" attribute="open_to_offers" />
                  )}
                </>
              )}
            </>
          )}
          {isLearn && <LearnFilters />}
        </Stack>
        {isMobile && isActivity && !isMoodBoard && type !== "job" && (
          <div className="pt-4 d-flex justify-content-end">
            <SortByFilter type={type} />
          </div>
        )}
      </div>

      {isMoodBoard && AddMoodBoardContentElement}
      {isLearn && categories && (
        <Row className="mx-0 mt-4">
          <Col className="px-0 overflow-auto pb-2">
            <FilterPills options={[{ value: null, label: "All" }, ...(categories[type] || [])]} />
          </Col>
        </Row>
      )}
      <LoadingContainer loading={loading || isNull(categories)}>{SearchResult}</LoadingContainer>
      {(isFavourites || isLearn || isAll || isMoodBoard) && !isJobs && (
        <ContentDetailModal
          type={type}
          show={showDetail}
          onAction={handleGetSearch}
          onViewed={(id) => {
            var newData = [...data];
            const index = newData.findIndex((el) => el.id === id);
            if (index !== -1) {
              newData[index].views = newData[index].views + 1;
            }
            setData(newData);
          }}
          onHide={() => {
            setShowDetailObj(undefined);
            if (isLearn) {
              navigate(`/learn/${type}s?${queryString}`, { replace: true });
            }
          }}
          list={data}
          startIndex={showDetailObj}
        />
      )}
      {((isMyProfileOrActivity && !isProfiles) || isFavourites) && selectedData.length > 0 && (
        <ConfirmDeletionWithProgressModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          type={type}
          onSuccessDelete={() => {
            debouncedSearch();
            setShowDeleteModal(false);
            setSelectedData([]);
          }}
          selectedData={selectedData}
        />
      )}
      {isMyProfile && !isProfiles && (
        <AddContentModal
          show={showAddModal}
          editObj={editDetailObj}
          onHide={() => {
            setShowAddModal(false);
            setEditDetailObj(undefined);
          }}
          Form={getAddForm(type)}
          onSuccess={async () => {
            await fetchAllPages();
            setShowAddModal(false);
            setEditDetailObj(undefined);
          }}
          type={type}
          size={addModalSize}
        />
      )}
    </>
  );
};

export default memo(SearchList);

import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Button, Dropdown, Image, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { isMobile } from "react-device-detect";
import { RxHamburgerMenu } from "react-icons/rx";
import { getProfileInitRoute } from "utils";
import { HEADER_ICONS } from "constants/icons";
import COLORS from "constants/colors";
import ACTION_TYPES from "constants/action-types";
import { useSidebar } from "hooks/sidebar";
import LOGO from "../../assets/logo.png";

// eslint-disable-next-line react/display-name
const HeaderProfileButton = React.forwardRef(
  ({ onClick, dropdownActive, setDropdownActive }, ref) => {
    return (
      <div ref={ref} onClick={onClick}>
        <ReactSVG
          src={HEADER_ICONS.PROFILE_HEADER_ICON}
          style={{
            color: dropdownActive ? COLORS.PRIMARY_ORANGE : COLORS.GREY2,
            width: "4rem"
          }}
          onClick={() => setDropdownActive(!dropdownActive)}
        />
      </div>
    );
  }
);

function HeaderDropdown({ user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownActive, setDropdownActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setDropdownActive(false);
  }, [location]);

  const initRoute = getProfileInitRoute(user);

  const logout = () => dispatch({ type: ACTION_TYPES.AUTH.LOGOUT });
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={HeaderProfileButton}
        dropdownActive={dropdownActive}
        setDropdownActive={() => setDropdownActive(!dropdownActive)}
      />
      <Dropdown.Menu flip style={{ left: "-100px" }} className="py-0">
        {[
          {
            label: "Dashboard",
            onClick: () => navigate("dashboard"),
            route: "/dashboard",
            active: location.pathname.startsWith("/dashboard")
          },
          {
            label: "My Profile",
            route: initRoute,
            onClick: () => navigate(initRoute),
            active: location.pathname.startsWith("/my-profile/")
          },
          {
            label: "My Activity",
            onClick: () => navigate("/my-activity"),
            route: "/my-activity",
            active: location.pathname.startsWith("/my-activity")
          },
          { label: "Logout", onClick: () => logout() }
        ].map((item, index) => (
          <React.Fragment key={`dropdown-item-${index}`}>
            {index !== 0 && <hr className="my-0" />}
            <Dropdown.Item
              className="py-2"
              active={item.active}
              onClick={() => {
                setDropdownActive(false);
                item.onClick();
              }}
            >
              {item.label}
            </Dropdown.Item>
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Header({ isLoggedIn, user }) {
  const location = useLocation();

  const isActiveLink = (nav) => location.pathname.startsWith(`/${nav}`);
  const { toggleSideBar } = useSidebar();
  const navigate = useNavigate();

  return (
    <Navbar bg="light" expand="lg" style={{ height: "10vh" }} className="px-4 shadow">
      {isMobile && <RxHamburgerMenu onClick={() => toggleSideBar()} size={30} />}
      <Navbar.Brand className="me-0">
        <Image src={LOGO} style={{ width: "7.5rem" }} />
      </Navbar.Brand>
      <>
        {!isMobile && (
          <Nav className="ms-auto">
            {[
              { href: "products", label: "Products" },
              { href: "learn", label: "Learn" },
              { href: "profiles", label: "Profiles" },
              { href: "jobs", label: "Jobs" }
            ].map((el, index) => (
              <Link
                to={el.href}
                key={`header-nav-link-${index}-${el}`}
                className={`text-decoration-none fw-bold px-3 ${
                  isActiveLink(el.href) ? "active" : ""
                }`}
                style={
                  isActiveLink(el.href) ? { color: COLORS.PRIMARY_ORANGE } : { color: "black" }
                }
              >
                {el.label}
              </Link>
            ))}
          </Nav>
        )}
        {!isLoggedIn && !isMobile && (
          <Stack direction="horizontal" gap={3} className="ms-3">
            {[
              { label: "SIGN UP", variant: "primary", route: "/signup" },
              { label: "SIGN IN", variant: "outline-primary", route: "/login" }
            ].map((el) => (
              <Button
                key={`${el.label}-button`}
                variant={el.variant}
                className="px-3"
                onClick={() => navigate(el.route)}
              >
                {el.label}
              </Button>
            ))}
          </Stack>
        )}
        {isLoggedIn === true && <HeaderDropdown user={user} />}
      </>
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.authReducer.isLoggedIn,
  user: state.authReducer.user
});

export default connect(mapStateToProps)(Header);

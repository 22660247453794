import { STATES } from "../../states";

const AREAS_OF_INTERESTS = [
  { value: "Architecture", label: "Architecture" },
  { value: "Design", label: "Design" },
  { value: "Civil Engineering", label: "Civil Engineering" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Structural Engineering", label: "Structural Engineering" },
  { value: "Operations", label: "Operations" },
  { value: "Technology", label: "Technology" },
  { value: "Graphic Design", label: "Graphic Design" },
  { value: "HR", label: "HR" },
  { value: "Accounts", label: "Accounts" },
  { value: "Sales", label: "Sales" },
  { value: "Marketing", label: "Marketing" }
];

const WORKING_MODES = [
  { value: "In office", label: "In office" },
  { value: "Work from home", label: "Work from home" },
  { value: "Hybrid", label: "Hybrid" }
];

const CURRENTLY_LOOKING_FOR = [
  { value: "Full time Job", label: "Full time Job" },
  { value: "Part time Job", label: "Part time Job" },
  { value: "Freelance work", label: "Freelance work" },
  { value: "Internship", label: "Internship" },
  { value: "Contract Based Work", label: "Contract Based Work" }
];

const LOCATION_PREFERENCES = STATES;

const EDUCATION_LEVEL = [
  { value: "Diploma", label: "Diploma" },
  { value: "Graduation", label: "Graduation" },
  { value: "Post Graduation", label: "Post Graduation" },
  { value: "Phd.", label: "Phd." }
];

const FIELD_OF_STUDIES = [
  { value: "Architecture", label: "Architecture" },
  { value: "Interior Designing", label: "Interior Designing" },
  { value: "Civil Engineering", label: "Civil Engineering" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "Structural Engineering", label: "Structural Engineering" }
];

const ACTIVELY_LOOKING = [
  {
    value: "Full time Job",
    label: "Full time Job"
  },
  {
    value: "Part time Job",
    label: "Part time Job"
  },
  {
    value: "Freelance work",
    label: "Freelance work"
  },
  {
    value: "Internship",
    label: "Internship"
  },
  {
    value: "Contract Based Work",
    label: "Contract Based Work"
  }
];
export {
  AREAS_OF_INTERESTS,
  WORKING_MODES,
  CURRENTLY_LOOKING_FOR,
  LOCATION_PREFERENCES,
  EDUCATION_LEVEL,
  FIELD_OF_STUDIES,
  ACTIVELY_LOOKING
};

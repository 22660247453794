import React from "react";
import { Sidebar } from "react-pro-sidebar";
import { connect } from "react-redux";
import StudentMenu from "./Profile/StudentMenu";
import Header from "./Header";
import COLORS from "../../constants/colors";
import StudioMenu from "./Profile/StudioMenu";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigationInfo } from "../../hooks";
import { isMobile } from "react-device-detect";
import BrandMenu from "./Profile/BrandMenu";
import { useSidebar } from "hooks/sidebar";
import PublicMenu from "./PublicMenu";
import { CloseButton, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function ProfileCompletionMenuView({ profile_completion_percentage }) {
  return (
    <div className="px-4  pb-5">
      <h5 className="mt-4 fw-bold">Profile Completion</h5>
      <div style={{ width: "8rem", height: "8rem" }} className="d-block mx-auto mt-4">
        <CircularProgressbar
          value={profile_completion_percentage}
          text={`${profile_completion_percentage}%`}
          styles={buildStyles({
            pathColor: COLORS.PRIMARY_ORANGE,
            textColor: "#f88",
            trailColor: "#d6d6d6",
            backgroundColor: COLORS.PRIMARY_ORANGE
          })}
        />
      </div>
    </div>
  );
}

function SideBar({ user }) {
  const { showSidebar, toggleSideBar } = useSidebar();
  const { isActivity, isMyProfile } = useNavigationInfo();
  var menu = undefined;
  if (isActivity || isMyProfile) {
    if (user.is_student) {
      menu = <StudentMenu />;
    } else if (user.is_studio) {
      menu = <StudioMenu />;
    } else if (user.is_brand) {
      menu = <BrandMenu />;
    }
  } else if (isMobile) {
    menu = <PublicMenu />;
  }

  return (
    <Sidebar
      style={{ backgroundColor: COLORS.GREY1 }}
      width={isMobile ? "100%" : "17.5rem"}
      toggled={showSidebar}
      breakPoint="800px"
    >
      {isMobile && (
        <div
          className="position-absolute"
          style={{ top: isMyProfile || isActivity ? 55 : 10, right: 20, zIndex: 20 }}
        >
          <CloseButton onClick={() => toggleSideBar()} />
        </div>
      )}
      {isMobile && (isMyProfile || isActivity) && (
        <Row className="py-2 bg-orange">
          {[
            { path: "/products", title: "Products" },
            { path: "/learn", title: "Learn" },
            { path: "/profiles", title: "Profiles" },
            { path: "/jobs", title: "Jobs" }
          ].map((link, index) => (
            <Col key={index} className="text-center">
              <Link to={link.path} className="text-decoration-none fw-bold text-white">
                {link.title}
              </Link>
            </Col>
          ))}
        </Row>
      )}
      {isMyProfile && <Header />}
      {isActivity && <h4 className="ms-4 mt-4">My Activity</h4>}
      {menu}
      {(isActivity || isMyProfile) && (
        <ProfileCompletionMenuView
          profile_completion_percentage={user.profile_completion_percentage}
        />
      )}
    </Sidebar>
  );
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user
});
export default connect(mapStateToProps)(SideBar);

import { Navigate, Route } from "react-router-dom";

export const getRoutes = (menu) => {
  const routes = [];

  menu.forEach((el) => {
    if (el.subMenu) {
      el.subMenu.forEach((subMenuEl, index) =>
        routes.push(
          <Route
            path={subMenuEl.path}
            key={`${subMenuEl.path}-${index}`}
            element={subMenuEl.component}
          />
        )
      );
      if (el.includeDefaultPath === true) {
        routes.push(
          <Route path={el.path} key={`${el.path}-included-by-default`} element={el.component} />
        );
      }
    } else if (el.defaultRoute && !el.path) {
      routes.push(
        <Route
          path={"/"}
          key={`default-route-${el.path}-${el.defaultRoute}`}
          element={<Navigate to={el.defaultRoute} replace />}
        />
      );
    } else {
      routes.push(<Route path={el.path} key={el.path} element={el.component} />);
    }
    if (el.notFoundRoute) {
      routes.push(
        <Route
          path={`/${el.path}/*`}
          key={`not-found-${el.path}-${el.notFoundRoute}`}
          element={<Navigate to={el.notFoundRoute} replace />}
        />
      );
    }
    if (el.defaultRoute) {
      routes.push(
        <Route
          path={"/"}
          key={`default-route-${el.path}-${el.defaultRoute}`}
          element={<Navigate to={el.defaultRoute} replace />}
        />
      );
    }
  });

  return routes;
};

import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { default as ReactSelect } from "react-select";
import CreateableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import VALIDATORS from "../../../validators";
import FormError from "../../FormError";
import { Form } from "react-bootstrap";
import DEFAULT_STYLE from "../../../styles";
import COLORS from "../../../constants/colors";
import { isFunction } from "lodash";
import AsyncCreateable from "react-select/async-creatable";
const Select = ({
  name,
  isMulti,
  isCreateable,
  isAsync,
  loadOptions,
  rules,
  showError = true,
  minLength = 1,
  required = true,
  options = [],
  isSearchable = true,
  ...props
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: rules || VALIDATORS.minLengthArray(minLength, required)
  });
  const isErrored = errors && errors[name];
  const styles = {
    container: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "0.3rem",
      ...(isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {})
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: COLORS.PRIMARY_ORANGE,
      color: "white",
      paddingLeft: "0.35rem"
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontWeight: "500",
      fontSize: "1rem",
      paddingRight: "0.5rem"
    })
  };

  const componentProps = {
    ...field,
    ...props,
    isSearchable: isSearchable,
    isMulti: isMulti,
    options: options,
    styles: styles
  };
  return (
    <Form.Group>
      {isCreateable && isAsync ? (
        <AsyncCreateable
          {...field}
          loadOptions={loadOptions}
          styles={styles}
          defaultOptions
          isMulti={isMulti}
          {...props}
        />
      ) : isCreateable ? (
        <CreateableSelect {...componentProps} />
      ) : isAsync && isFunction(loadOptions) ? (
        <AsyncSelect
          {...field}
          loadOptions={loadOptions}
          styles={styles}
          defaultOptions
          {...props}
        />
      ) : (
        <ReactSelect {...componentProps} />
      )}
      {isErrored && showError === true && <FormError>{errors[name].message}</FormError>}
    </Form.Group>
  );
};

export default Select;

import React, { useEffect, useState } from "react";
import BaseModal from "../Base";
import { Form, Modal } from "react-bootstrap";
import BackButton from "../../Buttons/Back";
import { UserSelectedCategories } from "../../../screens/ProfileDetails/Brand/Categories";
import ContentFactory from "../../../apiClients/ContentFactory";
import { isFunction, isObject } from "lodash";
import AddProductForm from "../../../forms/Content/AddProduct";
import Search from "../../Inputs/Search";

export default function AddProductsModal({ show, onHide, onSuccess }) {
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [search, setSearch] = useState("");

  const getUserCategories = () =>
    ContentFactory.createRequest("user-categories").list({ search }).then(setSelectedCategories);

  useEffect(() => {
    if (show) {
      getUserCategories();
    }
  }, [search, show]);

  useEffect(() => {
    if (isObject(selectedCategory) && showCategories === true) {
      setShowCategories(false);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (!show && selectedCategory) {
      setSelectedCategory(undefined);
    }
  }, [show]);

  const handleSuccess = () => {
    if (isFunction(onSuccess)) {
      onSuccess();
    }
  };

  return (
    <BaseModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {showCategories ? "Your Selected Categories" : "Have Many Products?"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showCategories && (
          <div className="mb-3">
            <div className="mb-2 mt-1">
              <BackButton onBack={() => setShowCategories(false)} />
            </div>
            <Form.Text muted>
              To add, edit or remove any category please make changes at categories section of your
              profile.
            </Form.Text>
          </div>
        )}
        {showCategories ? (
          <>
            <div className="mb-4">
              <Search searchString={search} setSearchString={setSearch} />
            </div>
            <UserSelectedCategories
              selectedCategories={selectedCategories}
              categorySelect={(cat) => {
                setSelectedCategory(cat);
                setShowCategories(false);
              }}
              level2Select={(cat) => {
                setSelectedCategory(cat);
                setShowCategories(false);
              }}
            />
          </>
        ) : (
          <AddProductForm
            setShowCategories={setShowCategories}
            selectedCategory={selectedCategory}
            categories={selectedCategories}
            onSuccess={handleSuccess}
          />
        )}
      </Modal.Body>
    </BaseModal>
  );
}

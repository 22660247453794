import React, { useState, Children, cloneElement } from "react";
import CSVHelp from "../../Modals/CSVHelp";

export default function CSVHelpContainer({ children, ...props }) {
  const [show, setShow] = useState(false);

  const childrenWithProp = Children.map(children, (child) => {
    return cloneElement(child, { show, setShow, ...props });
  });

  return (
    <>
      {childrenWithProp}
      <CSVHelp show={show} onHide={() => setShow(false)} />
    </>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import ContentList from "components/Base/ContentList";
import Blog from "components/Cards/Blog";

function DraftedBlogs() {
  const user = useSelector((state) => state.authReducer.user);

  return (
    <ContentList
      Card={Blog}
      type="blog"
      title="Drafted Blogs"
      subTitle="All your drafted blogs will be visible here"
      initialFilters={{
        is_drafted: true,
        author: user.id
      }}
      doNotShowAdd
    />
  );
}

export default DraftedBlogs;

import ContentFactory from "apiClients/ContentFactory";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import Select from "components/Inputs/Select";
import TagInput from "components/Inputs/TagInput";
import WYSIWYG from "components/Inputs/WSIWYG";
import BlogTemplatesModal from "components/Modals/BlogTemplates";
import BLOGS_CONSTANTS from "constants/Blogs";
import { isNil, omitBy } from "lodash";
import React, { useState, useEffect } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { renderErrorToast } from "utils";
import { showPromiseToast } from "utils";
import { isBase64 } from "utils";

export default function AddBlogForm({ id }) {
  const navigate = useNavigate();
  const { handleSubmit, clearErrors } = useFormContext();
  const [publish, setPublish] = useState(false);
  const [showBlogTemplates, setShowBlogTemplates] = useState(false);
  const blogClient = ContentFactory.createRequest("blog");
  const onSubmit = (data) => {
    var payload = {
      ...data,
      slug: id,
      category: data.category && data.category.value ? data.category.value : undefined,
      keywords: data.keywords.map((el) => el.tag),
      is_drafted: !publish,
      under_review: publish
    };
    payload = omitBy(payload, isNil);
    if (!isBase64(payload.thumbnail)) {
      delete payload.thumbnail;
    }
    const link = "/my-activity/my-blogs/posted";
    var promise;
    if (id) {
      promise = blogClient.partialUpdate(payload).then(() => navigate(link));
    } else {
      promise = blogClient.create(payload).then(() => navigate(link));
    }
    showPromiseToast(promise, {
      pending: "Saving blog",
      success: "Blog saved successfully",
      error: renderErrorToast()
    });
  };

  useEffect(() => {
    clearErrors();
  }, [publish]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <Form.Group>
          <Form.Label>Blog Title*</Form.Label>
          <ReactHookInput name="title" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Author Name*</Form.Label>
          <ReactHookInput name="author" required={publish} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Category*</Form.Label>
          <Select name="category" options={BLOGS_CONSTANTS.CATEGORIES} required={publish} />
        </Form.Group>
        <Form.Group className={`w-${isMobile ? 100 : 50}`}>
          <Form.Label className="mb-1">Upload Thumbnail*</Form.Label>
          <div className="mb-3">
            <Form.Text>Ideal Size: 400 X 230</Form.Text>
          </div>
          <ReactHookUpload
            type="image"
            name="thumbnail"
            required
            preferredText="Preferred Resolution: 400 X 330"
            maxSizeMB={1}
          />
        </Form.Group>
        <h6 className={`${isMobile ? "text-center" : ""}`}>OR</h6>
        <Form.Group className={isMobile ? "d-flex justify-content-center" : ""}>
          <div>
            <Form.Label className={isMobile ? "text-center w-100" : ""}>
              Select From Templates*
            </Form.Label>
            <div>
              <Button
                variant="outline-primary"
                className="px-5"
                onClick={() => setShowBlogTemplates(true)}
              >
                CHOOSE TEMPLATE
              </Button>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="my-3">
          <Form.Label>Blog Content*</Form.Label>
          <WYSIWYG name="content" required={publish} />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Keywords
            <span className="fst-italic text-secondary" style={{ fontSize: "85%" }}>
              {" "}
              (Max 10)
            </span>
          </Form.Label>
          <TagInput name="keywords" minLen={publish ? 1 : 0} required={publish} maxLen={10} />
        </Form.Group>
      </Stack>
      <div className={`my-4 ${isMobile ? "d-flex justify-content-between" : ""}`}>
        <Button
          variant="outline-primary"
          style={{ width: isMobile ? "47.5%" : "25%" }}
          type="submit"
          onClick={() => setPublish(false)}
        >
          SAVE
        </Button>
        <Button
          className={`ms-${isMobile ? 0 : 4}`}
          style={{ width: isMobile ? "47.5%" : "25%" }}
          type="submit"
          onClick={() => setPublish(true)}
        >
          POST
        </Button>
      </div>
      <BlogTemplatesModal show={showBlogTemplates} onHide={() => setShowBlogTemplates(false)} />
    </Form>
  );
}

import React from "react";
import Container from "react-bootstrap/esm/Container";
import { isMobile } from "react-device-detect";

// eslint-disable-next-line react/display-name
const BaseContainer = ({ children, style, className, ...props }) => {
  const headerHeight = "10vh";

  const containerMinHeight = isMobile ? 0 : `calc(100vh - ${headerHeight})`;
  return (
    <Container
      style={{ minHeight: containerMinHeight, ...style }}
      className={`${className || ""} mobile-horizontal-padding`}
      {...props}
    >
      {children}
    </Container>
  );
};

export default BaseContainer;

import React, { useState } from "react";
import BaseContainer from "../components/Containers/BaseContainer";
import { Col, Row, Stack } from "react-bootstrap";
import UnderlineHeading from "../components/Headings";
import COLORS from "../constants/colors";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

function MyActivity({ user }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();
  const { is_student, is_brand } = user;

  var items = [
    {
      heading: "My Favourites",
      subTitle: "All your saved items will be shown here",
      onClick: () => navigate("/my-activity/favourites/images")
    },
    {
      heading: "My Ideation Board",
      subTitle: "Create moodboards, Save project progress, and BOQs",
      onClick: () => navigate(`/my-activity/ideation-board/create`)
    },
    {
      heading: "My Blogs",
      subTitle: "Write blogs and share your knowledge",
      onClick: () => navigate("/my-activity/my-blogs/posted")
    }
  ];
  if (is_brand) {
    items.splice(1, 1);
  }
  items.push({
    heading: "My Jobs",
    subTitle: "Post jobs and manage received applications",
    onClick: () =>
      navigate(is_student ? `/my-activity/my-jobs/applied/` : `/my-activity/my-jobs/posted/`)
  });

  const handleMouseEnter = (index) => setHoveredIndex(index);

  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <BaseContainer className="py-4">
      <UnderlineHeading>Save. Create. Track.</UnderlineHeading>
      <p>All your activity relating to the following is recorded here.</p>
      <Row xs={1} md={3} className="mt-4">
        {items.map((el, index) => (
          <Col key={index}>
            <Stack
              style={{
                height: "10rem",
                backgroundColor: hoveredIndex === index ? COLORS.PRIMARY_ORANGE : "",
                color: hoveredIndex === index ? "white" : "",
                transition: "background-color 0.3s ease-in-out"
              }}
              onClick={el.onClick}
              className={`border text-center border-secondary rounded mb-4 align-items-center justify-content-center cursor-pointer`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <h6>{el.heading}</h6>
              <p className="px-3">{el.subTitle}</p>
            </Stack>
          </Col>
        ))}
      </Row>
    </BaseContainer>
  );
}
const mapStateToProps = (state) => ({
  user: state.authReducer.user
});

export default connect(mapStateToProps)(MyActivity);

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Stack from "react-bootstrap/esm/Stack";
import { Link } from "react-router-dom";
import { isUndefined } from "lodash";
import { isMobile } from "react-device-detect";
import { useAuth } from "hooks";
import Email from "components/Inputs/Email";
import Phone from "components/Inputs/Phone";
import Select from "components/Inputs/Select";
import ROLES from "constants/roles";
import Password from "components/Inputs/Password";
import CheckBox from "components/Inputs/CheckBox";
import AppLink from "components/AppLink";
import OtpVerification from "components/Modals/OtpVerification";
import ConfirmSignupModal from "components/Modals/ConfirmSignup";

const SignupForm = () => {
  const { handleSubmit, watch } = useFormContext();
  const { signupUser, verifyMobileAndLogin } = useAuth();
  const [showVerifyOTP, setShowVerifyOTP] = useState(undefined);
  const [showConfirmSignup, setShowConfirmSignup] = useState(undefined);
  const role = watch("role");

  const onSubmit = (data) => {
    const {
      phone: mobile,
      email,
      role: { value: role },
      password,
      otp
    } = data;
    const payload = {
      email,
      role,
      mobile,
      password,
      otp
    };
    if (isUndefined(showVerifyOTP)) {
      setShowConfirmSignup(() => () => {
        signupUser(payload, setShowVerifyOTP);
        setShowConfirmSignup(false);
      });
    } else {
      verifyMobileAndLogin(payload);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Email*</Form.Label>
          <Email required />
        </Form.Group>
        {isMobile ? (
          <>
            <Form.Group>
              <Form.Label>Phone Number*</Form.Label>
              <Phone />
            </Form.Group>
            <Form.Group>
              <Form.Label>User Role*</Form.Label>
              <Select name="role" options={ROLES} />
            </Form.Group>
          </>
        ) : (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Phone Number*</Form.Label>
                <Phone />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>User Role*</Form.Label>
                <Select name="role" options={ROLES} />
              </Form.Group>
            </Col>
          </Row>
        )}

        {isMobile ? (
          <>
            <Form.Group>
              <Form.Label>Password*</Form.Label>
              <Password required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password*</Form.Label>
              <Password name="confirmPassword" required />
            </Form.Group>
          </>
        ) : (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Password*</Form.Label>
                <Password required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Confirm Password*</Form.Label>
                <Password name="confirmPassword" required />
              </Form.Group>
            </Col>
            <Form.Text id="passwordHelpBlock" muted>
              Your password must be 8-20 characters long, contain letters and numbers, and must not
              contain spaces, special characters, or emoji.
            </Form.Text>
          </Row>
        )}
        <Form.Group className="d-flex align-items-center">
          <CheckBox
            name="termsAndConditions"
            required
            customLabel={
              <>
                <p className="ms-2 mb-0 helper-text">
                  I agree all{" "}
                  <span>
                    <Link>Terms and Conditions</Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link>Privacy Policy</Link>
                  </span>
                </p>
              </>
            }
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="text-uppercase">
          Sign Up
        </Button>
        <Row className="align-items-center align-self-center">
          <Col className="helper-text">
            <AppLink to="/login">Already Have an account? Login</AppLink>
          </Col>
        </Row>
      </Stack>
      <OtpVerification show={showVerifyOTP} setShow={setShowVerifyOTP} />
      <ConfirmSignupModal
        show={role && showConfirmSignup}
        entity={role ? role.label : undefined}
        onHide={() => setShowConfirmSignup(undefined)}
      />
    </Form>
  );
};

export default SignupForm;

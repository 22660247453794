import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import OtpVerificationForm from "../../../forms/OtpVerification";
import { Container } from "react-bootstrap";

export default function OtpVerificationContainer({ usePhone, handleResend }) {
  return (
    <Container className="p-4">
      <Row className="justify-content-between">
        <Col xs="auto">
          <h5>Verification Code</h5>
        </Col>
      </Row>
      <div className="mb-4">
        <Form.Text muted>
          An OTP is sent to the provided {usePhone === true ? "phone number" : "email"}. Please
          input that OTP here. If you did not receive it then click resend button.
        </Form.Text>
      </div>
      <OtpVerificationForm handleResend={handleResend} />
    </Container>
  );
}

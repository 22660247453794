import React from "react";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useAdvertiseWithUsModal } from "../../../hooks/advertiseWithUs";
import AdvertiseWithUsForm from "../../../forms/AdvertiseWithUs";

export default function AdvertiseWithUsModal() {
  const { show, hideAdvertiseWithUsModal } = useAdvertiseWithUsModal();
  const methods = useForm({ reValidateMode: "onChange" });
  return (
    <BaseModal show={show} onHide={hideAdvertiseWithUsModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Get Quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <AdvertiseWithUsForm />
        </FormProvider>
      </Modal.Body>
    </BaseModal>
  );
}

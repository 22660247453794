import AdvertisementCarousel from "components/AdvertisementCarousel";
import BrandsWithProducts from "components/BrandsWithProducts";
import Breadcrumbs from "components/Breadcrumbs";
import React from "react";
import { Container } from "react-bootstrap";
import ProductFilters from "screens/Products/Filters";

export default function ProductsContainer({ children, showBrandsWithProduct, noBreadcrumbs }) {
  return (
    <>
      <div className="w-100">
        <AdvertisementCarousel where="products" />
      </div>
      {showBrandsWithProduct && <BrandsWithProducts page_size={5} />}
      <ProductFilters />
      <Container>
        {!noBreadcrumbs && (
          <div className="mt-3 ms-2">
            <Breadcrumbs />
          </div>
        )}
        {children}
      </Container>
    </>
  );
}

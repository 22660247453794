import "./styles.scss";
import React from "react";
import { isArray, isEmpty } from "lodash";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import DEFAULT_IMAGE from "../../assets/sidebar-profile-bg.png";
import { getContentAbsoluteUrl } from "../../utils";

function ProfileBannerImage({ customUser, user, style = {} }) {
  var userObj = customUser || user;
  var { banner_images } = userObj;

  var image = (banner_images || []).filter((el) => el.is_selected === true);

  return (
    <Image
      src={
        isArray(image) && !isEmpty(image) ? getContentAbsoluteUrl(image[0].image) : DEFAULT_IMAGE
      }
      className="w-100"
      style={{ objectFit: "cover", ...style }}
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

export default connect(mapStateToProps, null)(ProfileBannerImage);

import ACTION_TYPES from "../../constants/action-types";

const initialState = {
  isLoadingIdeationBoards: false,
  getIdeationBoardError: undefined,
  ideationBoards: [],
  blogs: { pinnedBlogs: [], isLoadingPinnedBlogs: false, getPinnedBlogsError: undefined }
};
export default function contentReducer(state = initialState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_REQUEST:
      return { ...newState, isLoadingIdeationBoards: true };
    case ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_SUCCESS:
      return { ...newState, isLoadingIdeationBoards: false, ideationBoards: action.result };
    case ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_FAILURE:
      return { ...newState, isLoadingIdeationBoard: false, getIdeationBoardError: action.error };
    case ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_REQUEST:
      return { ...newState, blogs: { ...newState.blogs, isLoadingPinnedBlogs: true } };
    case ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_SUCCESS:
      return {
        ...newState,
        blogs: { ...newState.blogs, isLoadingPinnedBlogs: false, pinnedBlogs: action.result }
      };
    case ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_FAILURE:
      return {
        ...newState,
        blogs: { ...newState.blogs, isLoadingPinnedBlogs: false, getPinnedBlogsError: action.error }
      };
    default:
      return newState;
  }
}

import React, { useEffect, useState } from "react";
import { Button, CloseButton, Form, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isDate, isString, isUndefined } from "lodash";
import { useFormContext } from "react-hook-form";
import DateParser from "date";
import CheckBox from "components/Inputs/CheckBox";
import FormError from "components/FormError";
import StartEndDateInput from "components/Inputs/StartEndDate";
import COLORS from "constants/colors";
import EditDeleteIcons from "components/Icons/EditDeleteIcons";
import DateRangeFormatter from "components/DateRangeFormatter";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";
import { useResumeFields } from "hooks";
import ResumeFormContainer from "components/Containers/ResumeForm";
import { scroller } from "react-scroll";

function AddCertificationCourseForm({ onSave, onClose, certificationCourse }) {
  const [trainingProgram, setTrainingProgram] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [associateAlliance, setAssociateAlliance] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState([]);

  const clearForm = () => {
    setTrainingProgram("");
    setOrganisation("");
    setIsOnline(false);
    setStartDate(null);
    setEndDate(null);
    setAssociateAlliance("");
    setDescription("");
  };

  useEffect(() => {
    if (certificationCourse) {
      setTrainingProgram(certificationCourse.training_program);
      setOrganisation(certificationCourse.organisation);
      setIsOnline(certificationCourse.is_online);
      setStartDate(DateParser.parseFromYYYYMMDD(certificationCourse.start_date));
      setEndDate(DateParser.parseFromYYYYMMDD(certificationCourse.end_date));
      setAssociateAlliance(certificationCourse.associate_alliance);
      setDescription(description);
    }
  }, []);

  const validateCertificationCourseForm = () => {
    const errors = {};

    if (trainingProgram.trim() === "") {
      errors.trainingProgram = "Invalid training program";
    }

    if (organisation.trim() === "") {
      errors.organisation = "Invalid organisation";
    }

    if (startDate === null) {
      errors.startDate = "Invalid start date";
    }

    if (endDate === null) {
      errors.endDate = "Invalid end date";
    }

    return errors;
  };

  const onAdd = () => {
    const errors = validateCertificationCourseForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      onSave({
        training_program: trainingProgram,
        organisation,
        is_online: isOnline,
        start_date: isString(startDate)
          ? startDate
          : isDate(startDate)
          ? DateParser.formatDateToYYYYMMDD(startDate)
          : null,
        end_date: isString(endDate)
          ? endDate
          : isDate(endDate)
          ? DateParser.formatDateToYYYYMMDD(endDate)
          : null,
        associate_alliance: associateAlliance,
        description
      });
      clearForm();
      scroller.scrollTo("Courses & Certifications");
    }
  };

  return (
    <Stack gap={4} className="px-4 p-4" style={{ border: `1px ${COLORS.GREY2} dashed` }}>
      <div className="d-flex justify-content-between">
        <h6>{isUndefined(certificationCourse) ? "ADD" : "UPDATE"} COURSES & CERTIFICATIONS</h6>
        <CloseButton onClick={onClose} />
      </div>
      <Form.Group>
        <Form.Label>Training Program*</Form.Label>
        <Form.Control
          value={trainingProgram}
          onChange={(e) => setTrainingProgram(e.target.value)}
          required
        />
        {errors && errors.trainingProgram && <FormError>{errors.trainingProgram}</FormError>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Organisation*</Form.Label>
        <Form.Control
          value={organisation}
          onChange={(e) => setOrganisation(e.target.value)}
          required
        />
        {errors && errors.organisation && <FormError>{errors.organisation}</FormError>}
        <CheckBox
          containerStyle={{ marginTop: "0.5rem" }}
          isChecked={isOnline}
          setChecked={setIsOnline}
          label="Online"
          useReactHook={false}
        />
      </Form.Group>
      <StartEndDateInput
        showStartDate
        showEndDate
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        errors={errors}
      />
      <Form.Group>
        <Form.Label>Associate/Alliance</Form.Label>
        <Form.Control
          value={associateAlliance}
          onChange={(e) => setAssociateAlliance(e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          value={description}
          as="textarea"
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>
      <Button className="w-25" type="button" onClick={onAdd}>
        {isUndefined(certificationCourse) ? "ADD" : "UPDATE"}
      </Button>
    </Stack>
  );
}

export function SavedCertificationCourses({ certificationCourse, onEdit, onDelete }) {
  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between">
        <h6 className="mb-0">{certificationCourse.training_program}</h6>
        <EditDeleteIcons onDelete={onDelete} onEdit={onEdit} />
      </div>
      <p className="mb-0">
        {certificationCourse.organisation} ({certificationCourse.is_online ? "Online" : "Offline"})
      </p>
      <DateRangeFormatter
        startDate={certificationCourse.start_date}
        endDate={certificationCourse.end_date}
        dateFormat="MM/YYYY"
      />
      {certificationCourse.associateAlliance && (
        <p className="mb-0">{certificationCourse.associate_alliance}</p>
      )}
      {certificationCourse.description && (
        <Form.Text muted>{certificationCourse.description}</Form.Text>
      )}
    </div>
  );
}

function SavedCertificationCoursesSection({ certificationCourse, index, onUpdate, onDelete }) {
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const update = (updatedCertificationCourse) => {
    onUpdate(updatedCertificationCourse, index);
    setEdit(false);
  };

  const onClose = () => setEdit(false);

  const handleDelete = () => {
    onDelete(index);
    setDel(false);
  };

  return edit === true ? (
    <div className="mb-4">
      <AddCertificationCourseForm
        onSave={update}
        onClose={onClose}
        certificationCourse={certificationCourse}
      />
    </div>
  ) : (
    <>
      <SavedCertificationCourses
        certificationCourse={certificationCourse}
        onDelete={() => setDel(true)}
        onEdit={() => setEdit(true)}
      />
      <ConfirmDeletionModal
        show={del}
        onHide={() => setDel(false)}
        type="course & certification"
        onCancel={() => setDel(false)}
        onConfirm={handleDelete}
        hideSubTitle
      />
    </>
  );
}

export default function CertificationCourseSection() {
  const { control } = useFormContext();
  const { appendCourse, courses, updateCourse, removeCourse } = useResumeFields({ control });
  const [showForm, setShowForm] = useState(false);

  const onSave = (newCertificationCourse) => {
    appendCourse(newCertificationCourse);
    setShowForm(false);
  };

  const onClose = () => setShowForm(false);

  const onUpdate = (course, index) => updateCourse(index, course);

  const onDelete = (index) => removeCourse(index);

  return (
    <ResumeFormContainer>
      {courses.map((course, index) => (
        <SavedCertificationCoursesSection
          key={course.id}
          certificationCourse={course}
          index={index}
          onClose={onClose}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ))}

      {showForm === true ? (
        <AddCertificationCourseForm onSave={onSave} onClose={onClose} />
      ) : (
        <Link
          className="text-decoration-underline ms-2 fw-bold d-flex justify-content-end mt-3"
          onClick={() => setShowForm(true)}
        >
          ADD MORE
        </Link>
      )}
    </ResumeFormContainer>
  );
}

import Dropdown from "components/Dropdown";
import { STATES } from "constants/states";
import { useSearchParams } from "hooks/searchParams";
import React from "react";

export default function StateFilter() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "state";

  const value = getKey(KEY);

  return (
    <Dropdown
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
      value={value}
      options={STATES}
      label="State"
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

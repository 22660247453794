import React, { useEffect, useState } from "react";
import { Button, CloseButton, Form, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isUndefined } from "lodash";
import { useFormContext } from "react-hook-form";
import COLORS from "constants/colors";
import EditDeleteIcons from "components/Icons/EditDeleteIcons";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";
import { useResumeFields } from "hooks";
import ResumeFormContainer from "components/Containers/ResumeForm";
import FormError from "components/FormError";
import { scroller } from "react-scroll";

function AddAwardsAchievementsForm({ onSave, onClose, awardsAchievement }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  const clearForm = () => {
    setTitle("");
    setDescription("");
  };
  useEffect(() => {
    if (awardsAchievement) {
      setTitle(awardsAchievement.title);
      setDescription(awardsAchievement.description);
    }
  }, []);

  const validateAwardsAchievementsForm = () => {
    const errors = {};

    if (title.trim() === "") {
      errors.title = "Invalid title";
    }

    if (description.trim() === "") {
      errors.description = "Invalid description";
    }
    return errors;
  };

  const onAdd = () => {
    const errors = validateAwardsAchievementsForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      onSave({
        title,
        description
      });
      clearForm();
      scroller.scrollTo("Awards & Achievements");
    }
  };

  return (
    <Stack gap={4} className="px-4 p-4" style={{ border: `1px ${COLORS.GREY2} dashed` }}>
      <div className="d-flex justify-content-between">
        <h6>{isUndefined(awardsAchievement) ? "ADD" : "UPDATE"} AWARDS & ACHIEVEMENTS</h6>
        <CloseButton onClick={onClose} />
      </div>
      {[
        {
          label: "Title*",
          name: "title",
          value: title,
          controlProps: {
            type: "text",
            onChange: (e) => setTitle(e.target.value),
            required: true
          },
          error: errors?.title
        },
        {
          label: "Description*",
          name: "description",
          value: description,
          controlProps: {
            as: "textarea",
            onChange: (e) => setDescription(e.target.value),
            required: true
          },
          error: errors?.description
        }
      ].map((field, index) => (
        <Form.Group key={index}>
          <Form.Label>{field.label}</Form.Label>
          <Form.Control {...field.controlProps} value={field.value} />
          {field.error && <FormError>{field.error}</FormError>}
        </Form.Group>
      ))}
      <Button className="w-25" type="button" onClick={onAdd}>
        {isUndefined(awardsAchievement) ? "ADD" : "UPDATE"}
      </Button>
    </Stack>
  );
}

export function SavedAwardsAchievement({ awardsAchievement, onDelete, onEdit }) {
  return (
    <Stack gap={0} className="mb-3">
      <div className="d-flex justify-content-between">
        <h6 className="mb-0">{awardsAchievement.title}</h6>
        <EditDeleteIcons onDelete={onDelete} onEdit={onEdit} />
      </div>
      <Form.Text muted>{awardsAchievement.description}</Form.Text>
    </Stack>
  );
}

function SavedAwardsAchievementsSection({ awardsAchievement, index, onUpdate, onDelete }) {
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const update = (updatedCertificationCourse) => {
    setEdit(false);
    onUpdate(updatedCertificationCourse, index);
  };

  const onClose = () => {
    setEdit(false);
  };

  const handleDelete = () => {
    onDelete(index);
    setDel(false);
  };

  return edit === true ? (
    <AddAwardsAchievementsForm
      onSave={update}
      onClose={onClose}
      awardsAchievement={awardsAchievement}
    />
  ) : (
    <>
      <SavedAwardsAchievement
        awardsAchievement={awardsAchievement}
        onDelete={() => setDel(true)}
        onEdit={() => setEdit(true)}
      />
      <ConfirmDeletionModal
        show={del}
        onHide={() => setDel(false)}
        type="awards & achievements"
        onCancel={() => setDel(false)}
        onConfirm={handleDelete}
        hideSubTitle
      />
    </>
  );
}

export default function AwardsAchievementsSection() {
  const [showForm, setShowForm] = useState(false);
  const { control } = useFormContext();
  const {
    awardsAchievements,
    appendAwardsAchievement,
    removeAwardsAchievement,
    updateAwardsAchievements
  } = useResumeFields({ control });

  const onSave = (newAwardsAchievements) => {
    appendAwardsAchievement(newAwardsAchievements);
    setShowForm(false);
  };

  const onClose = () => setShowForm(false);

  const onUpdate = (newAwardsAchievements, index) =>
    updateAwardsAchievements(index, newAwardsAchievements);
  const onDelete = (index) => removeAwardsAchievement(index);

  return (
    <ResumeFormContainer>
      {awardsAchievements.map((award, index) => (
        <SavedAwardsAchievementsSection
          key={award.id}
          awardsAchievement={award}
          index={index}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ))}
      {showForm === true ? (
        <AddAwardsAchievementsForm onSave={onSave} onClose={onClose} />
      ) : (
        <Link
          className="text-decoration-underline ms-2 fw-bold d-flex justify-content-end mt-3"
          onClick={() => setShowForm(true)}
        >
          ADD MORE
        </Link>
      )}
    </ResumeFormContainer>
  );
}

import React, { forwardRef, memo } from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import COLORS from "constants/colors";

function Table({ data, columns, ...props }, ref) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <BootstrapTable striped="columns" ref={ref} {...props}>
      <thead>
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={`header-${index}-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="text-center text-nowrap px-4 text-capitalize"
                style={{ fontWeight: "600", color: COLORS.GREY2 }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext()).replace(
                      /_/g,
                      " "
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={`row-${index}-${row.id}`}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="text-center">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </BootstrapTable>
  );
}

export default memo(forwardRef(Table));

import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import ACTION_TYPES from "../../constants/action-types";
import { isArray, isEmpty, isFunction } from "lodash";
import { Image, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import COLORS from "../../constants/colors";
import { RxCross1 } from "react-icons/rx";
import ContentFactory from "../../apiClients/ContentFactory";
import { useNavigate } from "react-router-dom";

function PinnedBlogs({ pinnedBlogs, getPinnedBlogs, onSuccess }) {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      getPinnedBlogs();
    }
  }, [isLoggedIn]);

  return (
    isArray(pinnedBlogs) &&
    !isEmpty(pinnedBlogs) && (
      <div
        className="position-fixed py-4 px-2"
        style={{
          top: "25%",
          left: 0,
          borderRadius: "0.25rem",
          background: "rgba(31, 31, 31, 0.70)"
        }}
      >
        <p className="fw-bold mb-0" style={{ color: COLORS.PRIMARY_ORANGE }}>
          Pinned
        </p>
        {pinnedBlogs.map((el) => (
          <div key={el.blog.id}>
            <hr style={{ borderWidth: "0.25rem" }} className="text-white" />
            <Stack gap={2}>
              <RxCross1
                className="d-block mx-auto cursor-pointer"
                color="white"
                size={25}
                onClick={() =>
                  ContentFactory.createRequest("blog")
                    .deletePin(el.blog.id)
                    .then(getPinnedBlogs)
                    .then(() => isFunction(onSuccess) && onSuccess())
                }
              />
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip">{el.blog.title}</Tooltip>}
              >
                <Image
                  src={el.blog.thumbnail}
                  style={{ width: "2.75rem", height: "2.75rem", borderRadius: "0.5rem" }}
                  className="mx-auto d-block cursor-pointer"
                  onClick={() => navigate(`/blogs/${el.blog.slug}`)}
                />
              </OverlayTrigger>
            </Stack>
          </div>
        ))}
      </div>
    )
  );
}

const mapStateToProps = (state) => ({ pinnedBlogs: state.contentReducer.blogs.pinnedBlogs });

const mapDispatchToProps = (dispatch) => ({
  getPinnedBlogs: () => dispatch({ type: ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_REQUEST })
});

export default connect(mapStateToProps, mapDispatchToProps)(PinnedBlogs);

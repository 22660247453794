import React from "react";
import BaseContainer from "../BaseContainer";
import LoadingContainer from "../Loading";
import { FormProvider } from "react-hook-form";
import { isMobile } from "react-device-detect";

export default function ProfileDetailsContainer({
  children,
  loading,
  title,
  methods,
  fullWidth = false
}) {
  return (
    <BaseContainer className={`py-4 w-100 ps-${isMobile ? 2 : 4}`}>
      <LoadingContainer loading={loading}>
        <div className={fullWidth ? "w-100" : `w-${isMobile ? "100" : "50"}`}>
          {title && (
            <div className="mx-auto w-100">
              <h4 className="bordered-heading text-capitalize">{title}</h4>
            </div>
          )}
          <FormProvider {...methods}>{children}</FormProvider>
        </div>
      </LoadingContainer>
    </BaseContainer>
  );
}

import React, { createContext, useContext, useState } from "react";

const BookmarkModalContext = createContext();

export function useBookmarkModal() {
  return useContext(BookmarkModalContext);
}

export function BookmarkModalProvider({ children }) {
  const [show, setShow] = useState(undefined);

  const showBookmarkNotLoggedInModal = () => setShow(true);

  const hideBookmarkNotLoggedInModal = () => setShow(undefined);

  const contextValue = {
    show,
    showBookmarkNotLoggedInModal,
    hideBookmarkNotLoggedInModal
  };

  return (
    <BookmarkModalContext.Provider value={contextValue}>{children}</BookmarkModalContext.Provider>
  );
}

import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

export default function EllipsisText({ children, numOfLines, style = {}, ...props }) {
  return (
    <LinesEllipsis
      text={children}
      maxLine={numOfLines}
      ellipsis="..."
      trimRight
      style={style}
      {...props}
    />
  );
}

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import BaseModal from "../Base";

export default function AddContent({ show, onHide, onSuccess, type, Form, size, editObj }) {
  const methods = useForm({ reValidateMode: "onChange" });

  useEffect(() => {
    if (!show) {
      methods.reset();
    }
    return () => {
      methods.reset();
    };
  }, [show]);

  return (
    <BaseModal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title className="text-capitalize px-2">
          {editObj ? "Edit" : "Add"}{" "}
          {type === "arvr" ? "AR/VR" : type === "thesis" ? "Thesis & Dissertation" : type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-${isMobile ? 2 : 4}`}>
        <FormProvider {...methods}>
          <Form onSuccess={onSuccess} editObj={editObj} />
        </FormProvider>
      </Modal.Body>
    </BaseModal>
  );
}

import React, { useEffect, useState } from "react";
import ContentFactory from "../../../../apiClients/ContentFactory";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CheckBox from "../../../../components/Inputs/CheckBox";
import { AiOutlineRight } from "react-icons/ai";
import { isArray, isEmpty, isFunction, isObject, isUndefined } from "lodash";
import { Accordion, Button, Tab, Tabs } from "react-bootstrap";
import COLORS from "../../../../constants/colors";
import { renderErrorToast, showPromiseToast } from "../../../../utils";
import LockedContent from "../../../../components/Containers/LockedContent";
import { ReactSVG } from "react-svg";
import { DELETE_ICON } from "../../../../constants/icons";
import Search from "../../../../components/Inputs/Search";
import { useSearchParams } from "../../../../hooks/searchParams";
import ContentHeader from "components/Base/ContentHeader";
import { isMobile } from "react-device-detect";
import BackButton from "components/Buttons/Back";
import "./styles.scss";
import ProfileDetailsContainer from "components/Containers/ProfileDetails";

export const getAncestorsFromLeafCategory = (category, categories) => {
  var newCategories = [...categories];
  var retval = {};
  for (var i = 0; i < newCategories.length; i++) {
    const { children } = newCategories[i];
    if (children && !isEmpty(children)) {
      for (var j = 0; j < children.length; j++) {
        const subSubChildren = children[j].children;
        if (subSubChildren && !isEmpty(subSubChildren)) {
          for (var k = 0; k < subSubChildren.length; k++) {
            if (category.id === subSubChildren[k].id) {
              retval["parent"] = newCategories[i];
              retval["subCategory"] = children[j];
            }
          }
        } else {
          if (category.id === children[j].id) {
            retval["parent"] = newCategories[i];
          }
        }
      }
    } else {
      console.debug("No Sub chilren");
    }
  }
  return retval;
};

function RenderCategoriesTree({
  selectedCategories,
  categories,
  checkLevel0,
  checkLevel1,
  checkLevel2,
  activeLevel,
  setActiveLevel,
  selectedCategory,
  setSelectedCategory,
  setSelectedSubCategory,
  selectedSubCategory
}) {
  // useEffect(() => {
  //   if (isEmpty(categories)) {
  //     setSelectedCategory(undefined);
  //     setSelectedSubCategory(undefined);
  //   } else {
  //     if (categoriesPresent) {
  //       setSelectedCategory(categories[0]);
  //       if (isArray(categories[0].children)) {
  //         setSelectedSubCategory(categories[0].children[0]);
  //       }
  //     }
  //   }
  // }, [categories]);

  const selectedStyle = {
    fontWeight: "bold"
  };

  const listGroupStyle = {
    minHeight: "30rem",
    maxHeight: "30rem",
    overflow: "auto",
    border: "1px solid #cdcdcd"
  };

  const renderCategory = (category, onClick, onCheck) => {
    const isChecked = selectedCategories.find((el) => {
      const { id } = category;
      if (el.id === id) {
        console.debug("Found category id: ", el.id === id);
        return el;
      }
      var { children } = el;
      children = children || [];
      const element = (children || []).find((el) => el.id === id);
      if (element) {
        return element;
      }
      for (var i = 0; i < children.length; i++) {
        if (el.children[i]) {
          const subChildren = el.children[i].children;
          if (isArray(subChildren)) {
            const subElement = subChildren.find((el) => el.id === id);
            if (subElement) {
              return subElement;
            }
          }
        }
      }
    });
    return (
      <ListGroupItem
        className={`border-0 d-flex align-items-center justify-content-between ${
          isFunction(onClick) ? "cursor-pointer" : ""
        }`}
        key={category.id}
        style={{
          ...{ background: "transparent" },
          ...((selectedCategory && selectedCategory.id) === category.id ||
          (selectedSubCategory && selectedSubCategory.id) === category.id
            ? selectedStyle
            : {})
        }}
        onClick={() => isFunction(onClick) && onClick(category)}
      >
        <div className="d-flex align-items-center">
          <CheckBox
            useReactHook={false}
            setChecked={() => onCheck(category)}
            isChecked={!isUndefined(isChecked)}
          />
          <p className="mb-0 ms-2">{category.title}</p>
        </div>
        <div>
          <AiOutlineRight />
        </div>
      </ListGroupItem>
    );
  };

  const level1Select = (category) => {
    console.log("Evexuting1");
    setActiveLevel(2);
    setSelectedCategory(category);
    setSelectedSubCategory(category.children[0]);
  };

  const level2Select = (category) => {
    console.log("Evexuting2");

    setActiveLevel(3);
    setSelectedSubCategory(category);
  };

  return (
    <Row className="rounded" xs={1} sm={3}>
      {activeLevel !== 1 && isMobile && (
        <div className="mb-3">
          <BackButton
            onBack={() => {
              setActiveLevel(activeLevel - 1);
            }}
          >
            {activeLevel === 2 ? "Sub-Categories" : "Sub-Sub-Categories"}
          </BackButton>
        </div>
      )}
      {((isMobile && activeLevel === 1) || !isMobile) && (
        <Col className="px-0">
          <ListGroup
            style={{
              ...listGroupStyle,
              ...{ borderRadius: "0.5rem 0 0 0.5rem", backgroundColor: "#f2f2f2" }
            }}
          >
            {categories.map((category) => renderCategory(category, level1Select, checkLevel0))}
          </ListGroup>
        </Col>
      )}
      <>
        {((isMobile && activeLevel === 2) || !isMobile) && (
          <Col className="px-0 border-right">
            <ListGroup style={{ ...listGroupStyle, ...{ borderRadius: "0" } }}>
              {isObject(selectedCategory) &&
                selectedCategory.children.map((category) =>
                  renderCategory(category, level2Select, checkLevel1)
                )}
            </ListGroup>
          </Col>
        )}

        {((isMobile && activeLevel === 3) || !isMobile) && (
          <Col className="px-0">
            <ListGroup style={{ ...listGroupStyle, ...{ borderRadius: "0 0.5rem 0.5rem 0" } }}>
              {isObject(selectedSubCategory) &&
                selectedSubCategory.children.map((category) =>
                  renderCategory(category, undefined, checkLevel2)
                )}
            </ListGroup>
          </Col>
        )}
      </>
    </Row>
  );
}

export function UserSelectedCategories({
  checkLevel0,
  checkLevel1,
  checkLevel2,
  level2Select,
  selectedCategories,
  selectParentCategoryFromLevel2
}) {
  const selectParent = (level2) => selectParentCategoryFromLevel2(level2);

  return (
    <Accordion defaultActiveKey="0-0">
      {selectedCategories.map((el, index) =>
        isArray(el.children) && !isEmpty(el.children) ? (
          <Accordion.Item
            eventKey={`${el.level}-${index}-${el.title}`}
            key={`${el.level}-${index}-${el.title}`}
          >
            <Accordion.Header>
              <div className="d-flex align-items-center w-100 justify-content-between me-3">
                <p className="mb-0">{el.title}</p>
                {isFunction(checkLevel0) && (
                  <ReactSVG
                    src={DELETE_ICON}
                    style={{ width: "2rem" }}
                    onClick={() => checkLevel0(el)}
                  />
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <Accordion defaultActiveKey="1-0">
                {el.children.map((level1, level1Index) =>
                  isArray(level1.children) && !isEmpty(level1.children) ? (
                    <Accordion.Item
                      className="border-0"
                      key={`${level1.level}-${level1Index}-${level1.title}`}
                      eventKey={`${level1.level}-${level1Index}-${level1.title}`}
                    >
                      <Accordion.Header>
                        <div className="d-flex align-items-center w-100 justify-content-between me-3">
                          <p className="mb-0">{level1.title}</p>
                          {isFunction(checkLevel1) && (
                            <ReactSVG
                              src={DELETE_ICON}
                              style={{ width: "2rem" }}
                              onClick={() => checkLevel1(level1)}
                            />
                          )}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ListGroup className="border-0">
                          {level1.children.map((level2, level2Index) => (
                            <ListGroupItem
                              className="border-0 d-flex align-items-center py-3"
                              key={`${level2.level}-${level2Index}-${level2.title}`}
                            >
                              <div className="d-flex align-items-center w-100 justify-content-between me-3">
                                <p className="mb-0">{level2.title}</p>
                                {isFunction(checkLevel2) && (
                                  <ReactSVG
                                    src={DELETE_ICON}
                                    style={{ width: "2rem" }}
                                    onClick={() => checkLevel2(level2)}
                                  />
                                )}
                                {isFunction(level2Select) && (
                                  <p
                                    className="mb-0 fw-bold text-decoration-underline cursor-pointer"
                                    style={{ color: COLORS.PRIMARY_ORANGE }}
                                    onClick={() =>
                                      isFunction(selectParentCategoryFromLevel2)
                                        ? selectParent(level2)
                                        : level2Select(level2)
                                    }
                                  >
                                    SELECT
                                  </p>
                                )}
                              </div>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    <ListGroup
                      key={`${level1.level}-${index}-${level1.title}`}
                      className="border-0"
                    >
                      <ListGroupItem className="d-flex align-items-center py-3 border-0 border-top rounded-0 rounded-bottom">
                        <div className="d-flex align-items-center w-100 justify-content-between me-3">
                          <p className="mb-0">{level1.title}</p>
                          {isFunction(checkLevel2) && (
                            <ReactSVG
                              src={DELETE_ICON}
                              style={{ width: "2rem" }}
                              onClick={() => checkLevel2(level1)}
                            />
                          )}
                          {isFunction(level2Select) && (
                            <p
                              className="mb-0 fw-bold text-decoration-underline cursor-pointer"
                              style={{ color: COLORS.PRIMARY_ORANGE }}
                              onClick={() =>
                                isFunction(selectParentCategoryFromLevel2)
                                  ? selectParent(level1)
                                  : level2Select(level1)
                              }
                            >
                              SELECT
                            </p>
                          )}
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  )
                )}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <ListGroup key={`${el.level}-${index}-${el.title}`} className="border-0">
            <ListGroupItem
              className={`${
                selectedCategories.length === 0
                  ? ""
                  : index === 0
                  ? "rounded-0 rounded-top border-bottom-0"
                  : index === selectedCategories.length - 1
                  ? "rounded-0 rounded-bottom border-top-0"
                  : "rounded-0 border-0"
              } d-flex align-items-center py-3`}
            >
              <div className="d-flex align-items-center w-100 justify-content-between me-3">
                <p className="mb-0">{el.title}</p>
                {isFunction(checkLevel2) && (
                  <ReactSVG
                    src={DELETE_ICON}
                    style={{ width: "2rem" }}
                    onClick={() => checkLevel2(el)}
                  />
                )}
                {isFunction(level2Select) && (
                  <p
                    className="mb-0 fw-bold text-decoration-underline cursor-pointer"
                    style={{ color: COLORS.PRIMARY_ORANGE }}
                    onClick={() =>
                      isFunction(selectParentCategoryFromLevel2)
                        ? selectParent(el)
                        : level2Select(el)
                    }
                  >
                    SELECT
                  </p>
                )}
              </div>
            </ListGroupItem>
          </ListGroup>
        )
      )}
    </Accordion>
  );
}

export default function Categories() {
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [saving, setSaving] = useState(false);
  const [search, setSearch] = useState("");

  const client = ContentFactory.createRequest("user-categories");
  const { addKey, searchParamsAsObject, queryString } = useSearchParams();

  const getUserCategories = () => client.list().then(setSelectedCategories);
  const getCategories = () =>
    ContentFactory.createRequest("categories")
      .list({ ...searchParamsAsObject })
      .then(setCategories);

  useEffect(() => {
    Promise.all([getCategories(), getUserCategories()]).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    addKey("search", search);
  }, [search]);

  useEffect(() => {
    getCategories();
  }, [queryString]);

  const level0CategoryExists = (id) => {
    return selectedCategories.findIndex((el) => el.id === id) !== -1;
  };

  const getParentFromSubCategory = (category) => {
    var newCategories = [...categories];
    for (var i = 0; i < newCategories.length; i++) {
      const { children } = newCategories[i];
      for (var j = 0; j < children.length; j++) {
        if (category.id === children[j].id) {
          return newCategories[i];
        }
      }
    }
  };

  const checkLevel0 = (category) => {
    const index = level0CategoryExists(category.id);
    var newSelectedCategories = [...selectedCategories];
    if (!index) {
      setSelectedCategories([...newSelectedCategories, category]);
    } else {
      const index = selectedCategories.findIndex((el) => el.id === category.id);
      newSelectedCategories.splice(index, 1);
      setSelectedCategories(newSelectedCategories);
    }
  };

  const checkLevel1 = (category) => {
    var newSelectedCategories = [...selectedCategories];
    console.debug("Category: ", category);

    const index = newSelectedCategories.findIndex((el) => {
      const { children } = el;
      console.debug("Children: ", children);
      for (var i = 0; i < children.length; i++) {
        console.debug(category.id === children[i].id);
        if (category.id === children[i].id) {
          return true;
        }
      }
    });

    console.debug("index: ", index);
    var parent = getParentFromSubCategory(category);

    if (index === -1) {
      const index = level0CategoryExists(parent.id);
      if (index) {
        const index = newSelectedCategories.findIndex((el) => el.id === parent.id);
        newSelectedCategories[index].children.push(category);
      } else {
        newSelectedCategories.push({ ...parent, children: [category] });
      }
    } else {
      console.debug("Found category");
      const parentIndex = newSelectedCategories.findIndex((el) => el.id === parent.id);
      const { children } = newSelectedCategories[parentIndex];
      if (children.length === 1) {
        console.debug("Should remove parent");

        newSelectedCategories.splice(parentIndex, 1);
      } else {
        console.debug("Should not remove parent");
        var newChildren = [...children];

        const indexToDelete = newChildren.findIndex((el) => el.id === category.id);
        newChildren.splice(indexToDelete, 1);
        newSelectedCategories[parentIndex] = {
          ...newSelectedCategories[parentIndex],
          children: newChildren
        };
      }
    }
    console.debug("New selected categories here: ", newSelectedCategories);
    setSelectedCategories(newSelectedCategories);
  };

  const checkLevel2 = (category) => {
    const { parent, subCategory } = getAncestorsFromLeafCategory(category, categories);
    console.log("Parent: ", parent, subCategory);
    var parentFound = -1;
    var subCategoryFound = -1;
    // Iterate over parent
    const newSelectedCategories = [...selectedCategories];
    console.debug("New selected categories: ", newSelectedCategories);
    for (var i = 0; i < newSelectedCategories.length; i++) {
      if (newSelectedCategories[i].id === parent.id) {
        parentFound = i;
      }
      const { children } = newSelectedCategories[i];
      // Iterate over children of parent (sub-category)
      for (var j = 0; j < children.length; j++) {
        console.log("Children: ", children[j]);
        if (children[j] && children[j].id === subCategory.id) {
          subCategoryFound = j;
        }
        console.log("childrem: ", children);
        const subSubChildren = children[j].children;
        if (isArray(subSubChildren)) {
          // Iterate over children of sub-category (sub-sub-category)
          for (var k = 0; k < subSubChildren.length; k++) {
            if (subSubChildren[k].id === category.id) {
              if (subSubChildren.length === 1) {
                if (children.length === 1) {
                  console.log("Remove parent category");
                  newSelectedCategories.splice(i, 1);
                } else {
                  console.log("Remove from sub category");
                  children.splice(j, 1);
                }
              } else {
                console.log("Remove leaf node");
                subSubChildren.splice(k, 1);
              }
              console.log("New Selected : ", newSelectedCategories);
              setSelectedCategories(newSelectedCategories);
              return;
            }
          }
        }
      }
    }
    console.log("Parent found: ", parentFound);
    console.log("Sub category found: ", subCategoryFound);
    if (parentFound !== -1 && subCategoryFound !== -1) {
      if (
        Object.keys(newSelectedCategories[parentFound].children[subCategoryFound]).includes(
          "children"
        )
      ) {
        newSelectedCategories[parentFound].children[subCategoryFound].children.push(category);
      } else {
        newSelectedCategories[parentFound].children[subCategoryFound].children = [category];
      }
    } else if (parentFound === -1) {
      newSelectedCategories.push({
        ...parent,
        children: [{ ...subCategory, children: [category] }]
      });
    } else if (subCategoryFound === -1) {
      newSelectedCategories[parentFound].children = [
        ...newSelectedCategories[parentFound].children,
        { ...subCategory, children: [category] }
      ];
    }

    console.log("New selected categorues: ", newSelectedCategories);
    setSelectedCategories(newSelectedCategories);
  };

  console.log("Selected categories: ", selectedCategories);

  const save = () => {
    if (saving === false) {
      setSaving(true);
    }
    showPromiseToast(
      client.create(selectedCategories, false).then(() => setSaving(false)),
      {
        pending: "Saving categories",
        success: "Categories saved successfully",
        error: renderErrorToast()
      }
    );
  };

  const categoriesPresent = isArray(categories) && !isEmpty(categories);
  const [selectedCategory, setSelectedCategory] = useState(
    categoriesPresent ? categories[0] : undefined
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    categoriesPresent ? categories[0].children[0] : undefined
  );
  const [activeLevel, setActiveLevel] = useState(1);
  console.log("active level1: ", activeLevel);

  function SelectCategories() {
    return (
      <>
        <RenderCategoriesTree
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          categories={categories}
          checkLevel0={checkLevel0}
          checkLevel1={checkLevel1}
          checkLevel2={checkLevel2}
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          activeLevel={activeLevel}
          setActiveLevel={setActiveLevel}
          setSelectedCategory={setSelectedCategory}
          setSelectedSubCategory={setSelectedSubCategory}
        />
        <p className="mt-2">
          Didn’t find your category here? Please{" "}
          <span className="fw-bold cursor-pointer" style={{ color: COLORS.PRIMARY_ORANGE }}>
            Contact Us.
          </span>
        </p>
        <Button className="px-5" disabled={saving} onClick={save}>
          SAVE
        </Button>
      </>
    );
  }

  function SavedCategories() {
    return (
      <>
        <h6>Your Selected Categories</h6>
        <p>All categories that you have chosen will be shown here. Click delete icon to remove.</p>
        <UserSelectedCategories
          selectedCategories={selectedCategories}
          checkLevel0={checkLevel0}
          checkLevel1={checkLevel1}
          checkLevel2={checkLevel2}
        />
      </>
    );
  }
  return (
    <ProfileDetailsContainer loading={loading} fullWidth="100%">
      <ContentHeader
        title="Categories"
        subTitle="Please input the requested details below. Click submit once you are done."
      />
      <LockedContent>
        <div className={`w-${isMobile ? 100 : 50} mb-4`}>
          <Search searchString={search} setSearchString={setSearch} />
        </div>
        <Row xs={1}>
          <Col xs={isMobile ? null : 7}>
            {isMobile ? (
              <Tabs className="border-bottom-0 justify-content-between py-2 categories-tab">
                <Tab eventKey="select-categories" title="Categories" className="py-2">
                  <div className="px-2">
                    <SelectCategories />
                  </div>
                </Tab>
                <Tab eventKey="saved-categories" title="Saved Categories" className="py-4">
                  <div className="px-2">
                    <SavedCategories />
                  </div>
                </Tab>
              </Tabs>
            ) : (
              <SelectCategories />
            )}
          </Col>
          {!isMobile && (
            <Col xs={isMobile ? null : 5}>
              <SavedCategories />
            </Col>
          )}
        </Row>
      </LockedContent>
    </ProfileDetailsContainer>
  );
}

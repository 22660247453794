import React, { useState } from "react";
import { Accordion, Card, Form } from "react-bootstrap";
import { BsPlus, BsDash } from "react-icons/bs";

function CustomToggle({ title, subTitle, icon, isOpen, onToggle, titleStyle }) {
  const handleClick = () => {
    onToggle();
  };

  return (
    <Card.Header
      className="bg-white border-bottom-0 px-2 cursor-pointer"
      style={{ borderRadius: 0 }}
      onClick={handleClick}
    >
      <div className="py-3 d-flex justify-content-between align-items-center cursor-pointer">
        <div className="d-flex align-items-center">
          <div>
            <div>
              {icon}
              <Form.Label className="fw-bold m-0 ms-3" style={titleStyle || {}}>
                {title}
              </Form.Label>
            </div>
            <Form.Text muted style={{ fontSize: "70%" }}>
              {subTitle}
            </Form.Text>
          </div>
        </div>
        {isOpen ? <BsDash size={25} /> : <BsPlus size={25} />}
      </div>
    </Card.Header>
  );
}

export default function ResumeAccordion({
  children,
  title,
  subTitle,
  icon,
  cardClass,
  eventKey,
  titleStyle,
  openByDefault = true
}) {
  const [isOpen, setIsOpen] = useState(openByDefault);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Accordion
      activeKey={isOpen ? eventKey : undefined}
      onSelect={() => {}} // This is important to prevent automatic toggling
    >
      <Card
        className={`border-start-0 border-end-0 border-top-0 pb-3${
          cardClass ? ` ${cardClass}` : ""
        }`}
        style={{ borderRadius: 0 }}
      >
        <CustomToggle
          title={title}
          subTitle={subTitle}
          icon={icon}
          isOpen={isOpen}
          onToggle={handleToggle}
          titleStyle={titleStyle}
        />
        <Accordion.Collapse eventKey={eventKey}>{children}</Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

import { useFieldArray } from "react-hook-form";

export function useResumeFields({ control }) {
  const {
    fields: experience,
    append: appendExperience,
    remove: removeExperience,
    update: updateExperience
  } = useFieldArray({
    control,
    name: "employment",
    defaultValues: []
  });
  const {
    fields: education,
    append: appendEducation,
    remove: removeEducation,
    update: updateEducation
  } = useFieldArray({
    control,
    name: "education",
    defaultValues: []
  });
  const {
    fields: courses,
    append: appendCourse,
    remove: removeCourse,
    update: updateCourse
  } = useFieldArray({
    control,
    name: "courses",
    defaultValues: []
  });
  const {
    fields: awardsAchievements,
    append: appendAwardsAchievement,
    remove: removeAwardsAchievement,
    update: updateAwardsAchievements
  } = useFieldArray({
    control,
    name: "awards",
    defaultValues: []
  });

  return {
    experience,
    education,
    appendExperience,
    removeExperience,
    appendEducation,
    removeEducation,
    courses,
    appendCourse,
    removeCourse,
    awardsAchievements,
    appendAwardsAchievement,
    removeAwardsAchievement,
    updateAwardsAchievements,
    updateCourse,
    updateEducation,
    updateExperience
  };
}

import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";
import UploadResumeForm from "../../../forms/UploadResume";

export default function UploadResumeFileModal({
  showAddResumeFile,
  setShowAddResumeFile,
  onSuccess
}) {
  const methods = useForm({ reValidateMode: "onChange" });
  useEffect(() => {
    if (!showAddResumeFile) {
      methods.reset();
    }
    return () => {
      methods.reset();
    };
  }, []);
  return (
    <BaseModal show={showAddResumeFile} onHide={() => setShowAddResumeFile(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Upload Resume File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <UploadResumeForm
            onSuccess={() => {
              onSuccess();
              setShowAddResumeFile(false);
            }}
          />
        </FormProvider>
      </Modal.Body>
    </BaseModal>
  );
}

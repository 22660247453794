const COMPANY_TYPES = [
  "Architectural Studio",
  "Interior Design Studio",
  "Lighting Design Studio",
  "Landscape Architect",
  "Furniture Design Studio",
  "Facade Design",
  "Interior Decorator",
  "Structural Consultant",
  "MEP Consultant",
  "BIM Consultant",
  "PMC Consultant"
].map((profession) => ({ value: profession, label: profession }));

const startYear = 1800;
const currentYear = new Date().getFullYear();

const YEARS = Array.from({ length: currentYear - startYear + 1 }, (_, index) => ({
  label: startYear + index,
  value: startYear + index
})).reverse();

export { COMPANY_TYPES, YEARS };

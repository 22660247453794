import PdfPreview from "../components/PdfPreview";
import EngagementActions from "../components/EngagementActions";
import { getContentAbsoluteUrl } from "../utils";
import EngagementStats from "../components/EngagementStats";
import { Button, Image, Stack, Table } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import { isArray, isEmpty, isFunction, isString } from "lodash";
import { useNavigationInfo } from "hooks";
import { Element, scroller } from "react-scroll";
import { useSelector } from "react-redux";
import ShareButton from "components/Buttons/Share";
import { isMobile } from "react-device-detect";
import ContentFactory from "apiClients/ContentFactory";

export default function ContentDetailsNavigator({
  list,
  onAction,
  type,
  doNoShowNav,
  onNext,
  onPrevious,
  startIndex = 0,
  onViewed
}) {
  const lookupField = ["catalogue"].includes(type) ? "id" : "slug";
  const [activeIndex, setActiveIndex] = useState(startIndex);
  const { isMoodBoard } = useNavigationInfo();

  const { isLoggedIn } = useSelector((state) => state.authReducer.isLoggedIn);
  useEffect(() => {
    setActiveIndex(startIndex);
  }, [startIndex]);

  useEffect(() => {
    scroller.scrollTo("detail");
  }, [activeIndex]);

  var ACTIONS = [
    {
      onClick: () => {
        setActiveIndex(activeIndex - 1);
        if (isFunction(onPrevious)) {
          onPrevious(list[activeIndex - 1][lookupField]);
        }
      },
      label: "Previous",
      disabled: activeIndex === 0
    },
    {
      component:
        type === "catalogue" ? (
          <div style={{ width: "2.5rem", height: "2.5rem" }}>
            <ShareButton />
          </div>
        ) : (
          <EngagementActions
            key="engagement-action"
            type={type}
            buttonStyle={"2"}
            obj={list[activeIndex]}
            onSuccess={onAction}
            showIdeationBoardIcon={isLoggedIn}
          />
        )
    },
    {
      onClick: () => {
        setActiveIndex(activeIndex + 1);
        if (isFunction(onNext)) {
          onNext(list[activeIndex + 1][lookupField]);
        }
      },
      label: "Next",
      disabled: activeIndex === list.length - 1
    }
  ];

  if (doNoShowNav) {
    ACTIONS.splice(0, 1);
    ACTIONS.splice(1, 1);
  }

  const componentMap = {
    image: ImageDetails,
    video: VideoDetails,
    portfolio: PortfolioDetails,
    project: ProjectDetails,
    thesis: ThesisDetails,
    arvr: ARVRDetails,
    catalogue: CatalogueDetails
  };

  var DetailComponent = componentMap[type];

  return (
    <Element name="detail">
      <div className={`bg-white px-${isMobile ? 0 : 4} py-${isMobile ? 4 : 4}`}>
        <Stack gap={4}>
          <div className="mx-auto mb-3">
            <h4 className="text-center">{list[activeIndex].title}</h4>
            <EngagementStats
              colClassName="justify-content-center"
              likes={list[activeIndex].likes}
              saves={list[activeIndex].saves}
              views={list[activeIndex].views}
            />
          </div>
        </Stack>
        <Stack
          direction="horizontal"
          className={`justify-content-${!doNoShowNav ? "between" : "center"} mb-3 mt-1`}
        >
          {ACTIONS.map(
            (el) =>
              el.component || (
                <Button
                  key={el.label}
                  style={{ width: isMobile ? "25%" : "15%" }}
                  variant="outline-dark"
                  onClick={el.onClick}
                  disabled={el.disabled}
                >
                  {el.label}
                </Button>
              )
          )}
        </Stack>
        <div>
          <DetailComponent
            obj={isMoodBoard ? list[activeIndex].obj : list[activeIndex]}
            onViewed={onViewed}
          />
        </div>
      </div>
    </Element>
  );
}

export function Description({ description, title }) {
  return (
    isString(description) &&
    !isEmpty(description) && (
      <div className="mt-4">
        <h6>{title || "Brief Description"}</h6>
        <p>{description}</p>
      </div>
    )
  );
}

function ContentImage({ src }) {
  return (
    <Image src={getContentAbsoluteUrl(src)} style={{ objectFit: "contain" }} className="w-100" />
  );
}

function ImageDetails({ obj, onViewed }) {
  const client = ContentFactory.createRequest("image");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      client.view(obj.id).then((status) => {
        if (status === 204) {
          if (isFunction(onViewed)) {
            onViewed(obj.id);
          }
        }
      });
    }
  }, [isLoggedIn]);

  return (
    <>
      <ContentImage src={obj.image} />
      <Description description={obj.description} />
    </>
  );
}

function VideoDetails({ obj, onViewed }) {
  const client = ContentFactory.createRequest("video");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      client.view(obj.id).then((status) => {
        if (status === 204) {
          if (isFunction(onViewed)) {
            onViewed(obj.id);
          }
        }
      });
    }
  }, [isLoggedIn]);
  return (
    <>
      <ReactPlayer url={getContentAbsoluteUrl(obj.video)} loop width="100%" controls />
      <Description description={obj.description} />
    </>
  );
}

function PortfolioDetails({ obj, onViewed }) {
  const client = ContentFactory.createRequest("portfolio");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      client.view(obj.id).then((status) => {
        if (status === 204) {
          if (isFunction(onViewed)) {
            onViewed(obj.id);
          }
        }
      });
    }
  }, [isLoggedIn]);
  return (
    <>
      <PdfPreview file={getContentAbsoluteUrl(obj.file)} />
      <Description description={obj.description} />
    </>
  );
}

function ProjectDetails({ obj, onViewed }) {
  const client = ContentFactory.createRequest("project");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      client.view(obj.id).then((status) => {
        if (status === 204) {
          if (isFunction(onViewed)) {
            onViewed(obj.id);
          }
        }
      });
    }
  }, [isLoggedIn]);
  return (
    <>
      <h6 className="fw-bold mt-4 mb-3">Project Info</h6>
      <div>
        <Table borderless className={`w-${isMobile ? 100 : 50}`}>
          <tbody>
            {[
              { label: "Project Type", value: obj.type },
              { label: "Start Date", value: obj.start_date },
              { label: "End Date", value: obj.end_date },
              {
                label: "Area Covered",
                value: `${obj.plot_area} ${obj.plot_area_unit}`
              },
              {
                label: "Cost Per sq. ft.",
                value: `Rs. ${obj.cost_per_square_feet}`
              }
            ].map(
              (item, index) =>
                isString(item.value) &&
                !isEmpty(item.value) &&
                !item.value.includes("null") &&
                !item.value.includes("undefined") && (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td style={{ fontWeight: "600" }}>{item.value}</td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
      </div>
      {isString(obj.specifications) && !isEmpty(obj.specifications) && (
        <Description description={obj.specifications} />
      )}
      {isString(obj.role) && !isEmpty(obj.role) && (
        <Description description={obj.role} title="My Role & Responsibilities" />
      )}
      {isArray(obj.images) && !isEmpty(obj.images) && (
        <div className="my-4">
          <h6 className="fw-bold">Images</h6>
          {obj.images.map((el, index) => (
            <div className="mt-4" key={`${index}-${obj.tile}-image`}>
              <ContentImage src={`/${el}`} />
            </div>
          ))}
        </div>
      )}
      {isArray(obj.videos) && !isEmpty(obj.videos) && obj.videos.every((el) => !isEmpty(el)) && (
        <>
          <h6 className="mt-5 fw-bold">Videos</h6>
          {obj.videos.map((el, index) => (
            <div key={`${index}-${obj.tile}-video`} className="mt-4">
              <ReactPlayer
                url={getContentAbsoluteUrl(el)}
                loop
                width="100%"
                height="40rem"
                controls
              />
            </div>
          ))}
        </>
      )}
    </>
  );
}

function ThesisDetails({ obj, onViewed }) {
  const client = ContentFactory.createRequest("thesis");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      client.view(obj.id).then((status) => {
        if (status === 204) {
          if (isFunction(onViewed)) {
            onViewed(obj.id);
          }
        }
      });
    }
  }, [isLoggedIn]);
  return (
    <>
      <PdfPreview file={obj.file} />
      <Description description={obj.description} />
    </>
  );
}

function ARVRDetails({ obj, onViewed }) {
  const client = ContentFactory.createRequest("arvr");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      client.view(obj.id).then((status) => {
        if (status === 204) {
          if (isFunction(onViewed)) {
            onViewed(obj.id);
          }
        }
      });
    }
  }, [isLoggedIn]);
  return (
    <>
      <ReactPlayer url={getContentAbsoluteUrl(obj.link)} loop width="100%" height="30vw" controls />
      <Description description={obj.description} />
    </>
  );
}

function CatalogueDetails({ obj }) {
  return (
    <>
      <PdfPreview file={obj.file} />
      <p>
        <span className="fw-bold">Category:</span> {obj.category}
      </p>
    </>
  );
}

import React from "react";
import BaseModal from "../Base";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { isArray } from "lodash";
import { Col, Image } from "react-bootstrap";

export default function ViewProductMediaModal({ show, onHide }) {
  return (
    <BaseModal show={isArray(show)} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Product Media</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-5">
        <h6 className="mb-3">Cover Image</h6>
        {isArray(show) && <Image src={show[0]} rounded style={{ width: "12.5rem" }} />}
        <h6 className="my-3">Images</h6>
        <Row md={5}>
          {isArray(show) &&
            show.slice(1).map((el, index) => (
              <Col key={`${el}-${index}`}>
                <Image src={el} rounded style={{ width: "100%" }} />
              </Col>
            ))}
        </Row>
      </Modal.Body>
    </BaseModal>
  );
}

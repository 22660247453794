import React from "react";
import BorderedButton from "../Bordered";
import { ReactSVG } from "react-svg";
import { PLUS_ICON } from "../../../constants/icons";

export default function AddContetnBorderedButton({ onClick, label }) {
  return (
    <BorderedButton
      style={{
        padding: "1rem 0",
        borderRadius: "0.25rem",
        fontSize: "1.25rem",
        justifyContent: "start",
        paddingLeft: "2%",
        textTransform: "capitalize"
      }}
      onClick={onClick}
    >
      <ReactSVG src={PLUS_ICON} style={{ width: "2.5rem", height: "100%" }} className="me-4" />
      {label}
    </BorderedButton>
  );
}

const LANGUAGES = [
  "Hindi",
  "English",
  "Spanish",
  "French",
  "Russian",
  "Portuguese",
  "German",
  "Mandarin",
  "Arabic",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu"
].map((language) => ({ value: language, label: language }));

export default LANGUAGES;

import React, { useEffect, useRef, useState } from "react";
import JobFilters from "../../components/Filters/JobFilters";
import ContentFactory from "../../apiClients/ContentFactory";
import LoadingContainer from "../../components/Containers/Loading";
import { Col, Row } from "react-bootstrap";
import { isArray, isEmpty, isEqual, isUndefined } from "lodash";
import Job from "../../components/Cards/Job";
import { useNavigate, useParams } from "react-router-dom";
import JobDetails from "../../components/Job/Details";
import COLORS from "../../constants/colors";
import { useSearchParams } from "../../hooks/searchParams";
import CandidateFilters from "../../components/Filters/CandidateFilters";
import ProfileCard from "../../components/Cards/Profile";
import Profile from "../Profile";
import { isMobile } from "react-device-detect";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function JobSearch({ isCandidateSearch = false }) {
  const CONSTANT_FILTERS = isCandidateSearch
    ? { group: "student", page: 1, page_size: 20 }
    : { is_posted: true };

  const { id } = useParams();
  const { searchParamsAsObject, queryString, convertSearchParamsValuesToArray } = useSearchParams();

  const isDetails = !isUndefined(id);
  const client = ContentFactory.createRequest(isCandidateSearch ? "profile" : "job");
  const [filters, setFilters] = useState(searchParamsAsObject);
  const [loading, setLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState("80rem");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const targetRowRef = useRef(null);
  const jobDetailsColRef = useRef(null);
  let targetColOffset = 0;
  const navigationPrefix = `/jobs/${isCandidateSearch ? "recruiter" : "seeker"}`;

  useEffect(() => {
    if (isEqual(filters, searchParamsAsObject)) {
      setFilters(convertSearchParamsValuesToArray());
    }
  }, []);

  const handleGetSearch = () =>
    client
      .list({ ...CONSTANT_FILTERS, ...searchParamsAsObject })
      .then((result) => (isCandidateSearch ? result.result : result))
      .then(setSearchResults)
      .then(() => setLoading(false));

  useEffect(() => {
    handleGetSearch();
  }, [isDetails, queryString]);

  useEffect(() => {
    if (targetRowRef.current) {
      targetRowRef.current.scrollTop = targetColOffset;
    }
  }, [targetRowRef]);

  useEffect(() => {
    if (isDetails && isArray(searchResults) && !isEmpty(searchResults) && isDetails) {
      navigate(`${navigationPrefix}/search?${queryString}`);
    }
  }, [queryString]);

  useEffect(() => {
    if (isDetails && jobDetailsColRef.current) {
      const colHeight = jobDetailsColRef.current.clientHeight;
      setMaxHeight(colHeight);
    }
  }, [id]);

  const handleHideSection = () =>
    navigate(`/jobs/${isCandidateSearch ? "recruiter" : "seeker"}/search`);

  return (
    <div>
      <LoadingContainer loading={loading}>
        {isCandidateSearch ? (
          <CandidateFilters filters={filters} setFilters={setFilters} />
        ) : isMobile && isDetails ? null : (
          <JobFilters filters={filters} setFilters={setFilters} />
        )}

        {isArray(searchResults) && !isEmpty(searchResults) && (
          <Row className="my-4 mx-2">
            {((isMobile && !isDetails) || !isMobile) && (
              <Col sm={isDetails ? 3 : 12}>
                <Row
                  xs={isCandidateSearch ? 2 : 1}
                  sm={isDetails ? 1 : isCandidateSearch ? 5 : 4}
                  ref={targetRowRef}
                  style={{
                    overflow: isDetails ? "scroll" : "none",
                    maxHeight: isDetails ? maxHeight : undefined
                  }}
                >
                  {searchResults.map((el, index) => (
                    <Col key={`job-${index}-${el.id}`} className={`mb-4 ${isMobile ? "px-0" : ""}`}>
                      {isCandidateSearch ? (
                        <ProfileCard
                          obj={el}
                          onClick={() =>
                            navigate(`${navigationPrefix}/search/details/${el.slug}?${queryString}`)
                          }
                          onBookmarked={handleGetSearch}
                        />
                      ) : (
                        <Job
                          obj={el}
                          onClick={() =>
                            navigate(`${navigationPrefix}/search/details/${el.slug}?${queryString}`)
                          }
                          cardStyle={{ backgroundColor: el.slug === id ? COLORS.GREY1 : "white" }}
                          onBookmarked={handleGetSearch}
                        />
                      )}
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
            {isDetails && (
              <Col ref={jobDetailsColRef} className="px-0">
                {!isMobile && (
                  <div
                    className="text-end mb-3 text-primary fw-bold cursor-pointer"
                    onClick={handleHideSection}
                  >
                    HIDE SECTION
                  </div>
                )}
                {isCandidateSearch ? (
                  <Profile userId={id} showSecondCol={false} showBack={false} />
                ) : (
                  <JobDetails id={id} handleGetSearch={handleGetSearch} />
                )}
              </Col>
            )}
          </Row>
        )}
      </LoadingContainer>
    </div>
  );
}

export default withMobileOnlySidebar(JobSearch);

import React from "react";
import BaseContainer from "../BaseContainer";
import { Card, Carousel, Col, Row, Image } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import AUTH_FRAME_1 from "../../../assets/Auth/Auth-Frame-1.png";
import AUTH_FRAME_2 from "../../../assets/Auth/Auth-Frame-2.png";
import AUTH_FRAME_3 from "../../../assets/Auth/Auth-Frame-3.png";
import AUTH_FRAME_4 from "../../../assets/Auth/Auth-Frame-4.png";
import AutoPlayCarousel from "../../AutoPlayCarousel";
import { isMobile } from "react-device-detect";

export default function AuthContainer({ Form, HeaderComponent }) {
  const methods = useForm({ reValidateMode: "onChange" });

  return (
    <BaseContainer>
      <Row
        style={{
          maxHeight: "inherit",
          marginTop: isMobile ? "1.5rem" : undefined
        }}
      >
        <Col className="d-flex align-items-center justify-content-center w-100">
          <Card
            className={`border-0 w-100${isMobile ? "" : " shadow rounded"}`}
            style={isMobile ? {} : { padding: "3rem", marginLeft: "3rem" }}
          >
            <div>{HeaderComponent}</div>
            <FormProvider {...methods}>
              <Form />
            </FormProvider>
          </Card>
        </Col>
        {!isMobile && (
          <Col>
            <AutoPlayCarousel interval={6500} className="pt-4">
              {[AUTH_FRAME_1, AUTH_FRAME_2, AUTH_FRAME_3, AUTH_FRAME_4].map((el) => (
                <Carousel.Item key={el}>
                  <Image
                    src={el}
                    className="w-100"
                    style={{ maxHeight: "90vh", objectFit: "contain" }}
                  />
                </Carousel.Item>
              ))}
            </AutoPlayCarousel>
          </Col>
        )}
      </Row>
    </BaseContainer>
  );
}

import React, { useEffect, useState } from "react";
import BaseContainer from "../../../../components/Containers/BaseContainer";
import { FormProvider, useForm } from "react-hook-form";
import AddResumeForm from "../../../../forms/Content/AddResume";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContentFactory from "../../../../apiClients/ContentFactory";
import { ReactSVG } from "react-svg";
import RESUME_PREVIEW from "../../../../assets/icons/resume-preview.svg";
import Profile from "../../../Profile";
import LoadingContainer from "components/Containers/Loading";
import { isMobile } from "react-device-detect";

export default function AddResume() {
  const { id } = useParams();
  const navigation = useNavigate();
  const { hash } = useLocation();
  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue } = methods;
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(id ? true : false);

  useEffect(() => {
    if (id) {
      ContentFactory.createRequest("resume")
        .list({ id })
        .then((result) => {
          const resume = result[0];
          [
            "title",
            "headline",
            "about",
            "employment",
            "education",
            "courses",
            "awards",
            "image"
          ].forEach((el) => setValue(el, resume[el]));
          [
            "hobbies",
            "technical_skills",
            "management_skills",
            "personal_skills",
            "languages"
          ].forEach((skillType) => {
            const skills = (resume[skillType] || []).map((el) => ({ label: el, value: el }));
            setValue(skillType, skills);
          });
        })
        .then(() => setLoading(false));
    }
  }, []);

  const handlePreview = () => navigation("#preview");

  useEffect(() => {
    setPreview(hash === "#preview");
  }, [hash]);

  return preview ? (
    <div className="w-100">
      <Profile
        preview={preview}
        previewProfile={{ resume: methods.watch() }}
        showSecondCol={false}
      />
    </div>
  ) : (
    <BaseContainer className="py-4">
      <LoadingContainer loading={loading}>
        <div style={{ width: isMobile ? "100%" : "60%" }}>
          <div className="w-100 d-flex justify-content-between">
            <h4 className="bordered-heading text-capitalize">Resume</h4>
            <div>
              <ReactSVG
                src={RESUME_PREVIEW}
                className="cursor-pointer"
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 40");
                  svg.removeAttribute("height");
                }}
                onClick={handlePreview}
              />
            </div>
          </div>
          <FormProvider {...methods}>
            <AddResumeForm />
          </FormProvider>
        </div>
      </LoadingContainer>
    </BaseContainer>
  );
}

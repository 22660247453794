import { SEARCH_ICON } from "constants/icons";
import { isNumber } from "lodash";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { isMobile } from "react-device-detect";

const AsyncSearchFilter = ({ client, labelKey, renderMenuItemChildren, maxResults }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    if (isLoading === false) {
      setIsLoading(true);
    }
    client
      .list({
        search: query,
        page: 1,
        per_page: 20
      })
      .then((result) => setOptions(result.result))
      .then(() => setIsLoading(false));
  };

  const filterBy = () => true;

  return (
    <AsyncTypeahead
      clearButton
      maxResults={maxResults}
      filterBy={filterBy}
      labelKey={labelKey}
      delay={10}
      id="async-search"
      isLoading={isLoading}
      options={options}
      onSearch={handleSearch}
      placeholder="Search"
      useCache={false}
      renderMenuItemChildren={renderMenuItemChildren}
      paginate={isNumber(maxResults)}
      inputProps={{
        style: {
          paddingLeft: "40px",
          border: "none",
          backgroundColor: isMobile ? "#AAAAAA30" : "white"
        },
        className: "py-2"
      }}
    >
      {({ selected }) => (
        <div className="rbt-aux ms-1" style={{ left: 0 }}>
          {!selected.length && <Image src={SEARCH_ICON} />}
        </div>
      )}
    </AsyncTypeahead>
  );
};

export default AsyncSearchFilter;

import React from "react";
import ContentList from "../../../components/Base/ContentList";
import { connect } from "react-redux";

function UnderReview({ user }) {
  return (
    <ContentList
      type="job"
      title="Under Review Jobs"
      subTitle="Jobs which are under review or not accepted are all shown here"
      initialFilters={{
        under_review_rejected: true,
        user: user.id
      }}
      cardProps={{ showSelect: false }}
      doNotShowAdd
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(UnderReview);

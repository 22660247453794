import React, { useEffect, useState } from "react";
import BaseContainer from "../../components/Containers/BaseContainer";
import ContentFactory from "../../apiClients/ContentFactory";
import LoadingContainer from "../../components/Containers/Loading";
import { BsPlus } from "react-icons/bs";
import COLORS from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import { Col, Image, Row, Stack } from "react-bootstrap";
import FILE from "../../assets/icons/file.svg";
import { ReactSVG } from "react-svg";
import { isArray, isEmpty, isUndefined } from "lodash";
import { ThreeDotsDropdown } from "../../components/Buttons/ThreeDotsVertical";
import DEFAULT_RESUME from "../../assets/icons/resume/default-resume.png";
import ROUTE_CONSTANTS from "../../constants/routes";
import BorderedButton from "../../components/Buttons/Bordered";
import ConfirmDeletionModal from "../../components/Modals/ConfirmDeletion";
import { downloadContent } from "../../utils";
import PdfPreviewModal from "../../components/Modals/PdfPreview";
import LockedContent from "../../components/Containers/LockedContent";
import UploadResumeFileModal from "../../components/Modals/UploadResumeFileModal";
import ResumePDF from "components/ResumePDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const resumeClient = ContentFactory.createRequest("resume");
const resumeFileClient = ContentFactory.createRequest("resume-file");

const {
  ROUTE_PREFIXES: { MY_PROFILE }
} = ROUTE_CONSTANTS;

const SavedResumeTile = ({ resume, title, getResumes, dropDownOptions = [], isFile = false }) => {
  if (
    !resume.default &&
    dropDownOptions.filter((el) => el.label === "Set as default/primary resume").length === 0
  ) {
    dropDownOptions.push({
      label: "Set as default/primary resume",
      onClick: () => {
        resumeClient.makeDefault(resume.id, isFile).then(() => getResumes());
      }
    });
  }
  return (
    <div
      className="rounded px-2 py-3 d-flex justify-content-between align-items-center w-100 position-relative"
      style={{ border: "1px black solid" }}
    >
      {resume.default && (
        <Image
          src={DEFAULT_RESUME}
          style={{ position: "absolute", right: "-0.6rem", top: "-0.8rem" }}
        />
      )}
      <div className="d-flex justify-content-center align-items-center">
        <ReactSVG src={FILE} />
        <div className="ms-3">{resume && resume.title ? resume.title : title}</div>
      </div>
      <ThreeDotsDropdown options={dropDownOptions || []} size={25} className="me-4" />
    </div>
  );
};

const PDFResumes = ({ resume, getResumeFiles, getAllResumes, setDelete, setPreviewPdfFile }) => {
  const { id, file, title } = resume;

  const deleteResume = () => {
    setDelete(() => {
      return () =>
        resumeFileClient
          .remove(id)
          .then(getResumeFiles)
          .then(() => setDelete(undefined));
    });
  };

  var dropDownOptions = [
    { label: "Delete", onClick: deleteResume },
    { label: "Preview", onClick: () => setPreviewPdfFile(file) },
    { label: "Download", onClick: () => downloadContent(file, `Resume-${title}.pdf`) }
  ];

  return (
    <SavedResumeTile
      resume={resume}
      dropDownOptions={dropDownOptions}
      getResumes={getAllResumes}
      isFile
    />
  );
};

function SavedResumeFileCol({ children }) {
  return <Col className="mb-3">{children}</Col>;
}

function MyResumes({
  resumes,
  resumeFiles,
  getAllResumes,
  getResumeFiles,
  setDeleteResume,
  setPreviewPdfFile
}) {
  const navigate = useNavigate();
  const handleAddResume = () => navigate(`/${MY_PROFILE}/resume/add`);
  const [showAddResumeFile, setShowAddResumeFile] = useState(false);
  const user = useSelector((state) => state.authReducer.user);

  const SavedResumes = () => {
    const downloadResume = async () => {
      const doc = <ResumePDF resume={resumes[0]} user={user} />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, "ML-Created-Resume.pdf");
    };
    return (
      <div>
        <div>
          {isArray(resumes) && !isEmpty(resumes) && <h6 className="mb-3">Created Resume - ML</h6>}
          <Row xs={3} className="w-100">
            <Col className={isMobile ? "w-100 pe-0" : ""}>
              {isArray(resumes) && !isEmpty(resumes) ? (
                <SavedResumeTile
                  resume={resumes[0]}
                  dropDownOptions={[
                    {
                      label: "Edit",
                      onClick: () => navigate(`/${MY_PROFILE}/resume/${resumes[0].id}/edit`)
                    },
                    {
                      label: "Preview",
                      onClick: () => navigate(`/${MY_PROFILE}/resume/${resumes[0].id}/edit#preview`)
                    },
                    {
                      label: "Download",
                      onClick: downloadResume
                    }
                  ]}
                  getResumes={getAllResumes}
                />
              ) : (
                <BorderedButton onClick={handleAddResume}>
                  <BsPlus className="add-icon" size={60} color={COLORS.GREY2} />
                  <p className="mb-0">CREATE RESUME</p>
                </BorderedButton>
              )}
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <h6 className="mb-3">Attached Resume PDFs (max 4 allowed)</h6>
          <Row xs={1} sm={3}>
            {resumeFiles.length < 4 && (
              <SavedResumeFileCol>
                <BorderedButton onClick={() => setShowAddResumeFile(true)}>
                  <BsPlus size={60} color={COLORS.GREY2} />
                  <p className="mb-0">UPLOAD RESUME FILE</p>
                </BorderedButton>
              </SavedResumeFileCol>
            )}
            {resumeFiles.map((el, index) => (
              <SavedResumeFileCol key={index}>
                <PDFResumes
                  resume={el}
                  getResumeFiles={getResumeFiles}
                  getAllResumes={getAllResumes}
                  setDelete={setDeleteResume}
                  setPreviewPdfFile={setPreviewPdfFile}
                />
              </SavedResumeFileCol>
            ))}
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      {resumes.length === 0 && resumeFiles.length === 0 && (
        <Stack gap={1}>
          <div>
            <h6>Multiple Resumes</h6>
            <p>Add not just one but many, each catering to your job profile.</p>
          </div>
          <div>
            <h6>Update frequently</h6>
            <p>Keep your resume updated to attract recruiters.</p>
          </div>
          <div>
            <h6>Download for external use</h6>
            <p>Download your resume and keep a hard copy for your ease.</p>
          </div>
        </Stack>
      )}
      <SavedResumes />
      <UploadResumeFileModal
        showAddResumeFile={showAddResumeFile}
        setShowAddResumeFile={setShowAddResumeFile}
        onSuccess={getResumeFiles}
      />
    </>
  );
}

export default function Resume() {
  const [resumes, setResumes] = useState([]);
  const [resumeFiles, setResumeFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteResume, setDeleteResume] = useState(undefined);
  const [previewPdfFile, setPreviewPdfFile] = useState(undefined);

  const getResumes = () => resumeClient.list().then(setResumes);

  const getResumeFiles = () => resumeFileClient.list().then(setResumeFiles);

  const getAllResumes = () => Promise.all([getResumes(), getResumeFiles()]);

  useEffect(() => {
    getAllResumes().then(() => setLoading(false));
  }, []);

  return (
    <BaseContainer className="py-4">
      <div className="mx-auto w-100">
        <h4 className="bordered-heading text-capitalize">Resume</h4>
        <p className="mt-3">
          {resumes.length === 0 && resumeFiles.length === 0
            ? "Create your professional resume here."
            : "View all your created resumes here"}
        </p>
      </div>
      <LockedContent>
        <LoadingContainer loading={loading}>
          <MyResumes
            resumes={resumes}
            resumeFiles={resumeFiles}
            getResumes={getResumes}
            getResumeFiles={getResumeFiles}
            getAllResumes={getAllResumes}
            setDeleteResume={setDeleteResume}
            setPreviewPdfFile={setPreviewPdfFile}
          />
          {isArray(resumeFiles) && !isEmpty(resumeFiles) && (
            <>
              <ConfirmDeletionModal
                type="resume file"
                show={!isUndefined(deleteResume)}
                onHide={() => setDeleteResume(undefined)}
                onCancel={() => setDeleteResume(undefined)}
                onConfirm={deleteResume}
              />
              <PdfPreviewModal
                file={previewPdfFile}
                show={!isUndefined(previewPdfFile)}
                onHide={() => setPreviewPdfFile(undefined)}
              />
            </>
          )}
        </LoadingContainer>
      </LockedContent>
    </BaseContainer>
  );
}

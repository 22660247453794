import React from "react";
import ContentList from "../../components/Base/ContentList";
import { connect } from "react-redux";

function ContentARVR({ user }) {
  return (
    <ContentList
      type="arvr"
      title="AR/VR"
      subTitle="Display your AR/VRs"
      addText="Add New AR/VR"
      initialFilters={{ user: user.id }}
      addModalSize="lg"
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(ContentARVR);

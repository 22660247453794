import React, { useEffect, useState } from "react";
import { Form, Stack, Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { isArray, isDate, isEmpty, isObject, isString, isUndefined } from "lodash";
import { convertDataUrlToBase64, isValidURL } from "utils";
import { getContentAbsoluteUrl } from "utils";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import PROJECT_CONSTANTS from "constants/Projects";
import CheckBox from "components/Inputs/CheckBox";
import CustomDate from "components/Inputs/CustomDate";
import Area from "components/Inputs/Area";
import Price from "components/Inputs/Price";
import DateParser from "date";
import ContentFactory from "apiClients/ContentFactory";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import FormFooter from "forms/Footer";
import FormError from "components/FormError";
import { isMobile } from "react-device-detect";

const AddProjectForm = ({ onSuccess, editObj }) => {
  const [part, setPart] = useState(1);
  const {
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
    setError
  } = useFormContext();
  const parts = ["Basic Details", "Document"];
  const startDate = watch("start_date");

  const isCompleted = watch("is_completed");
  const isOngoing = watch("is_ongoing");

  const handleSetImages = () =>
    Promise.all(
      editObj.images.map((imageUrl) => convertDataUrlToBase64(getContentAbsoluteUrl(imageUrl)))
    );

  useEffect(() => {
    if (isObject(editObj)) {
      if (isArray(editObj.images) && !isEmpty(editObj.images)) {
        handleSetImages().then((images) => setValue("images", images));
      }
      if (isArray(editObj.videos) && !isEmpty(editObj.videos)) {
        setValue(
          "videos",
          isArray(editObj.videos) && !isEmpty(editObj.videos) ? editObj.videos[0] : null
        );
      }
      convertDataUrlToBase64(editObj.thumbnail).then((image) => setValue("thumbnail", [image]));

      ["start_date", "end_date"].forEach((el) => setValue(el, new Date(editObj[el])));

      [
        "title",
        "location",
        "total_build_up_area",
        "plot_area",
        "specifications",
        "role",
        "cost_per_square_feet"
      ].forEach((el) => setValue(el, editObj[el]));

      ["type", "plot_area_unit", "total_build_up_area_unit"].forEach((el) =>
        setValue(el, { label: editObj[el], value: editObj[el] })
      );

      if (editObj.status === "Ongoing") {
        setValue("is_ongoing", true);
      } else {
        setValue("is_completed", true);
      }
    }
  }, []);

  useEffect(() => {
    clearErrors("project_status");
    if (isCompleted === true && isOngoing === true) {
      setValue("is_ongoing", false);
    }
  }, [isCompleted]);

  useEffect(() => {
    clearErrors("project_status");
    if (isCompleted === true && isOngoing === true) {
      setValue("is_completed", false);
    }
  }, [isOngoing]);

  const FormPartTwo = (
    <>
      {[
        {
          label: "Add your Project Images*",
          name: "images",
          type: "image",
          multiple: true,
          preferredText: "Preferred Resolution: 1240 X 680",
          maxSizeMB: 1
        },
        {
          label: "Add your Project Video",
          name: "videos",
          type: "video",
          multiple: false,
          component: ReactHookInput,
          componentProps: {
            required: false,
            validation: {
              validate: (value) =>
                isString(value) && !isEmpty(value) && !isValidURL(value)
                  ? "Please enter a valid URL"
                  : undefined
            }
          }
        }
      ].map((group, index) => (
        <Row key={index}>
          <Col>
            <Form.Group className="py-3">
              <Form.Label>{group.label}</Form.Label>
              {group.component ? (
                <group.component name={group.name} {...group.componentProps} />
              ) : (
                <ReactHookUpload
                  name={group.name}
                  required={group.required}
                  type={group.type}
                  multiple={group.multiple}
                  {...group}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
      ))}
    </>
  );

  const FormPartOne = (
    <Row className="mx-0" xs={1}>
      <Col sm={4} className="px-0">
        <Form.Label>Upload Thumbnail*</Form.Label>
        <ReactHookUpload
          name="thumbnail"
          required
          type="image"
          maxSizeMB={1}
          preferredText="Preferred Resolution: 400 X 330"
        />
      </Col>
      <Col sm={8}>
        <Stack gap={3} className="w-100">
          <Row xs={1} sm={2}>
            {[
              {
                label: "Title*",
                name: "title",
                required: true,
                type: "text"
              },
              {
                label: "Location (City & State)",
                name: "location",
                required: true,
                type: "text"
              }
            ].map((field, index) => (
              <Col key={index} style={{ marginBottom: isMobile && index === 0 ? "1rem" : 0 }}>
                <Form.Group>
                  <Form.Label>{field.label}</Form.Label>
                  <ReactHookInput type={field.type} name={field.name} required={field.required} />
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row xs={1} sm={2}>
            <Col style={{ marginBottom: isMobile ? "1rem" : 0 }}>
              <Form.Group>
                <Form.Label>Project Type*</Form.Label>
                <Select options={PROJECT_CONSTANTS.TYPES} name="type" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Project Status*</Form.Label>
                <Stack direction="horizontal" className="mt-2" gap={4}>
                  {[
                    {
                      name: "is_completed",
                      label: "Completed"
                    },
                    {
                      name: "is_ongoing",
                      label: "Ongoing"
                    }
                  ].map((checkbox, index) => (
                    <CheckBox key={index} name={checkbox.name} label={checkbox.label} />
                  ))}
                </Stack>
                {errors && errors.project_status && errors.project_status.message && (
                  <FormError className="d-flex justify-content-center">
                    {errors.project_status.message}
                  </FormError>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row xs={1} sm={isCompleted ? 2 : 1}>
            {[
              {
                label: "Start Date",
                name: "start_date",
                maxDate: new Date(),
                show: true,
                required: false
              },
              {
                label: "End Date",
                name: "end_date",
                minDate: startDate,
                maxDate: new Date(),
                show: isCompleted,
                required: isCompleted && !isUndefined(startDate)
              }
            ].map(
              (field, index) =>
                field.show && (
                  <Col key={index} style={{ marginBottom: isMobile && index === 0 ? "1rem" : 0 }}>
                    <Form.Group>
                      <Form.Label>{field.label}</Form.Label>
                      <CustomDate
                        name={field.name}
                        minDate={field.minDate}
                        maxDate={field.maxDate}
                        required={field.required}
                      />
                    </Form.Group>
                  </Col>
                )
            )}
          </Row>
          <Row xs={1} sm={2}>
            {[
              {
                label: "Total Built-up Area",
                name: "total_build_up_area"
              },
              {
                label: "Plot Area",
                name: "plot_area"
              }
            ].map((field, index) => (
              <Col key={index} style={{ marginBottom: isMobile && index === 0 ? "1rem" : 0 }}>
                <Form.Group>
                  <Form.Label>{field.label}</Form.Label>
                  <Area name={field.name} />
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Form.Group>
            <Form.Label>Cost per Square Feet</Form.Label>
            <Price name="cost_per_square_feet" />
          </Form.Group>
          <Row xs={1} sm={2}>
            {[
              {
                label: "Specifications of the project",
                name: "specifications"
              },
              {
                label: "Your role in the project",
                name: "role"
              }
            ].map((field, index) => (
              <Col key={index} style={{ marginBottom: isMobile && index === 0 ? "1rem" : 0 }}>
                <Form.Group>
                  <Form.Label>{field.label}</Form.Label>
                  <ReactHookInput as="textarea" name={field.name} />
                </Form.Group>
              </Col>
            ))}
          </Row>
        </Stack>
      </Col>
    </Row>
  );

  const handleFormOneSubmit = () => {
    var hasError = false;
    if (!isCompleted && !isOngoing) {
      hasError = true;
      setError("project_status", { message: "This field is required" });
    } else if (errors.project_status) {
      hasError = false;
      clearErrors("project_status");
    }
    if (watch("total_build_up_area") && !watch("total_build_up_area_unit")) {
      hasError = true;
      setError("total_build_up_area", { message: "Please select a unit for total build up area" });
    } else {
      clearErrors("total_build_up_area");
    }
    if (watch("plot_area") && !watch("plot_area_unit")) {
      hasError = true;
      setError("plot_area", { message: "Please select a unit for plot area" });
    } else {
      clearErrors("plot_area");
    }

    if (!hasError) {
      setPart(part + 1);
    }
  };

  const handleFormTwoSubmit = async (data) => {
    const { images, is_completed, thumbnail, title, location, type, videos } = data;
    if (!isArray(images) || isEmpty(images)) {
      setError("images", { message: "Please select atleast one project image" });
      return;
    }

    const payload = {
      ...data,
      start_date: isDate(data.start_date)
        ? DateParser.formatDateToYYYYMMDD(data.start_date)
        : isString(data.start_date)
        ? data.start_date
        : null,
      end_date: isDate(data.end_date)
        ? DateParser.formatDateToYYYYMMDD(data.end_date)
        : isString(data.end_date)
        ? data.end_date
        : null,
      images,
      status: is_completed ? "Completed" : "Ongoing",
      title,
      thumbnail,
      location,
      type: type.value,
      videos: [videos],
      plot_area_unit: data.plot_area_unit ? data.plot_area_unit.value : null,
      total_build_up_area_unit: data.total_build_up_area_unit
        ? data.total_build_up_area_unit.value
        : null
    };
    Object.keys(payload).forEach((key) => {
      if (payload[key] === undefined || payload[key] === null) {
        delete payload[key];
      }
    });
    const client = ContentFactory.createRequest("project");
    const promise = (
      editObj ? client.update({ ...payload, id: editObj.id }) : client.create(payload)
    ).then(onSuccess);

    showPromiseToast(promise, {
      pending: "Saving project",
      success: "Project saved successfully",
      error: renderErrorToast()
    });
  };

  return (
    <Form onSubmit={handleSubmit(part === 1 ? handleFormOneSubmit : handleFormTwoSubmit)}>
      {part === 1 ? FormPartOne : FormPartTwo}
      <FormFooter part={part} setPart={setPart} parts={parts} />
    </Form>
  );
};

export default AddProjectForm;

import { takeLatest } from "@redux-saga/core/effects";
import ACTION_TYPES from "../constants/action-types";
import { getIdeationBoard } from "./Content/IdeationBoard/actions";
import { getPinnedBlogs } from "./Content/Blogs/actions";
import { getCategories, getProductFilters } from "./Products/actions";

export default function* rootSaga() {
  yield takeLatest(
    ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_REQUEST,
    getIdeationBoard
  );
  yield takeLatest(ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_REQUEST, getPinnedBlogs);
  yield takeLatest(ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_REQUEST, getProductFilters);
  yield takeLatest(ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_REQUEST, getCategories);
}

import moment from "moment";

export default class DateParser {
  static months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  static contentCardDateTimeString(date) {
    return new Date(date)
      .toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric"
      })
      .split("-")
      .map((part, index) => (index === 0 ? part.toUpperCase() : part))
      .join("-");
  }

  static formatDateToYYYYMMDD(date) {
    if (!(date instanceof Date)) {
      throw new Error("Input is not a valid Date object");
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  static parseFromYYYYMMDD(dateString) {
    return moment(dateString, "YYYY-MM-DD").toDate();
  }

  static formatDateToDDMMMYYYY(inputDate) {
    try {
      // Parse the input date string into a Date object
      const inputDateObj = new Date(inputDate);

      // Check if the date is valid
      if (isNaN(inputDateObj)) {
        throw new Error("Invalid date");
      }

      // Define months array for month names

      // Get day, month, and year components
      const day = inputDateObj.getDate();
      const month = DateParser.months[inputDateObj.getMonth()];
      const year = inputDateObj.getFullYear();

      // Format the date as "10 July 2022"
      const formattedDate = `${day} ${month} ${year}`;

      return formattedDate;
    } catch (error) {
      return "Invalid date format. Please provide a valid date.";
    }
  }
}

const COLORS = {
  PRIMARY_ORANGE: "#FF7A59",
  LIGHT_ORANGE: "#FFF1EC",
  GREY1: "#F5F5F5",
  GREY2: "#696969",
  GREY3: "#D9d9d9",
  TURQUOISE: "#28AD95",
  LINEAR_GRADIENTS: {
    GREEN: "linear-gradient(90deg, #28AD95 43.75%, rgba(40, 173, 149, 0.78) 97.5%)"
  }
};

export default COLORS;

const getApplicationStatusColors = (status) =>
  status === "Selected" || status === "Hired"
    ? "green"
    : status === "In-Touch"
    ? "yellow"
    : "black";

export { getApplicationStatusColors };

import React, { useState } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import COLORS from "constants/colors";
import { Stack } from "react-bootstrap";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";

export default function PdfPreview({ file, width = 400, height = 500, controls = true }) {
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [minHeight, setMinHeight] = useState(null);

  const handleDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const handlePreviousPage = (e) => {
    e.stopPropagation();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = (e) => {
    e.stopPropagation();

    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const scaleUp = (e) => {
    e.stopPropagation();
    setScale(scale + 0.2);
  };

  const scaleDown = (e) => {
    e.stopPropagation();
    setScale(scale - 0.2);
  };

  const scaleButtonProps = { color: "white", size: 30, className: "cursor-pointer" };

  const handlePageLoadSuccess = (pageObj) => {
    if (pageObj.height > height) {
      setMinHeight(pageObj.height || height);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: COLORS.GREY2,
          overflow: "scroll"
        }}
        className="w-100 mt-3 position-relative"
      >
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-end mx-3 position-absolute"
          style={{ right: 20, top: 10, zIndex: 10000 }}
        >
          <BiZoomIn onClick={scaleUp} {...scaleButtonProps} />
          <BiZoomOut onClick={scaleDown} {...scaleButtonProps} />
        </Stack>
        <div style={{ minHeight }}>
          <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
            <Page
              pageNumber={currentPage}
              scale={scale}
              width={width}
              className="bg-transparent"
              onLoadSuccess={handlePageLoadSuccess}
            />
          </Document>
        </div>
      </div>
      {controls === true && (
        <div className="d-flex justify-content-center p-4 cursor-pointer bg-white">
          <div onClick={handlePreviousPage} className="px-2 me-3">
            <IoIosArrowBack />
          </div>
          <span>
            {currentPage} / {numPages}
          </span>
          <div onClick={handleNextPage} className="px-2 ms-3 cursor-pointer">
            <IoIosArrowForward />
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import Card from "react-bootstrap/esm/Card";
import EngagementStats from "../../EngagementStats";
import ReactPlayer from "react-player";
import { DATE_TEXT_STYLE, SUBTITLE_TEXT_STYLE } from "../default-styles";
import BaseContentCard, {
  BaseContentCardDetailsSection,
  CardTitle,
  RenderBookmarkIcon,
  RenderSelect
} from "../../Base/Card";
import { useNavigationInfo } from "../../../hooks/route";
import { getContentAbsoluteUrl } from "../../../utils";
import DateParser from "../../../date";
import { isFunction } from "lodash";

export default function Video({
  obj,
  onBookmarked,
  onAddedToIdeationBoard,
  isSelected,
  handleSelection,
  onClick,
  cardStyle = {},
  showSelect = true
}) {
  const { isMyProfileOrActivity } = useNavigationInfo();

  return (
    <BaseContentCard onClick={isFunction(onClick) && onClick} style={{ ...cardStyle }}>
      <RenderSelect
        isSelected={isSelected}
        handleSelection={handleSelection}
        showSelect={showSelect}
      />
      <RenderBookmarkIcon
        onAddedToIdeationBoard={onAddedToIdeationBoard}
        obj={obj}
        onBookmarked={onBookmarked}
        type="video"
      />
      <div className="content-card-image w-100 d-flex justify-content-center align-items-center">
        <ReactPlayer url={getContentAbsoluteUrl(obj.video)} loop width="100%" height="100%" />
      </div>
      <BaseContentCardDetailsSection>
        <Card.Text className="text-muted" style={DATE_TEXT_STYLE}>
          {DateParser.contentCardDateTimeString(obj.created)}
        </Card.Text>
        <CardTitle>{obj.title}</CardTitle>
        <Card.Subtitle className="text-muted" style={SUBTITLE_TEXT_STYLE}>
          {isMyProfileOrActivity ? obj.category : obj.user}
        </Card.Subtitle>
        <EngagementStats likes={obj.likes} saves={obj.saves} views={obj.views} />
      </BaseContentCardDetailsSection>{" "}
    </BaseContentCard>
  );
}

import React from "react";
import { useBookmarkModal } from "../../../hooks/bookmarkModal";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NotLoggedInBookmarkModa() {
  const { show, hideBookmarkNotLoggedInModal } = useBookmarkModal();
  return (
    <BaseModal show={show} onHide={hideBookmarkNotLoggedInModal}>
      <Modal.Body className="text-center">
        You are not logged in. Please <Link to="/login">login</Link>
      </Modal.Body>
    </BaseModal>
  );
}

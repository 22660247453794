import React from "react";
import ContentTable from "../../components/Base/ContentTable";
import SortByFilter from "../../components/Filters/SortBy";
import StatusFilter from "../../components/Filters/Status";
import StakeholdersFilters from "../../components/Filters/Stakeholders";
import { useSelector } from "react-redux";
import MobileLeadCard from "components/Cards/MobileLeadCard";

function ContentLeads() {
  const user = useSelector((state) => state.authReducer.user);
  const { is_brand, is_student } = user;
  const FILTER_PILL_OPTIONS = is_brand
    ? [
        { value: "Quotation", label: "Quotation" },
        { value: "Sample", label: "Sample" },
        { value: "Appointment", label: "Appointment" }
      ]
    : is_student
    ? [
        { value: null, label: "All" },
        { value: "Quotation", label: "Quotation" },
        { value: "Collaboration", label: "Collaboration" },
        { value: "Project", label: "Project" },
        { value: "Job Offers", label: "Job Offers" },
        { value: "Others", label: "Others" }
      ]
    : [
        { value: null, label: "All" },
        { value: "Quotation", label: "Quotation" },
        { value: "Collaboration", label: "Collaboration" },
        { value: "Project", label: "Project" },
        { value: "Others", label: "Others" }
      ];

  return (
    <ContentTable
      type="lead"
      title="My Leads"
      subTitle="All your fresh and past leads will apear here"
      filterPillsOptions={FILTER_PILL_OPTIONS}
      MobileCard={MobileLeadCard}
      FilterComponent={
        <>
          <StakeholdersFilters key="stakeholder-dropdown-filter" filterKey="sender__group" />
          <StatusFilter key="status-dropdown-filter" options={[{ label: "Status", value: null }]} />
          <SortByFilter key="sort-dropdown-filter" azKey="name" zaKey="-name" />
        </>
      }
    />
  );
}

export default ContentLeads;

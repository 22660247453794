import AdvertisementFactory from "apiClients/AdvertisementFactory";
import React, { useEffect, useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default function AdvertisementCarousel({ where, type = "image" }) {
  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    AdvertisementFactory.createRequest(type).list({ where }).then(setAdvertisements);
  }, []);

  return (
    <Carousel style={{ height: isMobile ? "25vh" : "60vh" }} className="w-100" controls={false}>
      {advertisements.map((el) => (
        <Carousel.Item key={`advertisement-${el.id}`} className="w-100 h-100">
          <Image src={el.image} className="w-100 h-100" style={{ objectFit: "cover" }} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

import React, { useEffect, useState } from "react";
import PersonalDetailsForm from "../../../../forms/Student/PersonalDetails";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import StudentPersonalDetailsAPI from "../../../../apiClients/Profile/Student/PersonalDetails";
import UserProfileAPIClient from "../../../../apiClients/Profile/User";
import ACTION_TYPES from "../../../../constants/action-types";
import ProfileDetailsContainer from "../../../../components/Containers/ProfileDetails";
import ContentHeader from "components/Base/ContentHeader";

function PersonalDetails({ user, updateUser }) {
  const [loading, setLoading] = useState(true);
  var defaultValues = {
    email: user.email,
    secondary_email: user.secondary_email,
    secondary_mobile: user.secondary_mobile
  };
  if (user) {
    if (user.name) {
      defaultValues["first_name"] = user.name.split(" ")[0];
      defaultValues["last_name"] = user.name.split(" ")[1];
    }
    if (user.mobile) {
      defaultValues["phone"] = user.mobile;
    }
  }

  const methods = useForm({
    reValidateMode: "onChange",
    defaultValues
  });
  const { setValue } = methods;

  const handleSetValues = (data) => {
    [
      "about",
      "country",
      "pincode",
      "address_line1",
      "instagram",
      "behance",
      "pinterest",
      "linkedin"
    ].forEach((el) => data[el] && setValue(el, data[el]));
    if (data.is_working_professional === true) {
      setValue("is_working_professional", true);
      setValue("is_student", false);
    } else {
      setValue("is_working_professional", false);
      setValue("is_student", true);
    }
    ["education_level", "city", "actively_looking_for", "field_of_studies", "state"].forEach(
      (el) =>
        data[el] &&
        setValue(el, {
          value: data[el],
          label: data[el]
        })
    );
    if (data.date_of_birth) {
      setValue("date_of_birth", new Date(data.date_of_birth));
    }
  };

  useEffect(() => {
    Promise.all([
      new StudentPersonalDetailsAPI().read(),
      new UserProfileAPIClient().getUserInfo()
    ]).then((result) => {
      handleSetValues(result[0]);
      updateUser(result[1]);
      setLoading(false);
    });
  }, []);

  return (
    <ProfileDetailsContainer loading={loading} methods={methods}>
      <ContentHeader title="Personal Details" doNotShowSubtitle />
      <PersonalDetailsForm handleSetValues={handleSetValues} />
    </ProfileDetailsContainer>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch({ type: ACTION_TYPES.AUTH.UPDATE_USER, user })
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);

import React from "react";
import BaseContainer from "../../components/Containers/BaseContainer";
import ContentHeader from "../../components/Base/ContentHeader";
import LockedContent from "../../components/Containers/LockedContent";
import SearchTable from "../../components/Base/SearchTable";
import ContentList from "../../components/Base/ContentList";
import { useSelector } from "react-redux";

export function FavouriteProducts() {
  const user = useSelector((state) => state.authReducer.user);
  return (
    <ContentList
      type="product"
      initialFilters={{ user: user.id }}
      doNotShowAdd={true}
      cardProps={{ showSelect: true }}
    />
  );
}

export default function ContentProducts() {
  const user = useSelector((state) => state.authReducer.user);
  return (
    <BaseContainer className="py-4">
      <ContentHeader title="Products" subTitle="Display your products for sale here" />
      <LockedContent>
        <div className="mt-3">
          <SearchTable type="product" initialFilters={{ user: user.id }} />
        </div>
      </LockedContent>
    </BaseContainer>
  );
}

import ResumeFormContainer from "components/Containers/ResumeForm";
import Select from "components/Inputs/Select";
import COLORS from "constants/colors";
import LANGUAGES from "constants/languages";
import SKILLS from "constants/skills";
import React from "react";
import { Form, Stack } from "react-bootstrap";

export default function SkillsHobbiesLanguagesSection() {
  return (
    <ResumeFormContainer>
      <Stack gap={4} className="mt-3">
        {[
          {
            name: "technical_skills",
            label: "Technical Skills",
            options: SKILLS.TECHNICAL_SKILLS,
            inputLabel: "Add Skills"
          },
          {
            name: "management_skills",
            label: "Management Skills",
            options: SKILLS.MANAGEMENT_SKILLS,
            inputLabel: "Add Skills"
          },
          {
            name: "personal_skills",
            label: "Personal Skills",
            options: SKILLS.PERSONAL_SKILLS,
            inputLabel: "Add Skills"
          },
          {
            name: "languages",
            label: "Languages",
            options: LANGUAGES,
            inputLabel: "Add Langauges"
          },
          {
            name: "hobbies",
            label: "Hobbies",
            options: [],
            createable: true,
            inputLabel: "Add Hobbies"
          }
        ].map((el) => (
          <Form.Group key={el.label}>
            <Form.Label>
              <span className="fw-bold">{el.label}</span>
              {el.maxLength && (
                <span className="text-muted fst-italic ms-2">(Max {el.maxLength})</span>
              )}
            </Form.Label>
            <div
              style={{ border: `1px ${COLORS.GREY2} dashed`, width: "max-content" }}
              className="w-100 p-3"
            >
              <Form.Text className="text-muted">{el.inputLabel}</Form.Text>
              <Select
                className="w-100 mt-2"
                name={el.name}
                isMulti
                isSearchable
                isCreateable={el.createable}
                options={el.options}
                required={false}
                minLength={0}
              />
            </div>
          </Form.Group>
        ))}
      </Stack>
    </ResumeFormContainer>
  );
}

const JOB_CONSTANTS = {
  EMPLOYMENT_TYPES: [
    "Full Time",
    "Contract",
    "Part Time",
    "Freelancer",
    "Intern",
    "Volunteer",
    "Freelancing"
  ]
    .sort()
    .map((type) => ({ value: type, label: type })),
  WORKPLACE_TYPES: ["On-Site", "Hybrid", "Work From Home/Remote"].sort().map((arrangement) => ({
    value: arrangement,
    label: arrangement
  })),
  MINIMUM_EXPERIENCE_REQUIRED: [
    "0-2 years",
    "2.1-4 years",
    "4.1-6 years",
    "6.1-12 years",
    "More than 12 years"
  ]
    .sort()
    .map((level) => ({ value: level, label: level })),
  EXPERIENCE_LEVEL: [
    "Entry Level/Intermediate",
    "Intermediate",
    "Mid Level",
    "Mid Level/Senior/Executive",
    "Senior/Executive"
  ]
    .sort()
    .map((level) => ({
      value: level,
      label: level
    }))
};

export default JOB_CONSTANTS;

import React, { useState } from "react";
import BaseModal from "../Base";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import UserProfileAPIClient from "../../../apiClients/Profile/User";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "../../../forms/ForgotPassword";

export default function ForgotPasswordModal({ show, onHide }) {
  const methods = useForm({ reValidateMode: "onChange" });
  const [showVerification, setShowVerification] = useState(undefined);
  const [usePhone, setUsePhone] = useState(false);

  const onForgotPasswordSubmit = (data) => {
    const userClient = new UserProfileAPIClient();

    toast.promise(userClient.sendOTP(data.email || data.phone, true), {
      pending: "Sending verification code",
      success: "Verification code sent succssfully",
      error: {
        render: function ({ data }) {
          return data.response.data.detail || data.response.data.error;
        }
      }
    });
    setShowVerification(data.email || data.phone);
  };
  return (
    <BaseModal show={show} onHide={onHide}>
      <Container className="p-4">
        <Row className="justify-content-between">
          <Col xs="auto">
            <h5>Forgot Password</h5>
          </Col>
          {showVerification === undefined && (
            <Col xs="auto">
              <Link onClick={() => setUsePhone(!usePhone)}>
                Use {usePhone === true ? "Email" : "Phone Number"}
              </Link>
            </Col>
          )}
        </Row>
        <div className="mb-4">
          {showVerification === undefined && (
            <Form.Text muted>
              Please input your registered {usePhone === true ? "phone number" : "email id"} below.
            </Form.Text>
          )}
        </div>
        <FormProvider {...methods}>
          <ForgotPasswordForm
            usePhone={usePhone}
            onSubmit={onForgotPasswordSubmit}
            showVerification={showVerification}
          />
        </FormProvider>
      </Container>
    </BaseModal>
  );
}

import React, { useEffect, useState } from "react";
import DateParser from "../../../date";
import { Button, Image, Stack } from "react-bootstrap";
import { isArray, isEmpty } from "lodash";
import COLORS from "../../../constants/colors";
import ShareButton from "../../Buttons/Share";
import FavouriteButton from "../../Buttons/Favourite";
import ApplyJobModal from "../../Modals/ApplyJob";
import LoadingContainer from "../../Containers/Loading";
import ContentFactory from "../../../apiClients/ContentFactory";
import { useSelector } from "react-redux";
import Location from "../../Location";
import VACANCIES from "../../../assets/icons/vacancies.png";
import BRIEFCASE from "../../../assets/icons/briefcase.png";
import RUPPEE from "../../../assets/icons/ruppee.png";
import { useNavigationInfo } from "../../../hooks";
import MINIMUM_PROFILE_COMPLETION from "../../../constants/profile-completion";
import TimeAgo from "timeago-react";
import { formatPrice } from "utils";
import BackButton from "components/Buttons/Back";
import { isMobile } from "react-device-detect";

function ListJobSkills({ title, list }) {
  return (
    <div>
      <h6>{title}</h6>
      <Stack direction="horizontal" gap={2} style={{ flexWrap: "wrap" }}>
        {list.map((el) => (
          <p
            key={el}
            className="mb-0 px-3 py-1 rounded"
            style={{ fontSize: "85%", backgroundColor: COLORS.GREY1, whiteSpace: "nowrap" }}
          >
            {el}
          </p>
        ))}
      </Stack>
    </div>
  );
}

export default function JobDetails({ id, handleGetSearch }) {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const [job, setJob] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [applyJob, setApplyJob] = useState(false);
  const user = useSelector((state) => state.authReducer.user);
  const { isAppliedJobDescription } = useNavigationInfo();

  const client = ContentFactory.createRequest("job");

  const handleGetJob = async () => client.read(id).then(setJob);

  const onSuccess = () => Promise.all([handleGetSearch(), handleGetJob()]);

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
    }
    handleGetJob().then(() => setLoading(false));
  }, [id]);
  return (
    <LoadingContainer loading={loading}>
      {isMobile && (
        <div className="ps-2 pb-3 d-flex justify-content-between">
          <BackButton />
          {!isAppliedJobDescription && (
            <Stack direction="horizontal" gap={3}>
              {isLoggedIn && <FavouriteButton obj={job} type="job" onSuccess={onSuccess} />}
              <ShareButton />
            </Stack>
          )}
        </div>
      )}

      {job && (
        <div className="border rounded p-4">
          <Stack direction="horizontal" className="justify-content-between">
            <h5 className="fw-bold">{job.title}</h5>
            {!isAppliedJobDescription && !isMobile && (
              <Stack direction="horizontal" gap={3}>
                {isLoggedIn && <FavouriteButton obj={job} type="job" onSuccess={onSuccess} />}
                <ShareButton />
              </Stack>
            )}
          </Stack>
          <p className="text-secondary mb-1">
            {job.user.name}, {job.category}
          </p>
          <p className="text-secondary">
            {job.position} <span className="fst-italic">({job.workplace_type})</span>
          </p>
          <Location>{job.location || ""}</Location>
          <div className="d-flex align-items-center mt-2">
            <Image
              src={VACANCIES}
              style={{ width: 20, height: 20 }}
              className="me-2 object-fit-contain"
            />
            {job.vacancies}
          </div>
          <div className="d-flex align-items-center mt-2">
            <Image
              src={BRIEFCASE}
              style={{ width: 20, height: 20 }}
              className="me-2 object-fit-contain"
            />
            {job.employment_type}
          </div>
          <div className="d-flex align-items-center mt-2 mb-4">
            <Image
              src={RUPPEE}
              style={{ width: 20, height: 20 }}
              className="me-2 object-fit-contain"
            />
            {job.fixed_amount
              ? formatPrice(job.fixed_amount)
              : job.salary_min_amount && job.salary_max_amount
              ? `${formatPrice(job.salary_min_amount)} - ${formatPrice(job.salary_max_amount)}`
              : "Not Disclosed"}
          </div>
          <p className="mt-1">
            <span className="fw-bold">Apply Before:</span>{" "}
            {DateParser.formatDateToDDMMMYYYY(job.valid_till)}
          </p>
          <p className="mb-0 mt-2">{job.no_of_applicants} Applicants</p>
          <p className="fw-bold mt-2">
            Posted <TimeAgo datetime={job.created} />
          </p>
          {!isLoggedIn ||
          (user.is_student &&
            user.profile_completion_percentage > MINIMUM_PROFILE_COMPLETION.STUDENT &&
            job.has_applied === false) ? (
            <Button className="px-5 mb-3 mt-2" onClick={() => setApplyJob(true)}>
              APPLY ON ML
            </Button>
          ) : user.is_student &&
            user.profile_completion_percentage > MINIMUM_PROFILE_COMPLETION.STUDENT &&
            job.has_applied === true ? (
            <Button
              disabled
              className="px-5"
              style={{
                backgroundColor: COLORS.PRIMARY_ORANGE,
                color: "white",
                borderColor: COLORS.PRIMARY_ORANGE
              }}
            >
              APPLIED
            </Button>
          ) : null}
          <h6 className="mt-3">Job Description</h6>
          <p dangerouslySetInnerHTML={{ __html: job.description }} />
          <p className="mt-2">
            <span className="me-2" style={{ fontWeight: "600" }}>
              Minimum Experience Required{" "}
            </span>
            {job.min_experience_required}
          </p>
          <Stack gap={4} className="mt-3">
            {isArray(job.core_competencies) && !isEmpty(job.core_competencies) && (
              <ListJobSkills title="Core Competencies" list={job.core_competencies} />
            )}
            {isArray(job.personal_skills) && !isEmpty(job.personal_skills) && (
              <ListJobSkills title="Personal Skills" list={job.personal_skills} />
            )}
            {isArray(job.technical_skills) && !isEmpty(job.technical_skills) && (
              <ListJobSkills title="Technical Skills" list={job.technical_skills} />
            )}
            {isArray(job.management_skills) && !isEmpty(job.management_skills) && (
              <ListJobSkills title="Management Skills" list={job.management_skills} />
            )}
          </Stack>
          <ApplyJobModal
            show={applyJob}
            onHide={() => setApplyJob(false)}
            job={job}
            onSuccess={() => handleGetJob().then(() => setApplyJob(false))}
          />
        </div>
      )}
    </LoadingContainer>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import withSidebar from "../../components/Containers/Sidebar";
import STUDENT_ACTIVITY_ROUTES from "./Student";
import STUDIO_ACTIVITY_ROUTES from "./Studio";
import MyActivity from "../../screens/MyActivity";
import { getRoutes } from "../utils";
import BRAND_ACTIVITY_ROUTES from "./Brand";
import LoadingContainer from "components/Containers/Loading";

const ActivityRoutes = () => {
  const user = useSelector((state) => state.authReducer.user);
  const isLoadingIdeationBoards = useSelector(
    (state) => state.contentReducer.isLoadingIdeationBoards
  );

  const renderRoutes = () => {
    if (user.is_student) {
      return getRoutes(STUDENT_ACTIVITY_ROUTES);
    } else if (user.is_studio) {
      return getRoutes(STUDIO_ACTIVITY_ROUTES);
    } else {
      return getRoutes(BRAND_ACTIVITY_ROUTES);
    }
  };

  return (
    <LoadingContainer loading={isLoadingIdeationBoards}>
      <Routes>
        <Route path="" element={<MyActivity />} />
        {renderRoutes()}
      </Routes>
    </LoadingContainer>
  );
};

export default withSidebar(ActivityRoutes);

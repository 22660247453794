import { call, put } from "@redux-saga/core/effects";
import ACTION_TYPES from "../../../constants/action-types";
import { getPins } from "./services";

export function* getPinnedBlogs() {
  try {
    const result = yield call(getPins);
    yield put({ type: ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_SUCCESS, result });
  } catch (error) {
    console.error(error);
    yield put({ type: ACTION_TYPES.CONTENT.BLOGS.GET_PINNED_BLOGS_FAILURE, error });
  }
}

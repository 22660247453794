import React, { memo } from "react";
import COLORS from "../../../constants/colors";
import { BsPlus } from "react-icons/bs";
import ACCEPTED_FILE_TYPES from "../../../constants/accepted-file-types";
import { useDropzone } from "react-dropzone";
import { useController, useFormContext } from "react-hook-form";
import { isEmpty, isUndefined } from "lodash";
import Avatar from "react-avatar";
import VALIDATORS from "../../../validators";
import FormError from "../../FormError";
import { getContentAbsoluteUrl, isBase64 } from "../../../utils";

function AvatarBasedImageUpload({ name, required, width = 105, height = 105 }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const {
    field: { value, onChange }
  } = useController({ control, name, rules: required === true ? VALIDATORS.required : {} });

  const handleFileUpload = (obj) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(obj);
    });

  const onDrop = async (acceptedFiles) => {
    var files = [];
    for (var i = 0; i < acceptedFiles.length; i++) {
      const obj = await handleFileUpload(acceptedFiles[i]);
      files.push(obj);
    }
    onChange(files);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES.image,
    maxSize: 1 * 1024 * 1024
  });

  return (
    <div
      {...getRootProps()}
      className={`mt-4 cursor-pointer drop-box ${isDragActive ? "drag-active" : ""}`}
    >
      <input {...getInputProps()} />

      {!isUndefined(value) && !isEmpty(value) ? (
        <div
          style={{
            width,
            height
          }}
        >
          <Avatar src={isBase64(value[0]) ? value[0] : getContentAbsoluteUrl(value)} round />
        </div>
      ) : (
        <div
          style={{
            width,
            height,
            backgroundColor: COLORS.GREY1,
            border: "1px grey dashed"
          }}
          className="rounded-circle d-flex justify-content-center align-items-center"
        >
          <BsPlus size={50} color={COLORS.GREY2} />
        </div>
      )}
      {errors && errors[name] && errors[name].message && (
        <FormError>{errors[name].message}</FormError>
      )}
    </div>
  );
}

export default memo(AvatarBasedImageUpload);

import React from "react";
import Dropdown from "../../Dropdown";
import { useSearchParams } from "../../../hooks/searchParams";

export default function StatusFilter({ options }) {
  const { searchParamsAsObject, addKey } = useSearchParams();
  const { status } = searchParamsAsObject;
  const STATE_OPTIONS = [
    { label: "Pending", value: "Pending" },
    { label: "Resolved", value: "Resolved" },
    { label: "Cancelled", value: "Cancelled" }
  ];
  return (
    <Dropdown
      value={status}
      options={[...options, ...STATE_OPTIONS]}
      label="Status"
      onClick={(value) => addKey("status", value)}
    />
  );
}

import Dropdown from "components/Dropdown";
import { COMPANY_TYPES } from "constants/Profile/Studio";
import { useSearchParams } from "hooks/searchParams";
import React from "react";

export default function StudioCompanyTypeFilters() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "company_type";

  const value = getKey(KEY);
  return (
    <Dropdown
      value={value}
      options={COMPANY_TYPES}
      label="Company Type"
      onClick={(value) => addKey(KEY, value)}
      menuStyle={{ maxHeight: "15rem", overflow: "scroll" }}
    />
  );
}

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { isArray, isEmpty, isNil, isObject, isUndefined } from "lodash";
import ProductDetail from "./Detail";
import ContentFactory from "apiClients/ContentFactory";
import { useScrollToTop } from "hooks";
import CategoryCard from "components/Cards/Category";
import LoadingContainer from "components/Containers/Loading";
import { useSearchParams } from "hooks/searchParams";
import ProductsContainer from "./Container";
import { useSelector } from "react-redux";
import ProductList from "./List";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function Products({ showSubCategoriesOrProducts }) {
  const { categoryId, subCategoryId, subSubCategoryId, productSlug } = useParams();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const categoryClient = ContentFactory.createRequest("categories");
  const productClient = ContentFactory.createRequest("product");
  const [listings, setListings] = useState([]);
  const lookupKey = "slug";
  const [category, setCategory] = useState(undefined);
  const [detail, setDetail] = useState(undefined);
  const { scrollToTop } = useScrollToTop();
  const isLoadingFilters = useSelector((state) => state.productsReducer.isLoadingFilters);

  const showProducts =
    isUndefined(productSlug) &&
    !isUndefined(categoryId) &&
    isObject(category) &&
    (!isArray(category.children) || isEmpty(category.children));

  const showProduct = !isUndefined(detail) || productSlug;

  const getCategory = () => {
    var cat;
    if (categoryId) {
      cat = categories.find((el) => el[lookupKey] === categoryId);
    }
    if (categoryId && isObject(cat) && subCategoryId) {
      if (isArray(cat.children) && !isEmpty(cat.children)) {
        cat = cat.children.find((el) => el[lookupKey] === subCategoryId);
      }
    }
    if (subCategoryId && isObject(cat) && subSubCategoryId) {
      if (isArray(cat.children) && !isEmpty(cat.children)) {
        cat = cat.children.find((el) => el[lookupKey] === subSubCategoryId);
      } else {
        return null;
      }
    }
    return cat;
  };

  const handleGetProduct = () => {
    const id = productSlug || subSubCategoryId || subCategoryId || categoryId;
    if (id) {
      return productClient
        .read(id)
        .then(setDetail)
        .then(() => setLoading(false));
    }
  };

  useEffect(() => {
    scrollToTop();
    setLoading(false);
  }, [listings]);

  const getListings = async () => {
    setLoading(true);
    if (showProduct) {
      handleGetProduct();
    } else {
      setLoading(false);
    }
  };

  const { searchParamsAsObject, queryString } = useSearchParams();

  useEffect(() => {
    if (isObject(category)) {
      if (isArray(category.children) && !isEmpty(category.children)) {
        setListings(category.children);
        if (loading === true) {
          setLoading(false);
        }
      }
    }
  }, [category]);

  useEffect(() => {
    if (isArray(categories)) {
      if (loading === false) {
        setLoading(true);
      }
      const cat = getCategory();
      setCategory(cat);

      if (isNil(cat)) {
        handleGetProduct();
      } else {
        if (!isNil(detail)) {
          setDetail(undefined);
        }
      }
    }
  }, [
    queryString,
    categories,
    showSubCategoriesOrProducts,
    showProducts,
    categoryId,
    subCategoryId,
    subSubCategoryId
  ]);

  const getCategories = () => categoryClient.list({ ...searchParamsAsObject }).then(setCategories);

  useEffect(() => {
    getListings();
  }, [categories, productSlug]);

  useEffect(() => {
    getCategories();
  }, [queryString]);

  return showProducts ? (
    <div className="w-100">
      <ProductList initialFilters={{ category: subSubCategoryId || subCategoryId || categoryId }} />
    </div>
  ) : (
    <div className="w-100">
      <ProductsContainer showBrandsWithProduct>
        <LoadingContainer loading={loading || (showProduct && !detail) || isLoadingFilters}>
          <Container className="pb-5 px-0">
            {!showProduct && <h6 className="py-4 mb-0"> Filter By Categories</h6>}
            {showProduct ? (
              <ProductDetail detail={detail} onAction={getListings} />
            ) : (
              <Row sm={5} xs={2}>
                {listings.map((el) => (
                  <Col key={el.id} className="mb-4">
                    <CategoryCard obj={el} />
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </LoadingContainer>
      </ProductsContainer>
    </div>
  );
}

export default withMobileOnlySidebar(Products);

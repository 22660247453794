import React, { useState } from "react";
import BaseContainer from "../components/Containers/BaseContainer";
import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import ReactHookUpload from "../components/Inputs/ReactHookUpload";
import { copyToClipboard, createFormData, getContentAbsoluteUrl } from "../utils";
import { ReactSVG } from "react-svg";
import { COPY_ICON } from "../constants/icons";
import Api from "../apiClients/api";

export default function GetImageURL() {
  const [url, setUrl] = useState(undefined);
  const methods = useForm({ reValidateMode: "onChange" });
  const { handleSubmit, reset } = methods;
  const onSubmit = (data) => {
    Api.post("content/product-images/", createFormData(data)).then((result) => {
      setUrl(getContentAbsoluteUrl(result.data.image_url));
    });
  };
  return (
    <BaseContainer>
      <div className="p-4 w-50">
        <h4 className="fw-bold">Get Image URL</h4>
        <p className="mb-1">
          If you want to add Images within your CSV File, copy the generated URL and paste it in the
          image field of CSV file.
        </p>
        <Form.Text muted>Upload one image at a time.</Form.Text>
        <div className="mt-4">
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ReactHookUpload name="image" type="image" required />
              {url ? (
                <div>
                  <h6>Generated URL</h6>
                  <div
                    onClick={() => copyToClipboard(url)}
                    className="d-flex align-items-center border rounded cursor-pointer"
                  >
                    <p className="mb-0 px-3 py-2" style={{ width: "90%", wordBreak: "break-word" }}>
                      {url}
                    </p>
                    <div
                      style={{ width: "10%", borderLeft: "1px solid #dee2e6" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <ReactSVG src={COPY_ICON} className="px-4" />
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      reset();
                      setUrl(undefined);
                    }}
                    className="px-5 mt-4"
                  >
                    Reset
                  </Button>
                </div>
              ) : (
                <Button type="submit">Generate URL</Button>
              )}
            </Form>
          </FormProvider>
        </div>
      </div>
    </BaseContainer>
  );
}

import ContentFactory from "apiClients/ContentFactory";
import DropdownFactory from "apiClients/DropdownFactory";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import Select from "components/Inputs/Select";
import { useSearchParams } from "hooks/searchParams";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { renderErrorToast, showPromiseToast, convertDataUrlToBase64 } from "utils";

export default function AddCatalogueForm({ editObj, onSuccess }) {
  const { reset, handleSubmit, setValue } = useFormContext();
  const client = ContentFactory.createRequest("catalogue");
  const { getKey } = useSearchParams();
  const type = getKey("type");

  useEffect(() => {
    if (editObj) {
      convertDataUrlToBase64(editObj.thumbnail).then((thumbnail) =>
        setValue("thumbnail", [thumbnail])
      );
      convertDataUrlToBase64(editObj.file).then((file) => setValue("file", [file]));
      setValue("category", { label: editObj.category, value: editObj.category });
      setValue("title", editObj.title);
    }
  }, [editObj]);

  const onSubmit = (data) => {
    const promise = (
      editObj
        ? client.update({ ...data, type, category: data.category.value, id: editObj.id })
        : client.create({ ...data, category: data.category.value, type })
    )
      .then(onSuccess)
      .then(reset);

    showPromiseToast(promise, {
      pending: "Saving catalogue",
      success: "Catalogue saved successfully",
      error: renderErrorToast()
    });
  };

  const loadCategories = (search) =>
    DropdownFactory.createRequest("product-categories").list({ search });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <Row>
        <h6>Upload Thumbnail</h6>
        <Col>
          <ReactHookUpload
            type="image"
            required
            name="thumbnail"
            maxSizeMB={20}
            preferredText="Preferred Resolution: 400 X 330"
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Catalogue Name*</Form.Label>
            <ReactHookInput name="title" required />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Category*</Form.Label>
            <Select name="category" required loadOptions={loadCategories} isAsync />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="pb-5 mt-3">
        <h6>Upload Catalogue</h6>
        <ReactHookUpload type="pdf" required name="file" maxSizeMB={50} />
      </Form.Group>
      <Button className="w-100" type="submit">
        UPLOAD FILE
      </Button>
    </Form>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Stack, Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { isObject } from "lodash";
import { convertDataUrlToBase64 } from "utils";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import THESIS_CONSTANTS from "constants/Thesis";
import ContentFactory from "apiClients/ContentFactory";
import { renderErrorToast } from "utils";
import { showPromiseToast } from "utils";
import FormFooter from "forms/Footer";
import { isMobile } from "react-device-detect";

const AddThesisForm = ({ onSuccess, editObj }) => {
  const [part, setPart] = useState(1);
  const { handleSubmit, setValue, reset } = useFormContext();
  const parts = ["Basic Details", "Document"];

  useEffect(() => {
    if (isObject(editObj)) {
      convertDataUrlToBase64(editObj.thumbnail).then((image) => setValue("thumbnail", [image]));
      convertDataUrlToBase64(editObj.file).then((file) => setValue("file", [file]));
      ["title", "description", "college"].forEach((el) => setValue(el, editObj[el]));

      ["category", "degree_level", "source_type"].forEach((el) =>
        setValue(el, { label: editObj[el], value: editObj[el] })
      );
    }
  }, []);

  const FormPartTwo = (
    <Form.Group className="mb-4">
      <Form.Label>Add your Thesis/Disseration file*</Form.Label>
      <div className="overflow-scroll">
        <ReactHookUpload name="file" type="pdf" required maxSizeMB={25} />
      </div>
    </Form.Group>
  );

  const FormPartOne = (
    <Row xs={1} sm={2}>
      <Col sm={5}>
        <Form.Group>
          <Form.Label>Upload Thumbnail*</Form.Label>
          <ReactHookUpload
            name="thumbnail"
            required
            type="image"
            maxSizeMB={1}
            preferredText="Preferred Resolution: 400 X 330"
          />
        </Form.Group>
      </Col>
      <Col sm={7}>
        <Stack gap={3}>
          <Form.Group>
            <Form.Label>Title*</Form.Label>
            <ReactHookInput name="title" required type="text" />
          </Form.Group>
          <Row xs={1}>
            <Col>
              <Form.Group>
                <Form.Label>College*</Form.Label>
                <ReactHookInput name="college" required type="text" />
              </Form.Group>
            </Col>
            <Col className={isMobile ? "mt-3" : ""}>
              <Form.Group>
                <Form.Label>Degree Level*</Form.Label>
                <Select name="degree_level" options={THESIS_CONSTANTS.DEGREE_LEVELS} />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Source Type*</Form.Label>
            <Select options={THESIS_CONSTANTS.SOURCE_TYPE} name="source_type" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Category*</Form.Label>
            <Select options={THESIS_CONSTANTS.THESIS_CATEGORIES} name="category" />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Brief Description <span className="fst-italic fw-light">Optional</span>
            </Form.Label>
            <ReactHookInput as="textarea" name="description" />
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  );

  const handleFormOneSubmit = () => setPart(part + 1);

  const handleFormTwoSubmit = async (data) => {
    const payload = {
      ...data,
      category: data.category.value,
      degree_level: data.degree_level.value,
      source_type: data.source_type.value
    };

    const client = ContentFactory.createRequest("thesis");

    const promise = (
      editObj ? client.update({ ...payload, id: editObj.slug }) : client.create(payload)
    )
      .then(onSuccess)
      .then(reset);

    showPromiseToast(promise, {
      pending: `${editObj ? "Updating" : "Creating"} thesis`,
      success: `Thesis ${editObj ? "updated" : "created"} successfully`,
      error: renderErrorToast()
    });
  };

  return (
    <Form onSubmit={handleSubmit(part === 1 ? handleFormOneSubmit : handleFormTwoSubmit)}>
      {part === 1 ? FormPartOne : FormPartTwo}
      <FormFooter part={part} setPart={setPart} parts={parts} />
    </Form>
  );
};

export default AddThesisForm;

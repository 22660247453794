import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Button, CloseButton, Col, Form, Image, Row, Stack } from "react-bootstrap";
import DropdownCheckbox, { DropDownCheckboxMenuItem } from "../../Inputs/DropdownCheckbox";
import { useNavigationInfo } from "../../../hooks/route";
import { Link, useNavigate } from "react-router-dom";
import { STATES } from "../../../constants/states";
import { useSearchParams } from "../../../hooks/searchParams";
import JOB_CONSTANTS from "../../../constants/Jobs";
import { MoreFiltersDropdown } from "../MoreFilters";
import SKILLS from "../../../constants/skills";
import ContentFactory from "../../../apiClients/ContentFactory";
import DropdownFactory from "apiClients/DropdownFactory";
import { isMobile } from "react-device-detect";
import Sheet from "react-modal-sheet";
import FILTER_ICON from "assets/icons/filter.png";
import BottomSheet from "components/Modals/BottomSheet";

export default function JobFilters({ filters, setFilters }) {
  const [isOpen, setOpen] = useState(false);
  const loadCategories = () =>
    Promise.all([
      DropdownFactory.createRequest("job-categories").list(),
      DropdownFactory.createRequest("job-positions").list()
    ]);

  const [categories, setCategories] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const { isJobsSearch, isJobs } = useNavigationInfo();
  const {
    location,
    workplace_type,
    experience_level,
    job_type,
    designation,
    technical_skills,
    management_skills,
    personal_skills,
    desired_salary,
    category,
    company,
    date_posted
  } = filters;
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { setQueryFromObj, resetQuery, toQuery } = useSearchParams();
  const [filterOptions, setFilterOptions] = useState({});

  const handleSetFilters = (name, value) => {
    if (!value && name in Object.keys(filters)) {
      delete filters[name];
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleItemClick = (name, value, newValue) => {
    handleSetFilters(
      name,
      value.includes(newValue) ? value.filter((el) => el !== newValue) : [...value, newValue]
    );
  };
  useEffect(() => {
    loadCategories().then((result) => {
      setCategories(result[0]);
      setJobPositions(result[1]);
    });
  }, []);

  useEffect(() => {
    ContentFactory.createRequest("job").filters().then(setFilterOptions);
  }, []);

  const desiredSalaryFilter = useMemo(
    () =>
      [
        "<3 LPA",
        "3 LPA - 6 LPA",
        "6 LPA - 10 LPA",
        "10 LPA - 15 LPA",
        "15 LPA - 20 LPA",
        "20> LPA"
      ].map((el) => ({ value: el, label: el })),
    []
  );

  const handleSearch = () => {
    setQueryFromObj({ ...filters, search });
    if (isOpen) {
      setOpen(false);
    }
    if (!isJobsSearch) {
      navigate(`/jobs/seeker/search/?${toQuery(filters)}`);
    }
  };

  const renderOptions = (title, options, name, value) => (
    <Col className={`px-1 ${isMobile ? "py-2" : "py-0"}`}>
      <DropdownCheckbox
        title={title}
        name={name}
        options={options}
        setFilter={handleSetFilters}
        value={value}
      />
    </Col>
  );

  const filterOptionsArray = [
    { title: "Designation", options: jobPositions, name: "designation", value: designation },
    {
      title: "Job Type",
      options: JOB_CONSTANTS.EMPLOYMENT_TYPES,
      name: "job_type",
      value: job_type
    },
    {
      title: "Company",
      options: (filterOptions.companies || []).map((el) => ({ value: el, label: el })),
      name: "company",
      value: company
    },
    {
      title: "Experience Level",
      options: JOB_CONSTANTS.EXPERIENCE_LEVEL,
      name: "experience_level",
      value: experience_level
    },
    {
      title: "On-Site/Remote",
      options: JOB_CONSTANTS.WORKPLACE_TYPES,
      name: "workplace_type",
      value: workplace_type
    },
    {
      title: "Date Posted",
      options: ["Anytime", "Past 24 Hours", "Past Week", "Past Month"].map((el) => ({
        value: el,
        label: el
      })),
      name: "date_posted",
      value: date_posted
    }
  ];

  const renderFilterOption = (title, options, name, value) => (
    <Col className="px-1 mb-3">
      <DropdownCheckbox
        title={title}
        name={name}
        options={options}
        setFilter={handleSetFilters}
        value={value}
      />
    </Col>
  );

  const AccordionFilter = ({ item, eventKey }) => {
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <span className="fw-bold">{item.title}</span>
        </Accordion.Header>
        <Accordion.Body className="px-0 pb-0 pt-2">
          {item.options.map((el, itemIndex) => (
            <DropDownCheckboxMenuItem
              key={`${itemIndex + el.label}-dropdown-menu-item`}
              item={el}
              handleItemClick={() => handleItemClick(item.name, item.value || [], el.value)}
              checked={(item.value || []).includes(el.value)}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const moreFilters = [
    {
      title: "Job Category",
      name: "category",
      options: categories,
      value: category
    },
    {
      title: "Desired Salary",
      name: "desired_salary",
      options: desiredSalaryFilter,
      value: desired_salary
    },
    {
      title: "Technical Skills",
      name: "technical_skills",
      options: SKILLS.TECHNICAL_SKILLS,
      value: technical_skills
    },
    {
      title: "Management Skills",
      name: "management_skills",
      options: SKILLS.MANAGEMENT_SKILLS,
      value: management_skills
    },
    {
      title: "Personal Skills",
      name: "personal_skills",
      options: SKILLS.PERSONAL_SKILLS,
      value: personal_skills
    }
  ];

  const FiltersInAccordion = ({ list }) => {
    return (
      <Accordion defaultActiveKey="0">
        {list.map((item, index) => (
          <AccordionFilter
            key={`${index + item.title}-accordion-item`}
            item={item}
            eventKey={index}
          />
        ))}
      </Accordion>
    );
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setFilters({});
    resetQuery();
  };

  const MoreFilters = () => {
    return (
      <>
        <Col className="d-flex justify-content-end">
          <Link className="me-3" onClick={clearFilters}>
            Clear All
          </Link>
          <MoreFiltersDropdown>
            <FiltersInAccordion list={moreFilters} />
          </MoreFiltersDropdown>
        </Col>
      </>
    );
  };

  return (
    <Stack className={`rounded shadow p-${isMobile ? 3 : 5} bg-white`} gap={4}>
      <Row xs={1} sm={isJobsSearch ? 3 : 2}>
        <Col className="px-1">
          <Form.Group>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Job Title, Skill, Company Name"
            />
          </Form.Group>
        </Col>
        {(!isMobile || !isJobsSearch) &&
          [{ title: "Job Location", options: STATES, name: "location", value: location }].map(
            (option) => renderOptions(option.title, option.options, option.name, option.value)
          )}
        {isJobsSearch && !isMobile && (
          <Col>
            <Button className="w-100" onClick={handleSearch}>
              SEARCH
            </Button>
          </Col>
        )}
      </Row>
      {(!isMobile || !isJobsSearch) && (
        <div>
          <Row className="mb-1 pe-0">
            <Col>
              <h6>Add Filters</h6>
            </Col>
            {isJobsSearch && <MoreFilters />}
          </Row>
          <Row xs={2} sm={6}>
            {filterOptionsArray.map((option) =>
              renderFilterOption(option.title, option.options, option.name, option.value)
            )}
          </Row>
        </div>
      )}

      {isMobile && isJobs && isJobsSearch && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div
              onClick={() => setOpen(true)}
              className="d-flex justify-content-between align-items-center border border-dark rounded px-4 py-2"
            >
              <Image src={FILTER_ICON} style={{ width: "1rem", objectFit: "contain" }} />
              <h6 className="ms-2 mb-0">FILTERS</h6>
            </div>
            <Link onClick={clearFilters}>Clear All</Link>
          </div>
          <BottomSheet open={isOpen} close={() => setOpen(false)}>
            <div className="mx-3 mb-3 d-flex justify-content-between cursor-pointer">
              <h5 className="fw-bold">Filters</h5>
              <CloseButton onClick={() => setOpen(false)} />
            </div>
            <Sheet.Scroller>
              <FiltersInAccordion list={[...filterOptionsArray, ...moreFilters]} />
            </Sheet.Scroller>
          </BottomSheet>
        </>
      )}
      {(!isJobsSearch || isMobile) && (
        <Button
          className="px-5 mx-auto"
          style={{ width: isMobile ? "100%" : "max-content" }}
          onClick={handleSearch}
        >
          SEARCH
        </Button>
      )}
    </Stack>
  );
}

import ContentFactory from "apiClients/ContentFactory";
import ProfileImage from "components/ProfileImage";
import { isArray, isEmpty, isFunction } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

export default function BrandsWithProducts({ page_size = 5, setLoading, infiniteScroll = false }) {
  const [brands, setBrands] = useState([]);
  const client = ContentFactory.createRequest("profile");
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);

  const fetchData = async (onSuccess, fetchPage, pageSize) => {
    return await client()
      .list({
        page: fetchPage || nextPage || page,
        page_size: pageSize || page_size
      })
      .then(onSuccess)

      .catch((error) => console.error("Error fetching search results:", error));
  };

  const next = () =>
    fetchData((result) => {
      setPage(nextPage);
      setHasMore(result.has_next);
      setNextPage(result.next_page);
      setBrands((prevResults) => [...prevResults, ...result.result]);
    });

  useEffect(() => {
    client
      .list({
        group: "brand",
        has_products: true,
        do_not_exclude_me: true,
        page_size,
        page: page_size ? 1 : undefined
      })
      .then((result) => {
        setBrands(page_size ? result.result : result);
        if (result.has_next && infiniteScroll) {
          setHasMore(result.has_next);
        }
      })
      .then(() => {
        if (isFunction(setLoading)) {
          setLoading(false);
        }
      });
  }, []);

  return (
    isArray(brands) &&
    !isEmpty(brands) && (
      <Container className="mt-5">
        {infiniteScroll ? (
          <InfiniteScroll
            dataLength={brands}
            next={next}
            hasMore={hasMore}
            loader={<div>Loading</div>}
          >
            <Row sm={5}>
              {isArray(brands) &&
                brands.map((el) => {
                  return (
                    <Col
                      key={el.id}
                      className="cursor-pointer mb-3"
                      onClick={() => navigate(`/products/brands/${el.slug}?brand_name=${el.name}`)}
                    >
                      <div style={{ height: "10rem", width: "10rem" }} className="m-auto">
                        <ProfileImage customUser={el} round={false} />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </InfiniteScroll>
        ) : (
          <Row sm={5}>
            {isArray(brands) &&
              brands.map((el) => {
                return (
                  <Col
                    key={el.id}
                    className="cursor-pointer mb-3"
                    onClick={() => navigate(`/products/brands/${el.slug}?brand_name=${el.name}`)}
                  >
                    <div style={{ height: "10rem", width: "10rem" }} className="m-auto">
                      <ProfileImage customUser={el} round={false} />
                    </div>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    )
  );
}

import React from "react";
import COLORS from "../../../constants/colors";
import { useSearchParams } from "../../../hooks/searchParams";
import Pill from "components/Pill";
import { isMobile } from "react-device-detect";
export default function FilterPills({ options, containerStyle = {}, name = "category" }) {
  const { searchParams, addKey } = useSearchParams();

  return (
    <div
      className="d-flex align-items-center rounded-pill justify-content-between"
      style={{
        backgroundColor: COLORS.GREY1,
        overflow: "auto",
        width: isMobile ? undefined : "max-content",
        ...containerStyle
      }}
    >
      {options.map((el) => (
        <Pill
          key={el.label}
          label={el.label}
          color={searchParams.get(name) === el.value ? "white" : COLORS.GREY2}
          backgroundColor={
            searchParams.get(name) === el.value ? COLORS.PRIMARY_ORANGE : "transparent"
          }
          onClick={() => addKey(name, el.value)}
        />
      ))}
    </div>
  );
}

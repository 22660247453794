import store from "../redux/store";
import Api, { ApiHeaders } from "./api";

class RequestsClient extends ApiHeaders {
  async createRequest(payload) {
    const {
      authReducer: { user }
    } = store.getState();
    var sender;
    if (user && user.id) {
      sender = user.id;
    }
    await Api.post(`/leads/requests/`, { ...payload, sender }, this.authenticationHeaders);
  }
}

export default RequestsClient;

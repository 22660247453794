import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

export default function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();
  return (
    <Breadcrumb separator=">">
      {breadcrumbs.slice(1).map(({ breadcrumb, key }, index) => (
        <Breadcrumb.Item
          key={key}
          active={index === breadcrumbs.length - 2}
          onClick={() => navigate(key)}
          className="text-capitalize"
        >
          {breadcrumb}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

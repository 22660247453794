import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ACTION_TYPES from "constants/action-types";
import ContentFactory from "apiClients/ContentFactory";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import PROJECT_CONSTANTS from "constants/Projects";
import Area from "components/Inputs/Area";
import { omitBy, isNil } from "lodash";
import { isMobile } from "react-device-detect";

function AddIdeationBoardForm({ getIdeationBoards, onSuccess, onCancel, edit }) {
  const { handleSubmit } = useFormContext();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const client = ContentFactory.createRequest("ideation-board");

    data = omitBy(data, isNil);
    var { project_stage, project_type } = data;
    const payload = {
      ...{ ...data, project_stage: project_stage.value, project_type: project_type.value }
    };
    const result = await (edit ? client.update({ ...payload, id: edit }) : client.create(payload));
    getIdeationBoards();
    onSuccess();
    setTimeout(() => {
      navigate(`/my-activity/ideation-board/${result.id}`);
    }, 300);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Folder Name*</Form.Label>
          <ReactHookInput name="folder_name" placeholder="Folder Name" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Project Type*</Form.Label>
          <Select options={PROJECT_CONSTANTS.TYPES} name="project_type" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Project Stage*</Form.Label>
          <Select
            options={[
              { value: "Furnishing", label: "Furnishing" },
              { value: "Fit-out", label: "Fit-out" },
              { value: "Finishing", label: "Finishing" },
              { value: "Super Structure", label: "Super Structure" },
              { value: "Structure", label: "Structure" },
              { value: "Foundation", label: "Foundation" }
            ]}
            name="project_stage"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Location*</Form.Label>
          <ReactHookInput name="location" placeholder="City, State" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Input Project Area</Form.Label>
          <Area name="input_project_area" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description*</Form.Label>
          <ReactHookInput name="description" as="textarea" required />
        </Form.Group>
        <Row direction="horizontal" className="mt-3" xs={1} sm={2}>
          <Col>
            <Button type="submit" className="w-100">
              {edit ? "UPDATE" : "CREATE"}
            </Button>
          </Col>
          <Col style={{ marginTop: isMobile ? "1rem" : null }}>
            <Button variant="outline-primary" className="w-100" onClick={onCancel}>
              CANCEL
            </Button>
          </Col>
        </Row>
      </Stack>
    </Form>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getIdeationBoards: () =>
    dispatch({ type: ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_REQUEST })
});

export default connect(null, mapDispatchToProps)(AddIdeationBoardForm);

import React from "react";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import { useController, useFormContext } from "react-hook-form";
import FormError from "../../FormError";
import VALIDATORS, { validatePhoneNumber } from "../../../validators";
import { isEmpty, isFunction, isString } from "lodash";
import { Button, Form } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import DEFAULT_STYLE from "../../../styles";

const Phone = ({
  handleVerify,
  showSecondaryPhone,
  setShowSecondaryPhone,
  name = "phone",
  stdCode = "+91",
  required = true,
  ...props
}) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();
  const { field } = useController({ name, control, rules: VALIDATORS.phone(required) });
  const value = watch(name);
  const isErrored = errors && errors[name];

  return (
    <>
      <Form.Group className="d-flex w-100">
        <InputGroup className="">
          <InputGroup.Text>{stdCode}</InputGroup.Text>
          <FormControl
            {...field}
            type="number"
            placeholder="99999 99999"
            maxLength={10}
            style={isErrored ? DEFAULT_STYLE.INPUT.ERRORED : {}}
            {...props}
          />
        </InputGroup>
        {isFunction(handleVerify) && (
          <Button
            variant="outline-primary"
            className="px-3 ms-2"
            onClick={() => handleVerify(value)}
          >
            Verify
          </Button>
        )}
        {isString(value) &&
          !isEmpty(value) &&
          validatePhoneNumber(value) &&
          showSecondaryPhone === false && (
            <Button variant="outline-secondary ms-2" onClick={() => setShowSecondaryPhone(true)}>
              <FaPlus />
            </Button>
          )}
      </Form.Group>
      {isErrored && <FormError>{errors[name].message}</FormError>}
    </>
  );
};

export default Phone;

import { Menu, MenuItem } from "@szhsin/react-menu";
import React from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";

export function ThreeDotsDropdown({ options, size, color, className }) {
  return (
    <Menu
      menuButton={
        <div className="cursor-pointer" style={{ zIndex: 1000 }}>
          <BiDotsVerticalRounded size={size} color={color} className={className} />
        </div>
      }
    >
      {options.map((el, index) => (
        <MenuItem key={index + el.label} onClick={el.onClick} disabled={el.disabled}>
          {el.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

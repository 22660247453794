import React from "react";
import { useSearchParams } from "../../../hooks/searchParams";
import Dropdown from "../../Dropdown";
import { FIELD_OF_STUDIES } from "../../../constants/Profile/Student";

export default function FieldOfStudyFilter() {
  const { addKey, getKey } = useSearchParams();
  const KEY = "field_of_study";

  const value = getKey(KEY);

  return (
    <Dropdown
      value={value}
      options={FIELD_OF_STUDIES}
      label="Field Of Study"
      onClick={(value) => addKey(KEY, value)}
    />
  );
}

import React, { createContext, useContext, useState } from "react";

const IdeationBoardModalContext = createContext();

export function useIdeationBoardModal() {
  return useContext(IdeationBoardModalContext);
}

export function IdeationBoardModalProvider({ children }) {
  const [show, setShow] = useState(undefined);
  const [onSuccess, setOnSuccess] = useState(undefined);

  const showAddToIdeationBoardModal = (obj, onSuccessFn) => {
    setShow(obj);
    setOnSuccess(() => {
      return onSuccessFn;
    });
  };

  const hideAddToIdeationBoardModal = () => {
    setShow(undefined);
    setOnSuccess(undefined);
  };

  const addedToIdeationBoardOnSuccess = () => {
    onSuccess();
  };

  const contextValue = {
    show,
    showAddToIdeationBoardModal,
    hideAddToIdeationBoardModal,
    addedToIdeationBoardOnSuccess
  };

  return (
    <IdeationBoardModalContext.Provider value={contextValue}>
      {children}
    </IdeationBoardModalContext.Provider>
  );
}

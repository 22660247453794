import React from "react";
import Modal from "react-bootstrap/esm/Modal";

export default function BaseModal({ children, size = "md", centered = true, ...props }) {
  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
    >
      {children}
    </Modal>
  );
}

import React from "react";
import { isObject } from "lodash";
import { getAncestorsFromLeafCategory } from "screens/ProfileDetails/Brand/Categories";
import { downloadFile } from "utils";
import { getContentAbsoluteUrl } from "utils";
import UploadCSVButton from "components/Buttons/UploadCSVButton";
import AppLink from "components/AppLink";

export default function AddProductForm({
  setShowCategories,
  selectedCategory,
  categories,
  onSuccess
}) {
  function openImageTab() {
    var win = window.open(`${window.location.origin}${"/get-image-url"}`, "_blank");
    win.focus();
  }
  var parent, subCategory;
  if (selectedCategory) {
    const result = getAncestorsFromLeafCategory(selectedCategory, categories);
    if (result.parent) {
      parent = result.parent;
    }
    if (result.subCategory) {
      subCategory = result.subCategory;
    }
  }

  const getSelectedCategoryTitle = () => {
    var titles = [];
    if (parent) {
      titles.push(parent.title);
    }
    if (subCategory) {
      titles.push(subCategory.title);
    }
    titles.push(selectedCategory.title);
    return titles.join(" > ");
  };

  const downloadTemplate = () => downloadFile(getContentAbsoluteUrl(selectedCategory.template));

  return (
    <div className="pb-4">
      {[
        {
          title: "Select a category",
          link: !selectedCategory ? "CHOOSE CATEGORY" : getSelectedCategoryTitle(),
          onClick: () => setShowCategories(true)
        },
        {
          title: "Download our CSV file and input all your product details in just minutes.",
          link: "DOWNLOAD CSV TEMPLATE",
          onClick: downloadTemplate
        },
        {
          title: "Get Image URL",
          subTitle:
            "If you want to add Images within your CSV File, copy the generated URL  and paste it in the image field of CSV file.",
          link: "GENERATE URL",
          onClick: openImageTab
        },
        {
          title: "Upload CSV here",
          subTitle: "Only CSV format is allowed",
          component: parent && (
            <UploadCSVButton
              licenseKey={selectedCategory.csbox_license_id}
              disabled={!isObject(parent)}
              containerStyle={{ justifyContent: "flex-start" }}
              customAttributes={{ category: selectedCategory.id }}
              onSuccess={onSuccess}
              supportExternalValidation={true}
            />
          )
        }
      ].map((el, index) => (
        <div className="d-flex w-100 mt-4" key={index}>
          <div style={{ width: "10%" }}>
            <h6>Step {index + 1}:</h6>
          </div>
          <div style={{ width: "90%" }}>
            <h6 className="mb-1">{el.title}</h6>
            {el.subTitle && <p className="mb-1">{el.subTitle}</p>}
            {el.link ? (
              <AppLink style={{ fontWeight: "bold" }} onClick={el.onClick}>
                {el.link}
              </AppLink>
            ) : (
              el.component
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

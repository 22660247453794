import Api, { ApiHeaders } from "./api";

class ContentFactoryBase extends ApiHeaders {
  constructor(path) {
    super();
    this.path = path;
  }

  async list(filters, search, sortBy) {
    var params = {};

    if (filters) {
      params = { ...params, ...filters };
    }

    if (search) {
      params.search = search;
    }

    if (sortBy) {
      params.ordering = sortBy;
    }

    return await Api.get(this.path, params, this.authenticationHeaders).then(
      (response) => response.data
    );
  }

  async add(id) {
    return await Api.post(`${this.path}`, { id }, this.authenticationHeaders).then(
      (response) => response.data
    );
  }

  async remove(id) {
    return await Api.delete(`${this.path}${id}/`, null, this.authenticationHeaders).then(
      (response) => response.data
    );
  }
}

export default ContentFactoryBase;

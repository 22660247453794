import React, { useEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Learn from "./screens/Learn";
import MyProfileRoutes from "./routes/MyProfile";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import ActivityRoutes from "./routes/MyActivity";
import AllContentRoutes from "./routes/AllContent";
import Blog from "./screens/Blog";
import Profile from "./screens/Profile";
import ProfilesRoutes from "./routes/Profiles";
import ROUTE_CONSTANTS from "./constants/routes";
import { IdeationBoardModalProvider } from "./hooks/addToIdeationBoard";
import { AdvertiseWithUsModalProvider } from "./hooks/advertiseWithUs";
import AdvertiseWithUsModal from "./components/Modals/AdvertiseWithUs";
import AddToIdeationBoardModal from "./components/Modals/AddToIdeationBoard";
import Dashboard from "./screens/Dashboard";
import { SidebarProvider } from "./hooks/sidebar";
import STUDIO_SAMPLE_PROFILE from "./constants/SampleProfiles/Studio/profile";
import { useAuth } from "./hooks";
import LearnThesis from "./screens/LearnThesis";
import { BookmarkModalProvider } from "./hooks/bookmarkModal";
import NotLoggedInBookmarkModal from "./components/Modals/NotLoggedInBookmarkModal";
import STUDENT_PROFILE_SAMPLE from "./constants/SampleProfiles/Student/profile";
import Jobs from "./screens/Jobs";
import JobSearch from "./screens/Jobs/Search";
import JobApplications from "./screens/Jobs/Applications";
import GetImageURL from "./screens/GetImageURL";
import BRAND_PROFILE_SAMPLE from "constants/SampleProfiles/Brand/profile";
import FAQs from "screens/FAQs";
import Footer from "components/Footer";
import { getInitRoute } from "utils";
import Header from "components/Header";
import Careers from "screens/Careers";
import NavigationContainer from "components/Containers/NavigationContainer";
import ProductsHome from "screens/Products/Home";
import Products from "screens/Products";
import AllCategories from "screens/Products/Categories";
import AllBrands from "screens/Products/AllBrands";
import ProductList from "screens/Products/List";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";
import ACTION_TYPES from "constants/action-types";

function AuthenticatedRoute({ children }) {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  return isLoggedIn ? children : <Navigate to="/login" />;
}

const AppRoutes = ({ isLoggedIn, user }) => {
  const {
    ROUTE_PREFIXES: { MY_PROFILE }
  } = ROUTE_CONSTANTS;
  const { getUserInfo } = useAuth();

  const dispatch = useDispatch();
  const getCategories = () =>
    dispatch({ type: ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_REQUEST });

  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo();
    }
    getCategories();
  }, []);

  const initRoute = getInitRoute(user);

  const SEEKER_JOB_ROUTES = (
    <Routes>
      <Route path="" element={<JobSearch />} />
      <Route path="details/:id" element={<JobSearch />} />
    </Routes>
  );

  const RECRUITER_JOB_ROUTES = (
    <Routes>
      <Route path="" element={<JobSearch isCandidateSearch />} />
      <Route path="details/:id" element={<JobSearch isCandidateSearch />} />
    </Routes>
  );

  const JOB_ROUTES = (
    <Routes>
      <Route path="" element={<Jobs />} />
      <Route path=":id/applicants" element={<JobApplications />} />
      <Route path=":id/applicants/:applicationId" element={<JobApplications />} />
      <Route path="seeker/search/*" element={SEEKER_JOB_ROUTES} />
      <Route path="recruiter/search/*" element={RECRUITER_JOB_ROUTES} />
    </Routes>
  );

  const LEARN_IMAGE_ROUTES = (
    <Routes>
      <Route path="/" element={<Learn type="image" />} />
      <Route path="/:showContentId" element={<Learn type="image" />} />
    </Routes>
  );

  const LEARN_VIDEO_ROUTES = (
    <Routes>
      <Route path="/" element={<Learn type="video" />} />
      <Route path="/:showContentId" element={<Learn type="video" />} />
    </Routes>
  );

  const LEARN_THESIS_ROUTES = (
    <Routes>
      <Route path="/" element={<Learn type="thesis" />} />
      <Route path="/:id" element={<LearnThesis />} />
    </Routes>
  );

  const LEARN_BLOG_ROUTES = (
    <Routes>
      <Route path="/" element={<Learn type="blog" />} />
    </Routes>
  );
  const LEARN_ROUTES = (
    <Routes>
      <Route path="/" element={<Navigate to="images" replace />} />
      <Route path="/images/*" element={LEARN_IMAGE_ROUTES} />
      <Route path="/videos/*" element={LEARN_VIDEO_ROUTES} />
      <Route path="/blogs" element={LEARN_BLOG_ROUTES} />
      <Route path="/thesis/*" element={LEARN_THESIS_ROUTES} />
      <Route path="*" element={<Navigate to="images" replace />} />
    </Routes>
  );

  const PRODUCTS_ROUTES = (
    <Routes>
      <Route path="/" element={<ProductsHome />} />
      <Route path="/brands" element={<AllBrands />} />
      <Route path="/brands/:brand" element={<ProductList />} />
      <Route path="/categories" element={<AllCategories />} />
      <Route
        path="/categories/:categoryId/:subCategoryId?/:subSubCategoryId?/:productSlug?"
        element={<Products showSubCategoriesOrProducts />}
      />
    </Routes>
  );

  const CAREERS_ROUTES = (
    <Routes>
      <Route path="/" element={<Careers />} />
    </Routes>
  );
  const NewProfile = withMobileOnlySidebar(Profile);

  return (
    <Router>
      <SidebarProvider>
        <IdeationBoardModalProvider>
          <AdvertiseWithUsModalProvider>
            <NavigationContainer>
              <BookmarkModalProvider>
                <Header />
                <Routes>
                  <Route path="/learn/*" element={LEARN_ROUTES} />
                  <Route path="/profiles/*" element={<ProfilesRoutes />} />
                  <Route path="/jobs/*" element={JOB_ROUTES} />
                  <Route path="/products/*" element={PRODUCTS_ROUTES} />
                  <Route path="/blogs/:id" element={<Blog />} />
                  <Route path="/all/*" element={<AllContentRoutes />} />
                  <Route path="/get-image-url" element={<GetImageURL />} />
                  <Route path="/careers/*" element={CAREERS_ROUTES} />
                  <Route path="/faqs" element={<FAQs />} />
                  {!isLoggedIn && <Route path="/login" element={<Login />} />}
                  {!isLoggedIn && <Route path="/signup" element={<Signup />} />}
                  {user && (
                    <Route
                      path={`/${MY_PROFILE}/sample`}
                      element={
                        <AuthenticatedRoute>
                          <NewProfile
                            previewProfile={
                              user.is_studio
                                ? STUDIO_SAMPLE_PROFILE
                                : user.is_student
                                ? STUDENT_PROFILE_SAMPLE
                                : BRAND_PROFILE_SAMPLE
                            }
                          />
                        </AuthenticatedRoute>
                      }
                    />
                  )}

                  <Route
                    path={`/${MY_PROFILE}/*`}
                    element={
                      <AuthenticatedRoute>
                        <MyProfileRoutes />
                      </AuthenticatedRoute>
                    }
                  />
                  <Route
                    path="/my-activity/*"
                    element={
                      <AuthenticatedRoute>
                        <ActivityRoutes />
                      </AuthenticatedRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <AuthenticatedRoute>
                        <Dashboard />
                      </AuthenticatedRoute>
                    }
                  />
                  <Route
                    path="/*"
                    element={<Navigate to={isLoggedIn ? initRoute : "/login"} replace />}
                  />
                </Routes>
                <Footer />
                <AddToIdeationBoardModal />
                <AdvertiseWithUsModal />
                <NotLoggedInBookmarkModal />
              </BookmarkModalProvider>
            </NavigationContainer>
          </AdvertiseWithUsModalProvider>
        </IdeationBoardModalProvider>
      </SidebarProvider>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.authReducer.isLoggedIn,
  user: state.authReducer.user
});

export default connect(mapStateToProps, null)(AppRoutes);

import React from "react";
import BaseMenu from "../BaseMenu";
import { SIDEBAR_ICONS } from "constants/icons";
import { useSelector } from "react-redux";
import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PublicMenu() {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const navigate = useNavigate();

  return (
    <div>
      <BaseMenu
        options={[
          {
            label: "Products",
            path: "products",
            menuPath: "/products",
            icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
          },
          {
            label: "Learn",
            path: "learn",
            menuPath: "/learn",
            icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
          },
          {
            label: "Profiles",
            path: "profiles",
            menuPath: "/profiles",
            icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
          },
          {
            label: "Jobs",
            path: "jobs",
            menuPath: "/jobs",
            icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
          }
        ]}
      />
      {!isLoggedIn && (
        <Stack gap={4} direction="horizontal" className="mx-4">
          <Button className="w-50 py-2" onClick={() => navigate("/signup")}>
            SIGN UP
          </Button>
          <Button
            className="w-50 py-2"
            variant="outline-primary"
            onClick={() => navigate("/login")}
          >
            SIGN IN
          </Button>
        </Stack>
      )}
    </div>
  );
}

import React from "react";
import Sheet from "react-modal-sheet";

export default function BottomSheet({ children, open, close }) {
  return (
    <Sheet isOpen={open} onClose={close}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
}

import ContentFactoryBase from "./ContentFactoryBase";

class Image extends ContentFactoryBase {
  constructor(type) {
    super(`advertisements/images/`, type);
  }
}

class AdvertisementFactory {
  static createRequest(type) {
    switch (type) {
      case "image":
        return new Image(type);
      default:
        throw new Error(`Unsupported request type: ${type}`);
    }
  }
}

export default AdvertisementFactory;

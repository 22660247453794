import ContentFactory from "apiClients/ContentFactory";
import ReactHookInput from "components/Inputs/ReactHookInput";
import ReactHookUpload from "components/Inputs/ReactHookUpload";
import React from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useFormContext } from "react-hook-form";

export default function UploadResumeForm({ onSuccess }) {
  const { handleSubmit } = useFormContext();
  const resumeFileClient = ContentFactory.createRequest("resume-file");

  const onSubmit = (data) => resumeFileClient.create(data).then(() => onSuccess());

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Resume Title*</Form.Label>
          <ReactHookInput name="title" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Add your Resume file*</Form.Label>
          <ReactHookUpload name="file" type="pdf" required maxSizeMB={5} />
        </Form.Group>
        <hr />
        <Button className={`w-${isMobile ? "100" : "25"} d-block ms-auto`} type="submit">
          UPLOAD
        </Button>
      </Stack>
    </Form>
  );
}

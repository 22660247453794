import React from "react";
import ContentList from "../../../components/Base/ContentList";
import { connect } from "react-redux";
import Job from "../../../components/Cards/Job";

function Drafted({ user }) {
  return (
    <ContentList
      Card={Job}
      type="job"
      title="Drafted Jobs"
      subTitle="All your drafted jobs will be visible here"
      initialFilters={{
        is_drafted: true,
        user: user.id
      }}
      cardProps={{ showSelect: false }}
      doNotShowAdd
    />
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });
export default connect(mapStateToProps)(Drafted);

import React from "react";
import { Image } from "react-bootstrap";
import DOWNLOAD_ICON from "../../../assets/icons/download.png";
import COLORS from "../../../constants/colors";
import UploadCSVButton from "../../Buttons/UploadCSVButton";
import { isFunction } from "lodash";

export default function CSVUploader({
  setShow,
  licenseKey,
  onSuccess,
  supportExternalValidation,
  downloadCSV
}) {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-center align-items-center" onClick={downloadCSV}>
          <Image src={DOWNLOAD_ICON} style={{ height: "1.5rem" }} />
          <p
            className="fw-bold cursor-pointer mb-0 ms-2"
            style={{
              fontSize: "75%",
              color: COLORS.PRIMARY_ORANGE,
              textDecoration: "underline"
            }}
          >
            DOWNLOAD CSV FORMAT
          </p>
        </div>
        {isFunction(setShow) && (
          <p
            className="fw-bold text-decoration-underline cursor-pointer mb-0"
            style={{ fontSize: "75%" }}
            onClick={() => setShow(true)}
          >
            How does CSV work?
          </p>
        )}
      </div>
      <UploadCSVButton
        licenseKey={licenseKey}
        onSuccess={onSuccess}
        supportExternalValidation={supportExternalValidation}
      />
    </div>
  );
}

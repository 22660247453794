import React, { useEffect, useState } from "react";
import { Button, CloseButton, Form, Stack } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import { isDate, isEmpty, isString, isUndefined } from "lodash";
import { useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import DateParser from "date";
import COLORS from "constants/colors";
import FormError from "components/FormError";
import SPECIALISATIONS from "constants/specialisations";
import DEFAULT_STYLE from "styles";
import DEGREES from "constants/degrees";
import CheckBox from "components/Inputs/CheckBox";
import StartEndDateInput from "components/Inputs/StartEndDate";
import EditDeleteIcons from "components/Icons/EditDeleteIcons";
import DateRangeFormatter from "components/DateRangeFormatter";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";
import { useResumeFields } from "hooks";
import ResumeFormContainer from "components/Containers/ResumeForm";
import { scroller } from "react-scroll";

const DEGREES_TYPES = [
  { label: "PhD", value: "PhD" },
  { label: "Post Graduation", value: "Post Graduation" },
  { label: "Graduation", value: "Graduation" },
  { label: "Diploma", value: "Diploma" }
];

function AddEducationForm({ education, onSave, onClose }) {
  const [college, setCollege] = useState("");
  const [specialization, setSpecialization] = useState(undefined);
  const [degree, setDegree] = useState(undefined);
  const [isPursuing, setIsPursuing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [score, setScore] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [education_type, setEducation_type] = useState(undefined);

  useEffect(() => {
    if (isCompleted && isPursuing) {
      setIsCompleted(false);
      setEndDate(null);
    }
  }, [isPursuing]);

  useEffect(() => {
    if (isPursuing && isCompleted) {
      setIsPursuing(false);
    }
  }, [isCompleted]);

  useEffect(() => {
    if (education) {
      setSpecialization({ value: education.specialization, label: education.specialization });
      setStartDate(DateParser.parseFromYYYYMMDD(education.start_date));
      setEndDate(DateParser.parseFromYYYYMMDD(education.end_date));
      setIsPursuing(education.is_pursuing);
      setIsCompleted(education.is_completed);
      setScore(education.score);
      setCollege(education.college);
      setEducation_type(education.education_type);
      setDegree({ value: education.degree, label: education.degree });
    }
  }, []);

  const clearForm = () => {
    setCollege("");
    setSpecialization(undefined);
    setDegree(undefined);
    setIsPursuing(false);
    setIsCompleted(false);
    setStartDate(null);
    setEndDate(null);
    setScore("");
    setDescription("");
    setEducation_type(undefined);
  };

  const validateEducationForm = () => {
    const errors = {};

    if (college.trim() === "") {
      errors.college = "Invalid college";
    }

    if (degree && degree.value && degree.value.trim() === "") {
      errors.degree = "Invalid degree";
    }

    if (startDate === null) {
      errors.startDate = "Invalid start date";
    }

    if (!isPursuing && endDate === null) {
      errors.endDate = "Invalid end date";
    }

    return errors;
  };

  const onAdd = () => {
    const errors = validateEducationForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      onSave({
        college,
        specialization: specialization ? specialization.value : undefined,
        degree: degree.value,
        is_pursuing: isPursuing,
        is_completed: isCompleted,
        start_date:
          isString(startDate) && !isEmpty(startDate)
            ? startDate
            : isDate(startDate)
            ? DateParser.formatDateToYYYYMMDD(startDate)
            : null,
        end_date:
          isString(endDate) && !isEmpty(endDate)
            ? endDate
            : isDate(endDate)
            ? DateParser.formatDateToYYYYMMDD(endDate)
            : null,
        score,
        description,
        education_type
      });
      clearForm();
      scroller.scrollTo("Education");
    }
  };

  return (
    <>
      {isUndefined(education) && (
        <Stack className="p-4 mb-4" style={{ border: `1px ${COLORS.GREY2} dashed` }}>
          <div className="d-flex justify-content-between">
            <h6>ADD EDUCATION</h6>
            <CloseButton onClick={onClose} />
          </div>
          <div className="mt-1">
            {DEGREES_TYPES.map((el) => (
              <div
                key={el.value}
                className="d-flex align-items-center mb-2 cursor-pointer"
                onClick={() => setEducation_type(el.value)}
              >
                <BsPlus size={30} />
                <p className="mb-0 fw-bold">Add {el.value}</p>
              </div>
            ))}
          </div>
        </Stack>
      )}
      {education_type && (
        <Stack gap={4} className="p-4" style={{ border: `1px ${COLORS.GREY2} dashed` }}>
          <div className="d-flex justify-content-between">
            <h6>
              {isUndefined(education) ? "Add" : "Update"}{" "}
              {DEGREES_TYPES.filter((el) => el.label === education_type)[0].label}
            </h6>
            <CloseButton onClick={() => (education ? onClose() : setEducation_type(undefined))} />
          </div>
          <Form.Group>
            <Form.Label>College*</Form.Label>
            <Form.Control value={college} onChange={(e) => setCollege(e.target.value)} required />
            {errors && errors.college && <FormError>{errors.college}</FormError>}
          </Form.Group>
          {education_type !== "Diploma" && (
            <Form.Group>
              <Form.Label>Specialization</Form.Label>
              <ReactSelectCreatable
                value={specialization}
                onChange={setSpecialization}
                isSearchable={true}
                options={SPECIALISATIONS}
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "0.3rem",
                    ...(errors.degree ? DEFAULT_STYLE.INPUT.ERRORED : {})
                  })
                }}
              />
              {errors && errors.specialization && <FormError>{errors.specialization}</FormError>}
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Degree*</Form.Label>
            <ReactSelect
              value={degree}
              onChange={setDegree}
              isSearchable={true}
              options={DEGREES[education_type]}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: "0.3rem",
                  ...(errors.degree ? DEFAULT_STYLE.INPUT.ERRORED : {})
                })
              }}
            />
            {errors && errors.degree && <FormError>{errors.degree}</FormError>}
            <div className="d-flex mt-2">
              <CheckBox
                isChecked={isPursuing}
                setChecked={setIsPursuing}
                label="Pursuing"
                useReactHook={false}
              />
              <div className="ms-3">
                <CheckBox
                  isChecked={isCompleted}
                  setChecked={setIsCompleted}
                  label="Completed"
                  useReactHook={false}
                />
              </div>
            </div>
          </Form.Group>
          <StartEndDateInput
            showEndDate={!isPursuing}
            showStartDate
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            errors={errors}
          />
          <Form.Group>
            <Form.Label>Percentage/CGPA/Grade</Form.Label>
            <Form.Control value={score} onChange={(e) => setScore(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              value={description}
              as="textarea"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Button className="w-25" type="button" onClick={onAdd}>
            {isUndefined(education) ? "ADD" : "UPDATE"}
          </Button>
        </Stack>
      )}
    </>
  );
}

export function SavedEducation({ education, onDelete, onEdit }) {
  return (
    <Stack className="mb-3">
      <div className="d-flex justify-content-between">
        <h6 className="mb-0">{education.college}</h6>
        <EditDeleteIcons onDelete={onDelete} onEdit={onEdit} />
      </div>
      <p className="mb-0">{education.degree}</p>
      <DateRangeFormatter
        startDate={education.start_date}
        endDate={education.end_date}
        dateFormat="MM/YYYY"
        isPresent={education.is_pursuing}
      />
      {education.specialization && <p className="mb-0">{education.specialization}</p>}
      {education.score && <p className="mb-0">{education.score}</p>}
      {education.description && <Form.Text muted>{education.description}</Form.Text>}
    </Stack>
  );
}

function SavedEducationSection({ education, index, onUpdate, onDelete }) {
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const update = (updatedExperience) => {
    setEdit(false);
    onUpdate(updatedExperience, index);
  };

  const onClose = () => setEdit(false);

  const handleDelete = () => {
    onDelete(index);
    setDel(false);
  };

  return edit === true ? (
    <AddEducationForm onSave={update} onClose={onClose} education={education} />
  ) : (
    <>
      <SavedEducation
        onDelete={() => setDel(true)}
        onEdit={() => setEdit(true)}
        education={education}
      />
      <ConfirmDeletionModal
        show={del}
        onHide={() => setDel(false)}
        type="education"
        onCancel={() => setDel(false)}
        onConfirm={handleDelete}
        hideSubTitle
      />
    </>
  );
}

export default function EducationSection() {
  const { control } = useFormContext();
  const { updateEducation, removeEducation, appendEducation, education } = useResumeFields({
    control
  });
  const [showForm, setShowForm] = useState(false);

  const onSave = (newEducation) => {
    appendEducation(newEducation);
    setShowForm(false);
  };

  const onClose = () => setShowForm(false);

  const onDelete = (index) => removeEducation(index);

  const onUpdate = (updatedEducation, index) => updateEducation(index, updatedEducation);

  return (
    <ResumeFormContainer>
      {education.map((education, index) => (
        <SavedEducationSection
          key={education.id}
          education={education}
          index={index}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      ))}
      {showForm === true ? (
        <AddEducationForm onClose={onClose} onSave={onSave} />
      ) : (
        <Link
          className="text-decoration-underline ms-2 fw-bold d-flex justify-content-end mt-3"
          onClick={() => setShowForm(true)}
        >
          ADD MORE
        </Link>
      )}
    </ResumeFormContainer>
  );
}

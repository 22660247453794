import React, { Fragment } from "react";
import AuthContainer from "../components/Containers/AuthRight";
import SignupForm from "../forms/Signup";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function Signup() {
  return (
    <AuthContainer
      Form={SignupForm}
      HeaderComponent={
        <Fragment>
          <h1>Sign Up</h1>
          <p className="helper-text">Join our community now. Please enter your details below.</p>
        </Fragment>
      }
    />
  );
}

export default withMobileOnlySidebar(Signup);

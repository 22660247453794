import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseContainer from "../components/Containers/BaseContainer";
import LoadingContainer from "../components/Containers/Loading";
import ContentFactory from "../apiClients/ContentFactory";
import EngagementStats from "../components/EngagementStats";
import { Image, Stack } from "react-bootstrap";
import COLORS from "../constants/colors";
import BlogCard from "../components/Cards/Blog";
import RelatedContainer from "../components/Containers/RelatedContainer";
import DateParser from "../date";
import EngagementActions from "../components/EngagementActions";
import PinnedBlogs from "../components/PinnedBlogs";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";
import BackButton from "components/Buttons/Back";
import { useSelector } from "react-redux";

function BlogContent({ blog, handleGetBlog }) {
  return (
    <>
      <div>
        <p className="my-1">{DateParser.formatDateToYYYYMMDD(new Date(blog.created))}</p>
        <h3 className="mt-2 mb-0 fw-bold">{blog.title}</h3>
        <Stack direction="horizontal" className="justify-content-between align-items-center mt-2">
          <div>
            <p className="mb-1 fw-bold" style={{ color: COLORS.PRIMARY_ORANGE }}>
              {blog.author}
            </p>
          </div>
          <EngagementActions obj={blog} onSuccess={handleGetBlog} type="blog" showPins />
        </Stack>
      </div>
      <div>
        <EngagementStats likes={blog.likes} saves={blog.saves} views={blog.views} />
      </div>
      <div className="my-4">
        <Image
          src={blog.thumbnail}
          className="w-100"
          style={{
            borderRadius: "5px",
            objectFit: "contain"
          }}
        />
      </div>
      <div dangerouslySetInnerHTML={{ __html: blog.content }} id="blog-content" />
    </>
  );
}

function Recommended({ recommendations }) {
  return (
    <>
      <h6 className="mb-3">Recommended For You</h6>
      {recommendations.map((el) => (
        <div key={el.id} style={{ height: "25rem", marginBottom: "5%" }}>
          <BlogCard
            obj={el}
            showSelect={false}
            engagementStatsIconWidth="0.75rem"
            engagementStatsIconHeight="0.75rem"
          />
        </div>
      ))}
    </>
  );
}

function Blog() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState(undefined);
  const client = ContentFactory.createRequest("blog");
  const [viewed, setViewed] = useState(false);

  const handleGetBlog = () => client.get(id).then((result) => setBlog(result));
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (viewed === true) {
      setViewed(false);
    }
    if (loading === false) {
      setLoading(true);
    }
    handleGetBlog().then(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (blog && viewed === false && isLoggedIn) {
      client
        .view(blog.id)
        .then(() => {
          const newBlog = { ...blog };
          newBlog.views = newBlog.views + 1;
          setBlog(newBlog);
        })
        .then(() => setViewed(true));
    }
  }, [blog, isLoggedIn]);

  return (
    <BaseContainer>
      <LoadingContainer loading={loading}>
        <div className="p-3" style={{ background: "#F5F5F580" }}>
          <BackButton />
        </div>
        {blog && (
          <div className="mt-4">
            <PinnedBlogs onSuccess={handleGetBlog} />
            <RelatedContainer
              FirstCol={<BlogContent blog={blog} handleGetBlog={handleGetBlog} />}
              SecondCol={<Recommended recommendations={blog.recommended_blogs} />}
            />
          </div>
        )}
      </LoadingContainer>
    </BaseContainer>
  );
}

export default withMobileOnlySidebar(Blog);

/* eslint-disable no-undef */
import React, { useState } from "react";
import { Image, Stack } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import { isFunction } from "lodash";
import UploadProfileImageModal from "../../Modals/UploadProfileImage";
import UploadBannerImageModal from "../../Modals/UploadBannerImage";
import { connect } from "react-redux";
import PROFILE_PREVIEW_ICON from "../../../assets/icons/profile-preview.png";
import PROFILE_SAMPLE_ICON from "../../../assets/icons/profile-sample.png";
import DASHBOARD_ICON from "../../../assets/icons/dashboard-icon.png";

import { useNavigate } from "react-router-dom";
import ProfileImage from "../../ProfileImage";
import ProfileBannerImage from "../../ProfileBannerImage";
import ROUTE_CONSTANTS from "../../../constants/routes";

const EditIcon = ({ style, onClick }) => {
  const iconStyle = {
    width: "1.75rem",
    height: "1.75rem",
    ...style
  };

  return (
    <div
      style={iconStyle}
      onClick={() => isFunction(onClick) && onClick()}
      className="rounded-circle bg-white shadow d-flex justify-content-center align-items-center"
    >
      <BsPencil />
    </div>
  );
};

function Header({ user }) {
  const navigate = useNavigate();
  const [showUploadProfileImage, setShowUploadProfileImage] = useState(false);
  const [showUploadBackgroundImage, setShowUploadBackgroundImage] = useState(false);

  return (
    <div className="posiiton-relative">
      <div
        className="position-relative cursor-pointer"
        onClick={() => setShowUploadBackgroundImage(true)}
      >
        <ProfileBannerImage style={{ objectFit: "cover", height: "8rem" }} />
        <EditIcon style={{ position: "absolute", bottom: 10, right: 5 }} />
      </div>
      <div
        className="position-absolute shadow rounded-circle cursor-pointer"
        style={{
          border: "10px solid white",
          width: "8rem",
          height: "8rem",
          top: "4.5rem",
          left: 15
        }}
        onClick={() => setShowUploadProfileImage(true)}
      >
        <EditIcon style={{ position: "absolute", bottom: 10, right: -10 }} />
        <ProfileImage />
      </div>
      <div className="px-4" style={{ marginTop: "6rem" }}>
        <h5 className="fw-bold mb-1">Hello!</h5>
        {user && user.name && <h4 className="fw-bold text-secondary">{user.name}</h4>}
      </div>
      <Stack className="mx-auto mt-3" gap={1} style={{ paddingLeft: "30px" }}>
        {[
          {
            label: "Profile Preview",
            icon: PROFILE_PREVIEW_ICON,
            onClick: () => navigate(`/profiles/${user.slug}`)
          },
          {
            label: "Profile Sample",
            icon: PROFILE_SAMPLE_ICON,
            onClick: () => navigate(`/${ROUTE_CONSTANTS.ROUTE_PREFIXES.MY_PROFILE}/sample`)
          },
          {
            label: "Dashboard",
            icon: DASHBOARD_ICON,
            onClick: () => navigate("/dashboard")
          }
        ].map((el) => (
          <div
            key={el.label}
            style={{ borderRadius: "5px" }}
            className="py-1 d-flex align-items-center cursor-pointer"
            onClick={el.onClick}
          >
            <Image
              src={el.icon}
              className="me-2"
              style={{ width: "1rem", height: "1rem", objectFit: "contain" }}
            />
            <p className="mb-0" style={{ fontSize: "90%", fontWeight: "500" }}>
              {el.label}
            </p>
          </div>
        ))}
      </Stack>
      <hr style={{ borderColor: "#E1E2EC" }} />

      <UploadProfileImageModal
        show={showUploadProfileImage}
        onHide={() => setShowUploadProfileImage(false)}
      />
      <UploadBannerImageModal
        show={showUploadBackgroundImage}
        onHide={() => setShowUploadBackgroundImage(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.authReducer.user });

export default connect(mapStateToProps)(Header);

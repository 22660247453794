import React, { useEffect } from "react";
import { Form, Stack } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { isObject } from "lodash";
import ReactHookInput from "components/Inputs/ReactHookInput";
import Select from "components/Inputs/Select";
import { showPromiseToast } from "utils";
import { renderErrorToast } from "utils";
import ContentFactory from "apiClients/ContentFactory";
import FormFooter from "forms/Footer";
import DropdownFactory from "apiClients/DropdownFactory";

const AddVideo = ({ onSuccess, editObj }) => {
  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue, reset } = methods;

  useEffect(() => {
    if (isObject(editObj)) {
      ["title", "description", "video"].map((el) => setValue(el, editObj[el] || ""));

      ["category"].map((el) => setValue(el, { label: editObj[el], value: editObj[el] }));

      var tags = [];
      editObj.tags.forEach((el) => tags.push({ label: el, value: el }));
      setValue("tags", tags);
    }
  }, []);

  const loadCategories = (search) =>
    DropdownFactory.createRequest("video-categories").list({ search });

  const FormPartOne = (
    <Stack gap={3}>
      <Form.Group>
        <Form.Label>Title*</Form.Label>
        <ReactHookInput name="title" type="text" required />
      </Form.Group>
      <Form.Group>
        <Form.Label>Video Link*</Form.Label>
        <ReactHookInput name="video" required type="url" />
        <div>
          <Form.Text>Preferred Resolution: 1240 X 680</Form.Text>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Video Category*</Form.Label>
        <Select loadOptions={loadCategories} name="category" isAsync />
      </Form.Group>
      <Form.Group>
        <Form.Label>Tags*</Form.Label>
        <Select options={[]} isMulti isCreateable name="tags" />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Brief Description <span className="fst-italic fw-light">Optional</span>
        </Form.Label>
        <ReactHookInput name="description" as="textarea" />
      </Form.Group>
    </Stack>
  );

  const handleFormOneSubmit = async (data) => {
    const payload = {
      ...data,
      category: data.category.value,
      tags: data.tags.map((el) => el.value)
    };
    const client = ContentFactory.createRequest("video");

    const promise = (
      editObj ? client.update({ ...payload, id: editObj.id }, false) : client.create(payload, false)
    )
      .then(onSuccess)
      .then(reset);

    showPromiseToast(promise, {
      pending: `${editObj ? "Updating" : "Creating"} video`,
      success: `Video ${editObj ? "updated" : "created"} successfully`,
      error: renderErrorToast()
    });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleFormOneSubmit)}>
        {FormPartOne}
        <FormFooter />
      </Form>
    </FormProvider>
  );
};

export default AddVideo;

import React, { memo, useEffect, useState } from "react";
import BaseContainer from "../../components/Containers/BaseContainer";
import { connect, useDispatch, useSelector } from "react-redux";
import { isArray, isEmpty, isNil, isObject } from "lodash";
import { Button, Col, Row, Table } from "react-bootstrap";
import AddIdeationBoardModal from "../../components/Modals/AddIdeationBoard";
import { useNavigate, useParams } from "react-router-dom";
import BorderedButton from "../../components/Buttons/Bordered";
import LoadingContainer from "../../components/Containers/Loading";
import ContentFactory from "../../apiClients/ContentFactory";
import AddMoodBoardModal from "../../components/Modals/AddMoodBoard";
import AddContentBorderedButton from "../../components/Buttons/AddContentBordered";
import LockedContent from "../../components/Containers/LockedContent";
import { ReactSVG } from "react-svg";
import { PLUS_ICON } from "../../constants/icons";
import EditDeleteIcons from "components/Icons/EditDeleteIcons";
import { renderErrorToast, showPromiseToast } from "utils";
import ConfirmDeletionModal from "components/Modals/ConfirmDeletion";
import ACTION_TYPES from "constants/action-types";
import { isMobile } from "react-device-detect";
import ContentHeader from "components/Base/ContentHeader";
import Search from "components/Inputs/Search";
import TotalResult from "components/TotalResult";

function IdeationBoard({ ideationBoards }) {
  const params = useParams();
  var { id } = params;
  try {
    if (!id && params["*"]) {
      if (params["*"].includes("ideation")) {
        id = params["*"].split("/")[1];
      }
    }
  } catch (error) {
    console.error("Something went wrong while getting ideation board id");
  }
  const [loading, setLoading] = useState(false);
  const [ideationBoard, setIdeationBoard] = useState(undefined);
  const [showMoodBoardModal, setShowMoodBoardModal] = useState(false);
  const [showDeletion, setShowDeletion] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleGetIdeationBoard = () => {
    setLoading(true);

    if (id !== "create" && id) {
      ContentFactory.createRequest("ideation-board")
        .read(id)
        .then(setIdeationBoard)
        .then(() => setLoading(false));
    }
  };

  useEffect(() => {
    handleGetIdeationBoard();
  }, [id]);

  const isLoadingIdeationBoards = useSelector(
    (state) => state.contentReducer.isLoadingIdeationBoards
  );
  const [showAdd, setShowAdd] = useState(false);

  const dispatch = useDispatch();
  const getIdeationBoards = () =>
    dispatch({ type: ACTION_TYPES.CONTENT.IDEATION_BOARD.GET_IDEATION_BOARD_REQUEST });

  function EmptyIdeationBoard() {
    return (
      <div>
        <ol>
          {[
            "Create Folder",
            "Create Mood board",
            "Add Products",
            "Add Projects",
            "Add Images",
            "Add Videos"
          ].map((item, index) => (
            <li key={index} className="fw-bold" style={{ marginBottom: isMobile ? "5%" : "2%" }}>
              {item}
            </li>
          ))}
        </ol>
        <Button
          className="px-5 mt-3 d-flex justify-content-center align-items-center"
          onClick={() => setShowAdd(true)}
          style={{ width: isMobile ? "100%" : null }}
        >
          <span className="me-3">
            <ReactSVG src={PLUS_ICON} color="white" style={{ width: "1.5rem", height: "100%" }} />
          </span>
          ADD FOLDER
        </Button>
      </div>
    );
  }

  const handleDeleteIdeationBoard = () => {
    setLoading(true);
    const promise = ContentFactory.createRequest("ideation-board")
      .remove(ideationBoard.id)
      .then(getIdeationBoards)
      .then(() => navigate(`/my-activity/ideation-board/create`, { replace: true }))
      .then(() => setLoading(false));
    showPromiseToast(promise, {
      pending: "Deleting Ideation Board",
      success: "Ideation Board deleted successfully",
      error: renderErrorToast()
    });
  };

  const empty =
    id === "create" ||
    !isArray(ideationBoards) ||
    (isArray(ideationBoards) && isEmpty(ideationBoards));

  return (
    <BaseContainer className="py-4">
      <div className="mx-auto w-100">
        <LoadingContainer
          loading={(id !== "create" && !isObject(ideationBoard)) || isLoadingIdeationBoards}
        >
          <div className="d-flex justify-content-between">
            <div className={`px-${isMobile ? 0 : 3}`}>
              <ContentHeader
                title={ideationBoard ? ideationBoard.folder_name : "Ideation Board"}
                subTitle={empty ? "Ideate, Upload and Create easily using MaterialLibrary." : ""}
                doNotShowSubtitle={!empty}
                type="ideation-board"
              />
            </div>
            {!isNil(ideationBoard) && (
              <div className={`${isMobile ? "align-items-center d-flex mt-2" : ""}`}>
                <EditDeleteIcons
                  onDelete={() => setShowDeletion(true)}
                  onEdit={() => setShowAdd(true)}
                  size={35}
                />
              </div>
            )}
          </div>
          <LockedContent>
            {empty ? (
              <EmptyIdeationBoard />
            ) : (
              <LoadingContainer loading={loading || !ideationBoard}>
                {ideationBoard && (
                  <>
                    <Table borderless className={`w-${isMobile ? 100 : 50}`}>
                      <tbody>
                        {[
                          { label: "Project Type", value: ideationBoard.project_type },
                          { label: "Project Stage", value: ideationBoard.project_stage },
                          { label: "Location", value: ideationBoard.location },
                          {
                            label: "Input Project Area",
                            value: `${ideationBoard.input_project_area} ${ideationBoard.input_project_area_unit}`
                          },
                          {
                            label: "Product Description",
                            value: ideationBoard.description
                          }
                        ].map(
                          (item, index) =>
                            !item.value.includes("null") && (
                              <tr key={index}>
                                <td>{item.label}</td>
                                <th>{item.value}</th>
                              </tr>
                            )
                        )}
                      </tbody>
                    </Table>
                    <div className="mb-4">
                      <AddContentBorderedButton
                        onClick={() => setShowMoodBoardModal(true)}
                        label="Create Mood Board"
                      />
                    </div>
                    <div
                      className={`w-${
                        isMobile ? 100 : 50
                      } mb-4 d-flex justify-content-between align-items-center`}
                    >
                      <Search searchString={search} setSearchString={setSearch} />
                      <div className="ms-2">
                        <TotalResult count={ideationBoard.mood_boards.length} />
                      </div>
                    </div>
                    {ideationBoard.mood_boards && isArray(ideationBoard.mood_boards) && (
                      <Row xs={1} md={3}>
                        {ideationBoard.mood_boards.map((el) => (
                          <Col key={`mood-board-${el.id}`} className="mb-4">
                            <BorderedButton
                              style={{
                                paddingBottom: "1.75rem",
                                paddingTop: "1.75rem",
                                borderRadius: "0.75rem",
                                fontWeight: "bold"
                              }}
                              onClick={() =>
                                navigate(
                                  `/my-activity/ideation-board/${ideationBoard.id}/mood-board/${el.id}`
                                )
                              }
                            >
                              {el.name}
                            </BorderedButton>
                          </Col>
                        ))}
                      </Row>
                    )}
                    <AddMoodBoardModal
                      show={showMoodBoardModal}
                      onHide={() => setShowMoodBoardModal(false)}
                      onSuccess={handleGetIdeationBoard}
                      ideationBoardId={id}
                    />
                  </>
                )}
              </LoadingContainer>
            )}
          </LockedContent>
        </LoadingContainer>
      </div>
      <ConfirmDeletionModal
        show={showDeletion}
        onCancel={() => setShowDeletion(false)}
        onConfirm={handleDeleteIdeationBoard}
        onHide={() => setShowDeletion(false)}
        type="ideation board"
      />
      <AddIdeationBoardModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        ideationBoard={ideationBoard}
      />
    </BaseContainer>
  );
}

const mapStateToProps = (state) => ({ ideationBoards: state.contentReducer.ideationBoards });

export default connect(mapStateToProps)(memo(IdeationBoard));

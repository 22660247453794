import React from "react";
import ContentImages from "../../screens/UserContent/Images";
import ContentVideos from "../../screens/UserContent/Videos";
import ContentThesis from "../../screens/UserContent/Thesis";
import ContentPortfolio from "../../screens/UserContent/Portfolios";
import ContentProjects from "../../screens/UserContent/Projects";
import { SIDEBAR_ICONS } from "../../constants/icons";
import BusinessDetails from "../../screens/ProfileDetails/Studio/BusinessDetails";
import ROUTE_CONSTANTS from "../../constants/routes";

const {
  ROUTE_PREFIXES: { MY_PROFILE }
} = ROUTE_CONSTANTS;

const STUDIO_PROFILE_ROUTES = [
  {
    label: "Business Details",
    component: <BusinessDetails />,
    path: "business-details",
    menuPath: `/${MY_PROFILE}/business-details`,
    icon: SIDEBAR_ICONS.PERSONAL_DETAILS_SIDEBAR_ICON
  },
  {
    label: "Portfolios",
    component: <ContentPortfolio />,
    path: "portfolios",
    menuPath: `/${MY_PROFILE}/portfolios`,
    icon: SIDEBAR_ICONS.PORTFOLIO_SIDEBAR_ICON
  },
  {
    label: "Projects",
    component: <ContentProjects />,
    path: "projects",
    menuPath: `/${MY_PROFILE}/projects`,
    icon: SIDEBAR_ICONS.PROJECT_SIDEBAR_ICON
  },
  {
    label: "Thesis/Dissertation",
    component: <ContentThesis />,
    path: "thesis",
    menuPath: `/${MY_PROFILE}/thesis`,
    icon: SIDEBAR_ICONS.THESIS_SIDEBAR_ICON
  },
  {
    label: "Images",
    component: <ContentImages />,
    path: "images",
    menuPath: `/${MY_PROFILE}/images`,
    icon: SIDEBAR_ICONS.IMAGE_SIDEBAR_ICON
  },
  {
    label: "Videos",
    component: <ContentVideos />,
    path: "videos",
    menuPath: `/${MY_PROFILE}/videos`,
    icon: SIDEBAR_ICONS.VIDEO_SIDEBAR_ICON
  },
  { defaultRoute: `/${MY_PROFILE}/business-details/`, path: "my-profile" }
];

export default STUDIO_PROFILE_ROUTES;

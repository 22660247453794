import React, { createContext, useContext, useState } from "react";
import { isDesktop } from "react-device-detect";

const SidebarContext = createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

export function SidebarProvider({ children }) {
  const [show, setShow] = useState(isDesktop);

  const toggleSideBar = (value = undefined) => setShow(value || !show);

  const contextValue = {
    showSidebar: show,
    toggleSideBar
  };

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
}

import React from "react";
import BaseMenu from "../../BaseMenu";
import STUDENT_ACTIVITY_ROUTES from "../../../../routes/MyActivity/Student";
import { useNavigationInfo } from "../../../../hooks";
import getStudentProfileRoutes from "../../../../routes/MyProfile/Student";

export default function StudentMenu() {
  const { isActivity } = useNavigationInfo();
  const STUDENT_PROFILE_ROUTES = getStudentProfileRoutes();

  return <BaseMenu options={isActivity ? STUDENT_ACTIVITY_ROUTES : STUDENT_PROFILE_ROUTES} />;
}

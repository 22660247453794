import { call, put } from "@redux-saga/core/effects";
import ACTION_TYPES from "constants/action-types";
import { getFilters, getCategories as handleGetCategories } from "./services";

export function* getProductFilters() {
  try {
    const result = yield call(getFilters);
    yield put({ type: ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_SUCCESS, result });
  } catch (error) {
    console.error(error);
    yield put({ type: ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_FAILURE, error });
  }
}

export function* getCategories() {
  try {
    const result = yield call(handleGetCategories);
    yield put({ type: ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_SUCCESS, result });
  } catch (error) {
    console.error(error);
    yield put({ type: ACTION_TYPES.CONTENT.PRODUCTS.CATEGORIES.GET_CATEGORIES_FAILURE, error });
  }
}

export function* getPopular() {
  try {
    const result = yield call(getFilters);
    yield put({ type: ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_SUCCESS, result });
  } catch (error) {
    console.error(error);
    yield put({ type: ACTION_TYPES.CONTENT.PRODUCTS.FILTERS.GET_FILTERS_FAILURE, error });
  }
}

import ContentFactory from "apiClients/ContentFactory";
import ProductCard from "components/Cards/Product";
import LoadingContainer from "components/Containers/Loading";
import { useSearchParams } from "hooks/searchParams";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProductsContainer from "./Container";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { withMobileOnlySidebar } from "components/Containers/Sidebar";

function ProductList({ initialFilters = {} }) {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = ContentFactory.createRequest("product");
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const page_size = 15;
  const isLoadingFilters = useSelector((state) => state.productsReducer.isLoadingFilters);

  const fetchDataAtIndex = (index) =>
    fetchData(
      (result) => {
        var newData = [...listings];
        newData[index - 1] = result.result[0];
        setListings(newData);
      },
      index,
      1
    );

  const onBookmarked = (index, removed) => {
    if (removed) {
      var newData = [...listings];
      newData[index - 1].is_favourited = null;
      newData[index - 1].saves = newData[index - 1].saves - 1;
      setListings(newData);
    } else {
      fetchDataAtIndex(index);
    }
  };

  const onIdeationBoarded = (index, removed) => {
    if (removed) {
      var newData = [...listings];
      newData[index - 1].obj.ideation_board_id = null;
      newData[index - 1].obj.mood_board_id = null;
      newData[index - 1].obj.mood_board_link_id = null;
      setListings(newData);
    } else {
      fetchDataAtIndex(index);
    }
  };

  const { searchParamsAsObject, queryString } = useSearchParams();

  const fetchData = (onSuccess, fetchPage, pageSize) =>
    client
      .list({
        page: fetchPage || nextPage || page,
        page_size: pageSize || page_size,
        ...initialFilters,
        ...searchParamsAsObject
      })
      .then(onSuccess)
      .catch((error) => console.error("Error fetching search results:", error));

  const next = () =>
    fetchData((result) => {
      setPage(nextPage);
      setHasMore(result.has_next);
      setNextPage(result.next_page);
      setListings((prevResults) => [...prevResults, ...result.result]);
    });

  useEffect(() => {
    if (loading === false) {
      setLoading(true);
    }
    fetchData(
      (result) => {
        setPage(nextPage);
        setHasMore(result.has_next);
        setNextPage(result.next_page);
        setListings(result.result);
      },
      1,
      page_size
    ).then(() => setLoading(false));
  }, [queryString]);

  return (
    <div className="w-100">
      <ProductsContainer showBrandsWithProduct>
        <LoadingContainer loading={loading || isLoadingFilters}>
          <h6 className="py-4 mb-0"> Select Product</h6>
          <InfiniteScroll
            dataLength={listings}
            next={next}
            hasMore={hasMore}
            loader={<div>Loading</div>}
          >
            <Row sm={5} xs={2}>
              {listings.map((el, index) => (
                <Col key={`${el}.id-${index}`} className="mb-4">
                  <ProductCard
                    obj={el}
                    onBookmarked={(removed) => onBookmarked(index + 1, removed)}
                    onAddedToIdeationBoard={(removed) => onIdeationBoarded(index + 1, removed)}
                  />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </LoadingContainer>
      </ProductsContainer>
    </div>
  );
}

export default withMobileOnlySidebar(ProductList);

import React from "react";
import BaseMenu from "../../BaseMenu";
import { useNavigationInfo } from "../../../../hooks";
import STUDIO_PROFILE_ROUTES from "../../../../routes/MyProfile/Studio";
import STUDIO_ACTIVITY_ROUTES from "../../../../routes/MyActivity/Studio";

export default function StudioMenu() {
  const { isActivity } = useNavigationInfo();

  return <BaseMenu options={isActivity ? STUDIO_ACTIVITY_ROUTES : STUDIO_PROFILE_ROUTES} />;
}

import React, { useEffect, useState } from "react";
import ContentHeader from "../../components/Base/ContentHeader";
import BaseContainer from "../../components/Containers/BaseContainer";
import LoadingContainer from "../../components/Containers/Loading";
import ContentFactory from "../../apiClients/ContentFactory";
import Table from "../../components/Table";
import TableColumnsFactory from "../../factory/TableColumns";
import TotalResult from "../../components/TotalResult";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import JobDetails from "../../components/Job/Details";
import Back from "../../components/Buttons/Back";
import ReviewApplication from "../../components/Job/ReviewApplication";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import DateParser from "date";
import { getApplicationStatusColors } from "constants/colors";

export default function AppliedJobs({ isReview, isJobDetails }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = ContentFactory.createRequest("job-application");
  const columnsFactory = new TableColumnsFactory();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.authReducer.user);

  const getData = async () => client.list({ user: user.id }).then(setData);

  useEffect(() => {
    getData().then(() => setLoading(false));
  }, []);

  const onReview = (id) => navigate(`${id}/review`);
  const onJobDescription = (id) => navigate(`${id}/job-description`);

  return (
    <BaseContainer className="py-4">
      {isJobDetails ? (
        <div className={`px-${isMobile ? 0 : 3}`}>
          {!isMobile && (
            <div className="pb-3 pt-2">
              <Back />
            </div>
          )}
          <JobDetails id={id} />
        </div>
      ) : isReview ? (
        <div className={`px-${isMobile ? 0 : 3}`}>
          <div className="pb-3 pt-2">
            <Back />
          </div>

          <ReviewApplication id={id} />
        </div>
      ) : (
        <>
          <ContentHeader
            title="Applied Jobs"
            subTitle="View all your applied and saved jobs here."
          />
          <LoadingContainer loading={loading}>
            <div>
              <Row>
                <Col className="d-flex justify-content-end">
                  <TotalResult searchResults={data} />
                </Col>
              </Row>
              {isMobile ? (
                data.map((el) => (
                  <Row key={el.id} className="pt-2 mt-3 shadow mb-0">
                    <Col>
                      <div className="p-2">
                        <h4>{el.job_details.user.name}</h4>
                        <h6 className="text-secondary">{el.job_details.position}</h6>
                        <h6 className="text-secondary">{el.job_details.location}</h6>
                        <p className="mt-3 mb-1">
                          Applied on {`${DateParser.formatDateToDDMMMYYYY(new Date(el.created))}`}
                        </p>
                        <div
                          style={{
                            color: getApplicationStatusColors(el.status)
                          }}
                        >
                          {el.status}
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between mt-3">
                          <p className="fw-bold cursor-pointer" onClick={() => onReview(el.id)}>
                            Review Application
                          </p>
                          <p
                            className="fw-bold cursor-pointer"
                            onClick={() => onJobDescription(el.job_details.slug)}
                          >
                            Job Description
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))
              ) : (
                <Table
                  className="mt-4"
                  data={data}
                  columns={columnsFactory.createColumnsForAppliedJob(onReview, onJobDescription)}
                />
              )}
            </div>
          </LoadingContainer>
        </>
      )}
    </BaseContainer>
  );
}

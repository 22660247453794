import isDevelopment from "../config";

const CSV_TEMPLATE_IDS = {
  BRANCH_DETAILS: isDevelopment
    ? "EVrNagLEMkQcrhdSsagUPtkA1O2fZ9"
    : "EVrNagLEMkQcrhdSsagUPtkA1O2fZ9",
  DISTRIBUTION_NETWORK: isDevelopment
    ? "ln7DjFKKVJN5JOEKoUQpp93GucwEOB"
    : "ln7DjFKKVJN5JOEKoUQpp93GucwEOB"
};

export default CSV_TEMPLATE_IDS;

import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";

function Heading({ children }) {
  return <h6 className={`mt-${isMobile ? 5 : 0} text-white fw-bold`}>{children}</h6>;
}

function FooterLink({ children, to }) {
  return (
    <div>
      <Link to={to} className="text-white text-decoration-none">
        {children}
      </Link>
    </div>
  );
}

function FooterLinkContainer({ children }) {
  return <div className="mt-4">{children}</div>;
}

function FooterColumn({ heading, links, gap = 3, direction = "vertical" }) {
  return (
    <Col>
      <Heading>{heading}</Heading>
      <FooterLinkContainer>
        <Stack direction={direction} gap={gap}>
          {links.map((el, index) => (
            <FooterLink key={index}>{el.label}</FooterLink>
          ))}
        </Stack>
      </FooterLinkContainer>
    </Col>
  );
}

const columnsData = [
  { heading: "ORGANIZATION", links: [{ label: "About Us" }, { label: "Join Us" }] },
  {
    heading: "ASSISTANCE",
    links: [
      { label: "Get Featured" },
      { label: "Subscription Plans" },
      { label: "Help" },
      { label: "FAQs", to: "/faqs" },
      { label: "Report Inquiry" }
    ]
  },
  {
    heading: "LEGAL",
    links: [{ label: "Privacy Policy" }, { label: "Terms & Conditions" }, { label: "Copyright" }]
  },
  {
    heading: "SOCIAL",
    links: [
      { label: <FaFacebookF /> },
      { label: <FaInstagram /> },
      { label: <FaLinkedinIn /> },
      { label: <FaTwitter /> },
      { label: <FaYoutube /> }
    ],
    direction: "horizontal"
  },
  {
    heading: "CONTACT",
    links: [
      {
        label: (
          <Stack direction="horizontal" gap={2}>
            <FiPhone />
            <p className="mb-0">91 99999 99999</p>
          </Stack>
        )
      },
      {
        label: (
          <Stack direction="horizontal" gap={2}>
            <AiOutlineMail />
            <p className="mb-0">materiallibrary@gmail.com</p>
          </Stack>
        )
      }
    ]
  }
];

export default function Footer() {
  return (
    <div style={{ backgroundColor: "#1F1F1F" }} className="mt-4">
      <Container className={`px-${isMobile ? 3 : 5} pt-${isMobile ? 0 : 5} pb-5`}>
        <Row xs={2} sm={5}>
          {columnsData.map((column, index) => (
            <FooterColumn
              key={index}
              heading={column.heading}
              links={column.links}
              direction={column.direction}
              gap={column.gap}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
}

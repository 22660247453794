import React, { useEffect, useState } from "react";
import BaseContainer from "../../../components/Containers/BaseContainer";
import { FormProvider, useForm } from "react-hook-form";
import AddBlogForm from "../../../forms/Content/AddBlog";
import { useParams } from "react-router-dom";
import ContentFactory from "../../../apiClients/ContentFactory";
import { isObject, isString, isUndefined } from "lodash";
import LoadingContainer from "../../../components/Containers/Loading";
import ContentHeader from "components/Base/ContentHeader";
import { isMobile } from "react-device-detect";

export default function Add() {
  const methods = useForm({ reValidateMode: "onChange" });
  const { setValue } = methods;
  const { id } = useParams();
  const [loading, setLoading] = useState(!isUndefined(id));

  useEffect(() => {
    if (id) {
      ContentFactory.createRequest("blog")
        .get(id)
        .then((result) => {
          if (isObject(result)) {
            ["title", "author", "content"].forEach(
              (el) => isString(result[el]) && setValue(el, result[el])
            );
            setValue("thumbnail", [result.thumbnail]);
            setValue(
              "keywords",
              result.keywords.map((el) => ({ tag: el }))
            );
            if (result.category) {
              setValue("category", { value: result.category, label: result.category });
            }
          }
        })
        .then(() => setLoading(false));
    }
  }, []);

  return (
    <BaseContainer className="py-4">
      <LoadingContainer loading={loading}>
        <ContentHeader
          title="Create New Blog"
          subTitle="Create your blog here. Add images, videos and your content relating only to the topic
            you choose"
        />
        <div className={`w-${isMobile ? 100 : 75}`}>
          <FormProvider {...methods}>
            <AddBlogForm id={id} />
          </FormProvider>
        </div>
      </LoadingContainer>
    </BaseContainer>
  );
}

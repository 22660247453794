import React, { useEffect } from "react";
import BaseModal from "../Base";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import ConnectForm from "../../../forms/Connect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ConnectModal({ show, onHide, user }) {
  const methods = useForm({ reValidateMode: "onChange" });
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  useEffect(() => {
    if (!show) {
      methods.reset();
    }
    return () => {
      methods.reset();
    };
  }, [show]);

  function getTitle() {
    if (user.is_student) {
      return "Connect With Student";
    }
    if (user.is_studio) {
      return "Connect With Studio";
    }
    if (user.is_brand) {
      return "Connect With Brand";
    }
  }
  return (
    <BaseModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>{getTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoggedIn ? (
          <FormProvider {...methods}>
            <ConnectForm user={user} />
          </FormProvider>
        ) : (
          <div className="text-center pb-4">
            <h6 className="text-center">
              Please <Link to="/login">log in</Link> first to connect
            </h6>
          </div>
        )}
      </Modal.Body>
    </BaseModal>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import Add from "../../screens/UserContent/Blogs/Add";
import { SIDEBAR_ICONS } from "../../constants/icons";
import PostedBlogs from "screens/UserContent/Blogs/Posted";
import DraftedBlogs from "screens/UserContent/Blogs/Drafted";
import UnderReviewBlogs from "screens/UserContent/Blogs/UnderReview";

const POSTED_BLOGS_ROUTES = (
  <Routes>
    <Route path="" element={<PostedBlogs />} />
    <Route path="/create-new-blog" element={<Add />} />
  </Routes>
);

const DRAFT_BLOGS_ROUTES = (
  <Routes>
    <Route path="" element={<DraftedBlogs />} />
    <Route path="/update/:id" element={<Add />} />
  </Routes>
);

export default {
  label: "My Blogs",
  path: "my-blogs",
  subMenu: [
    {
      label: "Posted",
      path: "/my-blogs/posted/*",
      menuPath: "/my-activity/my-blogs/posted",
      component: POSTED_BLOGS_ROUTES,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Drafted",
      path: "/my-blogs/drafted/*",
      menuPath: "/my-activity/my-blogs/drafted",
      component: DRAFT_BLOGS_ROUTES,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    },
    {
      label: "Under Review",
      path: "/my-blogs/under-review",
      menuPath: "/my-activity/my-blogs/under-review",
      component: <UnderReviewBlogs />,
      icon: SIDEBAR_ICONS.DOT_SIDEBAR_ICON
    }
  ],
  icon: SIDEBAR_ICONS.MY_BLOGS_SIDEBAR_ICON,
  notFoundRoute: "/my-activity/my-blogs/posted"
};

import React from "react";
import ContentImages from "../../screens/UserContent/Images";
import ContentVideos from "../../screens/UserContent/Videos";
import ContentThesis from "../../screens/UserContent/Thesis";
import ContentPortfolio from "../../screens/UserContent/Portfolios";
import JobPreferences from "../../screens/ProfileDetails/Student/JobPreferences";
import PersonalDetails from "../../screens/ProfileDetails/Student/PersonalDetails";
import ContentProjects from "../../screens/UserContent/Projects";
import Resume from "../../screens/UserContent/Resume";
import { SIDEBAR_ICONS } from "../../constants/icons";
import { Route, Routes } from "react-router-dom";
import AddResume from "../../screens/ProfileDetails/Student/AddResume";
import Profile from "../../screens/Profile";
import ROUTE_CONSTANTS from "../../constants/routes";
import { useSelector } from "react-redux";

const {
  ROUTE_PREFIXES: { MY_PROFILE }
} = ROUTE_CONSTANTS;

const ADD_RESUME_ROUTES = (
  <Routes>
    <Route path="" element={<AddResume />} />
    <Route path="/preview" element={<Profile preview />} />
  </Routes>
);

const RESUME_ROUTES = (
  <Routes>
    <Route path="" element={<Resume />} />
    <Route path="/add/*" element={ADD_RESUME_ROUTES} />
    <Route path="/:id/edit" element={<AddResume />} />
  </Routes>
);

var STUDENT_PROFILE_ROUTES = [
  {
    label: "Personal Details",
    component: <PersonalDetails />,
    path: "personal-details",
    menuPath: `/${MY_PROFILE}/personal-details`,
    icon: SIDEBAR_ICONS.PERSONAL_DETAILS_SIDEBAR_ICON
  },
  {
    label: "Resume",
    component: RESUME_ROUTES,
    path: "resume/*",
    menuPath: "resume",
    icon: SIDEBAR_ICONS.RESUME_SIDEBAR_ICON
  },
  {
    label: "Job Preferences",
    component: <JobPreferences />,
    path: "job-preferences",
    menuPath: `/${MY_PROFILE}/job-preferences`,
    icon: SIDEBAR_ICONS.JOB_PREFERENCES_SIDEBAR_ICON
  },
  {
    label: "Portfolios",
    component: <ContentPortfolio />,
    path: "portfolios",
    menuPath: `/${MY_PROFILE}/portfolios`,
    icon: SIDEBAR_ICONS.PORTFOLIO_SIDEBAR_ICON
  },
  {
    label: "Projects",
    component: <ContentProjects />,
    path: "projects",
    menuPath: `/${MY_PROFILE}/projects`,
    icon: SIDEBAR_ICONS.PROJECT_SIDEBAR_ICON
  },
  {
    label: "Thesis/Dissertation",
    component: <ContentThesis />,
    path: "thesis",
    menuPath: `/${MY_PROFILE}/thesis`,
    icon: SIDEBAR_ICONS.THESIS_SIDEBAR_ICON
  },
  {
    label: "Images",
    component: <ContentImages />,
    path: "images",
    menuPath: `/${MY_PROFILE}/images`,
    icon: SIDEBAR_ICONS.IMAGE_SIDEBAR_ICON
  },
  {
    label: "Videos",
    component: <ContentVideos />,
    path: "videos",
    menuPath: `/${MY_PROFILE}/videos`,
    icon: SIDEBAR_ICONS.VIDEO_SIDEBAR_ICON
  },
  { defaultRoute: `/${MY_PROFILE}/personal-details/`, path: "my-profile" }
];

function getStudentProfileRoutes() {
  const user = useSelector((state) => state.authReducer.user);

  if (user && user.allow_thesis === false) {
    var routes = [];
    STUDENT_PROFILE_ROUTES.forEach((el) => {
      if (el.label !== "Thesis/Dissertation") {
        routes.push(el);
      }
    });
    return routes;
  }
  return STUDENT_PROFILE_ROUTES;
}

export default getStudentProfileRoutes;

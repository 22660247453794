import React, { useState } from "react";
import ProfileDetailsContainer from "../../../../components/Containers/ProfileDetails";
import { useForm } from "react-hook-form";
import SearchTable from "../../../../components/Base/SearchTable";
import { BrandDistributionNetworksAPI } from "../../../../apiClients/Profile/Brand/ProfileDetails";
import LockedContent from "../../../../components/Containers/LockedContent";
import MobileDistributionNetwork from "components/Cards/MobileDistributionNetwork";
import ContentHeader from "components/Base/ContentHeader";

export default function DistributionNetworks() {
  const client = new BrandDistributionNetworksAPI();
  const [loading] = useState(false);

  const methods = useForm({
    reValidateMode: "onChange"
  });

  return (
    <ProfileDetailsContainer loading={loading} methods={methods} fullWidth>
      <ContentHeader
        title="Distribution Networks"
        subTitle="All your saved branch details are shown here"
      />
      <LockedContent>
        <div className="mt-3">
          <SearchTable
            client={client}
            type="distribution-network"
            MobileCard={MobileDistributionNetwork}
          />
        </div>
      </LockedContent>
    </ProfileDetailsContainer>
  );
}

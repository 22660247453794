import Api from "./api";

class DropdownBase {
  constructor(type) {
    this.endpoint = "dropdowns";
    this.type = type;
  }

  async list(filters) {
    var params = {};

    if (filters) {
      params = { ...params, ...filters };
    }

    const response = await Api.get(`/${this.endpoint}/${this.type}/`, params, {});
    return response.data;
  }
}

class ImageCategories extends DropdownBase {
  constructor() {
    super("image-categories");
  }
}

class VideoCategories extends DropdownBase {
  constructor() {
    super("video-categories");
  }
}

class JobCategories extends DropdownBase {
  constructor() {
    super("job-categories");
  }
}

class JobPositions extends DropdownBase {
  constructor() {
    super("job-positions");
  }
}
class JobCoreCompetence extends DropdownBase {
  constructor() {
    super("job-core-competence");
  }
}

class ProductCategories extends DropdownBase {
  constructor() {
    super("product-categories");
  }
}
class DropdownFactory {
  static createRequest(type) {
    switch (type) {
      case "image-categories":
        return new ImageCategories();
      case "video-categories":
        return new VideoCategories();
      case "job-categories":
        return new JobCategories();
      case "job-positions":
        return new JobPositions();
      case "job-core-competence":
        return new JobCoreCompetence();
      case "product-categories":
        return new ProductCategories();
      default:
        throw new Error(`Unsupported request type: ${type}`);
    }
  }
}

export default DropdownFactory;

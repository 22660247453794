import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Line } from "rc-progress";
import ContentFactory from "../../../apiClients/ContentFactory";
import { toast } from "react-toastify";
import COLORS from "../../../constants/colors";
import { useAuth, useNavigationInfo } from "../../../hooks";
import MoodBoardContentFactory from "../../../apiClients/MoodBoardContentFactory";
import { useParams } from "react-router-dom";
import {
  BrandBranchDetailAPI,
  BrandDistributionNetworksAPI
} from "../../../apiClients/Profile/Brand/ProfileDetails";
import { isArray, isEmpty, isObject } from "lodash";
import FavouriteFactory from "apiClients/FavouriteFactory";
import { isMobile } from "react-device-detect";

const ConfirmDeletionWithProgressModal = ({
  show,
  onHide,
  type,
  selectedData,
  onSuccessDelete,
  useSlug
}) => {
  const [deleteProgress, setDeleteProgress] = useState(0);
  const { isMoodBoard, isFavourites } = useNavigationInfo();
  const { getUserInfo } = useAuth();
  const params = useParams();

  useEffect(() => {
    if (!show) {
      setDeleteProgress(0);
    }
  }, [show]);

  const handleDelete = async () => {
    try {
      const totalItems =
        isArray(selectedData) && !isEmpty(selectedData)
          ? selectedData.length
          : isObject(show)
          ? 1
          : 0;
      let completedItems = 0;

      for (let i = 0; i < totalItems; i++) {
        try {
          if (isMoodBoard) {
            const { id, moodBoardId } = params;
            await MoodBoardContentFactory.createRequest(type, id, moodBoardId).remove(
              selectedData[i]
            );
          } else if (type === "branch-detail") {
            await new BrandBranchDetailAPI().delete(selectedData[i].id);
          } else if (type === "distribution-network") {
            await new BrandDistributionNetworksAPI().delete(selectedData[i].id);
          } else {
            const lookupKey = useSlug ? "slug" : "id";
            const id =
              isObject(show) && show[lookupKey]
                ? show[lookupKey]
                : isObject(selectedData[i])
                ? selectedData[i][lookupKey]
                : selectedData[i];

            const client = isFavourites
              ? FavouriteFactory.createRequest(type)
              : ContentFactory.createRequest(type);

            await client.remove(id);
          }

          completedItems++;
          const progress = (completedItems / totalItems) * 100;
          setDeleteProgress(progress);
          getUserInfo();
        } catch (error) {
          console.error(`Error deleting item ${i + 1}:`, error);
        }
      }
      toast.success("Items deleted successfully");
      onSuccessDelete();
    } catch (error) {
      console.error("Error deleting items:", error);
    }
  };

  return (
    <Modal show={isObject(show) || show === true} onHide={onHide} centered size="lg">
      <Modal.Body>
        {deleteProgress === 0 ? (
          <>
            <Modal.Title className="text-center mb-4">
              Are you sure you want to delete the selected{" "}
              <span className="text-lowercase">
                {type.replace("-", " ")}
                {selectedData.length > 1 ? "s" : ""}?
              </span>
            </Modal.Title>
            <p className={`text-center w-${isMobile ? 75 : 50} mx-auto`}>
              Please note that it will take some time to process your request.
            </p>
            <div className="d-flex justify-content-evenly mt-4">
              <Button
                variant="outline-primary"
                style={{ width: "45%" }}
                className="text-uppercase"
                onClick={onHide}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ width: "45%" }}
                className="text-uppercase"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          </>
        ) : (
          <div className="text-center">
            <Line percent={deleteProgress} strokeWidth={4} strokeColor={COLORS.PRIMARY_ORANGE} />
            <p>{`Deleting... ${deleteProgress}%`}</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeletionWithProgressModal;
